import {useEffect, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import * as actions from '../../store/Actions'
import {getRatePercentages} from '../../store/reducers/ratePercentage/ratePercentageActions'
import {DraggableModal} from '../UI/DraggableModal'
import {Loader} from '../UI/Loader'

export const ModalSelectCatToolBreak = ({title = "", additionalData = null}) => {
    const dispatch = useDispatch()
    const ratePercentages = useSelector(state => state.ratePercentage.ratePercentages)
    const isGettingRatePercentages = useSelector(state => state.ratePercentage.isGettingRatePercentages)
    const [selectedRatePercentage, setSelectedRatePercentage] = useState("")

    useEffect(() => {
        if (additionalData.rateId) {
            dispatch(getRatePercentages(additionalData.rateId))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onHideClick = (e) => {
        e.preventDefault()
        dispatch({type: actions.HIDE_MODAL})
    }

    const accept = (e) => {
        e.preventDefault()
        additionalData.selectCatToolBreak(selectedRatePercentage)
        dispatch({type: actions.HIDE_MODAL})
    }

    const Body = () => {
        return (
            <>
                {isGettingRatePercentages ?
                    <Loader/>
                    :
                    <div className="control">
                        {ratePercentages?.map((ratePercentage, i) => (
                            <div className="level mb-0" key={i}>
                                <div className="level-left">
                                    <label className="radio">
                                        <input type="radio" name="ratePercentage" checked={ratePercentage === selectedRatePercentage}
                                               onChange={() => setSelectedRatePercentage(ratePercentage)} />
                                        {ratePercentage.cat_tool_break_name}
                                        {ratePercentage.cat_tool_break_match_percent_min !== null ?
                                            " (" + ratePercentage.cat_tool_break_match_percent_min + "%" + (ratePercentage.cat_tool_break_match_percent_max ?
                                                " - " + ratePercentage.cat_tool_break_match_percent_max + "%)" : ")") : ""} {ratePercentage.percent}%
                                    </label>
                                </div>
                            </div>
                        ))}
                    </div>
                }
            </>
        )
    }

    const Foot = () => {
        return (
            <>
                <button className="button is-link"
                        disabled={!selectedRatePercentage}
                        onClick={(e) => accept(e)}>
                    <FormattedMessage id="general.accept" />
                </button>
                <button className="button"
                        onClick={(e) => onHideClick(e)}>
                    <FormattedMessage id="general.cancel" />
                </button>
            </>
        )
    }

    return (
        <DraggableModal title={title} body={Body()} foot={Foot()} onHideClick={onHideClick} />
    )
}
