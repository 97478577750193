import {axiosInstance} from '../../../hoc/Axios/Axios'
import * as actions from '../../Actions'
import * as toastTypes from '../../../consts/enums/toastTypes'
import {encodeParameter} from '../../../utils/encodeParameter'

export const getPrices = (rateId, sort = "translations.source_language", order = "asc", page) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_PRICES})
    dispatch({type: actions.GETTING_PRICES})
    try {
        const url = "/prices/" + rateId + (page ? "?sort=" + sort + "&order=" + order + "&page=" + page : "/all")
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_PRICES, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_PRICES})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.prices_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.PRICE_ERROR, payload: error.response})
    }
}

export const getPricesByLanguage = (language, rateId, sort = "translations.source_language", order = "asc", page = 1, isBySourceLanguages = true) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_PRICES})
    dispatch({type: actions.GETTING_PRICES})
    try {
        language = encodeParameter(language)
        const url = (isBySourceLanguages ? "/prices/by-source-language/" : "/prices/by-target-language/") + language +
            "/" + rateId + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_PRICES, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_PRICES})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: isBySourceLanguages ?
                        "error.prices_by_source_language_do_not_exist" : "error.prices_by_target_language_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.PRICE_ERROR, payload: error.response})
    }
}

export const getPricesByUnit = (unit, rateId, sort = "translations.source_language", order = "asc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_PRICES})
    dispatch({type: actions.GETTING_PRICES})
    try {
        unit = encodeParameter(unit)
        const url = "/prices/by-unit/" + unit + "/" + rateId + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_PRICES, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_PRICES})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.prices_by_unit_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.PRICE_ERROR, payload: error.response})
    }
}

export const getPricesByPrice = (min, max, rateId, sort = "translations.source_language", order = "asc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_PRICES})
    dispatch({type: actions.GETTING_PRICES})
    try {
        const url = "/prices/by-price/" + min + "/" + max + "/" + rateId + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_PRICES, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_PRICES})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.prices_by_price_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.PRICE_ERROR, payload: error.response})
    }
}

export const getAllPrices = (rateId) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_ALL_PRICES})
    dispatch({type: actions.GETTING_ALL_PRICES})
    try {
        const res = await axiosInstance.get("/prices/" + rateId + "/all")
        dispatch({type: actions.GET_ALL_PRICES, payload: res.data})
    } catch (error) {
        dispatch({type: actions.PRICE_ERROR, payload: error.response})
    }
}

export const storePrice = (price) => async (dispatch, getState) => {
    try {
        await axiosInstance.post("/prices/store", price)
        dispatch({type: actions.PRICE_CREATED, payload: {status: true}})
    } catch (error) {
        dispatch({type: actions.PRICE_ERROR, payload: error.response})
    }
}

export const updatePrice = (price) => async (dispatch, getState) => {
    try {
        await axiosInstance.put("/prices/" + price.id, price)
        dispatch({type: actions.PRICE_UPDATED, payload: {status: true}})
    } catch (error) {
        dispatch({type: actions.PRICE_ERROR, payload: error.response})
    }
}

export const deletePrice = (id) => async (dispatch, getState) => {
    try {
        await axiosInstance.delete("/prices/" + id)
        dispatch({type: actions.PRICE_DELETED, payload: {status: true}})
    } catch (error) {
        dispatch({type: actions.PRICE_ERROR, payload: error.response})
    }
}
