import {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import * as actions from '../store/Actions'
import {getCustomersAndCustomersIdcp} from '../store/reducers/customer/customerActions'
import {Title} from '../components/UI/Title'
import {Search} from '../components/UI/Search'

export const Home = () => {
    const navigate = useNavigate()
    const intl = useIntl()
    const dispatch = useDispatch()
    const customers = useSelector(state => state.customer.customersAndCustomersIdcp)
    const isGettingCustomers = useSelector(state => state.customer.isGettingCustomersAndCustomersIdcp)
    const searchedCustomer = useSelector(state => state.customer.searchedCustomer)
    const [search, setSearch] = useState("")
    const [value, setValue] = useState("")

    useEffect(() => {
        setValue(searchedCustomer)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        dispatch({type: actions.RESET_CUSTOMERS_AND_CUSTOMERS_IDCP})
        dispatch({type: actions.SET_SEARCHED_CUSTOMER, payload: value})
        const timeout = setTimeout(() => setSearch(value), 1000)
        return () => clearTimeout(timeout)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    useEffect(() => {
        if (search && search.trim() !== "") {
            dispatch(getCustomersAndCustomersIdcp(search))
            setSearch("")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search])

    const changeCustomer = (e, data) => {
        e.preventDefault()
        if (data.id) {
            navigate("/consult-price", {state: {customer: data}})
        } else {
            navigate("/consult-price", {state: {standard: true, customer: data}})
        }
    }

    const dataText = (data) => {
        if (data.type) {
            return data.name + " (" + data.type + ")"
        }
        return data.name
    }

    return (
        <>
            <Title title={intl.formatMessage({id: "title.home"})} />
            <div className='container'>
                <div className="level">
                    <div className='container'>
                        <Search size="normal"
                                placeholder={intl.formatMessage({id: "placeholder.search_customer"})}
                                value={value}
                                inputChange={(e) => setValue(e.target.value)}
                                autofocus={true}
                                maxLength={58}
                                content={customers}
                                dataClick={changeCustomer}
                                dataText={dataText}
                                isLoading={isGettingCustomers}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
