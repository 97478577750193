import {useEffect, useRef, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import * as actions from '../store/Actions'
import {deleteValue, getValues, getValuesByTag, getValuesByTagType, getValuesByUnit, getValuesByValue, updateValue}
    from '../store/reducers/value/valueActions'
import {getActiveStandardRates} from '../store/reducers/rate/rateActions'
import {getUnits} from '../store/reducers/unit/unitActions'
import {Datatable} from '../components/Datatable/Datatable'
import {Title} from '../components/UI/Title'
import {Dropdown} from '../components/UI/Dropdown'
import {Search} from '../components/UI/Search'
import {TableIconButtonEdit} from '../components/UI/TableIconButtonEdit'
import {TableIconButtonDelete} from '../components/UI/TableIconButtonDelete'
import {Pagination} from '../components/UI/Pagination'
import * as modalTypes from '../consts/enums/modalTypes'
import * as toastTypes from '../consts/enums/toastTypes'
import {PAGINATION_PAGE_ROWS, STANDARD_CURRENCY_CODE, TAG_TYPES} from '../consts/app'
import {createLocaleDateText} from '../utils/createLocaleDateText'

export const TagValues = () => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const values = useSelector(state => state.value.values)
    const isGettingValues = useSelector(state => state.value.isGettingValues)
    const pagination = useSelector(state => state.value.pagination)
    const hasValueBeenCreated = useSelector(state => state.value.valueCreated)
    const hasValueBeenUpdated = useSelector(state => state.value.valueUpdated)
    const hasValueBeenDeleted = useSelector(state => state.value.valueDeleted)
    const valueError = useSelector(state => state.value.error)
    const activeStandardRates = useSelector(state => state.rate.activeStandardRates)
    const isGettingActiveStandardRates = useSelector(state => state.rate.isGettingActiveStandardRates)
    const units = useSelector(state => state.unit.units)
    const isGettingUnits = useSelector(state => state.unit.isGettingUnits)
    const locale = useSelector(state => state.locale.locale)
    const [activeStandardRate, setActiveStandardRate] = useState(null)
    const [tagFilter, setTagFilter] = useState("all")
    const [nameFilter, setNameFilter] = useState("")
    const [typeFilter, setTypeFilter] = useState("")
    const [unitFilter, setUnitFilter] = useState("")
    const [minFilter, setMinFilter] = useState("")
    const [maxFilter, setMaxFilter] = useState("")
    const firstRender = useRef(true)
    const searchType = useRef({type: "all", search: {param1: "", param2: ""}})
    const sortType = useRef({sort: "tags.type", order: "asc"})

    useEffect(() => {
        dispatch(getActiveStandardRates())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (hasValueBeenCreated) {
            getData()
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.SUCCESS, message: "message.value_created"}})
            dispatch({type: actions.VALUE_CREATED, payload: {status: false}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasValueBeenCreated])

    useEffect(() => {
        if (hasValueBeenUpdated) {
            getData()
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.SUCCESS, message: "message.value_updated"}})
            dispatch({type: actions.VALUE_UPDATED, payload: {status: false}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasValueBeenUpdated])

    useEffect(() => {
        if (hasValueBeenDeleted) {
            getData()
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.SUCCESS, message: "message.value_deleted"}})
            dispatch({type: actions.VALUE_DELETED, payload: {status: false}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasValueBeenDeleted])

    useEffect(() => {
        if (valueError) {
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.RESET_VALUE_ERROR})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueError])

    useEffect(() => {
        if (!isGettingActiveStandardRates && !firstRender.current) {
            if (activeStandardRates.length > 0) {
                setActiveStandardRate(activeStandardRates.find(standardRate => standardRate.currency_code === STANDARD_CURRENCY_CODE) ?? activeStandardRates[0])
            }
        }
        firstRender.current = false
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGettingActiveStandardRates])

    useEffect(() => {
        if (activeStandardRate) {
            getData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStandardRate])

    const getData = () => {
        setTagFilter("all")
        searchType.current = {type: "all", search: {param1: "", param2: ""}}
        dispatch(getValues(activeStandardRate.id, sortType.current.sort, sortType.current.order, 1))
    }

    const resetStandardRate = (standardRate) => {
        setActiveStandardRate(standardRate)
        resetData()
    }

    const resetData = () => {
        setNameFilter("")
        setTypeFilter("")
        setUnitFilter("")
        setMinFilter("")
        setMaxFilter("")
    }

    const filterTagsByName = (e) => {
        e.preventDefault()
        searchType.current = {type: "name", search: {param1: nameFilter, param2: ""}}
        dispatch(getValuesByTag(nameFilter, activeStandardRate.id, sortType.current.sort, sortType.current.order, 1))
    }

    const filterTagsByType = (type) => {
        searchType.current = {type: "type", search: {param1: type, param2: ""}}
        dispatch(getValuesByTagType(type, activeStandardRate.id, sortType.current.sort, sortType.current.order, 1))
    }

    const filterTagsByUnit = (unit) => {
        searchType.current = {type: "unit", search: {param1: unit, param2: ""}}
        dispatch(getValuesByUnit(unit, activeStandardRate.id, sortType.current.sort, sortType.current.order, 1))
    }

    const filterTagsByValue = (e) => {
        e.preventDefault()
        if (parseFloat(minFilter) > parseFloat(maxFilter)) {
            dispatch({type: actions.SHOW_TOAST, payload: {type:toastTypes.ERROR, message: "error.min_greater_than_max"}})
        } else {
            searchType.current = {type: tagFilter, search: {param1: minFilter, param2: maxFilter}}
            dispatch(getValuesByValue(minFilter, maxFilter, tagFilter, activeStandardRate.id, sortType.current.sort, sortType.current.order, 1))
        }
    }

    const sortTags = (column, sortDirection) => {
        sortType.current = {sort: column.sortField, order: sortDirection}
        getValuesPerPage(1)
    }

    const getValuesPerPage = (page) => {
        if (searchType.current.type === "all") {
            dispatch(getValues(activeStandardRate.id, sortType.current.sort, sortType.current.order, page))
        } else if (searchType.current.type === "name") {
            dispatch(getValuesByTag(searchType.current.search.param1, activeStandardRate.id, sortType.current.sort, sortType.current.order, page))
        } else if (searchType.current.type === "type") {
            dispatch(getValuesByTagType(searchType.current.search.param1, activeStandardRate.id, sortType.current.sort, sortType.current.order, page))
        } else if (searchType.current.type === "unit") {
            dispatch(getValuesByUnit(searchType.current.search.param1, activeStandardRate.id, sortType.current.sort, sortType.current.order, page))
        } else {
            dispatch(getValuesByValue(searchType.current.search.param1, searchType.current.search.param2,
                searchType.current.type, activeStandardRate.id, sortType.current.sort, sortType.current.order, page))
        }
    }

    const editValue = (value) => {
        dispatch(updateValue(value))
        dispatch({type: actions.HIDE_MODAL})
        dispatch({type: actions.SHOW_MODAL, payload: {type:modalTypes.LOADING}})
    }

    const deleteAValue = (id) => {
        dispatch(deleteValue(id))
        dispatch({type: actions.HIDE_MODAL})
        dispatch({type: actions.SHOW_MODAL, payload: {type:modalTypes.LOADING}})
    }

    const showCreateValue = (e) => {
        e.preventDefault()
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                type: modalTypes.NEW_VALUE,
                title: intl.formatMessage({id: "message_title.new_value"}),
                additionalData: activeStandardRate.id
            }
        })
    }

    const showEditValue = (e, value) => {
        e.preventDefault()
        e.stopPropagation()
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                type: modalTypes.EDIT_VALUE,
                title: intl.formatMessage({id: "message_title.edit_value"}),
                additionalData: {
                    value: value,
                    editValue: editValue
                }
            }
        })
    }

    const showDeleteConfirmation = (e, id) => {
        e.preventDefault()
        e.stopPropagation()
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                buttons: [
                    {
                        label: intl.formatMessage({id: "general.delete"}),
                        classes: 'button is-link',
                        handler: () => {
                            deleteAValue(id)
                        }
                    }
                ]
            }
        })
    }

    const TagsTable = () => {
        const columns = [
            {
                name: intl.formatMessage({id: "tag.name"}),
                selector: row => row.tag_name,
                wrap: true,
                sortable: true,
                sortField: 'tags.name'
            },
            {
                name: intl.formatMessage({id: "tag.type"}),
                selector: row => intl.formatMessage({id: "tag_type." + row.tag_type}),
                wrap: true,
                sortable: true,
                sortField: 'tags.type'
            },
            {
                name: intl.formatMessage({id: "tag.unit"}),
                selector: row => row.unit_type,
                wrap: true,
                sortable: true,
                sortField: 'units.type'
            },
            {
                name: intl.formatMessage({id: "tag.value"}),
                selector: row => row.price !== null ? row.price :
                    row.percent === null ? intl.formatMessage({id: "datatable_tag.not_value"}) :
                        row.reference_tag ? row.percent + "%" + intl.formatMessage({id: "datatable_tag.reference"}) + row.reference_tag_name :
                            row.percent + "%" + intl.formatMessage({id: "datatable_tag.reference_base"}),
                wrap: true,
                sortable: true,
                sortField: 'values'
            },
            {
                name: intl.formatMessage({id: "tag.description"}),
                selector: row => row.description,
                wrap: true,
                sortable: true,
                sortField: 'values.description'
            },
            {
                selector: row => (
                    <>
                        <TableIconButtonEdit handler={(e) => showEditValue(e, row)} />
                        <TableIconButtonDelete handler={(e) => showDeleteConfirmation(e, row.id)} />
                    </>
                ),
                right: true,
                wrap: true,
                grow: 0.1
            }
        ]
        return (
            <Datatable
                columns={columns}
                data={activeStandardRate ? values : []}
                defaultSortAsc={sortType.current.order !== "asc"}
                onSort={sortTags}
                paginationComponent={() =>
                    <Pagination pagination={pagination} handler={getValuesPerPage}
                                additionalText={activeStandardRate ?
                                    intl.formatMessage({id: "datatable.of_standard_rate"}) +
                                    createLocaleDateText(locale.name, activeStandardRate.created_at.substring(0, 10)) +
                                    intl.formatMessage({id: "datatable.by_user"}) +
                                    activeStandardRate.user_username
                                    : null}
                    />}
                paginationPerPage={pagination?.per_page ?? PAGINATION_PAGE_ROWS}
                progressPending={isGettingValues || isGettingActiveStandardRates}
                sortServer
            />
        )
    }

    return (
        <>
            <Title title={intl.formatMessage({id: "title.tag_values"})} />
            <div className="container">
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <label className="label">
                                <FormattedMessage id="tag.standard_rate" />
                                <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                                <span>:</span>
                            </label>
                        </div>
                        <div className="level-item">
                            <Dropdown id="standardRateDropdown"
                                      text={activeStandardRate ? activeStandardRate.currency_name : "-----"}
                                      isLoading={isGettingActiveStandardRates}
                                      content={<>
                                          {activeStandardRates.length > 0 ?
                                              <Link to="#" className="dropdown-item"
                                                    onClick={() => resetStandardRate("")}>
                                                  -----
                                              </Link>
                                              :
                                              null
                                          }
                                          {activeStandardRates?.map((standardRate, i) => (
                                              <Link to="#" className="dropdown-item" key={i}
                                                    onClick={() => resetStandardRate(standardRate)}>
                                                  {standardRate.currency_name}
                                              </Link>
                                          ))}
                                      </>}
                            />
                        </div>
                    </div>
                </div>
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <button className="button is-link"
                                    disabled={!activeStandardRate}
                                    onClick={(e) => showCreateValue(e)}>
                                <FormattedMessage id="tag.new_value"/>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <label className="label">
                                <FormattedMessage id="general.filter_by" />:
                            </label>
                        </div>
                        <div className="level-item mr-0">
                            <Dropdown id="tagFilterDropdown"
                                      text={tagFilter ? intl.formatMessage({id: "tag." + tagFilter}) : ""}
                                      disabled={!activeStandardRate}
                                      content={<>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    resetData()
                                                    getData()
                                                }}>
                                              <FormattedMessage id={"tag.all"} />
                                          </Link>
                                          <hr className="dropdown-divider" />
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setTagFilter("name")
                                                    resetData()
                                                }}>
                                              <FormattedMessage id={"tag.name"} />
                                          </Link>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setTagFilter("type")
                                                    resetData()
                                                }}>
                                              <FormattedMessage id={"tag.type"} />
                                          </Link>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setTagFilter("unit")
                                                    resetData()
                                                    dispatch(getUnits())
                                                }}>
                                              <FormattedMessage id={"tag.unit"} />
                                          </Link>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setTagFilter("percent")
                                                    resetData()
                                                }}>
                                              <FormattedMessage id={"tag.percent"} />
                                          </Link>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setTagFilter("price")
                                                    resetData()
                                                }}>
                                              <FormattedMessage id={"tag.price"} />
                                          </Link>
                                      </>}
                            />
                        </div>
                        <div className="level-item">
                            <div className="field has-addons">
                                {tagFilter === "name" ?
                                    <Search size={"normal"}
                                            placeholder={intl.formatMessage({id: "placeholder.filter_by_name"})}
                                            value={nameFilter}
                                            inputChange={(e) => setNameFilter(e.target.value)}
                                            maxLength={50}
                                            isLoading={(isGettingValues && nameFilter.trim() !== "")}
                                            disabledButton={nameFilter.trim() === ""}
                                            buttonClick={(e) => filterTagsByName(e)}
                                            buttonText={intl.formatMessage({id: "general.search"})}
                                    />
                                    :
                                    tagFilter === "type" ?
                                        <Dropdown id="tagTypesDropdown"
                                                  text={typeFilter ? intl.formatMessage({id: "tag_type." + typeFilter}) : "-----"}
                                                  content={<>
                                                      {TAG_TYPES?.map((type, i) => (
                                                          <Link to="#" className="dropdown-item" key={i}
                                                                onClick={() => {
                                                                    setTypeFilter(type)
                                                                    filterTagsByType(type)
                                                                }}>
                                                              <FormattedMessage id={"tag_type." + type} />
                                                          </Link>
                                                      ))}
                                                  </>}
                                        />
                                        :
                                        tagFilter === "unit" ?
                                            <Dropdown id="tagUnitsDropdown"
                                                      text={unitFilter ? unitFilter : "-----"}
                                                      isLoading={isGettingUnits}
                                                      content={<>
                                                          {units?.map((unit, i) => (
                                                              <Link to="#" className="dropdown-item" key={i}
                                                                    onClick={() => {
                                                                        setUnitFilter(unit.type)
                                                                        filterTagsByUnit(unit.type)
                                                                    }}>
                                                                  {unit.type}
                                                              </Link>
                                                          ))}
                                                      </>}
                                            />
                                            :
                                            tagFilter === "percent" || tagFilter === "price" ?
                                                <>
                                                    <div className="control">
                                                        <input className="input"
                                                               type="number"
                                                               placeholder={intl.formatMessage({id: "placeholder.min"})}
                                                               value={minFilter}
                                                               onChange={(e) => setMinFilter(e.target.value)}
                                                               min={0}
                                                               max={9999999}
                                                               step={0.1}
                                                               onWheel={(e) => e.target.blur()}
                                                        />
                                                    </div>
                                                    <div className="control">
                                                        <button className="button is-static" tabIndex="-1">-</button>
                                                    </div>
                                                    <div className="control">
                                                        <input className="input"
                                                               type="number"
                                                               placeholder={intl.formatMessage({id: "placeholder.max"})}
                                                               value={maxFilter}
                                                               onChange={(e) => setMaxFilter(e.target.value)}
                                                               min={0}
                                                               max={9999999}
                                                               step={0.1}
                                                               onWheel={(e) => e.target.blur()}
                                                        />
                                                    </div>
                                                    <button className={`button is-link ${isGettingValues &&
                                                    minFilter.trim() !== "" && maxFilter.trim() !== "" ? 'is-loading' : ''}`}
                                                            disabled={minFilter.trim() === "" || maxFilter.trim() === ""}
                                                            onClick={(e) => filterTagsByValue(e)}>
                                                        <FormattedMessage id="general.search" />
                                                    </button>
                                                </>
                                                :
                                                null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <TagsTable />
            </div>
        </>
    )
}
