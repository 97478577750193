import * as actions from '../../Actions'

const initialState = {
    currencies: [],
    currenciesIdcp: [],
    isGettingCurrencies: false,
    isGettingCurrenciesIdcp: false,
    error: null
}

export default function reducer (state = initialState, action) {
    switch (action.type) {
        case actions.GET_CURRENCIES:
            return {
                ...state,
                currencies: action.payload.data,
                isGettingCurrencies: false
            }
        case actions.GET_CURRENCIES_IDCP:
            return {
                ...state,
                currenciesIdcp: action.payload.data,
                isGettingCurrenciesIdcp: false
            }
        case actions.GETTING_CURRENCIES:
            return {
                ...state,
                isGettingCurrencies: true
            }
        case actions.GETTING_CURRENCIES_IDCP:
            return {
                ...state,
                isGettingCurrenciesIdcp: true
            }
        case actions.CURRENCY_ERROR:
            return {
                ...state,
                error: action.payload,
                isGettingCurrencies: false,
                isGettingCurrenciesIdcp: false
            }
        case actions.RESET_CURRENCIES:
            return {
                ...state,
                currencies: []
            }
        case actions.RESET_CURRENCIES_IDCP:
            return {
                ...state,
                currenciesIdcp: []
            }
        case actions.RESET_CURRENCY_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
