import * as actions from '../../Actions'

const initialState = {
    values: [],
    allValues: [],
    isGettingValues: false,
    isGettingAllValues: false,
    pagination: null,
    valueCreated: false,
    valueUpdated: false,
    valueDeleted: false,
    error: null
}

export default function reducer (state = initialState, action) {
    switch (action.type) {
        case actions.GET_VALUES:
            return {
                ...state,
                values: action.payload.data,
                pagination: {
                    "current_page": action.payload.current_page ?? null,
                    "links": action.payload.links ?? [],
                    "per_page": action.payload.per_page ?? null,
                    "to": action.payload.to ?? null,
                    "total": action.payload.total ?? null
                },
                isGettingValues: false
            }
        case actions.GET_ALL_VALUES:
            return {
                ...state,
                allValues: action.payload.data,
                isGettingAllValues: false
            }
        case actions.GETTING_VALUES:
            return {
                ...state,
                isGettingValues: true
            }
        case actions.GETTING_ALL_VALUES:
            return {
                ...state,
                isGettingAllValues: true
            }
        case actions.VALUE_CREATED:
            return {
                ...state,
                valueCreated: action.payload.status
            }
        case actions.VALUE_UPDATED:
            return {
                ...state,
                valueUpdated: action.payload.status
            }
        case actions.VALUE_DELETED:
            return {
                ...state,
                valueDeleted: action.payload.status
            }
        case actions.VALUE_ERROR:
            return {
                ...state,
                error: action.payload,
                isGettingValues: false,
                isGettingAllValues: false
            }
        case actions.RESET_VALUES:
            return {
                ...state,
                values: [],
                pagination: null
            }
        case actions.RESET_ALL_VALUES:
            return {
                ...state,
                allValues: []
            }
        case actions.RESET_VALUE_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
