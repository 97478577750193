import {useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import * as actions from '../../store/Actions'
import * as modalTypes from '../../consts/enums/modalTypes'
import {DraggableModal} from '../UI/DraggableModal'

export const ModalSelectTagsExport = ({title = "", additionalData = null}) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const [allChecked, setAllChecked] = useState(false)
    const [checkboxes, setCheckboxes] = useState([])

    useEffect(() => {
        if (additionalData.values.length < 1) {
            showSelectBreaks([])
        } else {
            const checkboxes = []
            for (let i = 0; i < additionalData.values.length; i++) {
                checkboxes.push(false)
            }
            setCheckboxes([...checkboxes])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onHideClick = (e) => {
        e.preventDefault()
        dispatch({type: actions.HIDE_MODAL})
    }

    const showSelectBreaks = (values) => {
        dispatch({type: actions.HIDE_MODAL})
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                type: modalTypes.SELECT_BREAKS_EXPORT,
                title: intl.formatMessage({id: "message_title.select_cat_tool_breaks_to_export"}),
                additionalData: {
                    units: additionalData.units,
                    prices: additionalData.prices,
                    values: values,
                    ratePercentages: additionalData.ratePercentages,
                    export: additionalData.export
                }
            }
        })
    }

    const changeAllChecked = () => {
        const checks = [...checkboxes]
        for (let i = 0; i < checks.length; i++) {
            checks[i] = !allChecked
        }
        setCheckboxes([...checks])
        setAllChecked(!allChecked)
    }

    const changeChecked = (i) => {
        const checks = [...checkboxes]
        checks[i] = !checks[i]
        setCheckboxes([...checks])
        if (allChecked || checks.filter(check => check === false).length < 1) {
            setAllChecked(!allChecked)
        }
    }

    const accept = (e) => {
        e.preventDefault()
        const values = []
        for (let i = 0; i < additionalData.values.length; i++) {
            if (checkboxes[i]) {
                values.push(JSON.parse(JSON.stringify(additionalData.values[i])))
            }
        }
        showSelectBreaks(values)
    }

    const Body = () => {
        return (
            <>
                <div className="level">
                    <div className="level-left">
                        <label className="checkbox has-text-weight-bold">
                            <input type="checkbox" checked={allChecked}
                                   onChange={(e) => changeAllChecked()} />
                            <FormattedMessage id="standard_rate.select_all_tags" />
                        </label>
                    </div>
                </div>
                {additionalData.values?.map((value, i) => (
                    <div key={i} className="level mb-0">
                        <div className="level-left">
                            <label className="checkbox">
                                <input type="checkbox" checked={checkboxes[i] ?? false}
                                       onChange={() => changeChecked(i)} />
                                {value.tag_name} {value.unit_id ? "(" + value.unit_type + ")" : ""} - {intl.formatMessage({id: "tag_type." + value.tag_type})}
                            </label>
                        </div>
                    </div>
                ))}
            </>
        )
    }

    const Foot = () => {
        return (
            <>
                <button className="button is-link"
                        onClick={(e) => accept(e)}>
                    <FormattedMessage id="general.accept" />
                </button>
                <button className="button"
                        onClick={(e) => onHideClick(e)}>
                    <FormattedMessage id="general.cancel" />
                </button>
            </>
        )
    }

    return (
        <DraggableModal title={title} body={Body()} foot={Foot()} onHideClick={onHideClick} />
    )
}
