import * as actions from '../../Actions'

const initialState = {
    isAuthenticated: false,
    token: null,
    expiresIn: null,
    user: null,
    error: null
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.LOGIN:
            return {
                ...state,
                isAuthenticated: true,
                token: action.payload.access_token,
                expiresIn: action.payload.expires_in,
                user: action.payload.user
            }
        case actions.LOGOUT:
            return {
                ...state,
                isAuthenticated: false,
                expiresIn: null,
                user: null,
            }
        case actions.LOGIN_ERROR:
            return {
                ...state,
                error: action.payload,
            }
        case actions.RESET_LOGIN_ERROR:
            return {
                ...state,
                error: null,
            }
        default:
            return state
    }
}
