import {useEffect, useRef, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import {getNotifications, getNotificationsByDate, getNotificationsByType} from '../store/reducers/notification/notificationActions'
import {Datatable} from '../components/Datatable/Datatable'
import {Title} from '../components/UI/Title'
import {Dropdown} from '../components/UI/Dropdown'
import {Pagination} from '../components/UI/Pagination'
import * as notificationTypes from '../consts/enums/notificationTypes'
import {NOTIFICATION_TYPES, PAGINATION_PAGE_ROWS} from '../consts/app'
import {createLocaleDateText} from '../utils/createLocaleDateText'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCalendarMinus, faChartLine} from '@fortawesome/free-solid-svg-icons'

export const Notifications = () => {
    const navigate = useNavigate()
    const intl = useIntl()
    const dispatch = useDispatch()
    const notifications = useSelector(state => state.notification.notifications)
    const isGettingNotifications = useSelector(state => state.notification.isGettingNotifications)
    const pagination = useSelector(state => state.notification.pagination)
    const locale = useSelector(state => state.locale.locale)
    const [notificationFilter, setNotificationFilter] = useState("all")
    const [typeFilter, setTypeFilter] = useState("")
    const [dateFilter, setDateFilter] = useState("")
    const searchType = useRef({type: "all", search: ""})
    const sortType = useRef({sort: "notifications.created_at", order: "desc"})

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = () => {
        setNotificationFilter("all")
        searchType.current = {type: "all", search: ""}
        dispatch(getNotifications(sortType.current.sort, sortType.current.order, 1))
    }

    const resetData = () => {
        setTypeFilter("")
        setDateFilter("")
    }

    const filterNotificationByType = (type) => {
        searchType.current = {type: "type", search: type}
        dispatch(getNotificationsByType(type, sortType.current.sort, sortType.current.order, 1))
    }

    const filterNotificationByDate = (e) => {
        e.preventDefault()
        searchType.current = {type: "date", search: dateFilter}
        dispatch(getNotificationsByDate(dateFilter, sortType.current.sort, sortType.current.order, 1))
    }

    const sortNotifications = (column, sortDirection) => {
        sortType.current = {sort: column.sortField, order: sortDirection}
        getNotificationsPerPage(1)
    }

    const getNotificationsPerPage = (page) => {
        if (searchType.current.type === "all") {
            dispatch(getNotifications(sortType.current.sort, sortType.current.order, page))
        } else if (searchType.current.type === "type") {
            dispatch(getNotificationsByType(searchType.current.search, sortType.current.sort, sortType.current.order, page))
        } else {
            dispatch(getNotificationsByDate(searchType.current.search, sortType.current.sort, sortType.current.order, page))
        }
    }

    const goToCustomerRate = (notification, e) => {
        e.preventDefault()
        e.stopPropagation()
        navigate("/customer-rates/" + notification.customer_rate_id, {
            state: {
                customerRate: {
                    customer_id: notification.customer_rate_customer_id,
                    manufacturer_id: notification.customer_rate_manufacturer_id,
                    project_id: notification.customer_rate_project_id
                }
            }
        })
    }

    const NotificationsTable = () => {
        const columns = [
            {
                name: intl.formatMessage({id: "notification.notification"}),
                selector: row => (
                    <>
                        <span className="icon has-text-link">
                            <FontAwesomeIcon icon={row.type === notificationTypes.EXPIRED_EFFECTIVE_DATE ?
                                faCalendarMinus : faChartLine} />
                        </span>
                        <FormattedMessage id={"notifications." + (row.type === notificationTypes.EXPIRED_EFFECTIVE_DATE ?
                            "expired_effective_date" : "exchange_rate_fluctuation")} />
                        <label className="label">
                            {row.customer_rate_name}
                        </label>
                    </>
                ),
                wrap: true,
                sortable: true,
                sortField: 'notifications.type'
            },
            {
                name: intl.formatMessage({id: "notification.date"}),
                selector: row => createLocaleDateText(locale.name, row.created_at.substring(0, 10)),
                wrap: true,
                sortable: true,
                sortField: 'notifications.created_at',
                grow: 0.3
            }
        ]
        return (
            <Datatable
                columns={columns}
                customStyles={{rows: {style: {cursor: 'pointer'}}}}
                data={notifications}
                defaultSortAsc={sortType.current.order !== "asc"}
                onRowClicked={goToCustomerRate}
                onSort={sortNotifications}
                paginationComponent={() => <Pagination pagination={pagination} handler={getNotificationsPerPage} />}
                paginationPerPage={pagination?.per_page ?? PAGINATION_PAGE_ROWS}
                progressPending={isGettingNotifications}
                sortServer
            />
        )
    }

    return (
        <>
            <Title title={intl.formatMessage({id: "title.notifications"})} />
            <div className="container">
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <label className="label">
                                <FormattedMessage id="general.filter_by" />:
                            </label>
                        </div>
                        <div className="level-item mr-0">
                            <Dropdown id="notificationFilterDropdown"
                                      text={notificationFilter ? intl.formatMessage({id: "notification." + notificationFilter}) : ""}
                                      content={<>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    resetData()
                                                    getData()
                                                }}>
                                              <FormattedMessage id={"notification.all"} />
                                          </Link>
                                          <hr className="dropdown-divider" />
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setNotificationFilter("type")
                                                    resetData()
                                                }}>
                                              <FormattedMessage id={"notification.type"} />
                                          </Link>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setNotificationFilter("date")
                                                    resetData()
                                                }}>
                                              <FormattedMessage id={"notification.date"} />
                                          </Link>
                                      </>}
                            />
                        </div>
                        <div className="level-item">
                            <div className="field has-addons">
                                {notificationFilter === "type" ?
                                    <Dropdown id="notificationTypesDropdown"
                                              text={typeFilter ? intl.formatMessage({id: "notification_type." + typeFilter}) : "-----"}
                                              content={<>
                                                  {NOTIFICATION_TYPES?.map((type, i) => (
                                                      <Link to="#" className="dropdown-item" key={i}
                                                            onClick={() => {
                                                                setTypeFilter(type)
                                                                filterNotificationByType(type)
                                                            }}>
                                                          <FormattedMessage id={"notification_type." + type}/>
                                                      </Link>
                                                  ))}
                                              </>}
                                    />
                                    :
                                    notificationFilter === "date" ?
                                        <>
                                            <div className="control">
                                                <input className="input"
                                                       type="date"
                                                       value={dateFilter}
                                                       onChange={(e) => setDateFilter(e.target.value)}
                                                />
                                            </div>
                                            <button className={`button is-link ${isGettingNotifications &&
                                            dateFilter.trim() !== "" ? 'is-loading' : ''}`}
                                                    disabled={dateFilter.trim() === ""}
                                                    onClick={(e) => filterNotificationByDate(e)}>
                                                <FormattedMessage id="general.search" />
                                            </button>
                                        </>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <NotificationsTable />
            </div>
        </>
    )
}
