import axios from 'axios'
import * as actions from '../../store/Actions'
import * as errorCodes from '../../consts/enums/errorCodes'
import * as toastTypes from '../../consts/enums/toastTypes'
import {API_URL} from '../../consts/config'

export const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    }
});

export const interceptor = (store) => {
    axiosInstance.interceptors.request.use(
        (config) => {
            config.headers.Authorization = (store.getState().auth.token) ? `Bearer ${store.getState().auth.token}` : ''
            return config
        },
        (error) => {
            return Promise.reject(error)
        }
    );

    axiosInstance.interceptors.response.use(
        (next) => {
            return Promise.resolve(next)
        },
        (error) => {
            let messageError
            if (!error.response || error.response.status === 400 ) {
                messageError = "connection_error"
                error = {response: {data: {error: "connection_error"}}}
            } else
                switch (error.response.status) {
                    case 401:
                        switch (error.response.data.error_code) {
                            case errorCodes.BAD_CREDENTIALS_ERROR:
                                messageError = "bad_credentials"
                                break
                            case errorCodes.DISABLED_USER:
                                messageError = "disabled_user"
                                break
                            default:
                                messageError = "session_expired"
                        }
                        break
                    case 403:
                        switch (error.response.data.error_code) {
                            case errorCodes.CANT_DELETE_REFERENCE_TAG:
                                messageError = "cannot_delete_reference_tag"
                                break
                            case errorCodes.CANT_DELETE_TAG:
                                messageError = "cannot_delete_tag"
                                break
                            default:
                                messageError = "forbidden"
                        }
                        break
                    case 404:
                        messageError = "not_found"
                        break
                    case 422:
                        switch (error.response.data.error_code) {
                            case errorCodes.USER_ALREADY_EXIST:
                                messageError = "user_already_exist"
                                break
                            case errorCodes.PRICE_ALREADY_EXIST:
                                messageError = "price_already_exist"
                                break
                            case errorCodes.LANGUAGE_PAIR_ALREADY_EXIST:
                                messageError = "language_pair_already_exist"
                                break
                            case errorCodes.VALUE_ALREADY_EXIST:
                                messageError = "value_already_exist"
                                break
                            case errorCodes.TAG_ALREADY_EXIST:
                                messageError = "tag_already_exist"
                                break
                            case errorCodes.RATE_PERCENTAGE_ALREADY_EXIST:
                                messageError = "rate_percentage_already_exist"
                                break
                            case errorCodes.UNIT_ALREADY_EXIST:
                                messageError = "unit_already_exist"
                                break
                            case errorCodes.CURRENCY_ALREADY_EXIST:
                                messageError = "currency_already_exist"
                                break
                            case errorCodes.EXCHANGE_RATE_ALREADY_EXIST:
                                messageError = "exchange_rate_already_exist"
                                break
                            case errorCodes.CAT_TOOL_BREAK_PERCENT_RANGE_NOT_ALLOWED:
                                messageError = "cat_tool_break_percent_range_not_allowed"
                                break
                            default:
                                messageError = "validation_error"
                        }
                        break
                    case error.response.status >= 500:
                        messageError = "connection_error"
                        break
                    default:
                        messageError = "general"
                }
            store.dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error." + messageError}})
            return Promise.reject(error)
        }
    )
}
