import {useRef, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import * as actions from '../../store/Actions'
import {getLanguagesIdcp, storeLanguagePair} from '../../store/reducers/languagePair/languagePairActions'
import {DraggableModal} from '../UI/DraggableModal'
import {Search} from '../UI/Search'
import {Tag} from '../UI/Tag'
import * as modalTypes from '../../consts/enums/modalTypes'
import * as toastTypes from '../../consts/enums/toastTypes'

export const ModalNewLanguagePair = ({title = "", standardRateId = null}) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const sourceLanguages = useSelector(state => state.languagePair.sourceLanguagesIdcp)
    const targetLanguages = useSelector(state => state.languagePair.targetLanguagesIdcp)
    const isGettingSourceLanguagesIdcp = useSelector(state => state.languagePair.isGettingSourceLanguagesIdcp)
    const isGettingTargetLanguagesIdcp = useSelector(state => state.languagePair.isGettingTargetLanguagesIdcp)
    const searchType = useRef("source_language")
    const [searchSourceLanguage, setSearchSourceLanguage] = useState("")
    const [searchTargetLanguage, setSearchTargetLanguage] = useState("")
    const [sourceLanguage, setSourceLanguage] = useState("")
    const [codeSourceLanguage, setCodeSourceLanguage] = useState("")
    const [targetLanguage, setTargetLanguage] = useState("")
    const [codeTargetLanguage, setCodeTargetLanguage] = useState("")

    const onHideClick = (e) => {
        e.preventDefault()
        dispatch({type: actions.HIDE_MODAL})
        dispatch({type: actions.RESET_LANGUAGES_IDCP})
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                type: modalTypes.NEW_PRICE,
                title: intl.formatMessage({id: "message_title.new_price"}),
                additionalData: {
                    standardRateId: standardRateId
                }
            }
        })
    }

    const changeSourceLanguage = (e, language) => {
        e.preventDefault()
        setSourceLanguage(language.description)
        setCodeSourceLanguage(language.code)
        setSearchSourceLanguage("")
        dispatch({type: actions.RESET_LANGUAGES_IDCP})
    }

    const changeTargetLanguage = (e, language) => {
        e.preventDefault()
        setTargetLanguage(language.description)
        setCodeTargetLanguage(language.code)
        setSearchTargetLanguage("")
        dispatch({type: actions.RESET_LANGUAGES_IDCP})
    }

    const languageData = (language) => {
        return language.description + (language.code ? " (" + language.code + ")" : "")
    }

    const getDataIdcp = (e) => {
        e.preventDefault()
        dispatch({type: actions.RESET_LANGUAGES_IDCP})
        if (searchType.current === "source_language") {
            dispatch(getLanguagesIdcp(searchSourceLanguage))
        } else {
            dispatch(getLanguagesIdcp(searchTargetLanguage, false))
        }
    }

    const removeSourceLanguage = (e) => {
        e.preventDefault()
        setSourceLanguage("")
        setCodeSourceLanguage("")
    }

    const removeTargetLanguage = (e) => {
        e.preventDefault()
        setTargetLanguage("")
        setCodeTargetLanguage("")
    }

    const createLanguagePair = (e) => {
        e.preventDefault()
        if (codeSourceLanguage === codeTargetLanguage) {
            dispatch({type: actions.SHOW_TOAST, payload: {type:toastTypes.ERROR, message: "error.source_language_equal_to_target_language"}})
        } else {
            const languagePair = {
                source_language: sourceLanguage,
                code_source_language: codeSourceLanguage,
                target_language: targetLanguage,
                code_target_language: codeTargetLanguage
            }
            if (!codeSourceLanguage) {
                delete languagePair.code_source_language
            }
            if (!codeTargetLanguage) {
                delete languagePair.code_target_language
            }
            dispatch(storeLanguagePair(languagePair))
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.RESET_LANGUAGES_IDCP})
            dispatch({type: actions.SHOW_MODAL, payload: {type: modalTypes.LOADING}})
        }
    }

    const Body = () => {
        return (
            <>
                <div className="field">
                    <div className="field is-grouped is-grouped-multiline">
                        <div className="control">
                            <label className="label">
                                <FormattedMessage id="language_pair.source_language" />
                                <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                                <span>:</span>
                            </label>
                        </div>
                        {sourceLanguage ?
                            <div className="control">
                                <Tag text={sourceLanguage + (codeSourceLanguage ? " (" + codeSourceLanguage + ")" : "")}
                                     handler={(e) => removeSourceLanguage(e)} />
                            </div>
                            :
                            null
                        }
                    </div>
                    <div className="field has-addons">
                        <Search placeholder={intl.formatMessage({id: "placeholder.search_source_language"})}
                                value={searchSourceLanguage}
                                inputChange={(e) => {
                                    setSearchSourceLanguage(e.target.value)
                                    dispatch({type: actions.RESET_LANGUAGES_IDCP})
                                }}
                                autofocus={true}
                                maxLength={24}
                                content={sourceLanguages}
                                dataClick={changeSourceLanguage}
                                dataText={languageData}
                                isLoading={isGettingSourceLanguagesIdcp}
                                disabledButton={searchSourceLanguage.trim() === ""}
                                buttonClick={(e) => {
                                    searchType.current = "source_language"
                                    getDataIdcp(e)
                                }}
                                buttonText={intl.formatMessage({id: "general.search"})}
                        />
                    </div>
                </div>
                <div className="field">
                    <div className="field is-grouped is-grouped-multiline">
                        <div className="control">
                            <label className="label">
                                <FormattedMessage id="language_pair.target_language" />
                                <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                                <span>:</span>
                            </label>
                        </div>
                        {targetLanguage ?
                            <div className="control">
                                <Tag text={targetLanguage + (codeTargetLanguage ? " (" + codeTargetLanguage + ")" : "")}
                                     handler={(e) => removeTargetLanguage(e)} />
                            </div>
                            :
                            null
                        }
                    </div>
                    <div className="field has-addons">
                        <Search placeholder={intl.formatMessage({id: "placeholder.search_target_language"})}
                                value={searchTargetLanguage}
                                inputChange={(e) => {
                                    setSearchTargetLanguage(e.target.value)
                                    dispatch({type: actions.RESET_LANGUAGES_IDCP})
                                }}
                                maxLength={24}
                                content={targetLanguages}
                                dataClick={changeTargetLanguage}
                                dataText={languageData}
                                isLoading={isGettingTargetLanguagesIdcp}
                                disabledButton={searchTargetLanguage.trim() === ""}
                                buttonClick={(e) => {
                                    searchType.current = "target_language"
                                    getDataIdcp(e)
                                }}
                                buttonText={intl.formatMessage({id: "general.search"})}
                        />
                    </div>
                </div>
            </>
        )
    }

    const Foot = () => {
        return (
            <>
                <button className="button is-link"
                        disabled={sourceLanguage.trim() === "" || targetLanguage.trim() === ""}
                        onClick={(e) => createLanguagePair(e)}>
                    <FormattedMessage id="general.save" />
                </button>
                <button className="button"
                        onClick={(e) => onHideClick(e)}>
                    <FormattedMessage id="general.cancel" />
                </button>
            </>
        )
    }

    return (
        <DraggableModal title={title} body={Body()} foot={Foot()} onHideClick={onHideClick} />
    )
}
