import {useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import * as actions from '../../store/Actions'
import {DraggableModal} from '../UI/DraggableModal'
import {STANDARD_CURRENCY_CODE} from '../../consts/app'

export const ModalSelectRate = ({title = "", additionalData = null}) => {
    const dispatch = useDispatch()
    const activeStandardRates = useSelector(state => state.rate.activeStandardRates)
    const linkedCustomerRates = useSelector(state => state.customerRate.linkedCustomerRates)
    const [selectedRate, setSelectedRate] = useState(activeStandardRates.length > 0 ?
        activeStandardRates.find(standardRate => standardRate.currency_code === STANDARD_CURRENCY_CODE) ??
        activeStandardRates[0] : null)

    const onHideClick = (e) => {
        e.preventDefault()
        dispatch({type: actions.HIDE_MODAL})
        additionalData.showSelectData(null)
    }

    const accept = (e) => {
        e.preventDefault()
        dispatch({type: actions.HIDE_MODAL})
        additionalData.showSelectData(selectedRate)
    }

    const Body = () => {
        return (
            <>
                <div className="field">
                    <label className="label">
                        <FormattedMessage id="message.select_rate" />
                    </label>
                </div>
                <div className="control">
                    {additionalData.type === 'customer' || additionalData.type === 'standard' ?
                        <>
                            {activeStandardRates?.map((standardRate, i) => (
                                <div className="level mb-0" key={i}>
                                    <div className="level-left">
                                        <label className="radio">
                                            <input type="radio" name="rate"
                                                   checked={standardRate === selectedRate}
                                                   onChange={() => setSelectedRate(standardRate)} />
                                            <FormattedMessage id="customer_rate.standard_rate" /> - {standardRate.currency_name}
                                        </label>
                                    </div>
                                </div>
                            ))}
                            {additionalData.type === 'customer' ?
                                <>
                                    {linkedCustomerRates?.map((customerRate, i) => (
                                        <div className="level mb-0" key={i}>
                                            <div className="level-left">
                                                <label className="radio">
                                                    <input type="radio" name="rate"
                                                           checked={customerRate === selectedRate}
                                                           onChange={() => setSelectedRate(customerRate)} />
                                                    {customerRate.project_id ?
                                                        <>
                                                            <FormattedMessage id="customer_rate.project_rate" />
                                                            : "{customerRate.name}"
                                                        </>
                                                        :
                                                        customerRate.manufacturer_id ?
                                                            <>
                                                                <FormattedMessage id="customer_rate.customer_and_manufacturer_rate" />
                                                                : "{customerRate.name}"
                                                            </>
                                                            :
                                                            <>
                                                                <FormattedMessage id="customer_rate.customer_rate" />
                                                                : "{customerRate.name}"
                                                            </>
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </>
                                :
                                null
                            }
                        </>
                        :
                        null
                    }
                </div>
            </>
        )
    }

    const Foot = () => {
        return (
            <>
                <button className="button is-link"
                        disabled={!selectedRate}
                        onClick={(e) => accept(e)}>
                    <FormattedMessage id="general.accept" />
                </button>
                <button className="button"
                        onClick={(e) => onHideClick(e)}>
                    <FormattedMessage id="general.cancel" />
                </button>
            </>
        )
    }

    return (
        <DraggableModal title={title} body={Body()} foot={Foot()} onHideClick={onHideClick} />
    )
}
