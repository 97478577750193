import {useRef} from 'react'
import Draggable from 'react-draggable'
import {Modal} from './Modal'

export const DraggableModal = ({title = "", body = null, foot = null, onHideClick = null}) => {
    const nodeRef = useRef(null)

    return (
        <Draggable handle="p" bounds={{top: -150, left: -300, right: 300, bottom: 150}} nodeRef={nodeRef}>
            <div ref={nodeRef}>
                <Modal title={title} body={body} foot={foot} onHideClick={onHideClick} />
            </div>
        </Draggable>
    )
}
