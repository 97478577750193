import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify'
import * as actions from '../../store/Actions'
import * as toastTypes from '../../consts/enums/toastTypes'

export const Toast = () => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const type = useSelector(state => state.toast.type)
    const message = useSelector(state => state.toast.message)
    const loggedUser = useSelector(state => state.auth.user)

    useEffect(() => {
        switch (type) {
            case toastTypes.SUCCESS:
                toast.success(intl.formatMessage({id: message}), {toastId: message})
                break
            case toastTypes.INFO:
                toast.info(intl.formatMessage({id: message}), {toastId: message})
                break
            case toastTypes.WARNING:
                toast.warning(intl.formatMessage({id: message}), {toastId: message})
                break
            case toastTypes.ERROR:
                if (message === "error.session_expired") {
                    if (loggedUser) {
                        toast.error(intl.formatMessage({id: message}), {toastId: message})
                    }
                    dispatch({type: actions.HIDE_MODAL})
                    dispatch({type: actions.RESET})
                } else {
                    toast.error(intl.formatMessage({id: message}), {toastId: message})
                }
                break
            default:
                if (message) {
                    toast(intl.formatMessage({id: message}), {toastId: message})
                }
        }
        dispatch({type: actions.RESET_TOAST})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message])

    return null
}
