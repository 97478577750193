import {useEffect, useRef, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import * as actions from '../store/Actions'
import {activateStandardRate} from '../store/reducers/rate/rateActions'
import {getAllPrices, getPrices, getPricesByLanguage, getPricesByPrice, getPricesByUnit} from '../store/reducers/price/priceActions'
import {getAllValues, getValues, getValuesByTag, getValuesByTagType, getValuesByUnit, getValuesByValue} from '../store/reducers/value/valueActions'
import {getAllRatePercentages, getRatePercentages, getRatePercentagesByName, getRatePercentagesByPercent} from '../store/reducers/ratePercentage/ratePercentageActions'
import {getUnits} from '../store/reducers/unit/unitActions'
import {Datatable} from '../components/Datatable/Datatable'
import {Title} from '../components/UI/Title'
import {IconButton} from '../components/UI/IconButton'
import {Dropdown} from '../components/UI/Dropdown'
import {Search} from '../components/UI/Search'
import {Pagination} from '../components/UI/Pagination'
import * as modalTypes from '../consts/enums/modalTypes'
import * as tagTypes from '../consts/enums/tagTypes'
import * as fileTypes from '../consts/enums/fileTypes'
import * as userTypes from '../consts/enums/userTypes'
import * as toastTypes from '../consts/enums/toastTypes'
import {CAT_TOOL_BREAK_TYPES, COMPANY_DATA_IN_EXPORT, MAX_REFERENCE_TAGS, PAGINATION_PAGE_ROWS,
    STANDARD_CURRENCY_ABBREVIATION, STANDARD_CURRENCY_CODE, TAG_TYPES} from '../consts/app'
import {roundDecimalNumber} from '../utils/roundDecimalNumber'
import {createLocaleDateText} from '../utils/createLocaleDateText'
import {exportRateToExcel} from '../utils/exportRateToExcel'
import {exportRateToPDF} from '../utils/exportRateToPDF'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck, faFileExcel, faFilePdf} from '@fortawesome/free-solid-svg-icons'

export const StandardRate = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const intl = useIntl()
    const dispatch = useDispatch()
    const hasStandardRateBeenActivated = useSelector(state => state.rate.standardRateActivated)
    const error = useSelector(state => state.rate.error)
    const prices = useSelector(state => state.price.prices)
    const isGettingPrices = useSelector(state => state.price.isGettingPrices)
    const pricesPagination = useSelector(state => state.price.pagination)
    const allPrices = useSelector(state => state.price.allPrices)
    const isGettingAllPrices = useSelector(state => state.price.isGettingAllPrices)
    const pricesError = useSelector(state => state.price.error)
    const values = useSelector(state => state.value.values)
    const isGettingValues = useSelector(state => state.value.isGettingValues)
    const valuesPagination = useSelector(state => state.value.pagination)
    const allValues = useSelector(state => state.value.allValues)
    const isGettingAllValues = useSelector(state => state.value.isGettingAllValues)
    const valuesError = useSelector(state => state.value.error)
    const ratePercentages = useSelector(state => state.ratePercentage.ratePercentages)
    const isGettingRatePercentages = useSelector(state => state.ratePercentage.isGettingRatePercentages)
    const ratePercentagesPagination = useSelector(state => state.ratePercentage.pagination)
    const allRatePercentages = useSelector(state => state.ratePercentage.allRatePercentages)
    const isGettingAllRatePercentages = useSelector(state => state.ratePercentage.isGettingAllRatePercentages)
    const ratePercentagesError = useSelector(state => state.ratePercentage.error)
    const units = useSelector(state => state.unit.units)
    const isGettingUnits = useSelector(state => state.unit.isGettingUnits)
    const unitsError = useSelector(state => state.unit.error)
    const locale = useSelector(state => state.locale.locale)
    const userType = useSelector(state => state.auth.user.role)
    const [activeRateCheckbox, setActiveRateCheckbox] = useState(false)
    const [languagePairFilter, setLanguagePairFilter] = useState("all")
    const [languageFilter, setLanguageFilter] = useState("")
    const [priceUnitFilter, setPriceUnitFilter] = useState("")
    const [priceMinFilter, setPriceMinFilter] = useState("")
    const [priceMaxFilter, setPriceMaxFilter] = useState("")
    const [contentTag, setContentTag] = useState("")
    const [tagFilter, setTagFilter] = useState("all")
    const [tagNameFilter, setTagNameFilter] = useState("")
    const [typeFilter, setTypeFilter] = useState("")
    const [valueUnitFilter, setValueUnitFilter] = useState("")
    const [valueMinFilter, setValueMinFilter] = useState("")
    const [valueMaxFilter, setValueMaxFilter] = useState("")
    const [catToolBreakFilter, setCatToolBreakFilter] = useState("all")
    const [catToolBreakNameFilter, setCatToolBreakNameFilter] = useState("")
    const [ratePercentageMinFilter, setRatePercentageMinFilter] = useState("")
    const [ratePercentageMaxFilter, setRatePercentageMaxFilter] = useState("")
    const standardRate = useRef("")
    const tab = useRef(1)
    const fileType = useRef("")
    const priceSearchType = useRef({type: "all", search: {param1: "", param2: ""}})
    const priceSortType = useRef({sort: "translations.source_language", order: "asc"})
    const valueSearchType = useRef({type: "all", search: {param1: "", param2: ""}})
    const valueSortType = useRef({sort: "tags.type", order: "asc"})
    const ratePercentageSearchType = useRef({type: "all", search: {param1: "", param2: ""}})
    const ratePercentageSortType = useRef({sort: "cat_tool_breaks.match_percent_min", order: "desc"})

    useEffect(() => {
        if (!location.state?.standardRate) {
            navigate("/standard-rates")
        } else {
            getData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (hasStandardRateBeenActivated) {
            standardRate.current['is_active'] = true
            setActiveRateCheckbox(true)
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.SUCCESS, message: "message.standard_rate_activated"}})
            dispatch({type: actions.STANDARD_RATE_ACTIVATED, payload: {status: false}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasStandardRateBeenActivated])

    useEffect(() => {
        if (error) {
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.RESET_RATE_ERROR})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    useEffect(() => {
        if (!isGettingUnits && !isGettingAllPrices && !isGettingAllValues && !isGettingAllRatePercentages) {
            if (!unitsError && !pricesError && !valuesError && !ratePercentagesError) {
                if (fileType.current === fileTypes.EXCEL || fileType.current === fileTypes.PDF) {
                    showSelectDataExport()
                }
            } else {
                dispatch({type: actions.RESET_UNIT_ERROR})
                dispatch({type: actions.RESET_PRICE_ERROR})
                dispatch({type: actions.RESET_VALUE_ERROR})
                dispatch({type: actions.RESET_RATE_PERCENTAGE_ERROR})
            }
            fileType.current = ""
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGettingUnits, isGettingAllPrices, isGettingAllValues, isGettingAllRatePercentages])

    const getData = () => {
        standardRate.current = location.state.standardRate
        setActiveRateCheckbox(standardRate.current.is_active)
        getPricesPerPage(1)
        getValuesPerPage(1)
        getRatePercentagesPerPage(1)
        dispatch(getAllValues(standardRate.current.id))
    }

    const updateActiveStatus = () => {
        dispatch(activateStandardRate(standardRate.current.id, standardRate.current.currency_id))
        dispatch({type: actions.HIDE_MODAL})
        dispatch({type: actions.SHOW_MODAL, payload: {type:modalTypes.LOADING}})
    }

    const showUpdateActiveStatusConfirmation = () => {
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                title: intl.formatMessage({id: "message_title.activate_standard_rate"}),
                message: intl.formatMessage({id: "message.activate_standard_rate"}),
                buttons: [
                    {
                        label: intl.formatMessage({id: "general.accept"}),
                        classes: 'button is-link',
                        handler: () => {
                            updateActiveStatus()
                        }
                    }
                ]
            }
        })
    }

    const resetPriceData = () => {
        setLanguageFilter("")
        setPriceUnitFilter("")
        setPriceMinFilter("")
        setPriceMaxFilter("")
    }

    const filterAllPrices = () => {
        setLanguagePairFilter("all")
        priceSearchType.current = {type: "all", search: {param1: "", param2: ""}}
        getPricesPerPage(1)
    }

    const filterPricesByLanguage = (e) => {
        e.preventDefault()
        priceSearchType.current = {type: languagePairFilter, search: {param1: languageFilter, param2: ""}}
        getPricesPerPage(1)
    }

    const filterPricesByUnit = (unit) => {
        priceSearchType.current = {type: "unit", search: {param1: unit, param2: ""}}
        getPricesPerPage(1)
    }

    const filterPricesByPrice = (e) => {
        e.preventDefault()
        if (parseFloat(priceMinFilter) > parseFloat(priceMaxFilter)) {
            dispatch({type: actions.SHOW_TOAST, payload: {type:toastTypes.ERROR, message: "error.min_greater_than_max"}})
        } else {
            priceSearchType.current = {type: "price", search: {param1: priceMinFilter, param2: priceMaxFilter}}
            getPricesPerPage(1)
        }
    }

    const sortLanguagePairs = (column, sortDirection) => {
        priceSortType.current = {sort: column.sortField, order: sortDirection}
        getPricesPerPage(1)
    }

    const resetValueData = () => {
        setTagNameFilter("")
        setTypeFilter("")
        setValueUnitFilter("")
        setValueMinFilter("")
        setValueMaxFilter("")
    }

    const filterAllValues = () => {
        setTagFilter("all")
        valueSearchType.current = {type: "all", search: {param1: "", param2: ""}}
        getValuesPerPage(1)
    }

    const filterValuesByTagName = (e) => {
        e.preventDefault()
        valueSearchType.current = {type: "name", search: {param1: tagNameFilter, param2: ""}}
        getValuesPerPage(1)
    }

    const filterValuesByTagType = (type) => {
        valueSearchType.current = {type: "type", search: {param1: type, param2: ""}}
        getValuesPerPage(1)
    }

    const filterValuesByUnit = (unit) => {
        valueSearchType.current = {type: "unit", search: {param1: unit, param2: ""}}
        getValuesPerPage(1)
    }

    const filterValuesByValue = (e) => {
        e.preventDefault()
        if (parseFloat(valueMinFilter) > parseFloat(valueMaxFilter)) {
            dispatch({type: actions.SHOW_TOAST, payload: {type:toastTypes.ERROR, message: "error.min_greater_than_max"}})
        } else {
            valueSearchType.current = {type: tagFilter, search: {param1: valueMinFilter, param2: valueMaxFilter}}
            getValuesPerPage(1)
        }
    }

    const sortTags = (column, sortDirection) => {
        valueSortType.current = {sort: column.sortField, order: sortDirection}
        getValuesPerPage(1)
    }

    const resetRatePercentageData = () => {
        setCatToolBreakNameFilter("")
        setRatePercentageMinFilter("")
        setRatePercentageMaxFilter("")
    }

    const filterAllRatePercentages = () => {
        setCatToolBreakFilter("all")
        ratePercentageSearchType.current = {type: "all", search: {param1: "", param2: ""}}
        getRatePercentagesPerPage(1)
    }

    const filterRatePercentagesByName = (name) => {
        ratePercentageSearchType.current = {type: "name", search: {param1: name, param2: ""}}
        getRatePercentagesPerPage(1)
    }

    const filterRatePercentagesByPercent = (e) => {
        e.preventDefault()
        if (parseFloat(ratePercentageMinFilter) > parseFloat(ratePercentageMaxFilter)) {
            dispatch({type: actions.SHOW_TOAST, payload: {type:toastTypes.ERROR, message: "error.min_greater_than_max"}})
        } else {
            ratePercentageSearchType.current = {type: catToolBreakFilter, search: {param1: ratePercentageMinFilter, param2: ratePercentageMaxFilter}}
            getRatePercentagesPerPage(1)
        }
    }

    const sortCatToolBreaks = (column, sortDirection) => {
        ratePercentageSortType.current = {sort: column.sortField, order: sortDirection}
        getRatePercentagesPerPage(1)
    }

    const getPricesPerPage = (page) => {
        if (priceSearchType.current.type === "all") {
            dispatch(getPrices(standardRate.current.id, priceSortType.current.sort, priceSortType.current.order, page))
        } else if (priceSearchType.current.type === "source_language") {
            dispatch(getPricesByLanguage(priceSearchType.current.search.param1, standardRate.current.id, priceSortType.current.sort, priceSortType.current.order, page))
        } else if (priceSearchType.current.type === "target_language") {
            dispatch(getPricesByLanguage(priceSearchType.current.search.param1, standardRate.current.id, priceSortType.current.sort, priceSortType.current.order, page, false))
        } else if (priceSearchType.current.type === "unit") {
            dispatch(getPricesByUnit(priceSearchType.current.search.param1, standardRate.current.id, priceSortType.current.sort, priceSortType.current.order, page))
        } else {
            dispatch(getPricesByPrice(priceSearchType.current.search.param1, priceSearchType.current.search.param2, standardRate.current.id,
                priceSortType.current.sort, priceSortType.current.order, page))
        }
    }

    const getValuesPerPage = (page) => {
        if (valueSearchType.current.type === "all") {
            dispatch(getValues(standardRate.current.id, valueSortType.current.sort, valueSortType.current.order, page))
        } else if (valueSearchType.current.type === "name") {
            dispatch(getValuesByTag(valueSearchType.current.search.param1, standardRate.current.id, valueSortType.current.sort, valueSortType.current.order, page))
        } else if (valueSearchType.current.type === "type") {
            dispatch(getValuesByTagType(valueSearchType.current.search.param1, standardRate.current.id, valueSortType.current.sort, valueSortType.current.order, page))
        } else if (valueSearchType.current.type === "unit") {
            dispatch(getValuesByUnit(valueSearchType.current.search.param1, standardRate.current.id, valueSortType.current.sort, valueSortType.current.order, page))
        } else {
            dispatch(getValuesByValue(valueSearchType.current.search.param1, valueSearchType.current.search.param2,
                valueSearchType.current.type, standardRate.current.id, valueSortType.current.sort, valueSortType.current.order, page))
        }
    }

    const getRatePercentagesPerPage = (page) => {
        if (ratePercentageSearchType.current.type === "all") {
            dispatch(getRatePercentages(standardRate.current.id, ratePercentageSortType.current.sort, ratePercentageSortType.current.order, page))
        } else if (ratePercentageSearchType.current.type === "name") {
            dispatch(getRatePercentagesByName(ratePercentageSearchType.current.search.param1, standardRate.current.id, ratePercentageSortType.current.sort, ratePercentageSortType.current.order, page))
        } else {
            dispatch(getRatePercentagesByPercent(ratePercentageSearchType.current.search.param1, ratePercentageSearchType.current.search.param2,
                ratePercentageSearchType.current.type, standardRate.current.id, ratePercentageSortType.current.sort, ratePercentageSortType.current.order, page))
        }
    }

    const createTextDate = (name = locale.name) => {
        return createLocaleDateText(name, standardRate.current.created_at.substring(0, 10))
    }

    const filterContentTags = () => {
        return allValues.filter(value => value.tag_type === tagTypes.CONTENT && value.percent !== null).sort((value1, value2) => {
            return value1.tag_name > value2.tag_name ? 1 : value1.tag_name < value2.tag_name ? - 1 : 0
        })
    }

    const calculateReferenceTagPrice = (price, referenceTag, referenceTagCount) => {
        if (referenceTagCount <= MAX_REFERENCE_TAGS) {
            const referenceValue = allValues.find(referenceValue => referenceValue.tag_id === referenceTag)
            if (referenceValue.reference_tag) {
                price = calculateReferenceTagPrice(price, referenceValue.reference_tag, referenceTagCount + 1)
            }
            if (referenceValue.percent !== null) {
                return price * (referenceValue.percent / 100)
            }
        }
        return price
    }

    const calculatePrice = (price) => {
        if (contentTag) {
            let total = price.total
            const value = allValues.find(value => value.id === parseInt(contentTag))
            if (value.unit_id === price.unit_id || !value.unit_id) {
                if (value.reference_tag) {
                    total = calculateReferenceTagPrice(total, value.reference_tag, 1)
                }
                total = total * (value.percent / 100)
            }
            return roundDecimalNumber(total)
        }
        return null
    }

    const LanguagePairsTable = () => {
        const columns = [
            {
                name: intl.formatMessage({id: "language_pair.source_language"}),
                selector: row => row.translation_source_language + (row.translation_code_source_language ? ' (' + row.translation_code_source_language + ')' : ""),
                wrap: true,
                sortable: true,
                sortField: 'translations.source_language'
            },
            {
                name: intl.formatMessage({id: "language_pair.target_language"}),
                selector: row => row.translation_target_language + (row.translation_code_target_language ? ' (' + row.translation_code_target_language + ')' : ""),
                wrap: true,
                sortable: true,
                sortField: 'translations.target_language'
            },
            {
                name: intl.formatMessage({id: "language_pair.unit"}),
                selector: row => row.unit_type,
                wrap: true,
                sortable: true,
                sortField: 'units.type'
            },
            {
                name: intl.formatMessage({id: "language_pair.price"}),
                selector: row => row.total,
                wrap: true,
                sortable: true,
                sortField: 'prices.total'
            },
            {
                name: (
                    <div className="select">
                        <select className="has-text-weight-bold contentTags"
                                value={contentTag}
                                onChange={(e) => setContentTag(e.target.value)}>
                            <option value="">
                                -----
                            </option>
                            {filterContentTags().map((value, i) => (
                                <option key={i} value={value.id}>
                                    {value.tag_name} {value.unit_id ? "(" + value.unit_type + ")" : ""}
                                </option>
                            ))}
                        </select>
                    </div>
                ),
                selector: row => calculatePrice(row),
                wrap: true
            }
        ]
        return (
            <Datatable
                columns={columns}
                data={prices}
                defaultSortAsc={priceSortType.current.order !== "asc"}
                onSort={sortLanguagePairs}
                paginationComponent={() => <Pagination pagination={pricesPagination} handler={getPricesPerPage} />}
                paginationPerPage={pricesPagination?.per_page ?? PAGINATION_PAGE_ROWS}
                progressPending={isGettingPrices}
                sortServer
            />
        )
    }

    const TagsTable = () => {
        const columns = [
            {
                name: intl.formatMessage({id: "tag.name"}),
                selector: row => row.tag_name,
                wrap: true,
                sortable: true,
                sortField: 'tags.name'
            },
            {
                name: intl.formatMessage({id: "tag.type"}),
                selector: row => intl.formatMessage({id: "tag_type." + row.tag_type}),
                wrap: true,
                sortable: true,
                sortField: 'tags.type'
            },
            {
                name: intl.formatMessage({id: "tag.unit"}),
                selector: row => row.unit_type,
                wrap: true,
                sortable: true,
                sortField: 'units.type'
            },
            {
                name: intl.formatMessage({id: "tag.value"}),
                selector: row => row.price !== null ? row.price :
                    row.percent === null ? intl.formatMessage({id: "datatable_tag.not_value"}) :
                        row.reference_tag ? row.percent + "%" + intl.formatMessage({id: "datatable_tag.reference"}) + row.reference_tag_name :
                            row.percent + "%" + intl.formatMessage({id: "datatable_tag.reference_base"}),
                wrap: true,
                sortable: true,
                sortField: 'values'
            },
            {
                name: intl.formatMessage({id: "tag.description"}),
                selector: row => row.description,
                wrap: true,
                sortable: true,
                sortField: 'values.description'
            }
        ]
        return (
            <Datatable
                columns={columns}
                data={values}
                defaultSortAsc={valueSortType.current.order !== "asc"}
                onSort={sortTags}
                paginationComponent={() => <Pagination pagination={valuesPagination} handler={getValuesPerPage} />}
                paginationPerPage={valuesPagination?.per_page ?? PAGINATION_PAGE_ROWS}
                progressPending={isGettingValues}
                sortServer
            />
        )
    }

    const CatToolBreaksTable = () => {
        const columns = [
            {
                name: intl.formatMessage({id: "cat_tool_break.name"}),
                selector: row => row.cat_tool_break_name + (row.cat_tool_break_match_percent_min !== null ?
                    " (" + row.cat_tool_break_match_percent_min + "%" + (row.cat_tool_break_match_percent_max ?
                        " - " + row.cat_tool_break_match_percent_max + "%)" : ")") : ""),
                wrap: true,
                sortable: true,
                sortField: 'cat_tool_breaks.match_percent_min'
            },
            {
                name: intl.formatMessage({id: "cat_tool_break.percent"}),
                selector: row => row.percent + "%",
                wrap: true,
                sortable: true,
                sortField: 'rate_percentages.percent'
            },
            {
                name: intl.formatMessage({id: "cat_tool_break.description"}),
                selector: row => row.description,
                wrap: true,
                sortable: true,
                sortField: 'rate_percentages.description'
            }
        ]
        return (
            <Datatable
                columns={columns}
                data={ratePercentages}
                defaultSortAsc={ratePercentageSortType.current.order !== "asc"}
                onSort={sortCatToolBreaks}
                paginationComponent={() => <Pagination pagination={ratePercentagesPagination} handler={getRatePercentagesPerPage} />}
                paginationPerPage={ratePercentagesPagination?.per_page ?? PAGINATION_PAGE_ROWS}
                progressPending={isGettingRatePercentages}
                sortServer
            />
        )
    }

    const getAllData = (e, type) => {
        e.preventDefault()
        setContentTag("")
        fileType.current = type
        dispatch(getUnits())
        dispatch(getAllPrices(standardRate.current.id))
        dispatch(getAllValues(standardRate.current.id))
        dispatch(getAllRatePercentages(standardRate.current.id))
    }

    const headersLanguagePairs = (units, language) => {
        const headers = [
            language["header.source_language"],
            language["header.target_language"]
        ]
        for (let i = 0; i < units.length; i++) {
            headers.push(language["header." + units[i].type])
        }
        return headers
    }

    const dataLanguagePair = (units, allPrices, language) => {
        const prices = []
        for (let i = 0; i < allPrices.length; i++) {
            const price = [
                language["language." + allPrices[i].translation_source_language] ?? null,
                language["language." + allPrices[i].translation_target_language] ?? null
            ]
            for (let j = 0; j < units.length; j++) {
                price.push(allPrices[i][j] ?? null)
            }
            prices.push(JSON.parse(JSON.stringify(Object.assign({}, price))))
        }
        return prices
    }

    const dataTags = (allValues, language) => {
        const values = []
        for (let i = 0; i < allValues.length; i++) {
            values.push({
                name: allValues[i].tag_name,
                unit: language["unit." + allValues[i].unit_type],
                value: allValues[i].price !== null ? allValues[i].price : allValues[i].percent !== null ?
                    allValues[i].percent + "%" + (allValues[i].reference_tag ?
                        language["additional.reference"] + allValues[i].reference_tag_name :
                        language["additional.reference_base"]) : language["additional.not_value"],
                description: allValues[i].description
            })
        }
        return values
    }

    const headersCatToolBreaks = (language) => {
        return [
            language["header.cat_tool_breaks"],
            "",
            ""
        ]
    }

    const dataCatToolBreaks = (allRatePercentages, language) => {
        const ratePercentages = []
        for (let i = 0; i < allRatePercentages.length; i++) {
            ratePercentages.push({
                name: allRatePercentages[i].cat_tool_break_name +
                    (allRatePercentages[i].cat_tool_break_match_percent_min !== null ?
                        " (" + allRatePercentages[i].cat_tool_break_match_percent_min + "%" +
                        (allRatePercentages[i].cat_tool_break_match_percent_max ?
                            " - " + allRatePercentages[i].cat_tool_break_match_percent_max + "%)" : ")") : ""),
                percent: allRatePercentages[i].percent + "%" + language["additional.reference_base"],
                description: allRatePercentages[i].description
            })
        }
        return ratePercentages
    }

    const dataRate = (locale, language) => {
        return [
            {
                header: language["rate_data.date"],
                data: createTextDate(locale)
            },
            {
                header: language["rate_data.exchange_rate"] + " " + language["currency." + standardRate.current.currency_code],
                data: standardRate.current.currency_code === STANDARD_CURRENCY_CODE ?
                    standardRate.current.exchange_rate_value : STANDARD_CURRENCY_ABBREVIATION + "/" +
                    (standardRate.current.currency_abbreviation ?? standardRate.current.currency_code) + ": " +
                    standardRate.current.exchange_rate_value
            }
        ]
    }

    const exportToExcel = (units, prices, values, ratePercentages, language) => {
        if (prices.length > 0 || values.length > 0 || ratePercentages.length > 0) {
            const data = {
                sheetData: [
                    ...dataRate(language.name, language.object).map(data => Object.values(data)),
                    [],
                    headersLanguagePairs(units, language.object),
                    ...dataLanguagePair(units, prices, language.object).map(languagePair => Object.values(languagePair)),
                    [],
                    ...dataTags(values, language.object).map(tag => Object.values(tag)),
                    [],
                    headersCatToolBreaks(language.object),
                    ...dataCatToolBreaks(ratePercentages, language.object).map(catToolBreak => Object.values(catToolBreak)),
                    [],
                    [COMPANY_DATA_IN_EXPORT]
                ],
                columnWidths: [30, 30, 20, 20, 20]
            }
            exportRateToExcel(language.object["rate_type.standard_rate"] + " - " + standardRate.current.currency_name, data)
        } else {
            dispatch({type: actions.SHOW_TOAST, payload: {type:toastTypes.ERROR, message: "error.no_data_selected_to_export"}})
        }
    }

    const exportToPDF = (units, prices, values, ratePercentages, language) => {
        if (prices.length > 0 || values.length > 0 || ratePercentages.length > 0) {
            exportRateToPDF(language.object["rate_type.standard_rate"] + " - " + standardRate.current.currency_name,
                [headersLanguagePairs(units, language.object), headersCatToolBreaks(language.object)],
                [dataLanguagePair(units, prices, language.object), dataTags(values, language.object),
                    dataCatToolBreaks(ratePercentages, language.object), dataRate(language.name, language.object), units],
                COMPANY_DATA_IN_EXPORT)
        } else {
            dispatch({type: actions.SHOW_TOAST, payload: {type:toastTypes.ERROR, message: "error.no_data_selected_to_export"}})
        }
    }

    const showSelectDataExport = () => {
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                type: modalTypes.SELECT_PAIRS_EXPORT,
                title: intl.formatMessage({id: "message_title.select_language_pairs_to_export"}),
                additionalData: {
                    units: units,
                    prices: allPrices,
                    values: allValues,
                    ratePercentages: allRatePercentages,
                    export: fileType.current === fileTypes.EXCEL ? exportToExcel : exportToPDF
                }
            }
        })
    }

    return (
        <>
            <Title title={intl.formatMessage({id: "title.standard_rate"})} />
            <div className="container">
                {standardRate.current ?
                    <>
                        <div className="level">
                            <div className="level-left">
                                <div className="level-item">
                                    <h4 className="subtitle is-4">
                                        <FormattedMessage id="standard_rate.standard_rate" /> {createTextDate()}
                                    </h4>
                                </div>
                            </div>
                            <div className="level-right">
                                <div className="level is-mobile">
                                    <div className="level-left">
                                    </div>
                                    <div className="level-right">
                                        <div className="level-item">
                                            <IconButton size={"medium"}
                                                        isLoading={fileType.current === fileTypes.EXCEL &&
                                                            (isGettingAllPrices || isGettingAllValues || isGettingAllRatePercentages)}
                                                        title={intl.formatMessage({id: "general.export_excel"})}
                                                        handler={(e) => getAllData(e, fileTypes.EXCEL)}
                                                        icon={<FontAwesomeIcon icon={faFileExcel} />}
                                            />
                                        </div>
                                        <div className="level-item">
                                            <IconButton size={"medium"}
                                                        isLoading={fileType.current === fileTypes.PDF &&
                                                            (isGettingAllPrices || isGettingAllValues || isGettingAllRatePercentages)}
                                                        title={intl.formatMessage({id: "general.download_pdf"})}
                                                        handler={(e) => getAllData(e, fileTypes.PDF)}
                                                        icon={<FontAwesomeIcon icon={faFilePdf} />}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="level is-mobile">
                            <div className="level-left">
                                <div className="level-item">
                                    <label className={userType !== userTypes.RATE_VIEWER && !standardRate.current.is_active ?
                                        "checkbox has-text-weight-bold" : "label"}>
                                        {userType !== userTypes.RATE_VIEWER && !standardRate.current.is_active ?
                                            <input type="checkbox" checked={activeRateCheckbox}
                                                   onChange={() => showUpdateActiveStatusConfirmation()} />
                                            :
                                            null
                                        }
                                        <FormattedMessage id={`standard_rate.${!standardRate.current.is_active ? 'not_' : ''}active_rate`} />
                                    </label>
                                </div>
                                {standardRate.current.is_active ?
                                    <div className="level-item">
                                        <span className="icon has-text-success">
                                            <FontAwesomeIcon icon={faCheck} />
                                       </span>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="level mb-0">
                            <div className="level-left">
                                <div className="level-item">
                                    <label className="label">
                                        <FormattedMessage id="standard_rate.currency" />: {standardRate.current.currency_name}
                                    </label>
                                </div>
                            </div>
                        </div>
                        {standardRate.current.currency_code !== STANDARD_CURRENCY_CODE ?
                            <div className="level mb-0">
                                <div className="level-left">
                                    <div className="level-item">
                                        <label className="label">
                                            {STANDARD_CURRENCY_ABBREVIATION}/{standardRate.current.currency_abbreviation ??
                                            standardRate.current.currency_code}: {standardRate.current.exchange_rate_value}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        <div className="tabs is-boxed is-medium mt-5">
                            <ul>
                                <li className={`${tab.current === 1 ? 'is-active' : ''}`} onClick={() => tab.current = 1}>
                                    <Link to="#">
                                        <FormattedMessage id="standard_rate_tab.language_pairs" />
                                    </Link>
                                </li>
                                <li className={`${tab.current === 2 ? 'is-active' : ''}`} onClick={() => tab.current = 2}>
                                    <Link to="#">
                                        <FormattedMessage id="standard_rate_tab.tags" />
                                    </Link>
                                </li>
                                <li className={`${tab.current === 3 ? 'is-active' : ''}`} onClick={() => tab.current = 3}>
                                    <Link to="#">
                                        <FormattedMessage id="standard_rate_tab.cat_tool_breaks" />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        {tab.current === 1 ?
                            <>
                                <div className="level">
                                    <div className="level-left">
                                        <div className="level-item">
                                            <label className="label">
                                                <FormattedMessage id="general.filter_by" />:
                                            </label>
                                        </div>
                                        <div className="level-item mr-0">
                                            <Dropdown id="languagePairFilterDropdown"
                                                      text={languagePairFilter ? intl.formatMessage({id: "language_pair." + languagePairFilter}) : ""}
                                                      content={<>
                                                          <Link to="#" className="dropdown-item"
                                                                onClick={() => {
                                                                    resetPriceData()
                                                                    filterAllPrices()
                                                                }}>
                                                              <FormattedMessage id={"language_pair.all"} />
                                                          </Link>
                                                          <hr className="dropdown-divider" />
                                                          <Link to="#" className="dropdown-item"
                                                                onClick={() => {
                                                                    setLanguagePairFilter("source_language")
                                                                    resetPriceData()
                                                                }}>
                                                              <FormattedMessage id={"language_pair.source_language"} />
                                                          </Link>
                                                          <Link to="#" className="dropdown-item"
                                                                onClick={() => {
                                                                    setLanguagePairFilter("target_language")
                                                                    resetPriceData()
                                                                }}>
                                                              <FormattedMessage id={"language_pair.target_language"} />
                                                          </Link>
                                                          <Link to="#" className="dropdown-item"
                                                                onClick={() => {
                                                                    setLanguagePairFilter("unit")
                                                                    resetPriceData()
                                                                    dispatch(getUnits())
                                                                }}>
                                                              <FormattedMessage id={"language_pair.unit"} />
                                                          </Link>
                                                          <Link to="#" className="dropdown-item"
                                                                onClick={() => {
                                                                    setLanguagePairFilter("price")
                                                                    resetPriceData()
                                                                }}>
                                                              <FormattedMessage id={"language_pair.price"} />
                                                          </Link>
                                                      </>}
                                            />
                                        </div>
                                        <div className="level-item">
                                            <div className="field has-addons">
                                                {languagePairFilter === "source_language" || languagePairFilter === "target_language" ?
                                                    <Search size={"normal"}
                                                            placeholder={intl.formatMessage({id: "placeholder.filter_by_" + languagePairFilter})}
                                                            value={languageFilter}
                                                            inputChange={(e) => setLanguageFilter(e.target.value)}
                                                            maxLength={24}
                                                            isLoading={(isGettingPrices && languageFilter.trim() !== "")}
                                                            disabledButton={languageFilter.trim() === ""}
                                                            buttonClick={(e) => filterPricesByLanguage(e)}
                                                            buttonText={intl.formatMessage({id: "general.search"})}
                                                    />
                                                    :
                                                    languagePairFilter === "unit" ?
                                                        <Dropdown id="languagePairUnitsDropdown"
                                                                  text={priceUnitFilter ? priceUnitFilter : "-----"}
                                                                  isLoading={isGettingUnits}
                                                                  content={<>
                                                                      {units?.map((unit, i) => (
                                                                          <Link to="#" className="dropdown-item" key={i}
                                                                                onClick={() => {
                                                                                    setPriceUnitFilter(unit.type)
                                                                                    filterPricesByUnit(unit.type)
                                                                                }}>
                                                                              {unit.type}
                                                                          </Link>
                                                                      ))}
                                                                  </>}
                                                        />
                                                        :
                                                        languagePairFilter === "price" ?
                                                            <>
                                                                <div className="control">
                                                                    <input className="input"
                                                                           type="number"
                                                                           placeholder={intl.formatMessage({id: "placeholder.min"})}
                                                                           value={priceMinFilter}
                                                                           onChange={(e) => setPriceMinFilter(e.target.value)}
                                                                           min={0}
                                                                           max={9999999}
                                                                           step={0.1}
                                                                           onWheel={(e) => e.target.blur()}
                                                                    />
                                                                </div>
                                                                <div className="control">
                                                                    <button className="button is-static" tabIndex="-1">-</button>
                                                                </div>
                                                                <div className="control">
                                                                    <input className="input"
                                                                           type="number"
                                                                           placeholder={intl.formatMessage({id: "placeholder.max"})}
                                                                           value={priceMaxFilter}
                                                                           onChange={(e) => setPriceMaxFilter(e.target.value)}
                                                                           min={0}
                                                                           max={9999999}
                                                                           step={0.1}
                                                                           onWheel={(e) => e.target.blur()}
                                                                    />
                                                                </div>
                                                                <button className={`button is-link ${isGettingPrices &&
                                                                priceMinFilter.trim() !== "" &&
                                                                priceMaxFilter.trim() !== "" ? 'is-loading' : ''}`}
                                                                        disabled={priceMinFilter.trim() === "" || priceMaxFilter.trim() === ""}
                                                                        onClick={(e) => filterPricesByPrice(e)}>
                                                                    <FormattedMessage id="general.search" />
                                                                </button>
                                                            </>
                                                            :
                                                            null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <LanguagePairsTable />
                            </>
                            :
                            tab.current === 2 ?
                                <>
                                    <div className="level">
                                        <div className="level-left">
                                            <div className="level-item">
                                                <label className="label">
                                                    <FormattedMessage id="general.filter_by" />:
                                                </label>
                                            </div>
                                            <div className="level-item mr-0">
                                                <Dropdown id="tagFilterDropdown"
                                                          text={tagFilter ? intl.formatMessage({id: "tag." + tagFilter}) : ""}
                                                          content={<>
                                                              <Link to="#" className="dropdown-item"
                                                                    onClick={() => {
                                                                        resetValueData()
                                                                        filterAllValues()
                                                                    }}>
                                                                  <FormattedMessage id={"tag.all"} />
                                                              </Link>
                                                              <hr className="dropdown-divider" />
                                                              <Link to="#" className="dropdown-item"
                                                                    onClick={() => {
                                                                        setTagFilter("name")
                                                                        resetValueData()
                                                                    }}>
                                                                  <FormattedMessage id={"tag.name"} />
                                                              </Link>
                                                              <Link to="#" className="dropdown-item"
                                                                    onClick={() => {
                                                                        setTagFilter("type")
                                                                        resetValueData()
                                                                    }}>
                                                                  <FormattedMessage id={"tag.type"} />
                                                              </Link>
                                                              <Link to="#" className="dropdown-item"
                                                                    onClick={() => {
                                                                        setTagFilter("unit")
                                                                        resetValueData()
                                                                        dispatch(getUnits())
                                                                    }}>
                                                                  <FormattedMessage id={"tag.unit"} />
                                                              </Link>
                                                              <Link to="#" className="dropdown-item"
                                                                    onClick={() => {
                                                                        setTagFilter("percent")
                                                                        resetValueData()
                                                                    }}>
                                                                  <FormattedMessage id={"tag.percent"} />
                                                              </Link>
                                                              <Link to="#" className="dropdown-item"
                                                                    onClick={() => {
                                                                        setTagFilter("price")
                                                                        resetValueData()
                                                                    }}>
                                                                  <FormattedMessage id={"tag.price"} />
                                                              </Link>
                                                          </>}
                                                />
                                            </div>
                                            <div className="level-item">
                                                <div className="field has-addons">
                                                    {tagFilter === "name" ?
                                                        <Search size={"normal"}
                                                                placeholder={intl.formatMessage({id: "placeholder.filter_by_name"})}
                                                                value={tagNameFilter}
                                                                inputChange={(e) => setTagNameFilter(e.target.value)}
                                                                maxLength={50}
                                                                isLoading={(isGettingValues && tagNameFilter.trim() !== "")}
                                                                disabledButton={tagNameFilter.trim() === ""}
                                                                buttonClick={(e) => filterValuesByTagName(e)}
                                                                buttonText={intl.formatMessage({id: "general.search"})}
                                                        />
                                                        :
                                                        tagFilter === "type" ?
                                                            <Dropdown id="tagTypesDropdown"
                                                                      text={typeFilter ? intl.formatMessage({id: "tag_type." + typeFilter}) : "-----"}
                                                                      content={<>
                                                                          {TAG_TYPES?.map((type, i) => (
                                                                              <Link to="#" className="dropdown-item" key={i}
                                                                                    onClick={() => {
                                                                                        setTypeFilter(type)
                                                                                        filterValuesByTagType(type)
                                                                                    }}>
                                                                                  <FormattedMessage id={"tag_type." + type} />
                                                                              </Link>
                                                                          ))}
                                                                      </>}
                                                            />
                                                            :
                                                            tagFilter === "unit" ?
                                                                <Dropdown id="tagUnitsDropdown"
                                                                          text={valueUnitFilter ? valueUnitFilter : "-----"}
                                                                          isLoading={isGettingUnits}
                                                                          content={<>
                                                                              {units?.map((unit, i) => (
                                                                                  <Link to="#" className="dropdown-item" key={i}
                                                                                        onClick={() => {
                                                                                            setValueUnitFilter(unit.type)
                                                                                            filterValuesByUnit(unit.type)
                                                                                        }}>
                                                                                      {unit.type}
                                                                                  </Link>
                                                                              ))}
                                                                          </>}
                                                                />
                                                                :
                                                                tagFilter === "percent" || tagFilter === "price" ?
                                                                    <>
                                                                        <div className="control">
                                                                            <input className="input"
                                                                                   type="number"
                                                                                   placeholder={intl.formatMessage({id: "placeholder.min"})}
                                                                                   value={valueMinFilter}
                                                                                   onChange={(e) => setValueMinFilter(e.target.value)}
                                                                                   min={0}
                                                                                   max={9999999}
                                                                                   step={0.1}
                                                                                   onWheel={(e) => e.target.blur()}
                                                                            />
                                                                        </div>
                                                                        <div className="control">
                                                                            <button className="button is-static" tabIndex="-1">-</button>
                                                                        </div>
                                                                        <div className="control">
                                                                            <input className="input"
                                                                                   type="number"
                                                                                   placeholder={intl.formatMessage({id: "placeholder.max"})}
                                                                                   value={valueMaxFilter}
                                                                                   onChange={(e) => setValueMaxFilter(e.target.value)}
                                                                                   min={0}
                                                                                   max={9999999}
                                                                                   step={0.1}
                                                                                   onWheel={(e) => e.target.blur()}
                                                                            />
                                                                        </div>
                                                                        <button className={`button is-link ${isGettingValues &&
                                                                        valueMinFilter.trim() !== "" &&
                                                                        valueMaxFilter.trim() !== "" ? 'is-loading' : ''}`}
                                                                                disabled={valueMinFilter.trim() === "" || valueMaxFilter.trim() === ""}
                                                                                onClick={(e) => filterValuesByValue(e)}>
                                                                            <FormattedMessage id="general.search" />
                                                                        </button>
                                                                    </>
                                                                    :
                                                                    null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <TagsTable />
                                </>
                                :
                                tab.current === 3 ?
                                    <>
                                        <div className="level">
                                            <div className="level-left">
                                                <div className="level-item">
                                                    <label className="label">
                                                        <FormattedMessage id="general.filter_by" />:
                                                    </label>
                                                </div>
                                                <div className="level-item mr-0">
                                                    <Dropdown id="catToolBreakFilterDropdown"
                                                              text={catToolBreakFilter ? intl.formatMessage({id: "cat_tool_break." + catToolBreakFilter}) : ""}
                                                              content={<>
                                                                  <Link to="#" className="dropdown-item"
                                                                        onClick={() => {
                                                                            resetRatePercentageData()
                                                                            filterAllRatePercentages()
                                                                        }}>
                                                                      <FormattedMessage id={"cat_tool_break.all"} />
                                                                  </Link>
                                                                  <hr className="dropdown-divider" />
                                                                  <Link to="#" className="dropdown-item"
                                                                        onClick={() => {
                                                                            setCatToolBreakFilter("name")
                                                                            resetRatePercentageData()
                                                                        }}>
                                                                      <FormattedMessage id={"cat_tool_break.name"} />
                                                                  </Link>
                                                                  <Link to="#" className="dropdown-item"
                                                                        onClick={() => {
                                                                            setCatToolBreakFilter("percent_range")
                                                                            resetRatePercentageData()
                                                                        }}>
                                                                      <FormattedMessage id={"cat_tool_break.percent_range"} />
                                                                  </Link>
                                                                  <Link to="#" className="dropdown-item"
                                                                        onClick={() => {
                                                                            setCatToolBreakFilter("percent")
                                                                            resetRatePercentageData()
                                                                        }}>
                                                                      <FormattedMessage id={"cat_tool_break.percent"} />
                                                                  </Link>
                                                              </>}
                                                    />
                                                </div>
                                                <div className="level-item">
                                                    <div className="field has-addons">
                                                        {catToolBreakFilter === "name" ?
                                                            <Dropdown id="catToolBreakNamesDropdown"
                                                                      text={catToolBreakNameFilter ? catToolBreakNameFilter : "-----"}
                                                                      content={<>
                                                                          {CAT_TOOL_BREAK_TYPES?.map((type, i) => (
                                                                              <Link to="#" className="dropdown-item" key={i}
                                                                                    onClick={() => {
                                                                                        setCatToolBreakNameFilter(type)
                                                                                        filterRatePercentagesByName(type)
                                                                                    }}>
                                                                                  {type}
                                                                              </Link>
                                                                          ))}
                                                                      </>}
                                                            />
                                                            :
                                                            catToolBreakFilter === "percent_range" || catToolBreakFilter === "percent" ?
                                                                <>
                                                                    <div className="control">
                                                                        <input className="input"
                                                                               type="number"
                                                                               placeholder={intl.formatMessage({id: "placeholder.min"})}
                                                                               value={ratePercentageMinFilter}
                                                                               onChange={(e) => setRatePercentageMinFilter(e.target.value)}
                                                                               min={0}
                                                                               max={9999999}
                                                                               step={0.1}
                                                                               onWheel={(e) => e.target.blur()}
                                                                        />
                                                                    </div>
                                                                    <div className="control">
                                                                        <button className="button is-static" tabIndex="-1">-</button>
                                                                    </div>
                                                                    <div className="control">
                                                                        <input className="input"
                                                                               type="number"
                                                                               placeholder={intl.formatMessage({id: "placeholder.max"})}
                                                                               value={ratePercentageMaxFilter}
                                                                               onChange={(e) => setRatePercentageMaxFilter(e.target.value)}
                                                                               min={0}
                                                                               max={9999999}
                                                                               step={0.1}
                                                                               onWheel={(e) => e.target.blur()}
                                                                        />
                                                                    </div>
                                                                    <button className={`button is-link ${isGettingRatePercentages &&
                                                                    ratePercentageMinFilter.trim() !== "" &&
                                                                    ratePercentageMaxFilter.trim() !== "" ? 'is-loading' : ''}`}
                                                                            disabled={ratePercentageMinFilter.trim() === "" || ratePercentageMaxFilter.trim() === ""}
                                                                            onClick={(e) => filterRatePercentagesByPercent(e)}>
                                                                        <FormattedMessage id="general.search" />
                                                                    </button>
                                                                </>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <CatToolBreaksTable />
                                    </>
                                    :
                                    null
                        }
                    </>
                    :
                    null
                }
            </div>
        </>
    )
}
