import {axiosInstance} from '../../../hoc/Axios/Axios'
import * as actions from '../../Actions'
import * as toastTypes from '../../../consts/enums/toastTypes'
import {encodeParameter} from '../../../utils/encodeParameter'

export const getValues = (rateId, sort = "tags.type", order = "asc", page) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_VALUES})
    dispatch({type: actions.GETTING_VALUES})
    try {
        const url = "/values/" + rateId + (page ? "?sort=" + sort + "&order=" + order + "&page=" + page : "/all")
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_VALUES, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_VALUES})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.values_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.VALUE_ERROR, payload: error.response})
    }
}

export const getValuesByTag = (tag, rateId, sort = "tags.type", order = "asc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_VALUES})
    dispatch({type: actions.GETTING_VALUES})
    try {
        tag = encodeParameter(tag)
        const url = "/values/by-tag/" + tag + "/" + rateId + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_VALUES, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_VALUES})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.values_by_tag_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.VALUE_ERROR, payload: error.response})
    }
}

export const getValuesByTagType = (tagType, rateId, sort = "tags.type", order = "asc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_VALUES})
    dispatch({type: actions.GETTING_VALUES})
    try {
        tagType = encodeParameter(tagType)
        const url = "/values/by-tag-type/" + tagType + "/" + rateId + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_VALUES, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_VALUES})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.values_by_tag_type_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.VALUE_ERROR, payload: error.response})
    }
}

export const getValuesByUnit = (unit, rateId, sort = "tags.type", order = "asc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_VALUES})
    dispatch({type: actions.GETTING_VALUES})
    try {
        unit = encodeParameter(unit)
        const url = "/values/by-unit/" + unit + "/" + rateId + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_VALUES, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_VALUES})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.values_by_unit_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.VALUE_ERROR, payload: error.response})
    }
}

export const getValuesByValue = (min, max, value, rateId, sort = "tags.type", order = "asc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_VALUES})
    dispatch({type: actions.GETTING_VALUES})
    try {
        const url = "/values/by-value/" + min + "/" + max + "/values." + value + "/" + rateId + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_VALUES, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_VALUES})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.values_by_" + value + "_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.VALUE_ERROR, payload: error.response})
    }
}

export const getAllValues = (rateId) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_ALL_VALUES})
    dispatch({type: actions.GETTING_ALL_VALUES})
    try {
        const res = await axiosInstance.get("/values/" + rateId + "/all")
        dispatch({type: actions.GET_ALL_VALUES, payload: res.data})
    } catch (error) {
        dispatch({type: actions.VALUE_ERROR, payload: error.response})
    }
}

export const storeValue = (value) => async (dispatch, getState) => {
    try {
        await axiosInstance.post("/values/store", value)
        dispatch({type: actions.VALUE_CREATED, payload: {status: true}})
    } catch (error) {
        dispatch({type: actions.VALUE_ERROR, payload: error.response})
    }
}

export const updateValue = (value) => async (dispatch, getState) => {
    try {
        await axiosInstance.put("/values/" + value.id, value)
        dispatch({type: actions.VALUE_UPDATED, payload: {status: true}})
    } catch (error) {
        dispatch({type: actions.VALUE_ERROR, payload: error.response})
    }
}

export const deleteValue = (id) => async (dispatch, getState) => {
    try {
        await axiosInstance.delete("/values/" + id)
        dispatch({type: actions.VALUE_DELETED, payload: {status: true}})
    } catch (error) {
        dispatch({type: actions.VALUE_ERROR, payload: error.response})
    }
}
