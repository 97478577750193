import * as actions from '../../Actions'

const initialState = {
    manufacturers: [],
    manufacturersIdcp: [],
    isGettingManufacturers: false,
    isGettingManufacturersIdcp: false,
    error: null
}

export default function reducer (state = initialState, action) {
    switch (action.type) {
        case actions.GET_MANUFACTURERS:
            return {
                ...state,
                manufacturers: action.payload.data,
                isGettingManufacturers: false
            }
        case actions.GET_MANUFACTURERS_IDCP:
            return {
                ...state,
                manufacturersIdcp: action.payload.data,
                isGettingManufacturersIdcp: false
            }
        case actions.GETTING_MANUFACTURERS:
            return {
                ...state,
                isGettingManufacturers: true
            }
        case actions.GETTING_MANUFACTURERS_IDCP:
            return {
                ...state,
                isGettingManufacturersIdcp: true
            }
        case actions.MANUFACTURER_ERROR:
            return {
                ...state,
                error: action.payload,
                isGettingManufacturers: false,
                isGettingManufacturersIdcp: false
            }
        case actions.RESET_MANUFACTURERS:
            return {
                ...state,
                manufacturers: []
            }
        case actions.RESET_MANUFACTURERS_IDCP:
            return {
                ...state,
                manufacturersIdcp: []
            }
        case actions.RESET_MANUFACTURER_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
