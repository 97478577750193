import {useEffect, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import {getUserNotifications} from '../../store/reducers/notification/notificationActions'
import * as notificationTypes from '../../consts/enums/notificationTypes'
import {Loader} from '../UI/Loader'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBell, faCalendarMinus, faChartLine} from '@fortawesome/free-solid-svg-icons'

export const NotificationsMenu = ({loggedUser = null}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userNotifications = useSelector(state => state.notification.userNotifications)
    const isGettingUserNotifications = useSelector(state => state.notification.isGettingUserNotifications)
    const [newNotifications, setNewNotifications] = useState(false)

    useEffect(() => {
        if (userNotifications.length < 1) {
            dispatch(getUserNotifications(loggedUser.id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!isGettingUserNotifications && userNotifications.length > 0) {
            setNewNotifications(true)
        } else {
            setNewNotifications(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGettingUserNotifications])

    const goToCustomerRate = (e, notification) => {
        e.preventDefault()
        navigate("/customer-rates/" + notification.customer_rate_id, {
            state: {
                customerRate: {
                    customer_id: notification.customer_rate_customer_id,
                    manufacturer_id: notification.customer_rate_manufacturer_id,
                    project_id: notification.customer_rate_project_id
                }
            }
        })
    }

    return (
        <div className="navbar-item has-dropdown is-hoverable">
            <Link to="#" className="navbar-link is-size-6">
                <span className={`icon ${newNotifications ? 'has-text-link' : ''}`}>
                    <FontAwesomeIcon icon={faBell} />
                </span>
            </Link>
            <div className="navbar-dropdown is-right">
                <div className="level navbar-item mb-0 is-mobile">
                    <div className="level-left">
                        <div className="level-item">
                            <label className="label mr-3">
                                <FormattedMessage id="notifications.news" />
                            </label>
                        </div>
                    </div>
                    <div className="level-right">
                        <div className="level-item">
                            <Link to="/notifications" className="navbar-item is-size-6">
                                <FormattedMessage id="notifications.all" />
                            </Link>
                        </div>
                    </div>
                </div>
                {isGettingUserNotifications ?
                    <Loader />
                    :
                    <>
                        {userNotifications.length > 0 ?
                            <>
                                {userNotifications?.map((notification, i) => (
                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                    <a className="navbar-item is-size-7" key={i}
                                       onClick={(e) => goToCustomerRate(e, notification)}>
                                        <span className="icon has-text-link">
                                            <FontAwesomeIcon icon={notification.type === notificationTypes.EXPIRED_EFFECTIVE_DATE ?
                                                faCalendarMinus : faChartLine} />
                                        </span>
                                        <span>
                                            <FormattedMessage id={"notifications." + (notification.type === notificationTypes.EXPIRED_EFFECTIVE_DATE ?
                                                "expired_effective_date" : "exchange_rate_fluctuation")} />
                                            <label className="label is-size-7">
                                                {notification.customer_rate_name}
                                            </label>
                                        </span>
                                    </a>
                                ))}
                            </>
                            :
                            <label className="label navbar-item is-size-7">
                                <FormattedMessage id="notifications.no_new_notifications" />
                            </label>
                        }
                    </>
                }
            </div>
        </div>
    )
}
