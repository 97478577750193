import * as actions from '../../Actions'

const initialState = {
    type: null,
    message: null
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.SHOW_TOAST:
            return {
                ...state,
                type: action.payload.type,
                message: action.payload.message
            }
        case actions.RESET_TOAST:
            return {
                ...state,
                type: null,
                message: null
            }
        default:
            return state
    }
}
