import Spanish from '../lang/es.json'
import ExportSpanish from '../lang/export/es.json'
import ExportEnglish from '../lang/export/en.json'
import * as userTypes from './enums/userTypes'
import * as tagTypes from './enums/tagTypes'
import * as catToolBreakTypes from './enums/catToolBreakTypes'
import * as notificationTypes from './enums/notificationTypes'

export const LOCALES = [
    {
        "name": "es",
        "object": Spanish
    }
]

export const EXPORT_LANGUAGES = [
    {
        "name": "es",
        "object": ExportSpanish
    },
    {
        "name": "en",
        "object": ExportEnglish
    }
]

export const USER_ROLES = [
    userTypes.RATE_VIEWER,
    userTypes.RATE_MANAGER,
    userTypes.MANAGER
]

export const TAG_TYPES = [
    tagTypes.CONTENT,
    tagTypes.SERVICE
]

export const CAT_TOOL_BREAK_TYPES = [
    catToolBreakTypes.NEW_WORDS,
    catToolBreakTypes.FUZZY_MATCHES,
    catToolBreakTypes.EXACT_MATCHES,
    catToolBreakTypes.CONTEXT_EXACT_MATCHES,
    catToolBreakTypes.REPETITIONS
]

export const NOTIFICATION_TYPES = [
    notificationTypes.EXPIRED_EFFECTIVE_DATE,
    notificationTypes.EXCHANGE_RATE_FLUCTUATION
]

export const STANDARD_CURRENCY_CODE = 'E'
export const STANDARD_CURRENCY_ABBREVIATION = 'EUR'

export const EXACT_MATCHES_PERCENT = 100
export const CONTEXT_EXACT_MATCHES_PERCENT = 101

export const UNIT_WORD_ID = 1

export const PAGINATION_PAGE_ROWS = 25

export const DECIMAL_DIGITS = 3

export const MAX_REFERENCE_TAGS = 5

export const COMPANY_DATA_IN_EXPORT = '© 2022 iDISC Information Technologies, S.L. | All rights reserved.| Passeig del Progrés, 96 - 08640 Olesa de Montserrat (Barcelona) | Tel. (+34) 93 778 73 00'
