import {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import * as actions from '../../store/Actions'
import {DraggableModal} from '../UI/DraggableModal'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPercent} from '@fortawesome/free-solid-svg-icons'

export const ModalApplyPercentage = ({title = "", setPercentage = null}) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const [percent, setPercent] = useState("")

    const onHideClick = (e) => {
        e.preventDefault()
        dispatch({type: actions.HIDE_MODAL})
    }

    const accept = (e) => {
        e.preventDefault()
        setPercentage(percent)
        dispatch({type: actions.HIDE_MODAL})
    }

    const Body = () => {
        return (
            <div className="columns">
                <div className="column is-one-third">
                    <div className="control has-icons-right">
                        <input className="input"
                               type="number"
                               value={percent}
                               placeholder={intl.formatMessage({id: "placeholder.percent"})}
                               onChange={(e) => setPercent(e.target.value)}
                               min={-100}
                               max={1000}
                               onWheel={(e) => e.target.blur()}
                        />
                        <span className="icon is-right">
                            <FontAwesomeIcon icon={faPercent} />
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    const Foot = () => {
        return (
            <>
                <button className="button is-link"
                        disabled={percent === ""}
                        onClick={(e) => accept(e)}>
                    <FormattedMessage id="general.accept" />
                </button>
                <button className="button"
                        onClick={(e) => onHideClick(e)}>
                    <FormattedMessage id="general.cancel" />
                </button>
            </>
        )
    }

    return (
        <DraggableModal title={title} body={Body()} foot={Foot()} onHideClick={onHideClick} />
    )
}
