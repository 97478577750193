import {axiosInstance} from '../../../hoc/Axios/Axios'
import * as actions from '../../Actions'
import * as toastTypes from '../../../consts/enums/toastTypes'
import {encodeParameter} from '../../../utils/encodeParameter'

export const getNotifications = (sort = "notifications.created_at", order = "desc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_NOTIFICATIONS})
    dispatch({type: actions.GETTING_NOTIFICATIONS})
    try {
        const url = "/notifications?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_NOTIFICATIONS, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_NOTIFICATIONS})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.notifications_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.NOTIFICATION_ERROR, payload: error.response})
    }
}

export const getNotificationsByType = (type, sort = "notifications.created_at", order = "desc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_NOTIFICATIONS})
    dispatch({type: actions.GETTING_NOTIFICATIONS})
    try {
        type = encodeParameter(type)
        const url = "/notifications/by-type/" + type + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_NOTIFICATIONS, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_NOTIFICATIONS})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.notifications_by_type_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.NOTIFICATION_ERROR, payload: error.response})
    }
}

export const getNotificationsByDate = (date, sort = "notifications.created_at", order = "desc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_NOTIFICATIONS})
    dispatch({type: actions.GETTING_NOTIFICATIONS})
    try {
        const url = "/notifications/by-date/" + date + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_NOTIFICATIONS, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_NOTIFICATIONS})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.notifications_by_date_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.NOTIFICATION_ERROR, payload: error.response})
    }
}

export const getUserNotifications = (userId) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_NOTIFICATIONS_USER})
    dispatch({type: actions.GETTING_NOTIFICATIONS_USER})
    try {
        const res = await axiosInstance.get("/notifications/user/" + userId)
        dispatch({type: actions.GET_NOTIFICATIONS_USER, payload: res.data})
    } catch (error) {
        dispatch({type: actions.NOTIFICATION_ERROR, payload: error.response})
    }
}
