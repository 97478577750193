import {useEffect, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import * as actions from '../../store/Actions'
import {getValues} from '../../store/reducers/value/valueActions'
import {DraggableModal} from '../UI/DraggableModal'
import {Tag} from '../UI/Tag'
import * as tagTypes from '../../consts/enums/tagTypes'

export const ModalSelectTags = ({title = "", additionalData = null}) => {
    const dispatch = useDispatch()
    const values = useSelector(state => state.value.values)
    const isGettingValues = useSelector(state => state.value.isGettingValues)
    const [contentTagSelectValue, setContentTagSelectValue] = useState("")
    const [serviceTagSelectValue, setServiceTagSelectValue] = useState("")
    const [contentTag, setContentTag] = useState("")
    const [serviceTags, setServiceTags] = useState([])

    useEffect(() => {
        if (additionalData.rateId) {
            dispatch(getValues(additionalData.rateId))
        }
        getTags()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getTags = () => {
        const content = additionalData.tags.filter(value => value.tag_type === tagTypes.CONTENT)
        if (content.length > 0) {
            setContentTag(additionalData.tags[0])
            const updateValues = [...additionalData.tags]
            updateValues.splice(0, 1)
            setServiceTags([...updateValues])
            setContentTagSelectValue(additionalData.tags[0].id)
        } else {
            setServiceTags([...additionalData.tags])
        }
    }

    const onHideClick = (e) => {
        e.preventDefault()
        dispatch({type: actions.HIDE_MODAL})
    }

    const filterContentTags = () => {
        // if the first service tag has a price or, no price or percentage
        if (serviceTags[0] && (serviceTags[0]?.price !== null || (serviceTags[0]?.price === null && serviceTags[0]?.percent === null))) {
            return []
        }
        const unit = serviceTags[0]?.unit_id ? serviceTags[0]?.unit_type : additionalData.unit
        if (unit) {
            return values.filter(value => value.tag_type === tagTypes.CONTENT &&
                value.price === null &&
                value.percent !== null &&
                (value.unit_type === unit || !value.unit_id))
        }
        if (serviceTags[0] && serviceTags[0]?.percent !== null) {
            return values.filter(value => value.tag_type === tagTypes.CONTENT &&
                (value.price !== null || value.percent !== null))
        }
        return values.filter(value => value.tag_type === tagTypes.CONTENT)
    }

    const filterServiceTags = () => {
        // if there is a content tag with no price or percent or the first service tag has no price or percent
        if ((contentTag && contentTag.price === null && contentTag.percent === null) ||
            (serviceTags[0] && serviceTags[0]?.price === null && serviceTags[0]?.percent === null)) {
            return []
        }
        const unit = contentTag.unit_id ? contentTag.unit_type : serviceTags[0]?.unit_id ? serviceTags[0]?.unit_type : additionalData.unit
        if (unit) {
            return values.filter(value => value.tag_type === tagTypes.SERVICE &&
                value.price === null &&
                value.percent !== null &&
                (value.unit_type === unit || !value.unit_id) &&
                !serviceTags.find(serviceTag => serviceTag.id === value.id))
        }
        if (contentTag || serviceTags.length > 0) {
            return values.filter(value => value.tag_type === tagTypes.SERVICE &&
                value.percent !== null &&
                !serviceTags.find(serviceTag => serviceTag.id === value.id))
        }
        return values.filter(value => value.tag_type === tagTypes.SERVICE)
    }

    const changeContentTag = (id) => {
        if (id) {
            setContentTag(values.find(value => value.id === parseInt(id)))
        } else {
            setContentTag("")
        }
        setContentTagSelectValue(id)
        setServiceTagSelectValue("")
    }

    const changeServiceTag = (id) => {
        setServiceTagSelectValue(id)
    }

    const addServiceTag = (e) => {
        e.preventDefault()
        setServiceTags([...serviceTags, values.find(value => value.id === parseInt(serviceTagSelectValue))])
        setServiceTagSelectValue("")
    }

    const removeContentTag = (e) => {
        e.preventDefault()
        setContentTag("")
        setContentTagSelectValue("")
    }

    const removeServiceTag = (e, i) => {
        e.preventDefault()
        const updateServiceTags = [...serviceTags]
        updateServiceTags.splice(i, 1)
        setServiceTags(updateServiceTags)
    }

    const accept = (e) => {
        e.preventDefault()
        if (contentTag) {
            additionalData.selectTags([contentTag, ...serviceTags], values)
        } else {
            additionalData.selectTags(serviceTags, values)
        }
        dispatch({type: actions.HIDE_MODAL})
    }

    const Body = () => {
        return (
            <>
                <div className="field">
                    <label className="label">
                        <FormattedMessage id="tag_type.CONTENT" />:
                    </label>
                    <div className={`select ${isGettingValues ? 'is-loading' : ''}`}>
                        <select value={contentTagSelectValue}
                                onChange={(e) => changeContentTag(e.target.value)}>
                            <option value="">
                                -----
                            </option>
                            {filterContentTags().map((value, i) => (
                                <option key={i} value={value.id}>
                                    {value.tag_name} {value.unit_id ? "(" + value.unit_type + ")" : ""}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="field">
                    <label className="label">
                        <FormattedMessage id="tag_type.SERVICE" />:
                    </label>
                    <div className="field has-addons">
                        <div className={`select ${isGettingValues ? 'is-loading' : ''}`}>
                            <select value={serviceTagSelectValue}
                                    onChange={(e) => changeServiceTag(e.target.value)}>
                                <option value="">
                                    -----
                                </option>
                                {filterServiceTags().map((value, i) => (
                                    <option key={i} value={value.id}>
                                        {value.tag_name} {value.unit_id ? "(" + value.unit_type + ")" : ""}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <button className="button is-link"
                                disabled={!serviceTagSelectValue}
                                onClick={(e) => addServiceTag(e)}>
                            <FormattedMessage id="general.add" />
                        </button>
                    </div>
                </div>
                {contentTag || (serviceTags.length > 0) ?
                    <>
                        <hr className="dropdown-divider" />
                        <div className="field">
                            <label className="label">
                                <FormattedMessage id="consult_price.selected_tags" />:
                            </label>
                            {contentTag ?
                                <Tag text={contentTag.tag_name + (contentTag.unit_id ? " (" + contentTag.unit_type + ")" : "")}
                                     handler={(e) => removeContentTag(e)}
                                />
                                :
                                null
                            }
                            {serviceTags.map((serviceTag, i) => (
                                <Tag key={i} text={serviceTag.tag_name + (serviceTag.unit_id ? " (" + serviceTag.unit_type + ")" : "")}
                                     handler={(e) => removeServiceTag(e, i)}
                                />
                            ))}
                        </div>
                    </>
                    :
                    null
                }
            </>
        )
    }

    const Foot = () => {
        return (
            <>
                <button className="button is-link"
                        disabled={!contentTag && serviceTags.length < 1}
                        onClick={(e) => accept(e)}>
                    <FormattedMessage id="general.accept" />
                </button>
                <button className="button"
                        onClick={(e) => onHideClick(e)}>
                    <FormattedMessage id="general.cancel" />
                </button>
            </>
        )
    }

    return (
        <DraggableModal title={title} body={Body()} foot={Foot()} onHideClick={onHideClick} />
    )
}
