export const IconButton = ({color = "link", size = "normal", isLoading = false,
                               disabled = false, title = "", handler = null, icon = null}) => {
    return (
        <button className={`button is-${color} is-${size} ${isLoading ? 'is-loading' : ''}`}
                disabled={disabled}
                title={title}
                onClick={handler}>
            <span className="icon">
                {icon}
            </span>
        </button>
    )
}
