import {useState} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import {LanguageSelector} from './LanguagesSelector'
import {NotificationsMenu} from './NotificationsMenu'
import {UserMenu} from './UserMenu'
import {NavbarItem} from '../UI/NavbarItem'
import {NavbarItemDropdown} from '../UI/NavbarItemDropdown'
import * as userTypes from '../../consts/enums/userTypes'
import logo from '../../assets/logo.png'

export const Header = () => {
    useNavigate()
    const intl = useIntl()
    const loggedUser = useSelector(state => state.auth.user)
    const userType = useSelector(state => (state.auth.user) ? state.auth.user.role : null)
    const [isActive, setIsActive] = useState(false)

    const HomeMenu = () => {
        return <NavbarItem to="/" text={intl.formatMessage({id: "menu.home"})} />
    }

    const RatesMenu = () => {
        const dropdownItems = [
            {
                to: "/standard-rates",
                text: intl.formatMessage({id: "menu.standard_rates"})
            },
            {
                to: "/customer-rates",
                text: intl.formatMessage({id: "menu.customer_rates"})
            },
            {
                to: "/consult-price",
                text: intl.formatMessage({id: "menu.consult_price"})
            }
        ]
        return (
            <NavbarItemDropdown text={intl.formatMessage({id: "menu.rates"})} dropdownItems={dropdownItems} />
        )
    }

    const ConfigMenu = () => {
        const dropdownItems = [
            {
                to: "#",
                text: intl.formatMessage({id: "menu.active_standard_rate"}),
                menu: [
                    {
                        to: "/language-pairs",
                        text: intl.formatMessage({id: "menu.language_pairs"})
                    },
                    {
                        to: "/tag-values",
                        text: intl.formatMessage({id: "menu.tag_values"})
                    },
                    {
                        to: "/cat-tool-breaks",
                        text: intl.formatMessage({id: "menu.cat_tool_breaks"})
                    }
                    ]
            },
            {
                to: "/tags",
                text: intl.formatMessage({id: "menu.tags"})
            },
            {
                to: "/units",
                text: intl.formatMessage({id: "menu.units"})
            },
            {
                to: "/currencies",
                text: intl.formatMessage({id: "menu.currencies"})
            }
        ]
        if (userType === userTypes.MANAGER) {
            dropdownItems.push({
                to: "/users",
                text: intl.formatMessage({id: "menu.users"})
            })
        }

        return (
            <NavbarItemDropdown text={intl.formatMessage({id: "menu.config"})} dropdownItems={dropdownItems} />
        )
    }

    const Menu = () => {
        if (userType === userTypes.MANAGER || userType === userTypes.RATE_MANAGER) {
            return (
                <>
                    <HomeMenu />
                    <RatesMenu />
                    <ConfigMenu />
                </>
            )
        } else if (userType === userTypes.RATE_VIEWER) {
            return (
                <>
                    <HomeMenu />
                </>
            )
        }
        return null
    }

    return (
        <nav className="navbar">
            <div className="navbar-brand">
                <Link to="/" className="navbar-item">
                    <img src={logo} alt={intl.formatMessage({id: "general.logo"})} />
                </Link>
                <span className={`navbar-burger ${isActive ? 'is-active' : ''}`}
                      onClick={() => setIsActive(!isActive)}
                      data-target="menu">
                    <span />
                    <span />
                    <span />
                </span>
            </div>
            <div id="menu" className={`navbar-menu ml-6 ${isActive ? 'is-active' : ''}`}
                 onClick={() => setIsActive(false)}>
                <div className="navbar-start">
                    <Menu />
                </div>
                <div className="navbar-end">
                    <LanguageSelector />
                    {loggedUser ?
                        <>
                            {userType === userTypes.MANAGER || userType === userTypes.RATE_MANAGER ?
                                <NotificationsMenu loggedUser={loggedUser} />
                                :
                                null
                            }
                            <UserMenu loggedUser={loggedUser} />
                        </>
                        :
                        null
                    }
                </div>
            </div>
        </nav>
    )
}
