import {axiosInstance} from '../../../hoc/Axios/Axios'
import * as actions from '../../Actions'
import * as toastTypes from '../../../consts/enums/toastTypes'
import {encodeParameter} from '../../../utils/encodeParameter'

export const getUsers = (sort = "username", order = "asc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_USERS})
    dispatch({type: actions.GETTING_USERS})
    try {
        const url = "/users?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_USERS, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_USERS})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.users_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.USER_ERROR, payload: error.response})
    }
}

export const getUsersByUsername = (username, sort = "username", order = "asc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_USERS})
    dispatch({type: actions.GETTING_USERS})
    try {
        username = encodeParameter(username)
        const url = "/users/by-username/" + username + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_USERS, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_USERS})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.users_by_username_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.USER_ERROR, payload: error.response})
    }
}

export const getUsersByRole = (role, sort = "username", order = "asc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_USERS})
    dispatch({type: actions.GETTING_USERS})
    try {
        role = encodeParameter(role)
        const url = "/users/by-role/" + role + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_USERS, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_USERS})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.users_by_role_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.USER_ERROR, payload: error.response})
    }
}

export const getUsersIdcp = (username) => async (dispatch, getState) => {
    dispatch({type: actions.GETTING_USERS_IDCP})
    try {
        username = encodeParameter(username)
        const res = await axiosInstance.get("/users-idcp/" + username)
        dispatch({type: actions.GET_USERS_IDCP, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.user_does_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.USER_ERROR, payload: error.response})
    }
}

export const storeUser = (user) => async (dispatch, getState) => {
    try {
        await axiosInstance.post("/users/store", user)
        dispatch({type: actions.USER_CREATED, payload: {status: true}})
    } catch (error) {
        dispatch({type: actions.USER_ERROR, payload: error.response})
    }
}

export const updateUser = (user) => async (dispatch, getState) => {
    try {
        await axiosInstance.put("/users/" + user.id, user)
        dispatch({type: actions.USER_UPDATED, payload: {status: true}})
    } catch (error) {
        dispatch({type: actions.USER_ERROR, payload: error.response})
    }
}

export const disableUser = (id) => async (dispatch, getState) => {
    try {
        await axiosInstance.delete("/users/" + id)
        dispatch({type: actions.USER_DELETED, payload: {status: true}})
    } catch (error) {
        dispatch({type: actions.USER_ERROR, payload: error.response})
    }
}
