import {useEffect, useRef, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import * as actions from '../store/Actions'
import {deleteRatePercentage, getRatePercentages, getRatePercentagesByName, getRatePercentagesByPercent,
    storeRatePercentage, updateRatePercentage} from '../store/reducers/ratePercentage/ratePercentageActions'
import {getActiveStandardRates} from '../store/reducers/rate/rateActions'
import {Datatable} from '../components/Datatable/Datatable'
import {Title} from '../components/UI/Title'
import {Dropdown} from '../components/UI/Dropdown'
import {TableIconButtonEdit} from '../components/UI/TableIconButtonEdit'
import {TableIconButtonDelete} from '../components/UI/TableIconButtonDelete'
import {Pagination} from '../components/UI/Pagination'
import * as modalTypes from '../consts/enums/modalTypes'
import * as toastTypes from '../consts/enums/toastTypes'
import {CAT_TOOL_BREAK_TYPES, PAGINATION_PAGE_ROWS, STANDARD_CURRENCY_CODE} from '../consts/app'
import {createLocaleDateText} from '../utils/createLocaleDateText'

export const CatToolBreaks = () => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const ratePercentages = useSelector(state => state.ratePercentage.ratePercentages)
    const isGettingRatePercentages = useSelector(state => state.ratePercentage.isGettingRatePercentages)
    const pagination = useSelector(state => state.ratePercentage.pagination)
    const hasRatePercentageBeenCreated = useSelector(state => state.ratePercentage.ratePercentageCreated)
    const hasRatePercentageBeenUpdated = useSelector(state => state.ratePercentage.ratePercentageUpdated)
    const hasRatePercentageBeenDeleted = useSelector(state => state.ratePercentage.ratePercentageDeleted)
    const error = useSelector(state => state.ratePercentage.error)
    const activeStandardRates = useSelector(state => state.rate.activeStandardRates)
    const isGettingActiveStandardRates = useSelector(state => state.rate.isGettingActiveStandardRates)
    const locale = useSelector(state => state.locale.locale)
    const [activeStandardRate, setActiveStandardRate] = useState(null)
    const [catToolBreakFilter, setCatToolBreakFilter] = useState("all")
    const [nameFilter, setNameFilter] = useState("")
    const [minFilter, setMinFilter] = useState("")
    const [maxFilter, setMaxFilter] = useState("")
    const firstRender = useRef(true)
    const searchType = useRef({type: "all", search: {param1: "", param2: ""}})
    const sortType = useRef({sort: "cat_tool_breaks.match_percent_min", order: "desc"})

    useEffect(() => {
        dispatch(getActiveStandardRates())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (hasRatePercentageBeenCreated) {
            getData()
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.SUCCESS, message: "message.rate_percentage_created"}})
            dispatch({type: actions.RATE_PERCENTAGE_CREATED, payload: {status: false}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasRatePercentageBeenCreated])

    useEffect(() => {
        if (hasRatePercentageBeenUpdated) {
            getData()
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.SUCCESS, message: "message.rate_percentage_updated"}})
            dispatch({type: actions.RATE_PERCENTAGE_UPDATED, payload: {status: false}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasRatePercentageBeenUpdated])

    useEffect(() => {
        if (hasRatePercentageBeenDeleted) {
            getData()
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.SUCCESS, message: "message.rate_percentage_deleted"}})
            dispatch({type: actions.RATE_PERCENTAGE_DELETED, payload: {status: false}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasRatePercentageBeenDeleted])

    useEffect(() => {
        if (error) {
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.RESET_RATE_PERCENTAGE_ERROR})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    useEffect(() => {
        if (!isGettingActiveStandardRates && !firstRender.current) {
            if (activeStandardRates.length > 0) {
                setActiveStandardRate(activeStandardRates.find(standardRate => standardRate.currency_code === STANDARD_CURRENCY_CODE) ?? activeStandardRates[0])
            }
        }
        firstRender.current = false
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGettingActiveStandardRates])

    useEffect(() => {
        if (activeStandardRate) {
            getData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStandardRate])

    const getData = () => {
        setCatToolBreakFilter("all")
        searchType.current = {type: "all", search: {param1: "", param2: ""}}
        getRatePercentagesPerPage(1)
    }

    const resetStandardRate = (standardRate) => {
        setActiveStandardRate(standardRate)
        resetData()
    }

    const resetData = () => {
        setNameFilter("")
        setMinFilter("")
        setMaxFilter("")
    }

    const filterCatToolBreaksByName = (name) => {
        searchType.current = {type: "name", search: {param1: name, param2: ""}}
        dispatch(getRatePercentagesByName(name, activeStandardRate.id, sortType.current.sort, sortType.current.order, 1))
    }

    const filterCatToolBreaksByPercent = (e) => {
        e.preventDefault()
        if (parseFloat(minFilter) > parseFloat(maxFilter)) {
            dispatch({type: actions.SHOW_TOAST, payload: {type:toastTypes.ERROR, message: "error.min_greater_than_max"}})
        } else {
            searchType.current = {type: catToolBreakFilter, search: {param1: minFilter, param2: maxFilter}}
            dispatch(getRatePercentagesByPercent(minFilter, maxFilter, catToolBreakFilter, activeStandardRate.id,
                sortType.current.sort, sortType.current.order, 1))
        }
    }

    const sortCatToolBreaks = (column, sortDirection) => {
        sortType.current = {sort: column.sortField, order: sortDirection}
        getRatePercentagesPerPage(1)
    }

    const getRatePercentagesPerPage = (page) => {
        if (searchType.current.type === "all") {
            dispatch(getRatePercentages(activeStandardRate.id, sortType.current.sort, sortType.current.order, page))
        } else if (searchType.current.type === "name") {
            dispatch(getRatePercentagesByName(searchType.current.search.param1, activeStandardRate.id, sortType.current.sort, sortType.current.order, page))
        } else {
            dispatch(getRatePercentagesByPercent(searchType.current.search.param1, searchType.current.search.param2,
                searchType.current.type, activeStandardRate.id, sortType.current.sort, sortType.current.order, page))
        }
    }

    const newPercentage = (percentage) => {
        dispatch(storeRatePercentage(percentage))
        dispatch({type: actions.HIDE_MODAL})
        dispatch({type: actions.SHOW_MODAL, payload: {type:modalTypes.LOADING}})
    }

    const editPercentage = (percentage) => {
        dispatch(updateRatePercentage(percentage))
        dispatch({type: actions.HIDE_MODAL})
        dispatch({type: actions.SHOW_MODAL, payload: {type:modalTypes.LOADING}})
    }

    const deletePercentage = (id) => {
        dispatch(deleteRatePercentage(id))
        dispatch({type: actions.HIDE_MODAL})
        dispatch({type: actions.SHOW_MODAL, payload: {type:modalTypes.LOADING}})
    }

    const showCreatePercentage = (e) => {
        e.preventDefault()
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                type: modalTypes.NEW_PERCENTAGE,
                title: intl.formatMessage({id: "message_title.new_percentage"}),
                additionalData: {
                    newPercentage: newPercentage,
                    standardRateId: activeStandardRate.id
                }
            }
        })
    }

    const showEditPercentage = (e, percentage) => {
        e.preventDefault()
        e.stopPropagation()
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                type: modalTypes.EDIT_PERCENTAGE,
                title: intl.formatMessage({id: "message_title.edit_percentage"}),
                additionalData: {
                    percentage: percentage,
                    editPercentage: editPercentage
                }
            }
        })
    }

    const showDeleteConfirmation = (e, id) => {
        e.preventDefault()
        e.stopPropagation()
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                buttons: [
                    {
                        label: intl.formatMessage({id: "general.delete"}),
                        classes: 'button is-link',
                        handler: () => {
                            deletePercentage(id)
                        }
                    }
                ]
            }
        })
    }

    const CatToolBreaksTable = () => {
        const columns = [
            {
                name: intl.formatMessage({id: "cat_tool_break.name"}),
                selector: row => row.cat_tool_break_name + (row.cat_tool_break_match_percent_min !== null ?
                    " (" + row.cat_tool_break_match_percent_min + "%" + (row.cat_tool_break_match_percent_max ?
                        " - " + row.cat_tool_break_match_percent_max + "%)" : ")") : ""),
                wrap: true,
                sortable: true,
                sortField: 'cat_tool_breaks.match_percent_min'
            },
            {
                name: intl.formatMessage({id: "cat_tool_break.percent"}),
                selector: row => row.percent + "%",
                wrap: true,
                sortable: true,
                sortField: 'rate_percentages.percent'
            },
            {
                name: intl.formatMessage({id: "cat_tool_break.description"}),
                selector: row => row.description,
                wrap: true,
                sortable: true,
                sortField: 'rate_percentages.description'
            },
            {
                selector: row => (
                    <>
                        <TableIconButtonEdit handler={(e) => showEditPercentage(e, row)} />
                        <TableIconButtonDelete handler={(e) => showDeleteConfirmation(e, row.id)} />
                    </>
                ),
                right: true,
                wrap: true,
                grow: 0.1
            }
        ]
        return (
            <Datatable
                columns={columns}
                data={activeStandardRate ? ratePercentages : []}
                defaultSortAsc={sortType.current.order !== "asc"}
                onSort={sortCatToolBreaks}
                paginationComponent={() =>
                    <Pagination pagination={pagination} handler={getRatePercentagesPerPage}
                                additionalText={activeStandardRate ?
                                    intl.formatMessage({id: "datatable.of_standard_rate"}) +
                                    createLocaleDateText(locale.name, activeStandardRate.created_at.substring(0, 10)) +
                                    intl.formatMessage({id: "datatable.by_user"}) +
                                    activeStandardRate.user_username
                                    : null}
                    />}
                paginationPerPage={pagination?.per_page ?? PAGINATION_PAGE_ROWS}
                progressPending={isGettingRatePercentages || isGettingActiveStandardRates}
                sortServer
            />
        )
    }

    return (
        <>
            <Title title={intl.formatMessage({id: "title.cat_tool_breaks"})} />
            <div className="container">
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <label className="label">
                                <FormattedMessage id="cat_tool_break.standard_rate" />
                                <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                                <span>:</span>
                            </label>
                        </div>
                        <div className="level-item">
                            <Dropdown id="standardRateDropdown"
                                      text={activeStandardRate ? activeStandardRate.currency_name : "-----"}
                                      isLoading={isGettingActiveStandardRates}
                                      content={<>
                                          {activeStandardRates.length > 0 ?
                                              <Link to="#" className="dropdown-item"
                                                    onClick={() => resetStandardRate("")}>
                                                  -----
                                              </Link>
                                              :
                                              null
                                          }
                                          {activeStandardRates?.map((standardRate, i) => (
                                              <Link to="#" className="dropdown-item" key={i}
                                                    onClick={() => resetStandardRate(standardRate)}>
                                                  {standardRate.currency_name}
                                              </Link>
                                          ))}
                                      </>}
                            />
                        </div>
                    </div>
                </div>
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <button className="button is-link"
                                    disabled={!activeStandardRate}
                                    onClick={(e) => showCreatePercentage(e)}>
                                <FormattedMessage id="cat_tool_break.new_percentage" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <label className="label">
                                <FormattedMessage id="general.filter_by" />:
                            </label>
                        </div>
                        <div className="level-item mr-0">
                            <Dropdown id="catToolBreakFilterDropdown"
                                      text={catToolBreakFilter ? intl.formatMessage({id: "cat_tool_break." + catToolBreakFilter}) : ""}
                                      disabled={!activeStandardRate}
                                      content={<>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    resetData()
                                                    getData()
                                                }}>
                                              <FormattedMessage id={"cat_tool_break.all"} />
                                          </Link>
                                          <hr className="dropdown-divider" />
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setCatToolBreakFilter("name")
                                                    resetData()
                                                }}>
                                              <FormattedMessage id={"cat_tool_break.name"} />
                                          </Link>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setCatToolBreakFilter("percent_range")
                                                    resetData()
                                                }}>
                                              <FormattedMessage id={"cat_tool_break.percent_range"} />
                                          </Link>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setCatToolBreakFilter("percent")
                                                    resetData()
                                                }}>
                                              <FormattedMessage id={"cat_tool_break.percent"} />
                                          </Link>
                                      </>}
                            />
                        </div>
                        <div className="level-item">
                            <div className="field has-addons">
                                {catToolBreakFilter === "name" ?
                                    <Dropdown id="catToolBreakNamesDropdown"
                                              text={nameFilter ? nameFilter : "-----"}
                                              content={<>
                                                  {CAT_TOOL_BREAK_TYPES?.map((type, i) => (
                                                      <Link to="#" className="dropdown-item" key={i}
                                                            onClick={() => {
                                                                setNameFilter(type)
                                                                filterCatToolBreaksByName(type)
                                                            }}>
                                                          {type}
                                                      </Link>
                                                  ))}
                                              </>}
                                    />
                                    :
                                    catToolBreakFilter === "percent_range" || catToolBreakFilter === "percent" ?
                                        <>
                                            <div className="control">
                                                <input className="input"
                                                       type="number"
                                                       placeholder={intl.formatMessage({id: "placeholder.min"})}
                                                       value={minFilter}
                                                       onChange={(e) => setMinFilter(e.target.value)}
                                                       min={0}
                                                       max={9999999}
                                                       step={0.1}
                                                       onWheel={(e) => e.target.blur()}
                                                />
                                            </div>
                                            <div className="control">
                                                <button className="button is-static" tabIndex="-1">-</button>
                                            </div>
                                            <div className="control">
                                                <input className="input"
                                                       type="number"
                                                       placeholder={intl.formatMessage({id: "placeholder.max"})}
                                                       value={maxFilter}
                                                       onChange={(e) => setMaxFilter(e.target.value)}
                                                       min={0}
                                                       max={9999999}
                                                       step={0.1}
                                                       onWheel={(e) => e.target.blur()}
                                                />
                                            </div>
                                            <button className={`button is-link ${isGettingRatePercentages &&
                                            minFilter.trim() !== "" && maxFilter.trim() !== "" ? 'is-loading' : ''}`}
                                                    disabled={minFilter.trim() === "" || maxFilter.trim() === ""}
                                                    onClick={(e) => filterCatToolBreaksByPercent(e)}>
                                                <FormattedMessage id="general.search" />
                                            </button>
                                        </>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <CatToolBreaksTable />
            </div>
        </>
    )
}
