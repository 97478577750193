import {useEffect, useRef, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {usePagination} from '../hooks/usePagination'
import * as actions from '../store/Actions'
import {getCustomerRate, getLinkedCustomerRate, storeCustomerRate} from '../store/reducers/customerRate/customerRateActions'
import {getActiveStandardRates} from '../store/reducers/rate/rateActions'
import {getExchangeRates} from '../store/reducers/exchangeRate/exchangeRateActions'
import {getUnits} from '../store/reducers/unit/unitActions'
import {getProject} from '../store/reducers/project/projectActions'
import {Title} from '../components/UI/Title'
import {Dropdown} from '../components/UI/Dropdown'
import {Search} from '../components/UI/Search'
import {Datatable} from '../components/Datatable/Datatable'
import {TableIconButtonEdit} from '../components/UI/TableIconButtonEdit'
import {TableIconButtonDelete} from '../components/UI/TableIconButtonDelete'
import {Pagination} from '../components/UI/Pagination'
import * as modalTypes from '../consts/enums/modalTypes'
import * as toastTypes from '../consts/enums/toastTypes'
import * as catToolBreakTypes from '../consts/enums/catToolBreakTypes'
import {CAT_TOOL_BREAK_TYPES, CONTEXT_EXACT_MATCHES_PERCENT, EXACT_MATCHES_PERCENT, PAGINATION_PAGE_ROWS,
    STANDARD_CURRENCY_ABBREVIATION, STANDARD_CURRENCY_CODE, TAG_TYPES} from '../consts/app'
import {getDateNow} from '../utils/getDateNow'
import {roundDecimalNumber} from '../utils/roundDecimalNumber'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPercent, faSave} from '@fortawesome/free-solid-svg-icons'

export const NewCustomerRate = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const intl = useIntl()
    const dispatch = useDispatch()
    const loggedUser = useSelector(state => state.auth.user)
    const isGettingLinkedCustomerRates = useSelector(state => state.customerRate.isGettingLinkedCustomerRates)
    const customerRate = useSelector(state => state.customerRate.customerRate)
    const isGettingCustomerRate = useSelector(state => state.customerRate.isGettingCustomerRate)
    const customerRateCreated = useSelector(state => state.customerRate.customerRateCreated)
    const error = useSelector(state => state.customerRate.error)
    const activeStandardRates = useSelector(state => state.rate.activeStandardRates)
    const isGettingActiveStandardRates = useSelector(state => state.rate.isGettingActiveStandardRates)
    const exchangeRates = useSelector(state => state.exchangeRate.exchangeRates)
    const isGettingExchangeRates = useSelector(state => state.exchangeRate.isGettingExchangeRates)
    const standardRateRatePercentages = useSelector(state => state.ratePercentage.ratePercentages)
    const isGettingRatePercentages = useSelector(state => state.ratePercentage.isGettingRatePercentages)
    const units = useSelector(state => state.unit.units)
    const isGettingUnits = useSelector(state => state.unit.isGettingUnits)
    const existingProject = useSelector(state => state.project.projects)
    const isGettingProject = useSelector(state => state.project.isGettingProjects)
    const firstRender = useRef(true)
    const customer = useRef(null)
    const manufacturer = useRef(null)
    const project = useRef(null)
    const name = useRef("")
    const isNewVersion = useRef(false)
    const linkedCustomerRate = useRef(null)
    const activeStandardRate = useRef(null)
    const currencyCode = useRef(STANDARD_CURRENCY_CODE)
    const currencyValue = useRef(1)
    const tab = useRef(1)
    const isRateBeginSaved = useRef(false)
    const [activeRateCheckbox, setActiveRateCheckbox] = useState(false)
    const [linkedRateCheckbox, setLinkedRateCheckbox] = useState(false)
    const [effectiveDate, setEffectiveDate] = useState("")
    const [customerRatePercent, setCustomerRatePercent] = useState("")
    const [exchangeRate, setExchangeRate] = useState(null)
    const [valueExchangeRate, setValueExchangeRate] = useState("")
    const [comment, setComment] = useState("")
    const [languagePairFilter, setLanguagePairFilter] = useState("all")
    const [languageFilter, setLanguageFilter] = useState("")
    const [priceUnitFilter, setPriceUnitFilter] = useState("")
    const [priceMinFilter, setPriceMinFilter] = useState("")
    const [priceMaxFilter, setPriceMaxFilter] = useState("")
    const [prices, setPrices] = useState([])
    const [filteredPrices, setFilteredPrices] = useState([])
    const [allPrices, setAllPrices] = useState([])
    const [tagFilter, setTagFilter] = useState("all")
    const [tagNameFilter, setTagNameFilter] = useState("")
    const [typeFilter, setTypeFilter] = useState("")
    const [valueUnitFilter, setValueUnitFilter] = useState("")
    const [valueMinFilter, setValueMinFilter] = useState("")
    const [valueMaxFilter, setValueMaxFilter] = useState("")
    const [values, setValues] = useState([])
    const [filteredValues, setFilteredValues] = useState([])
    const [allValues, setAllValues] = useState([])
    const [catToolBreakFilter, setCatToolBreakFilter] = useState("all")
    const [catToolBreakNameFilter, setCatToolBreakNameFilter] = useState("")
    const [ratePercentageMinFilter, setRatePercentageMinFilter] = useState("")
    const [ratePercentageMaxFilter, setRatePercentageMaxFilter] = useState("")
    const [ratePercentages, setRatePercentages] = useState([])
    const [filteredRatePercentages, setFilteredRatePercentages] = useState([])
    const [pricesPagination, setPricesPagination, updatePricesPagination] = usePagination()
    const [valuesPagination, setValuesPagination, updateValuesPagination] = usePagination()
    const [ratePercentagesPagination, setRatePercentagesPagination, updateRatePercentagesPagination] = usePagination()
    const priceSearchType = useRef({type: "all", search: {param1: "", param2: ""}})
    const priceSortOrder = useRef("asc")
    const valueSearchType = useRef({type: "all", search: {param1: "", param2: ""}})
    const valueSortOrder = useRef("asc")
    const ratePercentageSearchType = useRef({type: "all", search: {param1: "", param2: ""}})
    const ratePercentageSortOrder = useRef("desc")

    useEffect(() => {
        if (!location.state?.customerRateData) {
            navigate("/customer-rates")
        } else {
            getData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (activeStandardRates.length > 0) {
            activeStandardRate.current = activeStandardRates.find(standardRate => standardRate.currency_code === STANDARD_CURRENCY_CODE) ?? activeStandardRates[0]
        }
        if (!location.state?.linkedCustomerRate) {
            if (!isGettingLinkedCustomerRates && !isGettingActiveStandardRates && !firstRender.current) {
                dispatch({type: actions.HIDE_MODAL})
                dispatch({
                    type: actions.SHOW_MODAL, payload: {
                        type: modalTypes.SELECT_RATE,
                        title: intl.formatMessage({id: "message_title.select_rate"}),
                        additionalData: {
                            showSelectData: showSelectData,
                            type: 'customer'
                        }
                    }
                })
            } else if ((isGettingLinkedCustomerRates || isGettingActiveStandardRates) && !firstRender.current) {
                dispatch({type: actions.SHOW_MODAL, payload: {type: modalTypes.LOADING}})
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGettingLinkedCustomerRates, isGettingActiveStandardRates])

    useEffect(() => {
        setRatePercentages(JSON.parse(JSON.stringify(standardRateRatePercentages)))
        setRatePercentagesPagination(standardRateRatePercentages, 1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [standardRateRatePercentages])

    useEffect(() => {
        if (!isNewVersion.current) {
            if (!isGettingExchangeRates && exchangeRates[0]) {
                currencyCode.current = exchangeRates.find(exchangeRate => exchangeRate.currency_code === currencyCode.current)?.currency_code
                if (!currencyCode.current) {
                    currencyCode.current = STANDARD_CURRENCY_CODE
                }
                setExchangeRate(exchangeRates.find(exchangeRate => exchangeRate.currency_code === currencyCode.current))
                setValueExchangeRate("" + currencyValue.current)
            } else {
                setExchangeRate(null)
                setValueExchangeRate("")
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGettingExchangeRates])

    useEffect(() => {
        if (!isGettingProject && !firstRender.current) {
            if (existingProject) {
                dispatch({type: actions.HIDE_MODAL})
                dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.project_already_has_rate"}})
            } else {
                createCustomerRate()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGettingProject])

    useEffect(() => {
        if (!isGettingCustomerRate && !firstRender.current) {
            if (customerRate) {
                dispatch({type: actions.HIDE_MODAL})
                if (customerRate.manufacturer_id) {
                    dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.customer_and_manufacturer_already_has_rate"}})
                } else {
                    dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.customer_already_has_rate"}})
                }
            } else {
                createCustomerRate()
            }
        }
        firstRender.current = false
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGettingCustomerRate])

    useEffect(() => {
        if (customerRateCreated) {
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.SUCCESS, message: "message.customer_rate" + (isNewVersion.current ? "_version" : "") + "_created"}})
            if (isNewVersion.current) {
                navigate("/customer-rates/" + customerRateCreated.id, {state: {customerRate: customerRateCreated}})
            } else {
                navigate("/customer-rates")
            }
            dispatch({type: actions.CUSTOMER_RATE_CREATED, payload: {status: null}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerRateCreated])

    useEffect(() => {
        if (error) {
            if (!isRateBeginSaved.current) {
                navigate("/customer-rates/select-customer")
            }
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.RESET_CUSTOMER_RATE_ERROR})
            isRateBeginSaved.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    useEffect(() => {
        if ((prices.length > 0 || values.length > 0) && !isGettingExchangeRates) {
            let exchangeRate = roundDecimalNumber(valueExchangeRate)
            exchangeRate = isNaN(exchangeRate) ? 1 : exchangeRate <= 0 ? 1 : exchangeRate
            setPrices(prices.map(price => {
                return {
                    ...price,
                    total: roundDecimalNumber(price.total / currencyValue.current * exchangeRate)
                }
            }))
            setValues(values.map(value => {
                return {
                    ...value,
                    price: value.price !== null ? roundDecimalNumber(value.price / currencyValue.current * exchangeRate) : null
                }
            }))
            currencyValue.current = exchangeRate
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueExchangeRate])

    useEffect(() => {
        if (priceSearchType.current.type === "source_language") {
            setFilteredPrices(prices.filter(price =>
                price.translation_source_language.toLowerCase().includes(priceSearchType.current.search.param1.toLowerCase()) ||
                (price.translation_code_source_language ?
                    price.translation_code_source_language.toLowerCase().includes(priceSearchType.current.search.param1.toLowerCase()) : false)))
        } else if (priceSearchType.current.type === "target_language") {
            setFilteredPrices(prices.filter(price =>
                price.translation_target_language.toLowerCase().includes(priceSearchType.current.search.param1.toLowerCase()) ||
                (price.translation_code_target_language ?
                    price.translation_code_target_language.toLowerCase().includes(priceSearchType.current.search.param1.toLowerCase()) : false)))
        } else if (priceSearchType.current.type === "unit") {
            setFilteredPrices(prices.filter(price => price.unit_type === priceSearchType.current.search.param1))
        } else if (priceSearchType.current.type === "price") {
            const filteredPrices = prices.filter(price => calculatePrice(price.total) >= priceSearchType.current.search.param1 &&
                calculatePrice(price.total) <= priceSearchType.current.search.param2)
            setFilteredPrices(filteredPrices)
            setPricesPagination(filteredPrices, 1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prices, customerRatePercent])

    useEffect(() => {
        if (valueSearchType.current.type === "name") {
            setFilteredValues(values.filter(value => value.tag_name.toLowerCase().includes(valueSearchType.current.search.param1.toLowerCase())))
        } else if (valueSearchType.current.type === "type") {
            setFilteredValues(values.filter(value => value.tag_type === valueSearchType.current.search.param1))
        } else if (valueSearchType.current.type === "unit") {
            setFilteredValues(values.filter(value => value.unit_type === valueSearchType.current.search.param1))
        } else if (valueSearchType.current.type === "percent") {
            setFilteredValues(values.filter(value => value.percent >= valueSearchType.current.search.param1 &&
                value.percent <= valueSearchType.current.search.param2 && value.percent !== null))
        } else if (valueSearchType.current.type === "price") {
            const filteredValues = values.filter(value => calculatePrice(value.price) >= valueSearchType.current.search.param1 &&
                calculatePrice(value.price) <= valueSearchType.current.search.param2 && value.price !== null)
            setFilteredValues(filteredValues)
            setValuesPagination(filteredValues, 1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, customerRatePercent])

    useEffect(() => {
        if (ratePercentageSearchType.current.type === "name") {
            setFilteredRatePercentages(ratePercentages.filter(ratePercentage => ratePercentage.cat_tool_break_name === ratePercentageSearchType.current.search.param1))
        } else if (ratePercentageSearchType.current.type === "percent_range") {
            setFilteredRatePercentages(ratePercentages.filter(ratePercentage =>
                ((ratePercentage.cat_tool_break_match_percent_min <= ratePercentageSearchType.current.search.param1 &&
                        (ratePercentage.cat_tool_break_match_percent_max >= ratePercentageSearchType.current.search.param1 ||
                            ratePercentage.cat_tool_break_match_percent_max === null)) ||
                    (ratePercentage.cat_tool_break_match_percent_min <= ratePercentageSearchType.current.search.param2 &&
                        (ratePercentage.cat_tool_break_match_percent_max >= ratePercentageSearchType.current.search.param2 ||
                            ratePercentage.cat_tool_break_match_percent_max === null)) ||
                    ((ratePercentage.cat_tool_break_match_percent_min >= ratePercentageSearchType.current.search.param1 &&
                            ratePercentage.cat_tool_break_match_percent_min <= ratePercentageSearchType.current.search.param2) &&
                        (ratePercentage.cat_tool_break_match_percent_max >= ratePercentageSearchType.current.search.param1 &&
                            ratePercentage.cat_tool_break_match_percent_max <= ratePercentageSearchType.current.search.param2))) &&
                ratePercentage.cat_tool_break_match_percent_min !== null))
        } else if (ratePercentageSearchType.current.type === "percent") {
            setFilteredRatePercentages(ratePercentages.filter(ratePercentage => ratePercentage.percent >= ratePercentageSearchType.current.search.param1 &&
                ratePercentage.percent <= ratePercentageSearchType.current.search.param2))
        }
    }, [ratePercentages])

    const getData = () => {
        customer.current = location.state.customerRateData.customer
        manufacturer.current = location.state.customerRateData.manufacturer
        project.current = location.state.customerRateData.project
        dispatch(getActiveStandardRates())
        if (!location.state.isNewVersion) {
            name.current = location.state.customerRateData.name
            if (location.state.linkedCustomerRate) {
                showSelectData(location.state.linkedCustomerRate)
            } else {
                if (manufacturer.current) {
                    dispatch(getLinkedCustomerRate(customer.current.code, project.current?.code ? manufacturer.current.code : null))
                } else {
                    dispatch({type: actions.RESET_LINKED_CUSTOMER_RATES})
                }
            }
        } else {
            isNewVersion.current = true
            name.current = location.state.customerRateData.customerRate.name
            linkedCustomerRate.current = location.state.customerRateData.customerRate
            currencyCode.current = location.state.customerRateData.customerRate.currency_code
            currencyValue.current = location.state.customerRateData.customerRate.exchange_rate_value
            setExchangeRate({
                value: location.state.customerRateData.customerRate.exchange_rate_value,
                currency_id: location.state.customerRateData.customerRate.currency_id,
                currency_name: location.state.customerRateData.customerRate.currency_name,
                currency_code: location.state.customerRateData.customerRate.currency_code,
                currency_abbreviation: location.state.customerRateData.customerRate.currency_abbreviation
            })
            setValueExchangeRate(location.state.customerRateData.customerRate.exchange_rate_value)
            setCustomerRatePercent("" + (location.state.customerRateData.customerRate.special_customer_percent ?? ""))
            setActiveRateCheckbox(location.state.customerRateData.customerRate.rate_is_active)
            setLinkedRateCheckbox(location.state.customerRateData.customerRate.is_linked)
            setEffectiveDate(location.state.customerRateData.customerRate.effective_date ?? "")
            setComment(location.state.customerRateData.customerRate.comment ?? "")
            getNewPrices(JSON.parse(JSON.stringify(location.state.customerRateData.prices)), location.state.customerRateData.prices)
            getNewValues(JSON.parse(JSON.stringify(location.state.customerRateData.values)), location.state.customerRateData.values)
            dispatch({type: actions.GET_RATE_PERCENTAGES, payload: {data: location.state.customerRateData.ratePercentages}})
        }
    }

    const showSelectData = (rate) => {
        if (rate) {
            linkedCustomerRate.current = rate
            currencyCode.current = rate.currency_code ?? currencyCode.current
            currencyValue.current = rate.exchange_rate_value ?? currencyValue.current
            setCustomerRatePercent("" + (rate.special_customer_percent ?? ""))
            dispatch(getExchangeRates())
            dispatch({
                type: actions.SHOW_MODAL, payload: {
                    type: modalTypes.SELECT_PAIRS_NEW_RATE,
                    title: intl.formatMessage({id: "message_title.select_language_pairs_new_rate"}),
                    additionalData: {
                        getNewPrices: getNewPrices,
                        getNewValues: getNewValues,
                        rateId: rate.rate_id ?? rate.id
                    }
                }
            })
        } else {
            navigate("/customer-rates/select-customer")
        }
    }

    const createCustomerRate = () => {
        const newPrices = JSON.parse(JSON.stringify(prices))
        for (let i = 0; i < newPrices.length; i++) {
            delete newPrices[i].rate_id
        }
        const newValues = JSON.parse(JSON.stringify(values))
        for (let i = 0; i < newValues.length; i++) {
            delete newValues[i].rate_id
            for (let prop in newValues[i]) {
                if (!newValues[i][prop] && newValues[i][prop] !== 0) {
                    delete newValues[i][prop]
                }
            }
        }
        const newRatePercentages = []
        for (let i = 0; i < ratePercentages.length; i++) {
            newRatePercentages.push({
                ...JSON.parse(JSON.stringify(ratePercentages[i])),
                name: ratePercentages[i].cat_tool_break_name,
                match_percent_min: ratePercentages[i].cat_tool_break_match_percent_min,
                match_percent_max: ratePercentages[i].cat_tool_break_match_percent_max
            })
            delete newRatePercentages[i].rate_id
            if (!newRatePercentages[i].description) {
                delete newRatePercentages[i].description
            }
            if (ratePercentages[i].cat_tool_break_name !== catToolBreakTypes.NEW_WORDS &&
                ratePercentages[i].cat_tool_break_name !== catToolBreakTypes.FUZZY_MATCHES) {
                delete newRatePercentages[i].match_percent_min
                delete newRatePercentages[i].match_percent_max
            }
        }
        const value = roundDecimalNumber(valueExchangeRate)
        const customerRate = {
            user_id: loggedUser.id,
            is_active: activeRateCheckbox,
            name: name.current,
            is_linked: linkedRateCheckbox,
            effective_date: effectiveDate,
            special_customer_percent: roundDecimalNumber(customerRatePercent),
            comment: comment,
            customer: {
                name: customer.current.name,
                code: customer.current.code,
                type: customer.current.type
            },
            manufacturer: manufacturer.current ? {
                name: manufacturer.current.description,
                code: manufacturer.current.code
            } : null,
            project: project.current ? {
                name: project.current.description,
                code: project.current.code
            } : null,
            prices: newPrices,
            values: newValues,
            ratePercentages: newRatePercentages,
            exchangeRate: {
                value: isNaN(value) ? 1 : value <= 0 ? 1 : value,
                currency_id: exchangeRate.currency_id
            }
        }
        for (let prop in customerRate) {
            if (!customerRate[prop] && customerRate[prop] !== 0 && customerRate[prop] !== false) {
                delete customerRate[prop]
            }
        }
        dispatch(storeCustomerRate(customerRate))
        isRateBeginSaved.current = true
        dispatch({type: actions.SHOW_MODAL, payload: {type: modalTypes.LOADING}})
    }

    const showCreateCustomerRate = (e) => {
        e.preventDefault()
        if (isNewVersion.current) {
            createCustomerRate()
        } else {
            if (project.current) {
                dispatch(getProject(project.current.code, exchangeRate.currency_id))
            } else if (manufacturer.current) {
                dispatch(getCustomerRate(exchangeRate.currency_id, customer.current.code, manufacturer.current.code))
            } else {
                dispatch(getCustomerRate(exchangeRate.currency_id, customer.current.code))
            }
            dispatch({type: actions.SHOW_MODAL, payload: {type: modalTypes.LOADING}})
        }
    }

    const changeExchangeRate = (id) => {
        setExchangeRate(exchangeRates.find(exchangeRate => exchangeRate.id === parseInt(id)))
        setValueExchangeRate(exchangeRates.find(exchangeRate => exchangeRate.id === parseInt(id)).value)
    }

    const getNewPrices = (prices, allPrices) => {
        setLanguagePairFilter("all")
        priceSearchType.current = {type: "all", search: {param1: "", param2: ""}}
        priceSortOrder.current = "asc"
        setPrices([...prices])
        setAllPrices([...allPrices])
        setPricesPagination(prices, 1)
    }

    const resetPriceData = () => {
        setLanguageFilter("")
        setPriceUnitFilter("")
        setPriceMinFilter("")
        setPriceMaxFilter("")
    }

    const filterAllPrices = () => {
        priceSearchType.current = {type: "all", search: {param1: "", param2: ""}}
        setPricesPagination(prices, 1)
    }

    const filterPricesByLanguage = (e) => {
        e.preventDefault()
        priceSearchType.current = {type: languagePairFilter, search: {param1: languageFilter, param2: ""}}
        let filteredPrices
        if (languagePairFilter === "source_language") {
            filteredPrices = prices.filter(price =>
                price.translation_source_language.toLowerCase().includes(languageFilter.toLowerCase()) ||
                (price.translation_code_source_language ?
                    price.translation_code_source_language.toLowerCase().includes(languageFilter.toLowerCase()) : false))
        } else {
            filteredPrices = prices.filter(price =>
                price.translation_target_language.toLowerCase().includes(languageFilter.toLowerCase()) ||
                (price.translation_code_target_language ?
                    price.translation_code_target_language.toLowerCase().includes(languageFilter.toLowerCase()) : false))
        }
        setFilteredPrices(filteredPrices)
        setPricesPagination(filteredPrices, 1)
    }

    const filterPricesByUnit = (unit) => {
        priceSearchType.current = {type: "unit", search: {param1: unit, param2: ""}}
        const filteredPrices = prices.filter(price => price.unit_type === unit)
        setFilteredPrices(filteredPrices)
        setPricesPagination(filteredPrices, 1)
    }

    const filterPricesByPrice = (e) => {
        e.preventDefault()
        if (parseFloat(priceMinFilter) > parseFloat(priceMaxFilter)) {
            dispatch({type: actions.SHOW_TOAST, payload: {type:toastTypes.ERROR, message: "error.min_greater_than_max"}})
        } else {
            priceSearchType.current = {type: "price", search: {param1: priceMinFilter, param2: priceMaxFilter}}
            const filteredPrices = prices.filter(price => calculatePrice(price.total) >= priceMinFilter && calculatePrice(price.total) <= priceMaxFilter)
            setFilteredPrices(filteredPrices)
            setPricesPagination(filteredPrices, 1)
        }
    }

    const sortLanguagePairs = (column, sortDirection) => {
        priceSortOrder.current = sortDirection
        if (column.sortField === "translation_source_language") {
            setPrices(JSON.parse(JSON.stringify(prices.sort((price1, price2) => {
                return price1.translation_target_language > price2.translation_target_language ? (sortDirection === "asc" ? 1 : -1) :
                    price1.translation_target_language < price2.translation_target_language ? (sortDirection === "asc" ? -1 : 1) : 0
            }).sort((price1, price2) => {
                return price1.translation_source_language > price2.translation_source_language ? (sortDirection === "asc" ? 1 : -1) :
                    price1.translation_source_language < price2.translation_source_language ? (sortDirection === "asc" ? -1 : 1) : 0
            }))))
        } else if (column.sortField === "translation_target_language") {
            setPrices(JSON.parse(JSON.stringify(prices.sort((price1, price2) => {
                return price1.translation_source_language > price2.translation_source_language ? (sortDirection === "asc" ? 1 : -1) :
                    price1.translation_source_language < price2.translation_source_language ? (sortDirection === "asc" ? -1 : 1) : 0
            }).sort((price1, price2) => {
                return price1.translation_target_language > price2.translation_target_language ? (sortDirection === "asc" ? 1 : -1) :
                    price1.translation_target_language < price2.translation_target_language ? (sortDirection === "asc" ? -1 : 1) : 0
            }))))
        } else {
            setPrices(JSON.parse(JSON.stringify(prices.sort((price1, price2) => {
                return price1[column.sortField] > price2[column.sortField] ? (sortDirection === "asc" ? 1 : -1) :
                    price1[column.sortField] < price2[column.sortField] ? (sortDirection === "asc" ? -1 : 1) : 0
            }))))
        }
        setPricesPagination(priceSearchType.current.type === "all" ? prices : filteredPrices, 1)
    }

    const changePricesPagination = (page) => {
        setPricesPagination(priceSearchType.current.type === "all" ? prices : filteredPrices, parseInt(page))
    }

    const filterPricesPerPage = () => {
        let pricesPerPage = priceSearchType.current.type === "all" ? [...prices] : [...filteredPrices]
        if (pricesPagination) {
            pricesPerPage = pricesPerPage.splice((pricesPagination.current_page - 1) *
                pricesPagination.per_page, pricesPagination.per_page)
        }
        return pricesPerPage
    }

    const editPrice = (price) => {
        dispatch({type: actions.HIDE_MODAL})
        setPrices(prices.map(p => p.id !== price.id ? p :
            {
                ...p,
                total: price.total
            }
        ))
    }

    const deletePrice = (id) => {
        dispatch({type: actions.HIDE_MODAL})
        let pricesAvailable = [...prices]
        pricesAvailable.splice(prices.indexOf(prices.find(price => price.id === id)), 1)
        setPrices([...pricesAvailable])
        if (priceSearchType.current.type !== "all") {
            pricesAvailable = [...filteredPrices]
            pricesAvailable.splice(filteredPrices.indexOf(filteredPrices.find(price => price.id === id)), 1)
            setFilteredPrices([...pricesAvailable])
        }
        updatePricesPagination(pricesAvailable)
    }

    const showEditPrice = (e, price) => {
        e.preventDefault()
        e.stopPropagation()
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                type: modalTypes.EDIT_PRICE,
                title: intl.formatMessage({id: "message_title.edit_price"}),
                additionalData: {
                    price: price,
                    editPrice: editPrice,
                    customerRate: {
                        customerRatePercent: customerRatePercent
                    }
                }
            }
        })
    }

    const showAddPairs = (e) => {
        e.preventDefault()
        if (prices.length > 0 && prices.length === allPrices.length &&
            (activeStandardRate.current ? allPrices.find(price => price.rate_id === activeStandardRate.current.id) : true)) {
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.INFO, message: "message.all_language_pairs_new_customer_rate"}})
        } else {
            dispatch({
                type: actions.SHOW_MODAL, payload: {
                    type: modalTypes.ADD_PAIR,
                    title: intl.formatMessage({id: "message_title.add_language_pair"}),
                    additionalData: {
                        prices: prices,
                        allPrices: allPrices,
                        setAllPrices: setAllPrices,
                        getNewPrices: getNewPrices,
                        rateId: linkedCustomerRate.current?.rate_id ?? linkedCustomerRate.current?.id,
                        exchangeRateValue: linkedCustomerRate.current?.exchange_rate_value,
                        valueExchangeRate: valueExchangeRate
                    }
                }
            })
        }
    }

    const getNewValues = (values, allValues) => {
        setTagFilter("all")
        valueSearchType.current = {type: "all", search: {param1: "", param2: ""}}
        valueSortOrder.current = "asc"
        setValues([...values])
        setAllValues([...allValues])
        setValuesPagination(values, 1)
    }

    const resetValueData = () => {
        setTagNameFilter("")
        setTypeFilter("")
        setValueUnitFilter("")
        setValueMinFilter("")
        setValueMaxFilter("")
    }

    const filterAllValues = () => {
        valueSearchType.current = {type: "all", search: {param1: "", param2: ""}}
        setValuesPagination(values, 1)
    }

    const filterValuesByTagName = (e) => {
        e.preventDefault()
        valueSearchType.current = {type: "name", search: {param1: tagNameFilter, param2: ""}}
        const filteredValues = values.filter(value => value.tag_name.toLowerCase().includes(tagNameFilter.toLowerCase()))
        setFilteredValues(filteredValues)
        setValuesPagination(filteredValues, 1)
    }

    const filterValuesByTagType = (type) => {
        valueSearchType.current = {type: "type", search: {param1: type, param2: ""}}
        const filteredValues = values.filter(value => value.tag_type === type)
        setFilteredValues(filteredValues)
        setValuesPagination(filteredValues, 1)
    }

    const filterValuesByUnit = (unit) => {
        valueSearchType.current = {type: "unit", search: {param1: unit, param2: ""}}
        const filteredValues = values.filter(value => value.unit_type === unit)
        setFilteredValues(filteredValues)
        setValuesPagination(filteredValues, 1)
    }

    const filterValuesByValue = (e) => {
        e.preventDefault()
        if (parseFloat(valueMinFilter) > parseFloat(valueMaxFilter)) {
            dispatch({type: actions.SHOW_TOAST, payload: {type:toastTypes.ERROR, message: "error.min_greater_than_max"}})
        } else {
            valueSearchType.current = {type: tagFilter, search: {param1: valueMinFilter, param2: valueMaxFilter}}
            let filteredValues
            if (tagFilter === "percent") {
                filteredValues = values.filter(value => value.percent >= valueMinFilter && value.percent <= valueMaxFilter &&
                    value.percent !== null)
            } else {
                filteredValues = values.filter(value => calculatePrice(value.price) >= valueMinFilter && calculatePrice(value.price) <= valueMaxFilter &&
                    value.price !== null)
            }
            setFilteredValues(filteredValues)
            setValuesPagination(filteredValues, 1)
        }
    }

    const sortTags = (column, sortDirection) => {
        valueSortOrder.current = sortDirection
        if (column.sortField === "values") {
            setValues(JSON.parse(JSON.stringify(values.sort((value1, value2) => {
                if (value1.percent !== null && value2.percent !== null) {
                    return value1.percent > value2.percent ? (sortDirection === "asc" ? 1 : -1) :
                        value1.percent < value2.percent ? (sortDirection === "asc" ? -1 : 1) : 0
                }
                return value1.percent !== null ? (sortDirection === "asc" ? 1 : -1) :
                    value2.percent !== null ? (sortDirection === "asc" ? -1 : 1) : 0
            }).sort((value1, value2) => {
                if (value1.price !== null && value2.price !== null) {
                    return value1.price > value2.price ? (sortDirection === "asc" ? 1 : -1) :
                        value1.price < value2.price ? (sortDirection === "asc" ? -1 : 1) : 0
                }
                return value1.price !== null ? (sortDirection === "asc" ? 1 : -1) :
                    value2.price !== null ? (sortDirection === "asc" ? -1 : 1) : 0
            }))))
        } else if (column.sortField === "tag_type") {
            setValues(JSON.parse(JSON.stringify(values.sort((value1, value2) => {
                return value1.tag_name > value2.tag_name ? (sortDirection === "asc" ? 1 : -1) :
                    value1.tag_name < value2.tag_name ? (sortDirection === "asc" ? -1 : 1) : 0
            }).sort((value1, value2) => {
                return value1.tag_type > value2.tag_type ? (sortDirection === "asc" ? 1 : -1) :
                    value1.tag_type < value2.tag_type ? (sortDirection === "asc" ? -1 : 1) : 0
            }))))
        } else {
            setValues(JSON.parse(JSON.stringify(values.sort((value1, value2) => {
                if (value1[column.sortField] && value2[column.sortField]) {
                    return value1[column.sortField] > value2[column.sortField] ? (sortDirection === "asc" ? 1 : -1) :
                        value1[column.sortField] < value2[column.sortField] ? (sortDirection === "asc" ? -1 : 1) : 0
                }
                return value1[column.sortField] ? (sortDirection === "asc" ? 1 : -1) :
                    value2[column.sortField] ? (sortDirection === "asc" ? -1 : 1) : 0
            }))))
        }
        setValuesPagination(valueSearchType.current.type === "all" ? values : filteredValues, 1)
    }

    const changeValuesPagination = (page) => {
        setValuesPagination(valueSearchType.current.type === "all" ? values : filteredValues, parseInt(page))
    }

    const filterValuesPerPage = () => {
        let valuesPerPage = valueSearchType.current.type === "all" ? [...values] : [...filteredValues]
        if (valuesPagination) {
            valuesPerPage = valuesPerPage.splice((valuesPagination.current_page - 1) *
                valuesPagination.per_page, valuesPagination.per_page)
        }
        return valuesPerPage
    }

    const editValue = (value) => {
        dispatch({type: actions.HIDE_MODAL})
        setValues(values.map(v => v.id !== value.id ? v :
            {
                ...v,
                price: value.price ?? null,
                percent: value.percent ?? null,
                reference_tag: value.reference_tag ?? null,
                description: value.description ?? null,
                reference_tag_name: value.reference_tag ?
                    values[values.indexOf(values.find(v => v.tag_id === value.reference_tag))].tag_name : null,
                reference_tag_type: value.reference_tag ?
                    values[values.indexOf(values.find(v => v.tag_id === value.reference_tag))].tag_type : null
            }
        ))
    }

    const deleteValue = (id) => {
        dispatch({type: actions.HIDE_MODAL})
        const valueToDelete = values.find(value => value.id === id)
        let referenceValue
        if (valueToDelete.unit_id) {
            referenceValue = values.find(value => value.reference_tag === valueToDelete.tag_id &&
                (value.unit_id === valueToDelete.unit_id || !value.unit_id))
        } else {
            referenceValue = values.find(value => value.reference_tag === valueToDelete.tag_id)
        }
        if (!referenceValue) {
            let valuesAvailable = [...values]
            valuesAvailable.splice(values.indexOf(values.find(value => value.id === id)), 1)
            setValues([...valuesAvailable])
            if (valueSearchType.current.type !== "all") {
                valuesAvailable = [...filteredValues]
                valuesAvailable.splice(filteredValues.indexOf(filteredValues.find(value => value.id === id)), 1)
                setFilteredValues([...valuesAvailable])
            }
            updateValuesPagination(valuesAvailable)
        } else {
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.cannot_delete_reference_tag"}})
        }
    }

    const showEditValue = (e, value) => {
        e.preventDefault()
        e.stopPropagation()
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                type: modalTypes.EDIT_VALUE,
                title: intl.formatMessage({id: "message_title.edit_value"}),
                additionalData: {
                    value: value,
                    editValue: editValue,
                    allValues: values,
                    customerRate: {
                        customerRatePercent: customerRatePercent
                    }
                }
            }
        })
    }

    const showAddTags = (e) => {
        e.preventDefault()
        if (values.length > 0 && values.length === allValues.length &&
            (activeStandardRate.current ? allPrices.find(price => price.rate_id === activeStandardRate.current.id) : true)) {
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.INFO, message: "message.all_tags_new_customer_rate"}})
        } else {
            dispatch({
                type: actions.SHOW_MODAL, payload: {
                    type: modalTypes.ADD_TAG,
                    title: intl.formatMessage({id: "message_title.add_tag"}),
                    additionalData: {
                        values: values,
                        allValues: allValues,
                        setAllValues: setAllValues,
                        getNewValues: getNewValues,
                        rateId: linkedCustomerRate.current?.rate_id ?? linkedCustomerRate.current?.id,
                        exchangeRateValue: linkedCustomerRate.current?.exchange_rate_value,
                        valueExchangeRate: valueExchangeRate
                    }
                }
            })
        }
    }

    const getNewRatePercentages = (ratePercentages) => {
        setCatToolBreakFilter("all")
        ratePercentageSearchType.current = {type: "all", search: {param1: "", param2: ""}}
        ratePercentageSortOrder.current = "desc"
        const column = "cat_tool_break_match_percent_min"
        setRatePercentages(JSON.parse(JSON.stringify(ratePercentages.sort((ratePercentage1, ratePercentage2) => {
            if (ratePercentage1[column] !== null && ratePercentage2[column] !== null) {
                return ratePercentage1[column] > ratePercentage2[column] ? -1 :
                    ratePercentage1[column] < ratePercentage2[column] ? 1 : 0
            }
            return ratePercentage1[column] !== null ? -1 : ratePercentage2[column] !== null ? 1 : 0
        }))))
        setRatePercentagesPagination(ratePercentages, 1)
    }

    const resetRatePercentageData = () => {
        setCatToolBreakNameFilter("")
        setRatePercentageMinFilter("")
        setRatePercentageMaxFilter("")
    }

    const filterAllRatePercentages = () => {
        ratePercentageSearchType.current = {type: "all", search: {param1: "", param2: ""}}
        setRatePercentagesPagination(ratePercentages, 1)
    }

    const filterRatePercentagesByName = (name) => {
        ratePercentageSearchType.current = {type: "name", search: {param1: name, param2: ""}}
        const filteredRatePercentages = ratePercentages.filter(ratePercentage => ratePercentage.cat_tool_break_name === name)
        setFilteredRatePercentages(filteredRatePercentages)
        setRatePercentagesPagination(filteredRatePercentages, 1)
    }

    const filterRatePercentagesByPercent = (e) => {
        e.preventDefault()
        if (parseFloat(ratePercentageMinFilter) > parseFloat(ratePercentageMaxFilter)) {
            dispatch({type: actions.SHOW_TOAST, payload: {type:toastTypes.ERROR, message: "error.min_greater_than_max"}})
        } else {
            ratePercentageSearchType.current = {type: catToolBreakFilter, search: {param1: ratePercentageMinFilter, param2: ratePercentageMaxFilter}}
            let filteredRatePercentages
            if (catToolBreakFilter === "percent_range") {
                filteredRatePercentages = ratePercentages.filter(ratePercentage =>
                    ((ratePercentage.cat_tool_break_match_percent_min <= ratePercentageMinFilter &&
                            (ratePercentage.cat_tool_break_match_percent_max >= ratePercentageMinFilter ||
                                ratePercentage.cat_tool_break_match_percent_max === null)) ||
                        (ratePercentage.cat_tool_break_match_percent_min <= ratePercentageMaxFilter &&
                            (ratePercentage.cat_tool_break_match_percent_max >= ratePercentageMaxFilter ||
                                ratePercentage.cat_tool_break_match_percent_max === null)) ||
                        ((ratePercentage.cat_tool_break_match_percent_min >= ratePercentageMinFilter &&
                                ratePercentage.cat_tool_break_match_percent_min <= ratePercentageMaxFilter) &&
                            (ratePercentage.cat_tool_break_match_percent_max >= ratePercentageMinFilter &&
                                ratePercentage.cat_tool_break_match_percent_max <= ratePercentageMaxFilter))) &&
                    ratePercentage.cat_tool_break_match_percent_min !== null)
            } else {
                filteredRatePercentages = ratePercentages.filter(ratePercentage => ratePercentage.percent >= ratePercentageMinFilter &&
                    ratePercentage.percent <= ratePercentageMaxFilter)
            }
            setFilteredRatePercentages(filteredRatePercentages)
            setRatePercentagesPagination(filteredRatePercentages, 1)
        }
    }

    const sortCatToolBreaks = (column, sortDirection) => {
        ratePercentageSortOrder.current = sortDirection
        setRatePercentages(JSON.parse(JSON.stringify(ratePercentages.sort((ratePercentage1, ratePercentage2) => {
            if (ratePercentage1[column.sortField] !== null && ratePercentage2[column.sortField] !== null) {
                return ratePercentage1[column.sortField] > ratePercentage2[column.sortField] ? (sortDirection === "asc" ? 1 : -1) :
                    ratePercentage1[column.sortField] < ratePercentage2[column.sortField] ? (sortDirection === "asc" ? -1 : 1) : 0
            }
            return ratePercentage1[column.sortField] !== null ? (sortDirection === "asc" ? 1 : -1) :
                ratePercentage2[column.sortField] !== null ? (sortDirection === "asc" ? -1 : 1) : 0
        }))))
        setRatePercentagesPagination(ratePercentageSearchType.current.type === "all" ? ratePercentages : filteredRatePercentages, 1)
    }

    const changeRatePercentagesPagination = (page) => {
        setRatePercentagesPagination(ratePercentageSearchType.current.type === "all" ? ratePercentages : filteredRatePercentages, parseInt(page))
    }

    const filterRatePercentagesPerPage = () => {
        let ratePercentagesPerPage = ratePercentageSearchType.current.type === "all" ? [...ratePercentages] : [...filteredRatePercentages]
        if (ratePercentagesPagination) {
            ratePercentagesPerPage = ratePercentagesPerPage.splice((ratePercentagesPagination.current_page - 1) *
                ratePercentagesPagination.per_page, ratePercentagesPagination.per_page)
        }
        return ratePercentagesPerPage
    }

    const validatePercentRange = (percentMin, percentMax, newPercentMin, newPercentMax) => {
        return (percentMin <= newPercentMin && percentMax >= newPercentMin) ||
            (percentMin <= newPercentMax && percentMax >= newPercentMax) ||
            ((percentMin >= newPercentMin && percentMin <= newPercentMax) &&
                (percentMax >= newPercentMin && percentMax <= newPercentMax))
    }

    const newRatePercentage = (newRatePercentage) => {
        dispatch({type: actions.HIDE_MODAL})
        let ratePercentage
        let message
        if (newRatePercentage.name === catToolBreakTypes.NEW_WORDS || newRatePercentage.name === catToolBreakTypes.FUZZY_MATCHES) {
            ratePercentage = ratePercentages.find(percentage =>
                validatePercentRange(percentage.cat_tool_break_match_percent_min ?? 100,
                    percentage.cat_tool_break_match_percent_max ?? (percentage.cat_tool_break_match_percent_min ?? 100),
                    newRatePercentage.match_percent_min, newRatePercentage.match_percent_max))
            message = "cat_tool_break_percent_range_not_allowed"
        } else {
            ratePercentage = ratePercentages.find(percentage =>
                percentage.cat_tool_break_name === newRatePercentage.name && percentage.percent === newRatePercentage.percent)
            message = "rate_percentage_already_exist"
        }
        if (ratePercentage) {
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error." + message}})
        } else {
            const id = ratePercentages.length > 0 ? Math.max(...ratePercentages.map(ratePercentage => ratePercentage.id)) : 0
            getNewRatePercentages([...ratePercentages, {
                id: id + 1,
                percent: newRatePercentage.percent,
                description: newRatePercentage.description ?? null,
                rate_id: newRatePercentage.rate_id,
                cat_tool_break_name: newRatePercentage.name,
                cat_tool_break_match_percent_min: newRatePercentage.match_percent_min ??
                    (newRatePercentage.name === catToolBreakTypes.EXACT_MATCHES ? EXACT_MATCHES_PERCENT :
                        newRatePercentage.name === catToolBreakTypes.CONTEXT_EXACT_MATCHES ? CONTEXT_EXACT_MATCHES_PERCENT : null),
                cat_tool_break_match_percent_max: newRatePercentage.match_percent_max ?? null
            }])
        }
    }

    const editRatePercentage = (editedRatePercentage) => {
        dispatch({type: actions.HIDE_MODAL})
        let ratePercentage
        let message
        if (editedRatePercentage.name === catToolBreakTypes.NEW_WORDS || editedRatePercentage.name === catToolBreakTypes.FUZZY_MATCHES) {
            ratePercentage = ratePercentages.find(percentage => percentage.id !== editedRatePercentage.id &&
                validatePercentRange(percentage.cat_tool_break_match_percent_min ?? 100,
                    percentage.cat_tool_break_match_percent_max ?? (percentage.cat_tool_break_match_percent_min ?? 100),
                    editedRatePercentage.match_percent_min, editedRatePercentage.match_percent_max))
            message = "cat_tool_break_percent_range_not_allowed"
        } else {
            ratePercentage = ratePercentages.find(percentage => percentage.id !== editedRatePercentage.id &&
                percentage.cat_tool_break_name === editedRatePercentage.name && percentage.percent === editedRatePercentage.percent)
            message = "rate_percentage_already_exist"
        }
        if (ratePercentage) {
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error." + message}})
        } else {
            setRatePercentages(ratePercentages.map(rp => rp.id !== editedRatePercentage.id ? rp :
                {
                    ...rp,
                    cat_tool_break_match_percent_min: editedRatePercentage.match_percent_min ??
                        (editedRatePercentage.name === catToolBreakTypes.EXACT_MATCHES ? EXACT_MATCHES_PERCENT :
                            editedRatePercentage.name === catToolBreakTypes.CONTEXT_EXACT_MATCHES ? CONTEXT_EXACT_MATCHES_PERCENT : null),
                    cat_tool_break_match_percent_max: editedRatePercentage.match_percent_max ?? null,
                    percent: editedRatePercentage.percent,
                    description: editedRatePercentage.description ?? null
                }
            ))
        }
    }

    const deleteRatePercentage = (id) => {
        dispatch({type: actions.HIDE_MODAL})
        let ratePercentagesAvailable = [...ratePercentages]
        ratePercentagesAvailable.splice(ratePercentages.indexOf(ratePercentages.find(ratePercentage => ratePercentage.id === id)), 1)
        setRatePercentages([...ratePercentagesAvailable])
        if (ratePercentageSearchType.current.type !== "all") {
            ratePercentagesAvailable = [...filteredRatePercentages]
            ratePercentagesAvailable.splice(filteredRatePercentages.indexOf(filteredRatePercentages.find(ratePercentage => ratePercentage.id === id)), 1)
            setFilteredRatePercentages([...ratePercentagesAvailable])
        }
        updateRatePercentagesPagination(ratePercentagesAvailable)
    }

    const showEditRatePercentage = (e, ratePercentage) => {
        e.preventDefault()
        e.stopPropagation()
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                type: modalTypes.EDIT_PERCENTAGE,
                title: intl.formatMessage({id: "message_title.edit_percentage"}),
                additionalData: {
                    percentage: ratePercentage,
                    editPercentage: editRatePercentage
                }
            }
        })
    }

    const showAddRatePercentage = (e) => {
        e.preventDefault()
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                type: modalTypes.NEW_PERCENTAGE,
                title: intl.formatMessage({id: "message_title.new_percentage"}),
                additionalData: {
                    newPercentage: newRatePercentage
                }
            }
        })
    }

    const showDeleteConfirmation = (e, id, type) => {
        e.preventDefault()
        e.stopPropagation()
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                buttons: [
                    {
                        label: intl.formatMessage({id: "general.delete"}),
                        classes: 'button is-link',
                        handler: () => {
                            type === "price" ? deletePrice(id) : type === "value" ? deleteValue(id) : deleteRatePercentage(id)
                        }
                    }
                ]
            }
        })
    }

    const calculatePrice = (price) => {
        let total = price
        if (customerRatePercent) {
            total = total * (roundDecimalNumber(customerRatePercent) / 100)
        }
        return roundDecimalNumber(total)
    }

    const LanguagePairsTable = () => {
        const columns = [
            {
                name: intl.formatMessage({id: "language_pair.source_language"}),
                selector: row => row.translation_source_language + (row.translation_code_source_language ? ' (' + row.translation_code_source_language + ')' : ""),
                wrap: true,
                sortable: true,
                sortField: 'translation_source_language'
            },
            {
                name: intl.formatMessage({id: "language_pair.target_language"}),
                selector: row => row.translation_target_language + (row.translation_code_target_language ? ' (' + row.translation_code_target_language + ')' : ""),
                wrap: true,
                sortable: true,
                sortField: 'translation_target_language'
            },
            {
                name: intl.formatMessage({id: "language_pair.unit"}),
                selector: row => row.unit_type,
                wrap: true,
                sortable: true,
                sortField: 'unit_type'
            },
            {
                name: intl.formatMessage({id: "language_pair.price"}),
                selector: row => calculatePrice(row.total),
                wrap: true,
                sortable: true,
                sortField: 'total'
            },
            {
                selector: row => (
                    <>
                        <TableIconButtonEdit handler={(e) => showEditPrice(e, row)} />
                        <TableIconButtonDelete handler={(e) => showDeleteConfirmation(e, row.id, "price")} />
                    </>
                ),
                right: true,
                wrap: true,
                grow: 0.1
            }
        ]
        return (
            <Datatable
                columns={columns}
                data={filterPricesPerPage()}
                defaultSortAsc={priceSortOrder.current !== "asc"}
                onSort={sortLanguagePairs}
                paginationComponent={() => <Pagination pagination={pricesPagination} handler={changePricesPagination} />}
                paginationPerPage={pricesPagination?.per_page ?? PAGINATION_PAGE_ROWS}
                sortServer
            />
        )
    }

    const TagsTable = () => {
        const columns = [
            {
                name: intl.formatMessage({id: "tag.name"}),
                selector: row => row.tag_name,
                wrap: true,
                sortable: true,
                sortField: 'tag_name'
            },
            {
                name: intl.formatMessage({id: "tag.type"}),
                selector: row => intl.formatMessage({id: "tag_type." + row.tag_type}),
                wrap: true,
                sortable: true,
                sortField: 'tag_type'
            },
            {
                name: intl.formatMessage({id: "tag.unit"}),
                selector: row => row.unit_type,
                wrap: true,
                sortable: true,
                sortField: 'unit_type'
            },
            {
                name: intl.formatMessage({id: "tag.value"}),
                selector: row => row.price !== null ? calculatePrice(row.price) :
                    row.percent === null ? intl.formatMessage({id: "datatable_tag.not_value"}) :
                        row.reference_tag ? row.percent + "%" + intl.formatMessage({id: "datatable_tag.reference"}) + row.reference_tag_name :
                            row.percent + "%" + intl.formatMessage({id: "datatable_tag.reference_base"}),
                wrap: true,
                sortable: true,
                sortField: 'values'
            },
            {
                name: intl.formatMessage({id: "tag.description"}),
                selector: row => row.description,
                wrap: true,
                sortable: true,
                sortField: 'description'
            },
            {
                selector: row => (
                    <>
                        <TableIconButtonEdit handler={(e) => showEditValue(e, row)} />
                        <TableIconButtonDelete handler={(e) => showDeleteConfirmation(e, row.id, "value")} />
                    </>
                ),
                right: true,
                wrap: true,
                grow: 0.1
            }
        ]
        return (
            <Datatable
                columns={columns}
                data={filterValuesPerPage()}
                defaultSortAsc={valueSortOrder.current !== "asc"}
                onSort={sortTags}
                paginationComponent={() => <Pagination pagination={valuesPagination} handler={changeValuesPagination} />}
                paginationPerPage={valuesPagination?.per_page ?? PAGINATION_PAGE_ROWS}
                sortServer
            />
        )
    }

    const CatToolBreaksTable = () => {
        const columns = [
            {
                name: intl.formatMessage({id: "cat_tool_break.name"}),
                selector: row => row.cat_tool_break_name + (row.cat_tool_break_match_percent_min !== null ?
                    " (" + row.cat_tool_break_match_percent_min + "%" + (row.cat_tool_break_match_percent_max ?
                        " - " + row.cat_tool_break_match_percent_max + "%)" : ")") : ""),
                wrap: true,
                sortable: true,
                sortField: 'cat_tool_break_match_percent_min'
            },
            {
                name: intl.formatMessage({id: "cat_tool_break.percent"}),
                selector: row => row.percent + "%",
                wrap: true,
                sortable: true,
                sortField: 'percent'
            },
            {
                name: intl.formatMessage({id: "cat_tool_break.description"}),
                selector: row => row.description,
                wrap: true,
                sortable: true,
                sortField: 'description'
            },
            {
                selector: row => (
                    <>
                        <TableIconButtonEdit handler={(e) => showEditRatePercentage(e, row)} />
                        <TableIconButtonDelete handler={(e) => showDeleteConfirmation(e, row.id, "ratePercentage")} />
                    </>
                ),
                right: true,
                wrap: true,
                grow: 0.1
            }
        ]
        return (
            <Datatable
                columns={columns}
                data={filterRatePercentagesPerPage()}
                defaultSortAsc={ratePercentageSortOrder.current !== "asc"}
                onSort={sortCatToolBreaks}
                paginationComponent={() => <Pagination pagination={ratePercentagesPagination} handler={changeRatePercentagesPagination} />}
                paginationPerPage={ratePercentagesPagination?.per_page ?? PAGINATION_PAGE_ROWS}
                progressPending={isGettingRatePercentages}
                sortServer
            />
        )
    }

    return (
        <>
            <Title title={intl.formatMessage({id: "title.new_customer_rate" + (isNewVersion.current ? "_version" : "")})} />
            <div className="container">
                <div className="level mb-0">
                    <div className="level-left">
                        <div className="level-item">
                            <div className="container">
                                <label className="label">
                                    <FormattedMessage id="customer_rate.customer" />: {customer.current?.name} ({customer.current?.type})
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="level mb-0">
                    <div className="level-left">
                        <div className="level-item">
                            <div className="container">
                                <label className="label">
                                    <FormattedMessage id="customer_rate.manufacturer" />: {manufacturer.current?.description}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <div className="container">
                                <label className="label">
                                    <FormattedMessage id="customer_rate.project" />: {project.current?.description}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="level mb-2">
                    <div className="level-left">
                        <div className="level-item">
                            <label className="checkbox has-text-weight-bold">
                                <input type="checkbox" checked={activeRateCheckbox}
                                       onChange={() => setActiveRateCheckbox(!activeRateCheckbox)} />
                                <FormattedMessage id={`customer_rate.active_rate`} />
                            </label>
                        </div>
                    </div>
                </div>
                <div className="level mb-0">
                    <div className="level-left">
                        <div className="level-item">
                            <label className="checkbox has-text-weight-bold">
                                <input type="checkbox" checked={linkedRateCheckbox}
                                       onChange={() => {
                                           setLinkedRateCheckbox(!linkedRateCheckbox)
                                           setEffectiveDate("")
                                       }} />
                                <FormattedMessage id={`customer_rate.linked_rate`} />
                            </label>
                        </div>
                    </div>
                </div>
                <div className="level">
                    {linkedRateCheckbox ?
                        <div className="level-left">
                            <div className="level-item">
                                <label className="label">
                                    <FormattedMessage id="customer_rate.effective_date" />:
                                </label>
                            </div>
                            <div className="level-item">
                                <input className="input"
                                       type="date"
                                       value={effectiveDate}
                                       onChange={(e) => setEffectiveDate(e.target.value)}
                                       min={getDateNow()}
                                />
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <label className="label">
                                <FormattedMessage id="customer_rate.customer_rate_percent" />:
                            </label>
                        </div>
                        <div className="level-item">
                            <div className="control has-icons-right">
                                <input className="input"
                                       type="number"
                                       value={customerRatePercent}
                                       placeholder={intl.formatMessage({id: "placeholder.percent"})}
                                       onChange={(e) => setCustomerRatePercent(e.target.value)}
                                       min={0}
                                       max={1000}
                                       step={0.1}
                                       onWheel={(e) => e.target.blur()}
                                />
                                <span className="icon is-right">
                                    <FontAwesomeIcon icon={faPercent} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <h3 className="subtitle is-3">
                                {name.current}
                            </h3>
                        </div>
                    </div>
                    <div className="level-right">
                        <div className="level-item">
                            <button className="button is-link"
                                    disabled={(prices.length < 1 && values.length < 1) || isGettingRatePercentages || isGettingExchangeRates}
                                    onClick={(e) => showCreateCustomerRate(e)}>
                                <span className="icon">
                                    <FontAwesomeIcon icon={faSave} />
                                </span>
                                <span>
                                    <FormattedMessage id="general.save" />
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="level mb-1">
                    <div className="level-left">
                        <div className="level-item">
                            <label className="label">
                                <FormattedMessage id="customer_rate.currency" />
                                {!isNewVersion.current ?
                                    <>
                                        <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                                        <span>:</span>
                                    </>
                                    :
                                    <span>
                                        : {exchangeRate.currency_name}
                                    </span>
                                }
                            </label>
                        </div>
                        <div className="level-item">
                            {!isNewVersion.current ?
                                <div className="control">
                                    <div className={`select ${isGettingExchangeRates ? 'is-loading' : ''}`}>
                                        <select value={exchangeRate?.id}
                                                onChange={(e) => changeExchangeRate(e.target.value)}>
                                            {exchangeRates?.map((exchangeRate, i) => (
                                                <option key={i} value={exchangeRate.id}>
                                                    {exchangeRate.currency_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
                <div className="level">
                    {exchangeRate && exchangeRate.currency_code !== STANDARD_CURRENCY_CODE ?
                        <div className="level-left">
                            <div className="level-item">
                                <label className="label">
                                    {STANDARD_CURRENCY_ABBREVIATION}/{exchangeRate.currency_abbreviation ?? exchangeRate.currency_code}:
                                </label>
                            </div>
                            <div className="level-item">
                                <div className="control">
                                    <input className="input"
                                           type="number"
                                           placeholder={intl.formatMessage({id: "placeholder.exchange_rate"})}
                                           value={valueExchangeRate}
                                           onChange={(e) => setValueExchangeRate(e.target.value)}
                                           min={0}
                                           max={9999999}
                                           step={0.1}
                                           onWheel={(e) => e.target.blur()}
                                           style={{width: "10rem"}}
                                    />
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
                <div className="tabs is-boxed is-medium">
                    <ul>
                        <li className={`${tab.current === 1 ? 'is-active' : ''}`} onClick={() => tab.current = 1}>
                            <Link to="#">
                                <FormattedMessage id="standard_rate_tab.language_pairs" />
                            </Link>
                        </li>
                        <li className={`${tab.current === 2 ? 'is-active' : ''}`} onClick={() => tab.current = 2}>
                            <Link to="#">
                                <FormattedMessage id="standard_rate_tab.tags" />
                            </Link>
                        </li>
                        <li className={`${tab.current === 3 ? 'is-active' : ''}`} onClick={() => tab.current = 3}>
                            <Link to="#">
                                <FormattedMessage id="standard_rate_tab.cat_tool_breaks" />
                            </Link>
                        </li>
                    </ul>
                </div>
                {tab.current === 1 ?
                    <>
                        <div className="level">
                            <div className="level-left">
                                <div className="level-item">
                                    <button className="button is-link"
                                            onClick={(e) => showAddPairs(e)}>
                                        <FormattedMessage id="standard_rate.add_pair" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="level">
                            <div className="level-left">
                                <div className="level-item">
                                    <label className="label">
                                        <FormattedMessage id="general.filter_by" />:
                                    </label>
                                </div>
                                <div className="level-item mr-0">
                                    <Dropdown id="languagePairNewCustomerRateFilterDropdown"
                                              text={languagePairFilter ? intl.formatMessage({id: "language_pair." + languagePairFilter}) : ""}
                                              content={<>
                                                  <Link to="#" className="dropdown-item"
                                                        onClick={() => {
                                                            setLanguagePairFilter("all")
                                                            resetPriceData()
                                                            filterAllPrices()
                                                        }}>
                                                      <FormattedMessage id={"language_pair.all"} />
                                                  </Link>
                                                  <hr className="dropdown-divider" />
                                                  <Link to="#" className="dropdown-item"
                                                        onClick={() => {
                                                            setLanguagePairFilter("source_language")
                                                            resetPriceData()
                                                        }}>
                                                      <FormattedMessage id={"language_pair.source_language"} />
                                                  </Link>
                                                  <Link to="#" className="dropdown-item"
                                                        onClick={() => {
                                                            setLanguagePairFilter("target_language")
                                                            resetPriceData()
                                                        }}>
                                                      <FormattedMessage id={"language_pair.target_language"} />
                                                  </Link>
                                                  <Link to="#" className="dropdown-item"
                                                        onClick={() => {
                                                            setLanguagePairFilter("unit")
                                                            resetPriceData()
                                                            dispatch(getUnits())
                                                        }}>
                                                      <FormattedMessage id={"language_pair.unit"} />
                                                  </Link>
                                                  <Link to="#" className="dropdown-item"
                                                        onClick={() => {
                                                            setLanguagePairFilter("price")
                                                            resetPriceData()
                                                        }}>
                                                      <FormattedMessage id={"language_pair.price"} />
                                                  </Link>
                                              </>}
                                    />
                                </div>
                                <div className="level-item">
                                    <div className="field has-addons">
                                        {languagePairFilter === "source_language" || languagePairFilter === "target_language" ?
                                            <Search size={"normal"}
                                                    placeholder={intl.formatMessage({id: "placeholder.filter_by_" + languagePairFilter})}
                                                    value={languageFilter}
                                                    inputChange={(e) => setLanguageFilter(e.target.value)}
                                                    maxLength={24}
                                                    disabledButton={languageFilter.trim() === ""}
                                                    buttonClick={(e) => filterPricesByLanguage(e)}
                                                    buttonText={intl.formatMessage({id: "general.search"})}
                                            />
                                            :
                                            languagePairFilter === "unit" ?
                                                <Dropdown id="languagePairUnitsNewCustomerRateDropdown"
                                                          text={priceUnitFilter ? priceUnitFilter : "-----"}
                                                          isLoading={isGettingUnits}
                                                          content={<>
                                                              {units?.map((unit, i) => (
                                                                  <Link to="#" className="dropdown-item" key={i}
                                                                        onClick={() => {
                                                                            setPriceUnitFilter(unit.type)
                                                                            filterPricesByUnit(unit.type)
                                                                        }}>
                                                                      {unit.type}
                                                                  </Link>
                                                              ))}
                                                          </>}
                                                />
                                                :
                                                languagePairFilter === "price" ?
                                                    <>
                                                        <div className="control">
                                                            <input className="input"
                                                                   type="number"
                                                                   placeholder={intl.formatMessage({id: "placeholder.min"})}
                                                                   value={priceMinFilter}
                                                                   onChange={(e) => setPriceMinFilter(e.target.value)}
                                                                   min={0}
                                                                   max={9999999}
                                                                   step={0.1}
                                                                   onWheel={(e) => e.target.blur()}
                                                            />
                                                        </div>
                                                        <div className="control">
                                                            <button className="button is-static" tabIndex="-1">-</button>
                                                        </div>
                                                        <div className="control">
                                                            <input className="input"
                                                                   type="number"
                                                                   placeholder={intl.formatMessage({id: "placeholder.max"})}
                                                                   value={priceMaxFilter}
                                                                   onChange={(e) => setPriceMaxFilter(e.target.value)}
                                                                   min={0}
                                                                   max={9999999}
                                                                   step={0.1}
                                                                   onWheel={(e) => e.target.blur()}
                                                            />
                                                        </div>
                                                        <button className="button is-link"
                                                                disabled={priceMinFilter.trim() === "" || priceMaxFilter.trim() === ""}
                                                                onClick={(e) => filterPricesByPrice(e)}>
                                                            <FormattedMessage id="general.search" />
                                                        </button>
                                                    </>
                                                    :
                                                    null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <LanguagePairsTable />
                    </>
                    :
                    tab.current === 2 ?
                        <>
                            <div className="level">
                                <div className="level-left">
                                    <div className="level-item">
                                        <button className="button is-link"
                                                onClick={(e) => showAddTags(e)}>
                                            <FormattedMessage id="standard_rate.add_tag" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="level">
                                <div className="level-left">
                                    <div className="level-item">
                                        <label className="label">
                                            <FormattedMessage id="general.filter_by" />:
                                        </label>
                                    </div>
                                    <div className="level-item mr-0">
                                        <Dropdown id="tagNewCustomerRateFilterDropdown"
                                                  text={tagFilter ? intl.formatMessage({id: "tag." + tagFilter}) : ""}
                                                  content={<>
                                                      <Link to="#" className="dropdown-item"
                                                            onClick={() => {
                                                                setTagFilter("all")
                                                                resetValueData()
                                                                filterAllValues()
                                                            }}>
                                                          <FormattedMessage id={"tag.all"} />
                                                      </Link>
                                                      <hr className="dropdown-divider" />
                                                      <Link to="#" className="dropdown-item"
                                                            onClick={() => {
                                                                setTagFilter("name")
                                                                resetValueData()
                                                            }}>
                                                          <FormattedMessage id={"tag.name"} />
                                                      </Link>
                                                      <Link to="#" className="dropdown-item"
                                                            onClick={() => {
                                                                setTagFilter("type")
                                                                resetValueData()
                                                            }}>
                                                          <FormattedMessage id={"tag.type"} />
                                                      </Link>
                                                      <Link to="#" className="dropdown-item"
                                                            onClick={() => {
                                                                setTagFilter("unit")
                                                                resetValueData()
                                                                dispatch(getUnits())
                                                            }}>
                                                          <FormattedMessage id={"tag.unit"} />
                                                      </Link>
                                                      <Link to="#" className="dropdown-item"
                                                            onClick={() => {
                                                                setTagFilter("percent")
                                                                resetValueData()
                                                            }}>
                                                          <FormattedMessage id={"tag.percent"} />
                                                      </Link>
                                                      <Link to="#" className="dropdown-item"
                                                            onClick={() => {
                                                                setTagFilter("price")
                                                                resetValueData()
                                                            }}>
                                                          <FormattedMessage id={"tag.price"} />
                                                      </Link>
                                                  </>}
                                        />
                                    </div>
                                    <div className="level-item">
                                        <div className="field has-addons">
                                            {tagFilter === "name" ?
                                                <Search size={"normal"}
                                                        placeholder={intl.formatMessage({id: "placeholder.filter_by_name"})}
                                                        value={tagNameFilter}
                                                        inputChange={(e) => setTagNameFilter(e.target.value)}
                                                        maxLength={50}
                                                        disabledButton={tagNameFilter.trim() === ""}
                                                        buttonClick={(e) => filterValuesByTagName(e)}
                                                        buttonText={intl.formatMessage({id: "general.search"})}
                                                />
                                                :
                                                tagFilter === "type" ?
                                                    <Dropdown id="tagTypesNewCustomerRateDropdown"
                                                              text={typeFilter ? intl.formatMessage({id: "tag_type." + typeFilter}) : "-----"}
                                                              content={<>
                                                                  {TAG_TYPES?.map((type, i) => (
                                                                      <Link to="#" className="dropdown-item" key={i}
                                                                            onClick={() => {
                                                                                setTypeFilter(type)
                                                                                filterValuesByTagType(type)
                                                                            }}>
                                                                          <FormattedMessage id={"tag_type." + type} />
                                                                      </Link>
                                                                  ))}
                                                              </>}
                                                    />
                                                    :
                                                    tagFilter === "unit" ?
                                                        <Dropdown id="tagUnitsNewCustomerRateDropdown"
                                                                  text={valueUnitFilter ? valueUnitFilter : "-----"}
                                                                  isLoading={isGettingUnits}
                                                                  content={<>
                                                                      {units?.map((unit, i) => (
                                                                          <Link to="#" className="dropdown-item" key={i}
                                                                                onClick={() => {
                                                                                    setValueUnitFilter(unit.type)
                                                                                    filterValuesByUnit(unit.type)
                                                                                }}>
                                                                              {unit.type}
                                                                          </Link>
                                                                      ))}
                                                                  </>}
                                                        />
                                                        :
                                                        tagFilter === "percent" || tagFilter === "price" ?
                                                            <>
                                                                <div className="control">
                                                                    <input className="input"
                                                                           type="number"
                                                                           placeholder={intl.formatMessage({id: "placeholder.min"})}
                                                                           value={valueMinFilter}
                                                                           onChange={(e) => setValueMinFilter(e.target.value)}
                                                                           min={0}
                                                                           max={9999999}
                                                                           step={0.1}
                                                                           onWheel={(e) => e.target.blur()}
                                                                    />
                                                                </div>
                                                                <div className="control">
                                                                    <button className="button is-static" tabIndex="-1">-</button>
                                                                </div>
                                                                <div className="control">
                                                                    <input className="input"
                                                                           type="number"
                                                                           placeholder={intl.formatMessage({id: "placeholder.max"})}
                                                                           value={valueMaxFilter}
                                                                           onChange={(e) => setValueMaxFilter(e.target.value)}
                                                                           min={0}
                                                                           max={9999999}
                                                                           step={0.1}
                                                                           onWheel={(e) => e.target.blur()}
                                                                    />
                                                                </div>
                                                                <button className="button is-link"
                                                                        disabled={valueMinFilter.trim() === "" || valueMaxFilter.trim() === ""}
                                                                        onClick={(e) => filterValuesByValue(e)}>
                                                                    <FormattedMessage id="general.search" />
                                                                </button>
                                                            </>
                                                            :
                                                            null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <TagsTable />
                        </>
                        :
                        tab.current === 3 ?
                            <>
                                <div className="level">
                                    <div className="level-left">
                                        <div className="level-item">
                                            <button className="button is-link"
                                                    disabled={isGettingRatePercentages}
                                                    onClick={(e) => showAddRatePercentage(e)}>
                                                <FormattedMessage id="standard_rate.add_percentage" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="level">
                                    <div className="level-left">
                                        <div className="level-item">
                                            <label className="label">
                                                <FormattedMessage id="general.filter_by" />:
                                            </label>
                                        </div>
                                        <div className="level-item mr-0">
                                            <Dropdown id="catToolBreakNewCustomerRateFilterDropdown"
                                                      text={catToolBreakFilter ? intl.formatMessage({id: "cat_tool_break." + catToolBreakFilter}) : ""}
                                                      content={<>
                                                          <Link to="#" className="dropdown-item"
                                                                onClick={() => {
                                                                    setCatToolBreakFilter("all")
                                                                    resetRatePercentageData()
                                                                    filterAllRatePercentages()
                                                                }}>
                                                              <FormattedMessage id={"cat_tool_break.all"} />
                                                          </Link>
                                                          <hr className="dropdown-divider" />
                                                          <Link to="#" className="dropdown-item"
                                                                onClick={() => {
                                                                    setCatToolBreakFilter("name")
                                                                    resetRatePercentageData()
                                                                }}>
                                                              <FormattedMessage id={"cat_tool_break.name"} />
                                                          </Link>
                                                          <Link to="#" className="dropdown-item"
                                                                onClick={() => {
                                                                    setCatToolBreakFilter("percent_range")
                                                                    resetRatePercentageData()
                                                                }}>
                                                              <FormattedMessage id={"cat_tool_break.percent_range"} />
                                                          </Link>
                                                          <Link to="#" className="dropdown-item"
                                                                onClick={() => {
                                                                    setCatToolBreakFilter("percent")
                                                                    resetRatePercentageData()
                                                                }}>
                                                              <FormattedMessage id={"cat_tool_break.percent"} />
                                                          </Link>
                                                      </>}
                                            />
                                        </div>
                                        <div className="level-item">
                                            <div className="field has-addons">
                                                {catToolBreakFilter === "name" ?
                                                    <Dropdown id="catToolBreakNamesNewCustomerRateDropdown"
                                                              text={catToolBreakNameFilter ? catToolBreakNameFilter : "-----"}
                                                              content={<>
                                                                  {CAT_TOOL_BREAK_TYPES?.map((type, i) => (
                                                                      <Link to="#" className="dropdown-item" key={i}
                                                                            onClick={() => {
                                                                                setCatToolBreakNameFilter(type)
                                                                                filterRatePercentagesByName(type)
                                                                            }}>
                                                                          {type}
                                                                      </Link>
                                                                  ))}
                                                              </>}
                                                    />
                                                    :
                                                    catToolBreakFilter === "percent_range" || catToolBreakFilter === "percent" ?
                                                        <>
                                                            <div className="control">
                                                                <input className="input"
                                                                       type="number"
                                                                       placeholder={intl.formatMessage({id: "placeholder.min"})}
                                                                       value={ratePercentageMinFilter}
                                                                       onChange={(e) => setRatePercentageMinFilter(e.target.value)}
                                                                       min={0}
                                                                       max={9999999}
                                                                       step={0.1}
                                                                       onWheel={(e) => e.target.blur()}
                                                                />
                                                            </div>
                                                            <div className="control">
                                                                <button className="button is-static" tabIndex="-1">-</button>
                                                            </div>
                                                            <div className="control">
                                                                <input className="input"
                                                                       type="number"
                                                                       placeholder={intl.formatMessage({id: "placeholder.max"})}
                                                                       value={ratePercentageMaxFilter}
                                                                       onChange={(e) => setRatePercentageMaxFilter(e.target.value)}
                                                                       min={0}
                                                                       max={9999999}
                                                                       step={0.1}
                                                                       onWheel={(e) => e.target.blur()}
                                                                />
                                                            </div>
                                                            <button className="button is-link"
                                                                    disabled={ratePercentageMinFilter.trim() === "" || ratePercentageMaxFilter.trim() === ""}
                                                                    onClick={(e) => filterRatePercentagesByPercent(e)}>
                                                                <FormattedMessage id="general.search" />
                                                            </button>
                                                        </>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <CatToolBreaksTable />
                            </>
                            :
                            null
                }
                <hr className="dropdown-divider" />
                <div className="level is-mobile mt-5 mb-0">
                    <div className="level-left">
                        <div className="level-item">
                            <label className="label">
                                <FormattedMessage id="customer_rate.comment" />:
                            </label>
                        </div>
                    </div>
                </div>
                <div className="control mb-5">
                    <textarea className="textarea has-fixed-size"
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                              maxLength={255}
                    />
                </div>
            </div>
        </>
    )
}
