import {useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import * as actions from '../../store/Actions'
import {getAllValues, storeValue} from '../../store/reducers/value/valueActions'
import {getTags} from '../../store/reducers/tag/tagActions'
import {getUnits} from '../../store/reducers/unit/unitActions'
import {DraggableModal} from '../UI/DraggableModal'
import * as modalTypes from '../../consts/enums/modalTypes'
import {roundDecimalNumber} from '../../utils/roundDecimalNumber'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEuroSign, faPercent} from '@fortawesome/free-solid-svg-icons'

export const ModalNewValue = ({title = "", standardRateId = null}) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const values = useSelector(state => state.value.allValues)
    const tags = useSelector(state => state.tag.tags)
    const isGettingTags = useSelector(state => state.tag.isGettingTags)
    const units = useSelector(state => state.unit.units)
    const isGettingUnits = useSelector(state => state.unit.isGettingUnits)
    const [tag, setTag] = useState("")
    const [unit, setUnit] = useState("")
    const [valueType, setValueType] = useState("")
    const [price, setPrice] = useState("")
    const [percent, setPercent] = useState("")
    const [referenceTag, setReferenceTag] = useState("")
    const [description, setDescription] = useState("")

    useEffect(() => {
        dispatch(getAllValues(standardRateId))
        dispatch(getTags())
        dispatch(getUnits())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onHideClick = (e) => {
        e.preventDefault()
        dispatch({type: actions.HIDE_MODAL})
    }

    const filterReferenceTags = () => {
        // filter the tags whose id is different from the chosen tag id
        return tags.filter(referenceTag => referenceTag.id !== parseInt(tag) &&
            // and there is a value with the tag id
            values.find(value => value.tag_id === referenceTag.id &&
                // and value has percent
                value.percent !== null &&
                // and value has no reference tag equal to the chosen tag
                value.reference_tag !== parseInt(tag) &&
                // and if a unit was chosen, the value unit is equal to the chosen unit OR value has no unit
                (unit ? value.unit_id === parseInt(unit) || !value.unit_id : true)))
    }

    const changeTag = (id) => {
        setTag(id)
        resetReferenceTag()
    }

    const changeUnit = (id) => {
        setUnit(id)
        resetReferenceTag()
    }

    const resetReferenceTag = () => {
        setReferenceTag("")
        document.getElementById("referenceTagSelect").value = ""
    }

    const createValue = (e) => {
        e.preventDefault()
        const value = {
            price: roundDecimalNumber(price),
            percent: roundDecimalNumber(percent),
            reference_tag: parseInt(referenceTag),
            description: description,
            tag_id: parseInt(tag),
            unit_id: parseInt(unit),
            rate_id: standardRateId
        }
        if (description.trim() === "") {
            delete value.description
        }
        if (percent.trim() === "") {
            delete value.reference_tag
        }
        for (let prop in value) {
            if (!value[prop] && value[prop] !== 0) {
                delete value[prop]
            }
        }
        dispatch(storeValue(value))
        dispatch({type: actions.HIDE_MODAL})
        dispatch({type: actions.SHOW_MODAL, payload: {type:modalTypes.LOADING}})
    }

    const Body = () => {
        return (
            <>
                <div className="field">
                    <label className="label">
                        <FormattedMessage id="tag.tag" />
                        <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                        <span>:</span>
                    </label>
                    <div className="control">
                        <div className={`select ${isGettingTags ? 'is-loading' : ''}`}>
                            <select onChange={(e) => changeTag(e.target.value)}>
                                <option value="">
                                    -----
                                </option>
                                {tags?.map((tag, i) => (
                                    <option key={i} value={tag.id}>
                                        {tag.name} - {intl.formatMessage({id: "tag_type." + tag.type})}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="field">
                    <label className="label">
                        <FormattedMessage id="tag.unit" />:
                    </label>
                    <div className={`select ${isGettingUnits ? 'is-loading' : ''}`}>
                        <select onChange={(e) => changeUnit(e.target.value)}>
                            <option value="">
                                -----
                            </option>
                            {units?.map((unit, i) => (
                                <option key={i} value={unit.id}>
                                    {unit.type}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="field">
                    <div className="control">
                        <label className="radio">
                            <input type="radio" name="value" checked={valueType === "price"} onChange={() => {
                                setValueType("price")
                                setPercent("")
                                resetReferenceTag()
                            }} />
                            <FormattedMessage id="tag.price" />
                        </label>
                        <label className="radio">
                            <input type="radio" name="value" checked={valueType === "percent"} onChange={() => {
                                setValueType("percent")
                                setPrice("")
                            }} />
                            <FormattedMessage id="tag.percent" />
                        </label>
                    </div>
                </div>
                <div className={`field ${valueType !== "price" ? 'is-hidden' : ''}`}>
                    <label className="label">
                        <FormattedMessage id="tag.price" />:
                    </label>
                    <div className="columns">
                        <div className="column is-one-third">
                            <div className="control has-icons-left">
                                <input className="input"
                                       type="number"
                                       placeholder={intl.formatMessage({id: "placeholder.price"})}
                                       value={price}
                                       onChange={(e) => setPrice(e.target.value)}
                                       min={0}
                                       max={9999999}
                                       step={0.1}
                                       onWheel={(e) => e.target.blur()}
                                />
                                <span className="icon is-left">
                                    <FontAwesomeIcon icon={faEuroSign} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`field ${valueType !== "percent" ? 'is-hidden' : ''}`}>
                    <label className="label">
                        <FormattedMessage id="tag.percent" />:
                    </label>
                    <div className="columns">
                        <div className="column is-one-third">
                            <div className="control has-icons-right">
                                <input className="input"
                                       type="number"
                                       placeholder={intl.formatMessage({id: "placeholder.percent"})}
                                       value={percent}
                                       onChange={(e) => setPercent(e.target.value)}
                                       min={0}
                                       max={9999999}
                                       step={0.1}
                                       onWheel={(e) => e.target.blur()}
                                />
                                <span className="icon is-right">
                                    <FontAwesomeIcon icon={faPercent} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`field ${valueType !== "percent" || percent === "" ? 'is-hidden' : ''}`}>
                    <label className="label">
                        <FormattedMessage id="tag.reference_tag" />:
                    </label>
                    <div className={`select ${isGettingTags ? 'is-loading' : ''}`}>
                        <select id="referenceTagSelect" onChange={(e) => setReferenceTag(e.target.value)}>
                            <option value="">
                                -----
                            </option>
                            {filterReferenceTags().map((tag, i) => (
                                <option key={i} value={tag.id}>
                                    {tag.name} - {intl.formatMessage({id: "tag_type." + tag.type})}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="field">
                    <label className="label">
                        <FormattedMessage id="tag.description" />:
                    </label>
                    <div className="control">
                        <textarea className="textarea has-fixed-size"
                                  value={description}
                                  onChange={(e) => setDescription(e.target.value)}
                                  maxLength={255}
                        />
                    </div>
                </div>
            </>
        )
    }

    const Foot = () => {
        return (
            <>
                <button className="button is-link"
                        disabled={tag === ""}
                        onClick={(e) => createValue(e)}>
                    <FormattedMessage id="general.save" />
                </button>
                <button className="button"
                        onClick={(e) => onHideClick(e)}>
                    <FormattedMessage id="general.cancel" />
                </button>
            </>
        )
    }

    return (
        <DraggableModal title={title} body={Body()} foot={Foot()} onHideClick={onHideClick} />
    )
}
