import {axiosInstance} from '../../../hoc/Axios/Axios'
import * as actions from '../../Actions'
import * as toastTypes from '../../../consts/enums/toastTypes'
import {encodeParameter} from '../../../utils/encodeParameter'

export const getManufacturers = (customerId) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_MANUFACTURERS})
    dispatch({type: actions.GETTING_MANUFACTURERS})
    try {
        const url = customerId ? "/manufacturers/" + customerId : "/manufacturers"
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_MANUFACTURERS, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.manufacturers_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.MANUFACTURER_ERROR, payload: error.response})
    }
}

export const getManufacturersByName = (name, customerId) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_MANUFACTURERS})
    dispatch({type: actions.GETTING_MANUFACTURERS})
    try {
        name = encodeParameter(name)
        const url = "/manufacturers/by-name/" + name + "/" + customerId
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_MANUFACTURERS, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.manufacturers_by_name_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.MANUFACTURER_ERROR, payload: error.response})
    }
}

export const getManufacturersIdcp = (name, customerCode) => async (dispatch, getState) => {
    dispatch({type: actions.GETTING_MANUFACTURERS_IDCP})
    try {
        name = encodeParameter(name)
        const res = await axiosInstance.get("/manufacturers-idcp/" + name + "/" + customerCode)
        dispatch({type: actions.GET_MANUFACTURERS_IDCP, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.manufacturer_does_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.MANUFACTURER_ERROR, payload: error.response})
    }
}
