import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'
import {PersistGate} from 'redux-persist/integration/react'
import {Provider} from 'react-redux'
import {store, persistor} from "./store/store"
import App from './App'
import {interceptor} from "./hoc/Axios/Axios"
interceptor(store)

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
          <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                  <App />
              </PersistGate>
          </Provider>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
