import {useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import * as actions from '../../store/Actions'
import {getValues} from '../../store/reducers/value/valueActions'
import {getRatePercentages} from '../../store/reducers/ratePercentage/ratePercentageActions'
import {DraggableModal} from '../UI/DraggableModal'
import {Loader} from '../UI/Loader'

export const ModalSelectTagsNewRate = ({title = "", additionalData = null}) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const values = useSelector(state => state.value.values)
    const isGettingValues = useSelector(state => state.value.isGettingValues)
    const error = useSelector(state => state.value.error)
    const [allChecked, setAllChecked] = useState(false)
    const [checkboxes, setCheckboxes] = useState([])

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!isGettingValues) {
            const checkboxes = []
            for (let i = 0; i < values.length; i++) {
                checkboxes.push(false)
            }
            setCheckboxes([...checkboxes])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGettingValues])

    useEffect(() => {
        if (error) {
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.RESET_VALUE_ERROR})
            dispatch({type: actions.RESET_RATE_PERCENTAGES})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    const getData = () => {
        dispatch(getValues(additionalData.rateId))
    }

    const onHideClick = (e) => {
        e.preventDefault()
        additionalData.getNewValues([], values)
        closeSelectTags()
    }

    const closeSelectTags = () => {
        dispatch({type: actions.HIDE_MODAL})
        dispatch(getRatePercentages(additionalData.rateId))
    }

    const changeAllChecked = () => {
        const checks = [...checkboxes]
        for (let i = 0; i < checks.length; i++) {
            checks[i] = !allChecked
        }
        setCheckboxes([...checks])
        setAllChecked(!allChecked)
    }

    const changeChecked = (i) => {
        const checks = [...checkboxes]
        checks[i] = !checks[i]
        setCheckboxes([...checks])
        if (allChecked || checks.filter(check => check === false).length < 1) {
            setAllChecked(!allChecked)
        }
    }

    const accept = (e) => {
        e.preventDefault()
        const newValues = []
        for (let i = 0; i < values.length; i++) {
            if (checkboxes[i]) {
                newValues.push(JSON.parse(JSON.stringify(values[i])))
            }
        }
        for (let i = 0; i < newValues.length; i++) {
            if (!newValues.find(value => value.tag_id === newValues[i].reference_tag)) {
                newValues[i].reference_tag = null
                newValues[i].reference_tag_name = null
                newValues[i].reference_tag_type = null
            }
        }
        additionalData.getNewValues(newValues, values)
        closeSelectTags()
    }

    const Body = () => {
        return (
            <>
                {isGettingValues ?
                    <Loader />
                    :
                    <>
                        <div className="level">
                            <div className="level-left">
                                <label className="checkbox has-text-weight-bold">
                                    <input type="checkbox" checked={allChecked}
                                           onChange={(e) => changeAllChecked()} />
                                    <FormattedMessage id="standard_rate.select_all_tags" />
                                </label>
                            </div>
                        </div>
                        {values?.map((value, i) => (
                            <div key={i} className="level mb-0">
                                <div className="level-left">
                                    <label className="checkbox">
                                        <input type="checkbox" checked={checkboxes[i] ?? false}
                                               onChange={() => changeChecked(i)} />
                                        {value.tag_name} {value.unit_id ? "(" + value.unit_type + ")" : ""} - {intl.formatMessage({id: "tag_type." + value.tag_type})}
                                    </label>
                                </div>
                            </div>
                        ))}
                    </>
                }
            </>
        )
    }

    const Foot = () => {
        return (
            <>
                <button className="button is-link"
                        disabled={isGettingValues}
                        onClick={(e) => accept(e)}>
                    <FormattedMessage id="general.accept" />
                </button>
                <button className="button"
                        onClick={(e) => onHideClick(e)}>
                    <FormattedMessage id="general.cancel" />
                </button>
            </>
        )
    }

    return (
        <DraggableModal title={title} body={Body()} foot={Foot()} onHideClick={onHideClick} />
    )
}
