import {useEffect, useRef, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import * as actions from '../store/Actions'
import {deleteUnit, getUnits, getUnitsByType} from '../store/reducers/unit/unitActions'
import {Datatable} from '../components/Datatable/Datatable'
import {Title} from '../components/UI/Title'
import {Dropdown} from '../components/UI/Dropdown'
import {Search} from '../components/UI/Search'
import {TableIconButtonDelete} from '../components/UI/TableIconButtonDelete'
import {Pagination} from '../components/UI/Pagination'
import * as modalTypes from '../consts/enums/modalTypes'
import * as toastTypes from '../consts/enums/toastTypes'
import {PAGINATION_PAGE_ROWS, UNIT_WORD_ID} from '../consts/app'

export const Units = () => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const units = useSelector(state => state.unit.units)
    const isGettingUnits = useSelector(state => state.unit.isGettingUnits)
    const pagination = useSelector(state => state.unit.pagination)
    const hasUnitBeenCreated = useSelector(state => state.unit.unitCreated)
    const hasUnitBeenDeleted = useSelector(state => state.unit.unitDeleted)
    const error = useSelector(state => state.unit.error)
    const [unitFilter, setUnitFilter] = useState("all")
    const [typeFilter, setTypeFilter] = useState("")
    const searchType = useRef({type: "all", search: ""})
    const sortType = useRef({sort: "type", order: "asc"})

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (hasUnitBeenCreated) {
            getData()
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.SUCCESS, message: "message.unit_created"}})
            dispatch({type: actions.UNIT_CREATED, payload: {status: false}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasUnitBeenCreated])

    useEffect(() => {
        if (hasUnitBeenDeleted) {
            getData()
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.SUCCESS, message: "message.unit_deleted"}})
            dispatch({type: actions.UNIT_DELETED, payload: {status: false}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasUnitBeenDeleted])

    useEffect(() => {
        if (error) {
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.RESET_UNIT_ERROR})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    const getData = () => {
        setUnitFilter("all")
        searchType.current = {type: "all", search: ""}
        dispatch(getUnits(sortType.current.sort, sortType.current.order, 1))
    }

    const resetData = () => {
        setTypeFilter("")
    }

    const filterUnitsByType = (e) => {
        e.preventDefault()
        searchType.current = {type: "type", search: typeFilter}
        dispatch(getUnitsByType(typeFilter, sortType.current.sort, sortType.current.order, 1))
    }

    const sortUnits = (column, sortDirection) => {
        sortType.current = {sort: column.sortField, order: sortDirection}
        getUnitsPerPage(1)
    }

    const getUnitsPerPage = (page) => {
        if (searchType.current.type === "all") {
            dispatch(getUnits(sortType.current.sort, sortType.current.order, page))
        } else {
            dispatch(getUnitsByType(searchType.current.search, sortType.current.sort, sortType.current.order, page))
        }
    }

    const deleteAUnit = (id) => {
        dispatch(deleteUnit(id))
        dispatch({type: actions.HIDE_MODAL})
        dispatch({type: actions.SHOW_MODAL, payload: {type:modalTypes.LOADING}})
    }

    const showCreateUnit = (e) => {
        e.preventDefault()
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                type: modalTypes.NEW_UNIT,
                title: intl.formatMessage({id: "message_title.new_unit"})
            }
        })
    }

    const showDeleteConfirmation = (e, id) => {
        e.preventDefault()
        e.stopPropagation()
        if (id === UNIT_WORD_ID) {
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.cannot_delete_unit"}})
        } else {
            dispatch({
                type: actions.SHOW_MODAL, payload: {
                    buttons: [
                        {
                            label: intl.formatMessage({id: "general.delete"}),
                            classes: 'button is-link',
                            handler: () => {
                                deleteAUnit(id)
                            }
                        }
                    ]
                }
            })
        }
    }

    const UnitsTable = () => {
        const columns = [
            {
                name: intl.formatMessage({id: "unit.type"}),
                selector: row => row.type,
                wrap: true,
                sortable: true,
                sortField: 'type'
            },
            {
                selector: row => (
                    <TableIconButtonDelete handler={(e) => showDeleteConfirmation(e, row.id)} />
                ),
                right: true,
                wrap: true,
                grow: 0.1
            }
        ]
        return (
            <Datatable
                columns={columns}
                data={units}
                defaultSortAsc={sortType.current.order !== "asc"}
                onSort={sortUnits}
                paginationComponent={() => <Pagination pagination={pagination} handler={getUnitsPerPage} />}
                paginationPerPage={pagination?.per_page ?? PAGINATION_PAGE_ROWS}
                progressPending={isGettingUnits}
                sortServer
            />
        )
    }

    return (
        <>
            <Title title={intl.formatMessage({id: "title.units"})} />
            <div className="container">
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <button className="button is-link"
                                    onClick={(e) => showCreateUnit(e)}>
                                <FormattedMessage id="unit.new_unit" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <label className="label">
                                <FormattedMessage id="general.filter_by" />:
                            </label>
                        </div>
                        <div className="level-item mr-0">
                            <Dropdown id="unitFilterDropdown"
                                      text={unitFilter ? intl.formatMessage({id: "unit." + unitFilter}) : ""}
                                      content={<>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    resetData()
                                                    getData()
                                                }}>
                                              <FormattedMessage id={"unit.all"} />
                                          </Link>
                                          <hr className="dropdown-divider" />
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setUnitFilter("type")
                                                    resetData()
                                                }}>
                                              <FormattedMessage id={"unit.type"} />
                                          </Link>
                                      </>}
                            />
                        </div>
                        <div className="level-item">
                            <div className="field has-addons">
                                {unitFilter === "type" ?
                                    <Search size={"normal"}
                                            placeholder={intl.formatMessage({id: "placeholder.filter_by_type"})}
                                            value={typeFilter}
                                            inputChange={(e) => setTypeFilter(e.target.value)}
                                            maxLength={20}
                                            isLoading={(isGettingUnits && typeFilter.trim() !== "")}
                                            disabledButton={typeFilter.trim() === ""}
                                            buttonClick={(e) => filterUnitsByType(e)}
                                            buttonText={intl.formatMessage({id: "general.search"})}
                                    />
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <UnitsTable />
            </div>
        </>
    )
}
