import {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import * as actions from '../../store/Actions'
import {DraggableModal} from '../UI/DraggableModal'
import {roundDecimalNumber} from '../../utils/roundDecimalNumber'

export const ModalEditCurrency = ({title = "", exchangeRate = null, editExchangeRate = null}) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const [value, setValue] = useState("" + exchangeRate.value)

    const onHideClick = (e) => {
        e.preventDefault()
        dispatch({type: actions.HIDE_MODAL})
    }

    const edit = (e) => {
        e.preventDefault()
        const editedExchangeRate = {
            id: exchangeRate.id,
            value: roundDecimalNumber(value),
            currency_id: exchangeRate.currency_id
        }
        editExchangeRate(editedExchangeRate)
    }

    const Body = () => {
        return (
            <>
                <div className="field">
                    <label className="label">
                        <FormattedMessage id="currency.currency" />: {exchangeRate.currency_name}
                        {exchangeRate.currency_abbreviation ? " (" + exchangeRate.currency_abbreviation + ")" : null}
                    </label>
                </div>
                <div className="field">
                    <label className="label">
                        <FormattedMessage id="currency.exchange_rate" />
                        <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                        <span>:</span>
                    </label>
                    <div className="columns">
                        <div className="column is-one-third">
                            <div className="control">
                                <input className="input"
                                       type="number"
                                       placeholder={intl.formatMessage({id: "placeholder.exchange_rate"})}
                                       value={value}
                                       onChange={(e) => setValue(e.target.value)}
                                       autoFocus
                                       min={0}
                                       max={9999999}
                                       step={0.1}
                                       onWheel={(e) => e.target.blur()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const Foot = () => {
        return (
            <>
                <button className="button is-link"
                        disabled={value.trim() === ""}
                        onClick={(e) => edit(e)}>
                    <FormattedMessage id="general.save" />
                </button>
                <button className="button"
                        onClick={(e) => onHideClick(e)}>
                    <FormattedMessage id="general.cancel" />
                </button>
            </>
        )
    }

    return (
        <DraggableModal title={title} body={Body()} foot={Foot()} onHideClick={onHideClick} />
    )
}
