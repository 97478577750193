import {useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import * as actions from '../../store/Actions'
import * as modalTypes from '../../consts/enums/modalTypes'
import {DraggableModal} from '../UI/DraggableModal'

export const ModalSelectBreaksExport = ({title = "", additionalData = null}) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const [allChecked, setAllChecked] = useState(false)
    const [checkboxes, setCheckboxes] = useState([])

    useEffect(() => {
        if (additionalData.ratePercentages.length < 1) {
            showSelectLanguages([])
        } else {
            const checkboxes = []
            for (let i = 0; i < additionalData.ratePercentages.length; i++) {
                checkboxes.push(false)
            }
            setCheckboxes([...checkboxes])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onHideClick = (e) => {
        e.preventDefault()
        dispatch({type: actions.HIDE_MODAL})
    }

    const showSelectLanguages = (ratePercentages) => {
        dispatch({type: actions.HIDE_MODAL})
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                type: modalTypes.SELECT_LANGUAGE_EXPORT,
                title: intl.formatMessage({id: "message_title.select_export_language"}),
                additionalData: {
                    units: additionalData.units,
                    prices: additionalData.prices,
                    values: additionalData.values,
                    ratePercentages: ratePercentages,
                    export: additionalData.export
                }
            }
        })
    }

    const changeAllChecked = () => {
        const checks = [...checkboxes]
        for (let i = 0; i < checks.length; i++) {
            checks[i] = !allChecked
        }
        setCheckboxes([...checks])
        setAllChecked(!allChecked)
    }

    const changeChecked = (i) => {
        const checks = [...checkboxes]
        checks[i] = !checks[i]
        setCheckboxes([...checks])
        if (allChecked || checks.filter(check => check === false).length < 1) {
            setAllChecked(!allChecked)
        }
    }

    const accept = (e) => {
        e.preventDefault()
        const ratePercentages = []
        for (let i = 0; i < additionalData.ratePercentages.length; i++) {
            if (checkboxes[i]) {
                ratePercentages.push(JSON.parse(JSON.stringify(additionalData.ratePercentages[i])))
            }
        }
        showSelectLanguages(ratePercentages)
    }

    const Body = () => {
        return (
            <>
                <div className="level">
                    <div className="level-left">
                        <label className="checkbox has-text-weight-bold">
                            <input type="checkbox" checked={allChecked}
                                   onChange={(e) => changeAllChecked()} />
                            <FormattedMessage id="standard_rate.select_all_cat_tool_breaks" />
                        </label>
                    </div>
                </div>
                {additionalData.ratePercentages?.map((ratePercentage, i) => (
                    <div key={i} className="level mb-0">
                        <div className="level-left">
                            <label className="checkbox">
                                <input type="checkbox" checked={checkboxes[i] ?? false}
                                       onChange={() => changeChecked(i)} />
                                {ratePercentage.cat_tool_break_name}
                                {ratePercentage.cat_tool_break_match_percent_min !== null ?
                                    " (" + ratePercentage.cat_tool_break_match_percent_min + "%" + (ratePercentage.cat_tool_break_match_percent_max ?
                                        " - " + ratePercentage.cat_tool_break_match_percent_max + "%)" : ")") : ""} {ratePercentage.percent}%
                            </label>
                        </div>
                    </div>
                ))}
            </>
        )
    }

    const Foot = () => {
        return (
            <>
                <button className="button is-link"
                        onClick={(e) => accept(e)}>
                    <FormattedMessage id="general.accept" />
                </button>
                <button className="button"
                        onClick={(e) => onHideClick(e)}>
                    <FormattedMessage id="general.cancel" />
                </button>
            </>
        )
    }

    return (
        <DraggableModal title={title} body={Body()} foot={Foot()} onHideClick={onHideClick} />
    )
}
