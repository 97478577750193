import {IntlProvider} from "react-intl"
import {Routes, Route, Navigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {ToastContainer} from 'react-toastify'
import {Header} from './components/Header/Header'
import {Toast} from './components/Toast/Toast'
import {Modal} from './components/Modal/Modal'
import {Login} from './containers/Login'
import {Home} from './containers/Home'
import {StandardRates} from './containers/StandardRates'
import {StandardRate} from './containers/StandardRate'
import {NewStandardRate} from './containers/NewStandardRate'
import {CustomerRates} from './containers/CustomerRates'
import {CustomerRate} from './containers/CustomerRate'
import {SelectCustomer} from './containers/SelectCustomer'
import {NewCustomerRate} from './containers/NewCustomerRate'
import {ConsultPrice} from './containers/ConsultPrice'
import {LanguagePairs} from './containers/LanguagePairs'
import {TagValues} from './containers/TagValues'
import {CatToolBreaks} from './containers/CatToolBreaks'
import {Tags} from './containers/Tags'
import {Units} from './containers/Units'
import {Currencies} from './containers/Currencies'
import {Users} from './containers/Users'
import {Notifications} from './containers/Notifications'
import * as actions from './store/Actions'
import * as userTypes from './consts/enums/userTypes'
import './sass/styles.scss'
import 'react-toastify/dist/ReactToastify.css'

function App() {
    const dispatch = useDispatch()
    const locale = useSelector(state => state.locale.locale)
    const userType = useSelector(state => state.auth.user ? state.auth.user.role : null)

    window.onbeforeunload = () => {dispatch({type: actions.HIDE_MODAL})}
    window.onpopstate = () => {dispatch({type: actions.HIDE_MODAL})}

    const closeDropdown = () => {
        let dropdowns = document.getElementsByClassName("dropdown")
        for (let dropdown of dropdowns) {
            dropdown.classList.remove("is-active")
        }
    }

    const NotUserRoutes = () => {
        return (
            <Routes>
                <Route path="*" element={<Navigate to="/login" />} />
                <Route path="/login" element={<Login />} exact />
            </Routes>
        )
    }

    const ManagerRoutes = () => {
        return (
            <>
                <Routes>
                    <Route path="*" element={<Navigate to="/" />} />
                    <Route path="/" element={<Home />} exact />
                    <Route path="/standard-rates" element={<StandardRates />} exact />
                    <Route path="/standard-rates/:id" element={<StandardRate />} exact />
                    <Route path="/standard-rates/new" element={<NewStandardRate />} exact />
                    <Route path="/customer-rates" element={<CustomerRates />} exact />
                    <Route path="/customer-rates/:id" element={<CustomerRate />} exact />
                    <Route path="/customer-rates/select-customer" element={<SelectCustomer />} exact />
                    <Route path="/customer-rates/new" element={<NewCustomerRate />} exact />
                    <Route path="/consult-price" element={<ConsultPrice />} exact />
                    <Route path="/language-pairs" element={<LanguagePairs />} exact />
                    <Route path="/tag-values" element={<TagValues />} exact />
                    <Route path="/cat-tool-breaks" element={<CatToolBreaks />} exact />
                    <Route path="/tags" element={<Tags />} exact />
                    <Route path="/units" element={<Units />} exact />
                    <Route path="/currencies" element={<Currencies />} exact />
                    <Route path="/users" element={<Users />} exact />
                    <Route path="/notifications" element={<Notifications />} exact />
                </Routes>
            </>
        )
    }

    const RateManagerRoutes = () => {
        return (
            <>
                <Routes>
                    <Route path="*" element={<Navigate to="/" />} />
                    <Route path="/" element={<Home />} exact />
                    <Route path="/standard-rates" element={<StandardRates />} exact />
                    <Route path="/standard-rates/:id" element={<StandardRate />} exact />
                    <Route path="/standard-rates/new" element={<NewStandardRate />} exact />
                    <Route path="/customer-rates" element={<CustomerRates />} exact />
                    <Route path="/customer-rates/:id" element={<CustomerRate />} exact />
                    <Route path="/customer-rates/select-customer" element={<SelectCustomer />} exact />
                    <Route path="/customer-rates/new" element={<NewCustomerRate />} exact />
                    <Route path="/consult-price" element={<ConsultPrice />} exact />
                    <Route path="/language-pairs" element={<LanguagePairs />} exact />
                    <Route path="/tag-values" element={<TagValues />} exact />
                    <Route path="/cat-tool-breaks" element={<CatToolBreaks />} exact />
                    <Route path="/tags" element={<Tags />} exact />
                    <Route path="/units" element={<Units />} exact />
                    <Route path="/currencies" element={<Currencies />} exact />
                    <Route path="/notifications" element={<Notifications />} exact />
                </Routes>
            </>
        )
    }

    const RateViewerRoutes = () => {
        return (
            <>
                <Routes>
                    <Route path="*" element={<Navigate to="/" />} />
                    <Route path="/" element={<Home />} exact />
                    <Route path="/standard-rates" element={<StandardRates />} exact />
                    <Route path="/standard-rates/:id" element={<StandardRate />} exact />
                    <Route path="/customer-rates" element={<CustomerRates />} exact />
                    <Route path="/customer-rates/:id" element={<CustomerRate />} exact />
                    <Route path="/consult-price" element={<ConsultPrice />} exact />
                </Routes>
            </>
        )
    }

    const SystemRoutes = () => {
        if (userType) {
            switch (userType) {
                case userTypes.MANAGER:
                    return ManagerRoutes()
                case userTypes.RATE_MANAGER:
                    return RateManagerRoutes()
                case userTypes.RATE_VIEWER:
                    return RateViewerRoutes()
                default:
                    return NotUserRoutes()
            }
        } else {
            return <NotUserRoutes />
        }
    }

    return (
        <IntlProvider locale={locale.name} messages={locale.object}>
            <Header />
            <ToastContainer position={"top-center"} autoClose={2000} />
            <Toast />
            <Modal />
            <div onClick={() => closeDropdown()}>
                <SystemRoutes />
            </div>
        </IntlProvider>
    );
}

export default App;
