import logo from '../assets/logo2.png'
import {jsPDF} from 'jspdf'
import 'jspdf-autotable'

export const exportRateToPDF = (filename, headers, data, additionalData) => {
    const customer = []
    if (data[3]) {
        for (let i = 0; i < data[3].length; i++) {
            const c = []
            for (let prop in data[3][i]) {
                c.push(data[3][i][prop])
            }
            customer.push(c)
        }
    }
    const prices = []
    for (let i = 0; i < data[0].length; i++) {
        const price = []
        for (let prop in data[0][i]) {
            price.push(data[0][i][prop])
        }
        prices.push(price)
    }
    const values = []
    for (let i = 0; i < data[1].length; i++) {
        const value = []
        for (let prop in data[1][i]) {
            value.push(data[1][i][prop])
        }
        values.push(value)
    }
    const ratePercentages = []
    for (let i = 0; i < data[2].length; i++) {
        const ratePercentage = []
        for (let prop in data[2][i]) {
            ratePercentage.push(data[2][i][prop])
        }
        ratePercentages.push(ratePercentage)
    }
    const pdf = new jsPDF()
    pdf.addImage(logo, 5, 5, 24, 17)
    jsPDF.autoTableSetDefaults({headStyles: {fillColor: [219, 42, 44]}})
    if (data[3]) {
        pdf.autoTable({showHead: false, body: customer, startY: 25, styles: {fontSize: 8},
            columnStyles: {0: {cellWidth: 30, fillColor: [219, 42, 44], textColor: 255, fontStyle: 'bold'}}})
    }
    let finalY
    if (data[0].length > 0) {
        finalY = pdf.lastAutoTable.finalY
        pdf.autoTable({head: [headers[0]], body: prices, showHead: "firstPage",
            startY: data[3] ? finalY + 10 : 25,
            styles: {fontSize: 10},
            columnStyles: {0: {cellWidth: 50}, 1: {cellWidth: 50},
                2: {cellWidth: data[4].length < 2 ? "auto" : 25}, 3: {cellWidth: data[4].length < 3 ? "auto" : 25}}})
    }
    if (data[1].length > 0) {
        finalY = pdf.lastAutoTable.finalY
        pdf.autoTable({body: values, showHead: false,
            startY: data[0].length > 0 ? finalY + 15 : data[3] ? finalY + 10 : 25,
            styles: {fontSize: 8},
            columnStyles: {0: {cellWidth: 50, fontStyle: 'bold'}, 1: {cellWidth: 25}, 2: {cellWidth: 50}}})
    }
    if (data[2].length > 0) {
        finalY = pdf.lastAutoTable.finalY
        pdf.autoTable({head: [headers[1]], body: ratePercentages, showHead: "firstPage",
            startY: data[0].length > 0 || data[1].length > 0 ? finalY + 15 : data[3] ? finalY + 10 : 25,
            styles: {fontSize: 8},
            columnStyles: {0: {cellWidth: 50}, 1: {cellWidth: 50}}})
    }
    pdf.setFontSize(7)
    finalY = pdf.lastAutoTable.finalY
    pdf.text(additionalData, 5, finalY + 10)
    pdf.save(filename + ".pdf")
}
