import * as actions from '../../Actions'

const initialState = {
    projects: [],
    projectsIdcp: [],
    isGettingProjects: false,
    isGettingProjectsIdcp: false,
    error: null
}

export default function reducer (state = initialState, action) {
    switch (action.type) {
        case actions.GET_PROJECTS:
            return {
                ...state,
                projects: action.payload.data,
                isGettingProjects: false
            }
        case actions.GET_PROJECTS_IDCP:
            return {
                ...state,
                projectsIdcp: action.payload.data,
                isGettingProjectsIdcp: false
            }
        case actions.GETTING_PROJECTS:
            return {
                ...state,
                isGettingProjects: true
            }
        case actions.GETTING_PROJECTS_IDCP:
            return {
                ...state,
                isGettingProjectsIdcp: true
            }
        case actions.PROJECT_ERROR:
            return {
                ...state,
                error: action.payload,
                isGettingProjects: false,
                isGettingProjectsIdcp: false
            }
        case actions.RESET_PROJECTS:
            return {
                ...state,
                projects: []
            }
        case actions.RESET_PROJECTS_IDCP:
            return {
                ...state,
                projectsIdcp: []
            }
        case actions.RESET_PROJECT_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
