import {axiosInstance} from '../../../hoc/Axios/Axios'
import * as actions from '../../Actions'
import * as toastTypes from '../../../consts/enums/toastTypes'
import {encodeParameter} from '../../../utils/encodeParameter'

export const getCustomerRates = (sort = "rates.is_active", order = "desc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_CUSTOMER_RATES})
    dispatch({type: actions.GETTING_CUSTOMER_RATES})
    try {
        const url = "/rates/customer-rates?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_CUSTOMER_RATES, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_CUSTOMER_RATES})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.customer_rates_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.CUSTOMER_RATE_ERROR, payload: error.response})
    }
}

export const getCustomerRatesByName = (name, sort = "rates.is_active", order = "desc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_CUSTOMER_RATES})
    dispatch({type: actions.GETTING_CUSTOMER_RATES})
    try {
        name = encodeParameter(name)
        const url = "/rates/customer-rates/by-name/" + name + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_CUSTOMER_RATES, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_CUSTOMER_RATES})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.customer_rates_by_name_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.CUSTOMER_RATE_ERROR, payload: error.response})
    }
}

export const getCustomerRatesByCustomer = (customerId, sort = "rates.is_active", order = "desc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_CUSTOMER_RATES})
    dispatch({type: actions.GETTING_CUSTOMER_RATES})
    try {
        const url = "/rates/customer-rates/by-customer/" + customerId + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_CUSTOMER_RATES, payload: res.data})
    } catch (error) {
        dispatch({type: actions.CUSTOMER_RATE_ERROR, payload: error.response})
    }
}

export const getCustomerRatesByManufacturer = (manufacturerId, sort = "rates.is_active", order = "desc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_CUSTOMER_RATES})
    dispatch({type: actions.GETTING_CUSTOMER_RATES})
    try {
        const url = "/rates/customer-rates/by-manufacturer/" + manufacturerId + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_CUSTOMER_RATES, payload: res.data})
    } catch (error) {
        dispatch({type: actions.CUSTOMER_RATE_ERROR, payload: error.response})
    }
}

export const getCustomerRatesByProject = (projectId, sort = "rates.is_active", order = "desc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_CUSTOMER_RATES})
    dispatch({type: actions.GETTING_CUSTOMER_RATES})
    try {
        const url = "/rates/customer-rates/by-project/" + projectId + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_CUSTOMER_RATES, payload: res.data})
    } catch (error) {
        dispatch({type: actions.CUSTOMER_RATE_ERROR, payload: error.response})
    }
}

export const getCustomerRatesByCreationDate = (creationDate, sort = "rates.is_active", order = "desc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_CUSTOMER_RATES})
    dispatch({type: actions.GETTING_CUSTOMER_RATES})
    try {
        const url = "/rates/customer-rates/by-creation-date/" + creationDate + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_CUSTOMER_RATES, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_CUSTOMER_RATES})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.customer_rates_by_creation_date_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.CUSTOMER_RATE_ERROR, payload: error.response})
    }
}

export const getCustomerRatesByCreator = (creator, sort = "rates.is_active", order = "desc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_CUSTOMER_RATES})
    dispatch({type: actions.GETTING_CUSTOMER_RATES})
    try {
        creator = encodeParameter(creator)
        const url = "/rates/customer-rates/by-creator/" + creator + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_CUSTOMER_RATES, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_CUSTOMER_RATES})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.customer_rates_by_creator_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.CUSTOMER_RATE_ERROR, payload: error.response})
    }
}

export const getCustomerRatesByStatus = (status, sort = "rates.is_active", order = "desc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_CUSTOMER_RATES})
    dispatch({type: actions.GETTING_CUSTOMER_RATES})
    try {
        const url = "/rates/customer-rates/by-status/" + (status ? 1 : 0) + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_CUSTOMER_RATES, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_CUSTOMER_RATES})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.customer_rates_by_status_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.CUSTOMER_RATE_ERROR, payload: error.response})
    }
}

export const getCustomerRateVersion = (name, customerId, manufacturerId, projectId, page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_CUSTOMER_RATES})
    dispatch({type: actions.GETTING_CUSTOMER_RATES})
    try {
        name = encodeParameter(name)
        const url = "/rates/customer-rates/versions/" + name + "/" + customerId + (manufacturerId ? "/" + manufacturerId + (projectId ? "/" + projectId : "") : "") + "?page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_CUSTOMER_RATES, payload: res.data})
    } catch (error) {
        dispatch({type: actions.CUSTOMER_RATE_ERROR, payload: error.response})
    }
}

export const getCustomerRatesFrom = (customerId, manufacturerId, projectId) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_CUSTOMER_RATES})
    dispatch({type: actions.GETTING_CUSTOMER_RATES})
    try {
        const url = "/rates/customer-rates/from/" + customerId + (manufacturerId ? "/" + manufacturerId + (projectId ? "/" + projectId : "") : "")
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_CUSTOMER_RATES, payload: res.data})
    } catch (error) {
        dispatch({type: actions.CUSTOMER_RATE_ERROR, payload: error.response})
    }
}

export const getCustomerRate = (currencyId, customerCode, manufacturerCode) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_CUSTOMER_RATE})
    dispatch({type: actions.GETTING_CUSTOMER_RATE})
    try {
        const url = "/rates/customer-rate/" + currencyId + "/" + customerCode + (manufacturerCode ? "/" + manufacturerCode : "")
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_CUSTOMER_RATE, payload: res.data})
    } catch (error) {
        dispatch({type: actions.CUSTOMER_RATE_ERROR, payload: error.response})
    }
}

export const getNamedCustomerRate = (name) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_CUSTOMER_RATE})
    dispatch({type: actions.GETTING_CUSTOMER_RATE})
    try {
        name = encodeParameter(name)
        const url = "/rates/customer-rate/named/" + name
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_CUSTOMER_RATE, payload: res.data})
    } catch (error) {
        dispatch({type: actions.CUSTOMER_RATE_ERROR, payload: error.response})
    }
}

export const getLinkedCustomerRate = (customerCode, manufacturerCode) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_LINKED_CUSTOMER_RATES})
    dispatch({type: actions.GETTING_LINKED_CUSTOMER_RATES})
    try {
        const url = "/rates/customer-rates/linked/" + customerCode + (manufacturerCode ? "/" + manufacturerCode : "")
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_LINKED_CUSTOMER_RATES, payload: res.data})
    } catch (error) {
        dispatch({type: actions.CUSTOMER_RATE_ERROR, payload: error.response})
    }
}

export const storeCustomerRate = (customerRate) => async (dispatch, getState) => {
    try {
        const res = await axiosInstance.post("/rates/customer-rates/store", customerRate)
        dispatch({type: actions.CUSTOMER_RATE_CREATED, payload: res.data})
    } catch (error) {
        dispatch({type: actions.CUSTOMER_RATE_ERROR, payload: error.response})
    }
}

export const updateCustomerRateActiveStatus = (customerRateId, active) => async (dispatch, getState) => {
    try {
        const res = await axiosInstance.put("/rates/customer-rates/" + customerRateId + "/active", active)
        dispatch({type: actions.CUSTOMER_RATE_ACTIVE_STATUS_UPDATED, payload: res.data})
    } catch (error) {
        dispatch({type: actions.CUSTOMER_RATE_ERROR, payload: error.response})
    }
}
