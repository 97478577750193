import {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import * as actions from '../../store/Actions'
import {getCurrenciesIdcp} from '../../store/reducers/currency/currencyActions'
import {storeExchangeRate} from '../../store/reducers/exchangeRate/exchangeRateActions'
import {DraggableModal} from '../UI/DraggableModal'
import {Search} from '../UI/Search'
import {Tag} from '../UI/Tag'
import * as modalTypes from '../../consts/enums/modalTypes'
import {roundDecimalNumber} from '../../utils/roundDecimalNumber'

export const ModalNewCurrency = ({title = ""}) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const currencies = useSelector(state => state.currency.currenciesIdcp)
    const isGettingCurrenciesIdcp = useSelector(state => state.currency.isGettingCurrenciesIdcp)
    const [searchText, setSearchText] = useState("")
    const [name, setName] = useState("")
    const [code, setCode] = useState("")
    const [abbreviation, setAbbreviation] = useState("")
    const [value, setValue] = useState("")

    const onHideClick = (e) => {
        e.preventDefault()
        dispatch({type: actions.HIDE_MODAL})
    }

    const changeCurrencyData = (e, currency) => {
        e.preventDefault()
        setName(currency.description)
        setCode(currency.code)
        setAbbreviation(currency.abbreviation)
        setSearchText("")
        dispatch({type: actions.RESET_CURRENCIES_IDCP})
    }

    const currencyData = (currency) => {
        return currency.description + (currency.abbreviation ? " (" + currency.abbreviation + ")" : "")
    }

    const getDataIdcp = (e) => {
        e.preventDefault()
        dispatch({type: actions.RESET_CURRENCIES_IDCP})
        dispatch(getCurrenciesIdcp(searchText))
    }

    const removeCurrency = (e) => {
        e.preventDefault()
        setName("")
        setCode("")
        setAbbreviation("")
    }

    const createExchangeRate = (e) => {
        e.preventDefault()
        const exchangeRate = {
            name: name,
            code: code,
            abbreviation: abbreviation,
            value: roundDecimalNumber(value)
        }
        if (!abbreviation) {
            delete exchangeRate.abbreviation
        }
        dispatch(storeExchangeRate(exchangeRate))
        dispatch({type: actions.HIDE_MODAL})
        dispatch({type: actions.SHOW_MODAL, payload: {type:modalTypes.LOADING}})
    }

    const Body = () => {
        return (
            <>
                <div className="field">
                    <div className="field is-grouped is-grouped-multiline">
                        <div className="control">
                            <label className="label">
                                <FormattedMessage id="currency.currency" />
                                <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                                <span>:</span>
                            </label>
                        </div>
                        {name ?
                            <div className="control">
                                <Tag text={name + (abbreviation ? " (" + abbreviation + ")" : "")} handler={(e) => removeCurrency(e)} />
                            </div>
                            :
                            null
                        }
                    </div>
                    <div className="field has-addons">
                        <Search placeholder={intl.formatMessage({id: "placeholder.search_currency"})}
                                value={searchText}
                                inputChange={(e) => {
                                    setSearchText(e.target.value)
                                    dispatch({type: actions.RESET_CURRENCIES_IDCP})
                                }}
                                autofocus={true}
                                maxLength={37}
                                content={currencies}
                                dataClick={changeCurrencyData}
                                dataText={currencyData}
                                isLoading={isGettingCurrenciesIdcp}
                                disabledButton={searchText.trim() === ""}
                                buttonClick={(e) => getDataIdcp(e)}
                                buttonText={intl.formatMessage({id: "general.search"})}
                        />
                    </div>
                </div>
                <div className="field">
                    <label className="label">
                        <FormattedMessage id="currency.exchange_rate" />
                        <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                        <span>:</span>
                    </label>
                    <div className="columns">
                        <div className="column is-one-third">
                            <div className="control">
                                <input className="input"
                                       type="number"
                                       placeholder={intl.formatMessage({id: "placeholder.exchange_rate"})}
                                       value={value}
                                       onChange={(e) => setValue(e.target.value)}
                                       min={0}
                                       max={9999999}
                                       step={0.1}
                                       onWheel={(e) => e.target.blur()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const Foot = () => {
        return (
            <>
                <button className="button is-link"
                        disabled={name.trim() === "" || code.trim() === "" || value.trim() === ""}
                        onClick={(e) => createExchangeRate(e)}>
                    <FormattedMessage id="general.save" />
                </button>
                <button className="button"
                        onClick={(e) => onHideClick(e)}>
                    <FormattedMessage id="general.cancel" />
                </button>
            </>
        )
    }

    return (
        <DraggableModal title={title} body={Body()} foot={Foot()} onHideClick={onHideClick} />
    )
}
