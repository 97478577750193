import {axiosInstance} from '../../../hoc/Axios/Axios'
import * as actions from '../../Actions'
import * as toastTypes from '../../../consts/enums/toastTypes'
import {encodeParameter} from '../../../utils/encodeParameter'

export const getCustomers = () => async (dispatch, getState) => {
    dispatch({type: actions.RESET_CUSTOMERS})
    dispatch({type: actions.GETTING_CUSTOMERS})
    try {
        const res = await axiosInstance.get("/customers")
        dispatch({type: actions.GET_CUSTOMERS, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.customers_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.CUSTOMER_ERROR, payload: error.response})
    }
}

export const getCustomersByName = (name) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_CUSTOMERS})
    dispatch({type: actions.GETTING_CUSTOMERS})
    try {
        name = encodeParameter(name)
        const res = await axiosInstance.get("/customers/by-name" + (name ? "/" + name : ""))
        dispatch({type: actions.GET_CUSTOMERS, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.customers_by_name_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.CUSTOMER_ERROR, payload: error.response})
    }
}

export const getCustomersIdcp = (name) => async (dispatch, getState) => {
    dispatch({type: actions.GETTING_CUSTOMERS_IDCP})
    try {
        name = encodeParameter(name)
        const res = await axiosInstance.get("/customers-idcp/" + name)
        dispatch({type: actions.GET_CUSTOMERS_IDCP, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.customer_does_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.CUSTOMER_ERROR, payload: error.response})
    }
}

export const getCustomersAndCustomersIdcp = (name) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_CUSTOMERS_AND_CUSTOMERS_IDCP})
    dispatch({type: actions.GETTING_CUSTOMERS_AND_CUSTOMERS_IDCP})
    try {
        name = encodeParameter(name)
        const res = await axiosInstance.get("/customers-and-customers-idcp/" + name)
        dispatch({type: actions.GET_CUSTOMERS_AND_CUSTOMERS_IDCP, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.customers_by_name_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.CUSTOMER_ERROR, payload: error.response})
    }
}
