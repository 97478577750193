import {useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import * as actions from '../../store/Actions'
import * as modalTypes from '../../consts/enums/modalTypes'
import {DraggableModal} from '../UI/DraggableModal'

export const ModalSelectPairsExport = ({title = "", additionalData = null}) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const [allChecked, setAllChecked] = useState(false)
    const [checkboxes, setCheckboxes] = useState([])

    useEffect(() => {
        if (additionalData.prices.length < 1) {
            showSelectTags([])
        } else {
            const checkboxes = []
            for (let i = 0; i < additionalData.prices.length; i++) {
                checkboxes.push(false)
            }
            setCheckboxes([...checkboxes])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onHideClick = (e) => {
        e.preventDefault()
        dispatch({type: actions.HIDE_MODAL})
    }

    const pricesByUnit = (prices, units) => {
        let prices2 = []
        for (let i = 0; i < prices.length; i++) {
            if (prices2.filter(price => price.translation_id === prices[i].translation_id).length < 1) {
                const price = prices.filter(price => price.translation_id === prices[i].translation_id)
                const unitsPrice = []
                for (let j = 0; j < units.length; j++) {
                    unitsPrice.push(price.find(price => price.unit_id === units[j].id)?.total ?? null)
                }
                prices2.push({
                    translation_id: price[0].translation_id,
                    translation_source_language: price[0].translation_source_language,
                    translation_target_language: price[0].translation_target_language,
                    ...JSON.parse(JSON.stringify(Object.assign({}, unitsPrice)))
                })
            }
        }
        return prices2
    }

    const showSelectTags = (prices) => {
        const units = additionalData.units.filter(unit => prices.find(price => price.unit_id === unit.id))
        dispatch({type: actions.HIDE_MODAL})
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                type: modalTypes.SELECT_TAGS_EXPORT,
                title: intl.formatMessage({id: "message_title.select_tags_to_export"}),
                additionalData: {
                    units: units,
                    prices: pricesByUnit(prices, units),
                    values: additionalData.values,
                    ratePercentages: additionalData.ratePercentages,
                    export: additionalData.export
                }
            }
        })
    }

    const changeAllChecked = () => {
        const checks = [...checkboxes]
        for (let i = 0; i < checks.length; i++) {
            checks[i] = !allChecked
        }
        setCheckboxes([...checks])
        setAllChecked(!allChecked)
    }

    const changeChecked = (i) => {
        const checks = [...checkboxes]
        checks[i] = !checks[i]
        setCheckboxes([...checks])
        if (allChecked || checks.filter(check => check === false).length < 1) {
            setAllChecked(!allChecked)
        }
    }

    const createTextLanguagePair = (price) => {
        return price.translation_source_language +
            (price.translation_code_source_language ? " (" + price.translation_code_source_language + ")" : "") + " - " +
            price.translation_target_language +
            (price.translation_code_target_language ? " (" + price.translation_code_target_language + ")" : "") +
            " (" + price.unit_type + ")"
    }

    const accept = (e) => {
        e.preventDefault()
        const prices = []
        for (let i = 0; i < additionalData.prices.length; i++) {
            if (checkboxes[i]) {
                prices.push(JSON.parse(JSON.stringify(additionalData.prices[i])))
            }
        }
        showSelectTags(prices)
    }

    const Body = () => {
        return (
            <>
                <div className="level">
                    <div className="level-left">
                        <label className="checkbox has-text-weight-bold">
                            <input type="checkbox" checked={allChecked}
                                   onChange={(e) => changeAllChecked()} />
                            <FormattedMessage id="standard_rate.select_all_language_pairs" />
                        </label>
                    </div>
                </div>
                {additionalData.prices?.map((price, i) => (
                    <div key={i} className="level mb-0">
                        <div className="level-left">
                            <label className="checkbox">
                                <input type="checkbox" checked={checkboxes[i] ?? false}
                                       onChange={() => changeChecked(i)} />
                                {createTextLanguagePair(price)}
                            </label>
                        </div>
                    </div>
                ))}
            </>
        )
    }

    const Foot = () => {
        return (
            <>
                <button className="button is-link"
                        onClick={(e) => accept(e)}>
                    <FormattedMessage id="general.accept" />
                </button>
                <button className="button"
                        onClick={(e) => onHideClick(e)}>
                    <FormattedMessage id="general.cancel" />
                </button>
            </>
        )
    }

    return (
        <DraggableModal title={title} body={Body()} foot={Foot()} onHideClick={onHideClick} />
    )
}
