export const GENERAL_ERROR = 1
export const BAD_CREDENTIALS_ERROR = 2
export const TOKEN_ABSENT_ERROR = 3
export const TOKEN_EXPIRED_ERROR = 4
export const TOKEN_INVALID_ERROR = 5
export const DISABLED_USER = 6
export const USER_ALREADY_EXIST = 7
export const PRICE_ALREADY_EXIST = 8
export const LANGUAGE_PAIR_ALREADY_EXIST = 9
export const VALUE_ALREADY_EXIST = 10
export const TAG_ALREADY_EXIST = 11
export const RATE_PERCENTAGE_ALREADY_EXIST = 12
export const UNIT_ALREADY_EXIST = 13
export const CURRENCY_ALREADY_EXIST = 14
export const EXCHANGE_RATE_ALREADY_EXIST = 15
export const CUSTOMER_ALREADY_EXIST = 16
export const MANUFACTURER_ALREADY_EXIST = 17
export const PROJECT_ALREADY_EXIST = 18
export const CANT_DELETE_REFERENCE_TAG = 19
export const CAT_TOOL_BREAK_PERCENT_RANGE_NOT_ALLOWED = 20
export const CANT_DELETE_TAG = 21
