import {FormattedMessage} from 'react-intl'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {logout} from '../../store/reducers/auth/authActions'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSignOutAlt, faUser} from '@fortawesome/free-solid-svg-icons'

export const UserMenu = ({loggedUser = null}) => {
    const dispatch = useDispatch()

    return (
        <div className="navbar-item has-dropdown is-hoverable">
            <Link to="#" className="navbar-link is-size-6">
                <span className="icon">
                    <FontAwesomeIcon icon={faUser} />
                </span>
            </Link>
            <div className="navbar-dropdown is-right">
                <label className="label navbar-item">
                    <span className="icon mr-1">
                        <FontAwesomeIcon icon={faUser} />
                    </span>
                    {loggedUser.username}
                </label>
                <hr className="dropdown-divider" />
                <Link to="/login" className="navbar-item is-size-6"
                      onClick={() => dispatch(logout())}>
                    <span className="icon">
                        <FontAwesomeIcon icon={faSignOutAlt} />
                    </span>
                    <span>
                        <FormattedMessage id="menu.logout" />
                    </span>
                </Link>
            </div>
        </div>
    )
}
