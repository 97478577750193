import * as actions from '../../Actions'

const initialState = {
    standardRates: [],
    isGettingStandardRates: false,
    pagination: null,
    activeStandardRates: [],
    isGettingActiveStandardRates: false,
    standardRateCreated: false,
    standardRateActivated: false,
    error: null
}

export default function reducer (state = initialState, action) {
    switch (action.type) {
        case actions.GET_STANDARD_RATES:
            return {
                ...state,
                standardRates: action.payload.data,
                pagination: {
                    "current_page": action.payload.current_page,
                    "links": action.payload.links,
                    "per_page": action.payload.per_page,
                    "to": action.payload.to,
                    "total": action.payload.total
                },
                isGettingStandardRates: false
            }
        case actions.GET_ACTIVE_STANDARD_RATES:
            return {
                ...state,
                activeStandardRates: action.payload.data,
                isGettingActiveStandardRates: false
            }
        case actions.GETTING_STANDARD_RATES:
            return {
                ...state,
                isGettingStandardRates: true
            }
        case actions.GETTING_ACTIVE_STANDARD_RATES:
            return {
                ...state,
                isGettingActiveStandardRates: true
            }
        case actions.STANDARD_RATE_CREATED:
            return {
                ...state,
                standardRateCreated: action.payload.status
            }
        case actions.STANDARD_RATE_ACTIVATED:
            return {
                ...state,
                standardRateActivated: action.payload.status
            }
        case actions.RATE_ERROR:
            return {
                ...state,
                error: action.payload,
                isGettingStandardRates: false
            }
        case actions.RESET_STANDARD_RATES:
            return {
                ...state,
                standardRates: [],
                pagination: null
            }
        case actions.RESET_ACTIVE_STANDARD_RATES:
            return {
                ...state,
                activeStandardRates: []
            }
        case actions.RESET_RATE_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
