export const LOADING = 'LOADING'
export const SELECT_RATE = 'SELECT_RATE'
export const SELECT_PAIRS_NEW_RATE = 'SELECT_PAIRS_NEW_RATE'
export const SELECT_TAGS_NEW_RATE = 'SELECT_TAGS_NEW_RATE'
export const ADD_PAIR = 'ADD_PAIR'
export const ADD_TAG = 'ADD_TAG'
export const APPLY_PERCENTAGE = 'APPLY_PERCENTAGE'
export const SELECT_PAIRS_EXPORT = 'SELECT_PAIRS_EXPORT'
export const SELECT_TAGS_EXPORT = 'SELECT_TAGS_EXPORT'
export const SELECT_BREAKS_EXPORT = 'SELECT_BREAKS_EXPORT'
export const SELECT_LANGUAGE_EXPORT = 'SELECT_LANGUAGE_EXPORT'
export const SELECT_TAGS = 'SELECT_TAGS'
export const SELECT_CAT_TOOL_BREAK = 'SELECT_CAT_TOOL_BREAK'
export const NEW_USER = 'NEW_USER'
export const EDIT_USER = 'EDIT_USER'
export const NEW_PRICE = 'NEW_PRICE'
export const EDIT_PRICE = 'EDIT_PRICE'
export const NEW_LANGUAGE_PAIR = 'NEW_LANGUAGE_PAIR'
export const NEW_VALUE = 'NEW_VALUE'
export const EDIT_VALUE = 'EDIT_VALUE'
export const NEW_TAG = 'NEW_TAG'
export const EDIT_TAG = 'EDIT_TAG'
export const NEW_PERCENTAGE = 'NEW_PERCENTAGE'
export const EDIT_PERCENTAGE = 'EDIT_PERCENTAGE'
export const NEW_UNIT = 'NEW_UNIT'
export const NEW_CURRENCY = 'NEW_CURRENCY'
export const EDIT_CURRENCY = 'EDIT_CURRENCY'
