import * as actions from '../../Actions'

const initialState = {
    customers: [],
    customersIdcp: [],
    customersAndCustomersIdcp: [],
    isGettingCustomers: false,
    isGettingCustomersIdcp: false,
    isGettingCustomersAndCustomersIdcp: false,
    error: null,
    searchedCustomer: ""
}

export default function reducer (state = initialState, action) {
    switch (action.type) {
        case actions.GET_CUSTOMERS:
            return {
                ...state,
                customers: action.payload.data,
                isGettingCustomers: false
            }
        case actions.GET_CUSTOMERS_IDCP:
            return {
                ...state,
                customersIdcp: action.payload.data,
                isGettingCustomersIdcp: false
            }
        case actions.GET_CUSTOMERS_AND_CUSTOMERS_IDCP:
            return {
                ...state,
                customersAndCustomersIdcp: action.payload.data,
                isGettingCustomersAndCustomersIdcp: false
            }
        case actions.GETTING_CUSTOMERS:
            return {
                ...state,
                isGettingCustomers: true
            }
        case actions.GETTING_CUSTOMERS_IDCP:
            return {
                ...state,
                isGettingCustomersIdcp: true
            }
        case actions.GETTING_CUSTOMERS_AND_CUSTOMERS_IDCP:
            return {
                ...state,
                isGettingCustomersAndCustomersIdcp: true
            }
        case actions.CUSTOMER_ERROR:
            return {
                ...state,
                error: action.payload,
                isGettingCustomers: false,
                isGettingCustomersIdcp: false,
                isGettingCustomersAndCustomersIdcp: false
            }
        case actions.RESET_CUSTOMERS:
            return {
                ...state,
                customers: []
            }
        case actions.RESET_CUSTOMERS_IDCP:
            return {
                ...state,
                customersIdcp: []
            }
        case actions.RESET_CUSTOMERS_AND_CUSTOMERS_IDCP:
            return {
                ...state,
                customersAndCustomersIdcp: []
            }
        case actions.RESET_CUSTOMER_ERROR:
            return {
                ...state,
                error: null
            }
        case actions.SET_SEARCHED_CUSTOMER:
            return {
                ...state,
                searchedCustomer: action.payload
            }
        default:
            return state
    }
}
