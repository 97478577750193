import {useIntl} from 'react-intl'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEye} from '@fortawesome/free-solid-svg-icons'

export const TableIconButtonView = ({handler = null}) => {
    const intl = useIntl()

    return (
        <button className="button is-small is-white" title={intl.formatMessage({id: "general.view"})}
                onClick={handler}>
            <span className="icon">
                <FontAwesomeIcon icon={faEye} />
            </span>
        </button>
    )
}
