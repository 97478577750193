import {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {getUsersIdcp, storeUser} from '../../store/reducers/user/userActions'
import * as actions from '../../store/Actions'
import {DraggableModal} from '../UI/DraggableModal'
import {Search} from '../UI/Search'
import {Tag} from '../UI/Tag'
import * as modalTypes from '../../consts/enums/modalTypes'
import {USER_ROLES} from '../../consts/app'

export const ModalNewUser = ({title = ""}) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const users = useSelector(state => state.user.usersIdcp)
    const isGettingUsersIdcp = useSelector(state => state.user.isGettingUsersIdcp)
    const [searchText, setSearchText] = useState("")
    const [username, setUsername] = useState("")
    const [name, setName] = useState("")
    const [role, setRole] = useState("")

    const onHideClick = (e) => {
        e.preventDefault()
        dispatch({type: actions.HIDE_MODAL})
        dispatch({type: actions.RESET_USERS_IDCP})
    }

    const changeUserData = (e, user) => {
        e.preventDefault()
        setUsername(user.code)
        setName(user.name)
        setSearchText("")
        dispatch({type: actions.RESET_USERS_IDCP})
    }

    const userData = (user) => {
        return user.code + " - " + user.name
    }

    const getDataIdcp = (e) => {
        e.preventDefault()
        dispatch({type: actions.RESET_USERS_IDCP})
        dispatch(getUsersIdcp(searchText))
    }

    const removeUser = (e) => {
        e.preventDefault()
        setUsername("")
        setName("")
    }

    const createUser = (e) => {
        e.preventDefault()
        const user = {
            username: username,
            name: name,
            role: role
        }
        dispatch(storeUser(user))
        dispatch({type: actions.HIDE_MODAL})
        dispatch({type: actions.RESET_USERS_IDCP})
        dispatch({type: actions.SHOW_MODAL, payload: {type:modalTypes.LOADING}})
    }

    const Body = () => {
        return (
            <>
                <div className="field">
                    <div className="field is-grouped is-grouped-multiline">
                        <div className="control">
                            <label className="label">
                                <FormattedMessage id="user.user" />
                                <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                                <span>:</span>
                            </label>
                        </div>
                        {username ?
                            <div className="control">
                                <Tag text={username + " - " + name} handler={(e) => removeUser(e)} />
                            </div>
                            :
                            null
                        }
                    </div>
                    <div className="field has-addons">
                        <Search placeholder={intl.formatMessage({id: "placeholder.search_user"})}
                                value={searchText}
                                inputChange={(e) => {
                                    setSearchText(e.target.value)
                                    dispatch({type: actions.RESET_USERS_IDCP})
                                }}
                                autofocus={true}
                                maxLength={47}
                                content={users}
                                dataClick={changeUserData}
                                dataText={userData}
                                isLoading={isGettingUsersIdcp}
                                disabledButton={searchText.trim() === ""}
                                buttonClick={(e) => getDataIdcp(e)}
                                buttonText={intl.formatMessage({id: "general.search"})}
                        />
                    </div>
                </div>
                <div className="field">
                    <label className="label">
                        <FormattedMessage id="user.role" />
                        <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                        <span>:</span>
                    </label>
                    <div className="select">
                        <select onChange={(e) => setRole(e.target.value)}>
                            <option value="">
                                -----
                            </option>
                            {USER_ROLES?.map((role, i) => (
                                <option key={i} value={role}>
                                    {intl.formatMessage({id: "role." + role})}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </>
        )
    }

    const Foot = () => {
        return (
            <>
                <button className="button is-link"
                        disabled={username.trim() === "" || name.trim() === "" || role.trim() === ""}
                        onClick={(e) => createUser(e)}>
                    <FormattedMessage id="general.save" />
                </button>
                <button className="button"
                        onClick={(e) => onHideClick(e)}>
                    <FormattedMessage id="general.cancel" />
                </button>
            </>
        )
    }

    return (
        <DraggableModal title={title} body={Body()} foot={Foot()} onHideClick={onHideClick} />
    )
}
