import {axiosInstance} from '../../../hoc/Axios/Axios'
import * as actions from '../../Actions'
import * as toastTypes from '../../../consts/enums/toastTypes'
import {encodeParameter} from '../../../utils/encodeParameter'

export const getLanguagePairs = () => async (dispatch, getState) => {
    dispatch({type: actions.RESET_LANGUAGE_PAIRS})
    dispatch({type: actions.GETTING_LANGUAGE_PAIRS})
    try {
        const res = await axiosInstance.get("/language-pairs")
        dispatch({type: actions.GET_LANGUAGE_PAIRS, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.language_pairs_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.LANGUAGE_PAIR_ERROR, payload: error.response})
    }
}

export const getLanguagesIdcp = (language, isGetSourceLanguages = true) => async (dispatch, getState) => {
    if (isGetSourceLanguages) {
        dispatch({type: actions.GETTING_SOURCE_LANGUAGES_IDCP})
    } else {
        dispatch({type: actions.GETTING_TARGET_LANGUAGES_IDCP})
    }
    try {
        language = encodeParameter(language)
        const res = await axiosInstance.get("/languages-idcp/" + language)
        if (isGetSourceLanguages) {
            dispatch({type: actions.GET_SOURCE_LANGUAGES_IDCP, payload: res.data})
        } else {
            dispatch({type: actions.GET_TARGET_LANGUAGES_IDCP, payload: res.data})
        }
        if (res.data.data.length === 0) {
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.language_does_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.LANGUAGE_PAIR_ERROR, payload: error.response})
    }
}

export const storeLanguagePair = (languagePair) =>  async (dispatch, getState) => {
    dispatch({type: actions.CREATING_LANGUAGE_PAIR, payload: {status: true}})
    try {
        const res = await axiosInstance.post("/language-pairs/store", languagePair)
        dispatch({type: actions.LANGUAGE_PAIR_CREATED, payload: res.data})
    } catch (error) {
        dispatch({type: actions.LANGUAGE_PAIR_ERROR, payload: error.response})
    }
}
