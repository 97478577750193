import {axiosInstance} from '../../../hoc/Axios/Axios'
import * as actions from '../../Actions'
import * as toastTypes from '../../../consts/enums/toastTypes'
import {encodeParameter} from '../../../utils/encodeParameter'

export const getTags = (sort = "type", order = "asc", page) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_TAGS})
    dispatch({type: actions.GETTING_TAGS})
    try {
        const url = "/tags" + (page ? "?sort=" + sort + "&order=" + order + "&page=" + page : "/all")
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_TAGS, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.tags_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.TAG_ERROR, payload: error.response})
    }
}

export const getTagsByName = (name, sort = "type", order = "asc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_TAGS})
    dispatch({type: actions.GETTING_TAGS})
    try {
        name = encodeParameter(name)
        const url = "/tags/by-name/" + name + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_TAGS, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.tags_by_name_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.TAG_ERROR, payload: error.response})
    }
}

export const getTagsByType = (type, sort = "type", order = "asc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_TAGS})
    dispatch({type: actions.GETTING_TAGS})
    try {
        type = encodeParameter(type)
        const url = "/tags/by-type/" + type + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_TAGS, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.tags_by_type_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.TAG_ERROR, payload: error.response})
    }
}

export const storeTag = (tag) =>  async (dispatch, getState) => {
    try {
        await axiosInstance.post("/tags/store", tag)
        dispatch({type: actions.TAG_CREATED, payload: {status: true}})
    } catch (error) {
        dispatch({type: actions.TAG_ERROR, payload: error.response})
    }
}

export const updateTag = (tag) =>  async (dispatch, getState) => {
    try {
        await axiosInstance.put("/tags/" + tag.id, tag)
        dispatch({type: actions.TAG_UPDATED, payload: {status: true}})
    } catch (error) {
        dispatch({type: actions.TAG_ERROR, payload: error.response})
    }
}

export const deleteTag = (id) =>  async (dispatch, getState) => {
    try {
        await axiosInstance.delete("/tags/" + id)
        dispatch({type: actions.TAG_DELETED, payload: {status: true}})
    } catch (error) {
        dispatch({type: actions.TAG_ERROR, payload: error.response})
    }
}
