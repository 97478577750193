import * as actions from '../../Actions'

const initialState = {
    notifications: [],
    userNotifications: [],
    isGettingNotifications: false,
    isGettingUserNotifications: false,
    pagination: null,
    error: null
}

export default function reducer (state = initialState, action) {
    switch (action.type) {
        case actions.GET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload.data,
                pagination: {
                    "current_page": action.payload.current_page,
                    "links": action.payload.links,
                    "per_page": action.payload.per_page,
                    "to": action.payload.to,
                    "total": action.payload.total
                },
                isGettingNotifications: false
            }
        case actions.GET_NOTIFICATIONS_USER:
            return {
                ...state,
                userNotifications: action.payload.data,
                isGettingUserNotifications: false
            }
        case actions.GETTING_NOTIFICATIONS:
            return {
                ...state,
                isGettingNotifications: true
            }
        case actions.GETTING_NOTIFICATIONS_USER:
            return {
                ...state,
                isGettingUserNotifications: true
            }
        case actions.NOTIFICATION_ERROR:
            return {
                ...state,
                error: action.payload,
                isGettingNotifications: false,
                isGettingUserNotifications: false
            }
        case actions.RESET_NOTIFICATIONS:
            return {
                ...state,
                notifications: [],
                pagination: null
            }
        case actions.RESET_NOTIFICATIONS_USER:
            return {
                ...state,
                userNotifications: []
            }
        case actions.RESET_NOTIFICATION_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
