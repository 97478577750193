import {createStore, combineReducers, compose, applyMiddleware} from 'redux'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import * as actions from './Actions'
import authReducer from './reducers/auth/authReducer'
import rateReducer from './reducers/rate/rateReducer'
import customerRateReducer from './reducers/customerRate/customerRateReducer'
import customerReducer from './reducers/customer/customerReducer'
import manufacturerReducer from './reducers/manufacturer/manufacturerReducer'
import projectReducer from './reducers/project/projectReducer'
import priceReducer from './reducers/price/priceReducer'
import languagePairReducer from './reducers/languagePair/languagePairReducer'
import valueReducer from './reducers/value/valueReducer'
import tagReducer from './reducers/tag/tagReducer'
import ratePercentageReducer from './reducers/ratePercentage/ratePercentageReducer'
import catToolBreakReducer from './reducers/catToolBreak/catToolBreaksReducer'
import unitReducer from './reducers/unit/unitReducer'
import exchangeRateReducer from './reducers/exchangeRate/exchangeRateReducer'
import currencyReducer from './reducers/currency/currencyReducer'
import userReducer from './reducers/user/userReducer'
import notificationReducer from './reducers/notification/notificationReducer'
import toastReducer from './reducers/toast/toastReducer'
import modalReducer from './reducers/modal/modalReducer'
import localeReducer from './reducers/locale/localeReducer'

export const appReducer = combineReducers({
    auth: authReducer,
    rate: rateReducer,
    customerRate: customerRateReducer,
    customer: customerReducer,
    manufacturer: manufacturerReducer,
    project: projectReducer,
    price: priceReducer,
    languagePair: languagePairReducer,
    value: valueReducer,
    tag: tagReducer,
    ratePercentage: ratePercentageReducer,
    catToolBreak: catToolBreakReducer,
    unit: unitReducer,
    exchangeRate: exchangeRateReducer,
    currency: currencyReducer,
    user: userReducer,
    notification: notificationReducer,
    toast: toastReducer,
    modal: modalReducer,
    locale: localeReducer
})

const rootReducer = (state, action) => {
    if (action.type === actions.RESET) {
        storage.removeItem('persist:root')
        state = undefined
    }
    return appReducer(state, action)
}

const persistConfig = {
    key: 'root',
    storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION__ || compose

export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)))

export const persistor = persistStore(store)
