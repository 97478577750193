import * as actions from '../../Actions'

const initialState = {
    active: false,
    type: "",
    title: "",
    message: "",
    additionalData: null,
    buttons: []
}

export default function reducer (state = initialState, action) {
    switch (action.type) {
        case actions.SHOW_MODAL:
            return {
                ...state,
                ...action.payload,
                active: true
            }
        case actions.HIDE_MODAL:
            return {
                active: false,
                type: "",
                title: "",
                message: "",
                additionalData: null,
                buttons: []
            }
        default:
            return state
    }
}
