import * as actions from '../../Actions'

const initialState = {
    users: [],
    usersIdcp: [],
    isGettingUsers: false,
    isGettingUsersIdcp: false,
    pagination: null,
    userCreated: false,
    userUpdated: false,
    userDeleted: false,
    error: null
}

export default function reducer (state = initialState, action) {
    switch (action.type) {
        case actions.GET_USERS:
            return {
                ...state,
                users: action.payload.data,
                pagination: {
                    "current_page": action.payload.current_page,
                    "links": action.payload.links,
                    "per_page": action.payload.per_page,
                    "to": action.payload.to,
                    "total": action.payload.total
                },
                isGettingUsers: false
            }
        case actions.GET_USERS_IDCP:
            return {
                ...state,
                usersIdcp: action.payload.data,
                isGettingUsersIdcp: false
            }
        case actions.GETTING_USERS:
            return {
                ...state,
                isGettingUsers: true
            }
        case actions.GETTING_USERS_IDCP:
            return {
                ...state,
                isGettingUsersIdcp: true
            }
        case actions.USER_CREATED:
            return {
                ...state,
                userCreated: action.payload.status
            }
        case actions.USER_UPDATED:
            return {
                ...state,
                userUpdated: action.payload.status
            }
        case actions.USER_DELETED:
            return {
                ...state,
                userDeleted: action.payload.status
            }
        case actions.USER_ERROR:
            return {
                ...state,
                error: action.payload,
                isGettingUsers: false,
                isGettingUsersIdcp: false
            }
        case actions.RESET_USERS:
            return {
                ...state,
                users: [],
                pagination: null
            }
        case actions.RESET_USERS_IDCP:
            return {
                ...state,
                usersIdcp: []
            }
        case actions.RESET_USER_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
