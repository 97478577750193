export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const RESET_LOGIN_ERROR = 'RESET_LOGIN_ERROR'

export const GET_STANDARD_RATES = 'GET_STANDARD_RATES'
export const GETTING_STANDARD_RATES = 'GETTING_STANDARD_RATES'
export const GET_ACTIVE_STANDARD_RATES = 'GET_ACTIVE_STANDARD_RATES'
export const GETTING_ACTIVE_STANDARD_RATES = 'GETTING_ACTIVE_STANDARD_RATES'
export const STANDARD_RATE_CREATED = 'STANDARD_RATE_CREATED'
export const STANDARD_RATE_ACTIVATED = 'STANDARD_RATE_ACTIVATED'
export const RATE_ERROR = 'RATE_ERROR'
export const RESET_STANDARD_RATES = 'RESET_STANDARD_RATES'
export const RESET_ACTIVE_STANDARD_RATES = 'RESET_ACTIVE_STANDARD_RATES'
export const RESET_RATE_ERROR = 'RESET_RATE_ERROR'

export const GET_CUSTOMER_RATES = 'GET_CUSTOMER_RATES'
export const GET_LINKED_CUSTOMER_RATES = 'GET_LINKED_CUSTOMER_RATES'
export const GET_CUSTOMER_RATE = 'GET_CUSTOMER_RATE'
export const GETTING_CUSTOMER_RATES = 'GETTING_CUSTOMER_RATES'
export const GETTING_LINKED_CUSTOMER_RATES = 'GETTING_LINKED_CUSTOMER_RATES'
export const GETTING_CUSTOMER_RATE = 'GETTING_CUSTOMER_RATE'
export const CUSTOMER_RATE_CREATED = 'CUSTOMER_RATE_CREATED'
export const CUSTOMER_RATE_ACTIVE_STATUS_UPDATED = 'CUSTOMER_RATE_ACTIVE_STATUS_UPDATED'
export const CUSTOMER_RATE_ERROR = 'CUSTOMER_RATE_ERROR'
export const RESET_CUSTOMER_RATES = 'RESET_CUSTOMER_RATES'
export const RESET_LINKED_CUSTOMER_RATES = 'RESET_LINKED_CUSTOMER_RATES'
export const RESET_CUSTOMER_RATE = 'RESET_CUSTOMER_RATE'
export const RESET_CUSTOMER_RATE_ERROR = 'RESET_CUSTOMER_RATE_ERROR'

export const GET_CUSTOMERS = 'GET_CUSTOMERS'
export const GET_CUSTOMERS_IDCP = 'GET_CUSTOMERS_IDCP'
export const GET_CUSTOMERS_AND_CUSTOMERS_IDCP = 'GET_CUSTOMERS_AND_CUSTOMERS_IDCP'
export const GETTING_CUSTOMERS = 'GETTING_CUSTOMERS'
export const GETTING_CUSTOMERS_IDCP = 'GETTING_CUSTOMERS_IDCP'
export const GETTING_CUSTOMERS_AND_CUSTOMERS_IDCP = 'GETTING_CUSTOMERS_AND_CUSTOMERS_IDCP'
export const CUSTOMER_ERROR = 'CUSTOMER_ERROR'
export const RESET_CUSTOMERS = 'RESET_CUSTOMERS'
export const RESET_CUSTOMERS_IDCP = 'RESET_CUSTOMERS_IDCP'
export const RESET_CUSTOMERS_AND_CUSTOMERS_IDCP = 'RESET_CUSTOMERS_AND_CUSTOMERS_IDCP'
export const RESET_CUSTOMER_ERROR = 'RESET_CUSTOMER_ERROR'
export const SET_SEARCHED_CUSTOMER = 'SET_SEARCHED_CUSTOMER'

export const GET_MANUFACTURERS = 'GET_MANUFACTURERS'
export const GET_MANUFACTURERS_IDCP = 'GET_MANUFACTURERS_IDCP'
export const GETTING_MANUFACTURERS = 'GETTING_MANUFACTURERS'
export const GETTING_MANUFACTURERS_IDCP = 'GETTING_MANUFACTURERS_IDCP'
export const MANUFACTURER_ERROR = 'MANUFACTURER_ERROR'
export const RESET_MANUFACTURERS = 'RESET_MANUFACTURERS'
export const RESET_MANUFACTURERS_IDCP = 'RESET_MANUFACTURERS_IDCP'
export const RESET_MANUFACTURER_ERROR = 'RESET_MANUFACTURER_ERROR'

export const GET_PROJECTS = 'GET_PROJECTS'
export const GET_PROJECTS_IDCP = 'GET_PROJECTS_IDCP'
export const GETTING_PROJECTS = 'GETTING_PROJECTS'
export const GETTING_PROJECTS_IDCP = 'GETTING_PROJECTS_IDCP'
export const PROJECT_ERROR = 'PROJECT_ERROR'
export const RESET_PROJECTS = 'RESET_PROJECTS'
export const RESET_PROJECTS_IDCP = 'RESET_PROJECTS_IDCP'
export const RESET_PROJECT_ERROR = 'RESET_PROJECT_ERROR'

export const GET_PRICES = 'GET_PRICES'
export const GET_ALL_PRICES = 'GET_ALL_PRICES'
export const GETTING_PRICES = 'GETTING_PRICES'
export const GETTING_ALL_PRICES = 'GETTING_ALL_PRICES'
export const PRICE_CREATED = 'PRICE_CREATED'
export const PRICE_UPDATED = 'PRICE_UPDATED'
export const PRICE_DELETED = 'PRICE_DELETED'
export const PRICE_ERROR = 'PRICE_ERROR'
export const RESET_PRICES = 'RESET_PRICES'
export const RESET_ALL_PRICES = 'RESET_ALL_PRICES'
export const RESET_PRICE_ERROR = 'RESET_PRICE_ERROR'

export const GET_LANGUAGE_PAIRS = 'GET_LANGUAGE_PAIRS'
export const GET_SOURCE_LANGUAGES_IDCP = 'GET_SOURCE_LANGUAGES_IDCP'
export const GET_TARGET_LANGUAGES_IDCP = 'GET_TARGET_LANGUAGES_IDCP'
export const GETTING_LANGUAGE_PAIRS = 'GETTING_LANGUAGE_PAIRS'
export const GETTING_SOURCE_LANGUAGES_IDCP = 'GETTING_SOURCE_LANGUAGES_IDCP'
export const GETTING_TARGET_LANGUAGES_IDCP = 'GETTING_TARGET_LANGUAGES_IDCP'
export const LANGUAGE_PAIR_CREATED = 'LANGUAGE_PAIR_CREATED'
export const CREATING_LANGUAGE_PAIR = 'CREATING_LANGUAGE_PAIR'
export const LANGUAGE_PAIR_ERROR = 'LANGUAGE_PAIR_ERROR'
export const RESET_LANGUAGE_PAIRS = 'RESET_LANGUAGE_PAIRS'
export const RESET_LANGUAGES_IDCP = 'RESET_LANGUAGES_IDCP'
export const RESET_LANGUAGE_PAIR_ERROR = 'RESET_LANGUAGE_PAIRS_ERROR'

export const GET_VALUES = 'GET_VALUES'
export const GET_ALL_VALUES = 'GET_ALL_VALUES'
export const GETTING_VALUES = 'GETTING_VALUES'
export const GETTING_ALL_VALUES = 'GETTING_ALL_VALUES'
export const VALUE_CREATED = 'VALUE_CREATED'
export const VALUE_UPDATED = 'VALUE_UPDATED'
export const VALUE_DELETED = 'VALUES_DELETED'
export const VALUE_ERROR = 'VALUE_ERROR'
export const RESET_VALUES = 'RESET_VALUES'
export const RESET_ALL_VALUES = 'RESET_ALL_VALUES'
export const RESET_VALUE_ERROR = 'RESET_VALUE_ERROR'

export const GET_TAGS = 'GET_TAGS'
export const GETTING_TAGS = 'GETTING_TAGS'
export const TAG_CREATED = 'TAG_CREATED'
export const TAG_UPDATED = 'TAG_UPDATED'
export const TAG_DELETED = 'TAG_DELETED'
export const TAG_ERROR = 'TAG_ERROR'
export const RESET_TAGS = 'RESET_TAGS'
export const RESET_TAG_ERROR = 'RESET_TAG_ERROR'

export const GET_RATE_PERCENTAGES = 'GET_RATE_PERCENTAGES'
export const GET_ALL_RATE_PERCENTAGES = 'GET_ALL_RATE_PERCENTAGES'
export const GETTING_RATE_PERCENTAGES = 'GETTING_RATE_PERCENTAGES'
export const GETTING_ALL_RATE_PERCENTAGES = 'GETTING_ALL_RATE_PERCENTAGES'
export const RATE_PERCENTAGE_CREATED = 'RATE_PERCENTAGE_CREATED'
export const RATE_PERCENTAGE_UPDATED = 'RATE_PERCENTAGE_UPDATED'
export const RATE_PERCENTAGE_DELETED = 'RATE_PERCENTAGE_DELETED'
export const RATE_PERCENTAGE_ERROR = 'RATE_PERCENTAGE_ERROR'
export const RESET_RATE_PERCENTAGES = 'RESET_RATE_PERCENTAGES'
export const RESET_ALL_RATE_PERCENTAGES = 'RESET_ALL_RATE_PERCENTAGES'
export const RESET_RATE_PERCENTAGE_ERROR = 'RESET_RATE_PERCENTAGE_ERROR'

export const GET_CAT_TOOL_BREAKS = 'GET_CAT_TOOL_BREAKS'
export const GETTING_CAT_TOOL_BREAKS = 'GETTING_CAT_TOOL_BREAKS'
export const CAT_TOOL_BREAK_ERROR = 'CAT_TOOL_BREAK_ERROR'
export const RESET_CAT_TOOL_BREAKS = 'RESET_CAT_TOOL_BREAKS'
export const RESET_CAT_TOOL_BREAK_ERROR = 'RESET_CAT_TOOL_BREAK_ERROR'

export const GET_UNITS = 'GET_UNITS'
export const GETTING_UNITS = 'GETTING_UNITS'
export const UNIT_CREATED = 'UNIT_CREATED'
export const UNIT_DELETED = 'UNIT_DELETED'
export const UNIT_ERROR = 'UNIT_ERROR'
export const RESET_UNITS = 'RESET_UNITS'
export const RESET_UNIT_ERROR = 'RESET_UNIT_ERROR'

export const GET_EXCHANGE_RATES = 'GET_EXCHANGE_RATES'
export const GETTING_EXCHANGE_RATES = 'GETTING_EXCHANGE_RATES'
export const EXCHANGE_RATE_CREATED = 'EXCHANGE_RATE_CREATED'
export const EXCHANGE_RATE_UPDATED = 'EXCHANGE_RATE_UPDATED'
export const EXCHANGE_RATE_DELETED = 'EXCHANGE_RATE_DELETED'
export const EXCHANGE_RATE_ERROR = 'EXCHANGE_RATE_ERROR'
export const RESET_EXCHANGE_RATES = 'RESET_EXCHANGE_RATES'
export const RESET_EXCHANGE_RATE_ERROR = 'RESET_EXCHANGE_RATE_ERROR'

export const GET_CURRENCIES = 'GET_CURRENCIES'
export const GET_CURRENCIES_IDCP = 'GET_CURRENCIES_IDCP'
export const GETTING_CURRENCIES = 'GETTING_CURRENCIES'
export const GETTING_CURRENCIES_IDCP = 'GETTING_CURRENCIES_IDCP'
export const CURRENCY_ERROR = 'CURRENCY_ERROR'
export const RESET_CURRENCIES = 'RESET_CURRENCIES'
export const RESET_CURRENCIES_IDCP = 'RESET_CURRENCIES_IDCP'
export const RESET_CURRENCY_ERROR = 'RESET_CURRENCY_ERROR'

export const GET_USERS = 'GET_USERS'
export const GET_USERS_IDCP = 'GET_USERS_IDCP'
export const GETTING_USERS = 'GETTING_USERS'
export const GETTING_USERS_IDCP = 'GETTING_USERS_IDCP'
export const USER_CREATED = 'USER_CREATED'
export const USER_UPDATED = 'USER_UPDATED'
export const USER_DELETED = 'USER_DELETED'
export const USER_ERROR = 'USER_ERROR'
export const RESET_USERS = 'RESET_USERS'
export const RESET_USERS_IDCP = 'RESET_USERS_IDCP'
export const RESET_USER_ERROR = 'RESET_USER_ERROR'

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const GET_NOTIFICATIONS_USER = 'GET_NOTIFICATIONS_USER'
export const GETTING_NOTIFICATIONS = 'GETTING_NOTIFICATIONS'
export const GETTING_NOTIFICATIONS_USER = 'GETTING_NOTIFICATIONS_USER'
export const NOTIFICATION_ERROR = 'NOTIFICATION_ERROR'
export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS'
export const RESET_NOTIFICATIONS_USER = 'RESET_NOTIFICATIONS_USER'
export const RESET_NOTIFICATION_ERROR = 'RESET_NOTIFICATION_ERROR'

export const SHOW_TOAST = 'SHOW_TOAST'
export const RESET_TOAST = 'RESET_TOAST_TYPE'

export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'

export const SET_LOCALE = "SET_LOCALE"

export const RESET = "RESET"
