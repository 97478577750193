import {FormattedMessage} from 'react-intl'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleDoubleRight, faAngleDoubleLeft} from '@fortawesome/free-solid-svg-icons'

export const Pagination = ({pagination, handler = null, additionalText = ""}) => {

    const goToThePage = (e, page) => {
        e.preventDefault()
        handler(page)
    }

    return (
        <>
            <div className="level mt-3">
                <div className="level-left">
                    <label className="label is-size-7">
                        {additionalText}
                    </label>
                </div>
                <div className="level-right">
                    <label className="label is-size-7 mr-2">
                        {(pagination.current_page - 1) * pagination.per_page + 1}-{pagination.to} <FormattedMessage id={"datatable.range_separator"} /> {pagination.total}
                    </label>
                </div>
            </div>
            <nav className="pagination mb-3" role="navigation" aria-label="pagination">
                <ul className="pagination-list">
                    {pagination.links.slice(1, pagination.links.length - 1)?.map((link, i) => (
                        <li key={i}>
                            <button className={`pagination-link ${link.active ? 'is-current' : ''} button`}
                                    aria-label={"Page " + link.label}
                                    aria-current="page"
                                    onClick={(e) => goToThePage(e, link.label)}>
                                {link.label}
                            </button>
                        </li>
                    ))}
                </ul>
                <button className="pagination-previous button"
                        disabled={!pagination.links[0]?.url}
                        onClick={(e) => goToThePage(e, pagination.links[pagination.current_page - 1].label)}>
                    <span className="icon">
                        <FontAwesomeIcon icon={faAngleDoubleLeft} />
                    </span>
                    <span>
                        <FormattedMessage id="general.previous" />
                    </span>
                </button>
                <button className="pagination-next button"
                        disabled={!pagination.links[pagination.links.length - 1]?.url}
                        onClick={(e) => goToThePage(e, pagination.links[pagination.current_page + 1].label)}>
                    <span>
                        <FormattedMessage id="general.next" />
                    </span>
                    <span className="icon">
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </span>
                </button>
            </nav>
        </>
    )
}
