import * as actions from '../../Actions'
import {LOCALES} from '../../../consts/app'

const initialState = {
    locale: LOCALES[0]
}

export default function reducer (state = initialState, action) {
    switch (action.type) {
        case actions.SET_LOCALE:
            return {
                ...state,
                locale: LOCALES.find(locale => locale.name === action.payload)
            }
        default:
            return state
    }
}
