import {useEffect, useRef, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import * as actions from '../store/Actions'
import {deleteTag, getTags, getTagsByName, getTagsByType, updateTag} from '../store/reducers/tag/tagActions'
import {Datatable} from '../components/Datatable/Datatable'
import {Title} from '../components/UI/Title'
import {Dropdown} from '../components/UI/Dropdown'
import {Search} from '../components/UI/Search'
import {TableIconButtonEdit} from '../components/UI/TableIconButtonEdit'
import {TableIconButtonDelete} from '../components/UI/TableIconButtonDelete'
import {Pagination} from '../components/UI/Pagination'
import * as modalTypes from '../consts/enums/modalTypes'
import * as toastTypes from '../consts/enums/toastTypes'
import {PAGINATION_PAGE_ROWS, TAG_TYPES} from '../consts/app'

export const Tags = () => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const tags = useSelector(state => state.tag.tags)
    const isGettingTags = useSelector(state => state.tag.isGettingTags)
    const pagination = useSelector(state => state.tag.pagination)
    const hasTagBeenCreated = useSelector(state => state.tag.tagCreated)
    const hasTagBeenUpdated = useSelector(state => state.tag.tagUpdated)
    const hasTagBeenDeleted = useSelector(state => state.tag.tagDeleted)
    const tagError = useSelector(state => state.tag.error)
    const [tagFilter, setTagFilter] = useState("all")
    const [nameFilter, setNameFilter] = useState("")
    const [typeFilter, setTypeFilter] = useState("")
    const searchType = useRef({type: "all", search: ""})
    const sortType = useRef({sort: "type", order: "asc"})

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (hasTagBeenCreated) {
            getData()
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.SUCCESS, message: "message.tag_created"}})
            dispatch({type: actions.TAG_CREATED, payload: {status: false}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasTagBeenCreated])

    useEffect(() => {
        if (hasTagBeenUpdated) {
            getData()
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.SUCCESS, message: "message.tag_updated"}})
            dispatch({type: actions.TAG_UPDATED, payload: {status: false}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasTagBeenUpdated])

    useEffect(() => {
        if (hasTagBeenDeleted) {
            getData()
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.SUCCESS, message: "message.tag_deleted"}})
            dispatch({type: actions.TAG_DELETED, payload: {status: false}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasTagBeenDeleted])

    useEffect(() => {
        if (tagError) {
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.RESET_TAG_ERROR})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tagError])

    const getData = () => {
        setTagFilter("all")
        searchType.current = {type: "all", search: ""}
        dispatch(getTags(sortType.current.sort, sortType.current.order, 1))
    }

    const resetData = () => {
        setNameFilter("")
        setTypeFilter("")
    }

    const filterTagsByName = (e) => {
        e.preventDefault()
        searchType.current = {type: "name", search: nameFilter}
        dispatch(getTagsByName(nameFilter, sortType.current.sort, sortType.current.order, 1))
    }

    const filterTagsByType = (type) => {
        searchType.current = {type: "type", search: type}
        dispatch(getTagsByType(type, sortType.current.sort, sortType.current.order, 1))
    }

    const sortTags = (column, sortDirection) => {
        sortType.current = {sort: column.sortField, order: sortDirection}
        getTagsPerPage(1)
    }

    const getTagsPerPage = (page) => {
        if (searchType.current.type === "all") {
            dispatch(getTags(sortType.current.sort, sortType.current.order, page))
        } else if (searchType.current.type === "name") {
            dispatch(getTagsByName(searchType.current.search, sortType.current.sort, sortType.current.order, page))
        } else  {
            dispatch(getTagsByType(searchType.current.search, sortType.current.sort, sortType.current.order, page))
        }
    }

    const editTag = (tag) => {
        dispatch(updateTag(tag))
        dispatch({type: actions.HIDE_MODAL})
        dispatch({type: actions.SHOW_MODAL, payload: {type:modalTypes.LOADING}})
    }

    const deleteATag = (id) => {
        dispatch(deleteTag(id))
        dispatch({type: actions.HIDE_MODAL})
        dispatch({type: actions.SHOW_MODAL, payload: {type:modalTypes.LOADING}})
    }

    const showCreateTag = (e) => {
        e.preventDefault()
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                type: modalTypes.NEW_TAG,
                title: intl.formatMessage({id: "message_title.new_tag"})
            }
        })
    }

    const showEditTag = (e, tag) => {
        e.preventDefault()
        e.stopPropagation()
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                type: modalTypes.EDIT_TAG,
                title: intl.formatMessage({id: "message_title.edit_tag"}),
                additionalData: {
                    tag: tag,
                    editTag: editTag
                }
            }
        })
    }

    const showDeleteConfirmation = (e, id) => {
        e.preventDefault()
        e.stopPropagation()
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                buttons: [
                    {
                        label: intl.formatMessage({id: "general.delete"}),
                        classes: 'button is-link',
                        handler: () => {
                            deleteATag(id)
                        }
                    }
                ]
            }
        })
    }

    const TagsTable = () => {
        const columns = [
            {
                name: intl.formatMessage({id: "tag.name"}),
                selector: row => row.name,
                wrap: true,
                sortable: true,
                sortField: 'name'
            },
            {
                name: intl.formatMessage({id: "tag.type"}),
                selector: row => intl.formatMessage({id: "tag_type." + row.type}),
                wrap: true,
                sortable: true,
                sortField: 'type'
            },
            {
                selector: row => (
                    <>
                        <TableIconButtonEdit handler={(e) => showEditTag(e, row)} />
                        <TableIconButtonDelete handler={(e) => showDeleteConfirmation(e, row.id)} />
                    </>
                ),
                right: true,
                wrap: true,
                grow: 0.1
            }
        ]
        return (
            <Datatable
                columns={columns}
                data={tags}
                defaultSortAsc={sortType.current.order !== "asc"}
                onSort={sortTags}
                paginationComponent={() => <Pagination pagination={pagination} handler={getTagsPerPage} />}
                paginationPerPage={pagination?.per_page ?? PAGINATION_PAGE_ROWS}
                progressPending={isGettingTags}
                sortServer
            />
        )
    }

    return (
        <>
            <Title title={intl.formatMessage({id: "title.tags"})} />
            <div className="container">
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <button className="button is-link"
                                    onClick={(e) => showCreateTag(e)}>
                                <FormattedMessage id="tag.new_tag"/>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <label className="label">
                                <FormattedMessage id="general.filter_by" />:
                            </label>
                        </div>
                        <div className="level-item mr-0">
                            <Dropdown id="tagFilterDropdown"
                                      text={tagFilter ? intl.formatMessage({id: "tag." + tagFilter}) : ""}
                                      content={<>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    resetData()
                                                    getData()
                                                }}>
                                              <FormattedMessage id={"tag.all"} />
                                          </Link>
                                          <hr className="dropdown-divider" />
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setTagFilter("name")
                                                    resetData()
                                                }}>
                                              <FormattedMessage id={"tag.name"} />
                                          </Link>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setTagFilter("type")
                                                    resetData()
                                                }}>
                                              <FormattedMessage id={"tag.type"} />
                                          </Link>
                                      </>}
                            />
                        </div>
                        <div className="level-item">
                            <div className="field has-addons">
                                {tagFilter === "name" ?
                                    <Search size={"normal"}
                                            placeholder={intl.formatMessage({id: "placeholder.filter_by_name"})}
                                            value={nameFilter}
                                            inputChange={(e) => setNameFilter(e.target.value)}
                                            maxLength={50}
                                            isLoading={(isGettingTags && nameFilter.trim() !== "")}
                                            disabledButton={nameFilter.trim() === ""}
                                            buttonClick={(e) => filterTagsByName(e)}
                                            buttonText={intl.formatMessage({id: "general.search"})}
                                    />
                                    :
                                    tagFilter === "type" ?
                                        <Dropdown id="tagTypesDropdown"
                                                  text={typeFilter ? intl.formatMessage({id: "tag_type." + typeFilter}) : "-----"}
                                                  content={<>
                                                      {TAG_TYPES?.map((type, i) => (
                                                          <Link to="#" className="dropdown-item" key={i}
                                                                onClick={() => {
                                                                    setTypeFilter(type)
                                                                    filterTagsByType(type)
                                                                }}>
                                                              <FormattedMessage id={"tag_type." + type} />
                                                          </Link>
                                                      ))}
                                                  </>}
                                        />
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <TagsTable />
            </div>
        </>
    )
}
