import {useState, useEffect} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {login} from '../store/reducers/auth/authActions'
import * as actions from '../store/Actions'
import {Title} from '../components/UI/Title'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUser, faKey, faSignInAlt} from '@fortawesome/free-solid-svg-icons'

export const Login = () => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const error = useSelector(state => state.auth.error)
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (error) {
            setIsLoading(false)
            dispatch({type: actions.RESET_LOGIN_ERROR})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    const onSubmit = (e) => {
        e.preventDefault()
        const user = {
            username: username,
            password: password
        }
        dispatch(login(user))
    }

    return (
        <>
            <Title title={intl.formatMessage({id: "title.login"})} />
            <div className="container">
                <div className="column is-4 is-offset-4">
                    <form onSubmit={(e) => {
                        setIsLoading(true)
                        onSubmit(e)
                    }}>
                        <div className="field">
                            <div className="control has-icons-left">
                                <input id="username"
                                       className="input"
                                       type="text"
                                       placeholder={intl.formatMessage({id: "placeholder.username"})}
                                       value={username}
                                       onChange={(e) => setUsername(e.target.value)}
                                       autoFocus
                                       maxLength={47}
                                />
                                <span className="icon is-left">
                                    <FontAwesomeIcon icon={faUser} />
                                </span>
                            </div>
                        </div>
                        <div className="field">
                            <div className="control has-icons-left">
                                <input className="input"
                                       type="password"
                                       placeholder={intl.formatMessage({id: "placeholder.password"})}
                                       value={password}
                                       onChange={(e) => setPassword(e.target.value)}
                                       maxLength={50}
                                />
                                <span className="icon is-left">
                                    <FontAwesomeIcon icon={faKey} />
                                </span>
                            </div>
                        </div>
                        <div className="field">
                            <div className="control">
                                <button type="submit" className={`button is-fullwidth is-link ${isLoading ? 'is-loading' : ''}`}
                                        disabled={username.trim() === "" || password.trim() === "" || password.length < 5}>
                                    <span className="icon">
                                        <FontAwesomeIcon icon={faSignInAlt} />
                                    </span>
                                    <span>
                                        <FormattedMessage id="general.login" />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
