import {useEffect, useRef, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import {getStandardRates, getStandardRatesByCreationDate, getStandardRatesByCreator, getStandardRatesByCurrency,
    getStandardRatesByStatus} from '../store/reducers/rate/rateActions'
import {getExchangeRates} from '../store/reducers/exchangeRate/exchangeRateActions'
import {Datatable} from '../components/Datatable/Datatable'
import {Title} from '../components/UI/Title'
import {Dropdown} from '../components/UI/Dropdown'
import {Search} from '../components/UI/Search'
import {Pagination} from '../components/UI/Pagination'
import {PAGINATION_PAGE_ROWS} from '../consts/app'
import * as userTypes from '../consts/enums/userTypes'
import {createLocaleDateText} from '../utils/createLocaleDateText'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck} from '@fortawesome/free-solid-svg-icons'

export const StandardRates = () => {
    const navigate = useNavigate()
    const intl = useIntl()
    const dispatch = useDispatch()
    const standardRates = useSelector(state => state.rate.standardRates)
    const isGettingStandardRates = useSelector(state => state.rate.isGettingStandardRates)
    const pagination = useSelector(state => state.rate.pagination)
    const exchangeRates = useSelector(state => state.exchangeRate.exchangeRates)
    const isGettingExchangeRates = useSelector(state => state.exchangeRate.isGettingExchangeRates)
    const locale = useSelector(state => state.locale.locale)
    const userType = useSelector(state => state.auth.user.role)
    const [standardRateFilter, setStandardRateFilter] = useState("all")
    const [creationDateFilter, setCreationDateFilter] = useState("")
    const [currencyFilter, setCurrencyFilter] = useState("")
    const [creatorFilter, setCreatorFilter] = useState("")
    const [statusFilter, setStatusFilter] = useState("")
    const searchType = useRef({type: "all", search: ""})
    const sortType = useRef({sort: "rates.created_at", order: "desc"})

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = () => {
        setStandardRateFilter("all")
        searchType.current = {type: "all", search: ""}
        dispatch(getStandardRates(sortType.current.sort, sortType.current.order, 1))
    }

    const resetData = () => {
        setCreationDateFilter("")
        setCreatorFilter("")
        setStatusFilter("")
    }

    const filterStandardRateByCreationDate = (e) => {
        e.preventDefault()
        searchType.current = {type: "creation_date", search: creationDateFilter}
        dispatch(getStandardRatesByCreationDate(creationDateFilter, sortType.current.sort, sortType.current.order, 1))
    }

    const filterStandardRateByCurrency = (id) => {
        searchType.current = {type: "currency", search: id}
        dispatch(getStandardRatesByCurrency(id, sortType.current.sort, sortType.current.order, 1))
    }

    const filterStandardRateByCreator = (e) => {
        e.preventDefault()
        searchType.current = {type: "creator", search: creatorFilter}
        dispatch(getStandardRatesByCreator(creatorFilter, sortType.current.sort, sortType.current.order, 1))
    }

    const filterStandardRateByStatus = (status) => {
        searchType.current = {type: "status", search: status}
        dispatch(getStandardRatesByStatus(status, sortType.current.sort, sortType.current.order, 1))
    }

    const sortStandardRates = (column, sortDirection) => {
        sortType.current = {sort: column.sortField, order: sortDirection}
        getStandardRatesPerPage(1)
    }

    const getStandardRatesPerPage = (page) => {
        if (searchType.current.type === "all") {
            dispatch(getStandardRates(sortType.current.sort, sortType.current.order, page))
        } else if (searchType.current.type === "creation_date") {
            dispatch(getStandardRatesByCreationDate(searchType.current.search, sortType.current.sort, sortType.current.order, page))
        } else if (searchType.current.type === "currency") {
            dispatch(getStandardRatesByCurrency(searchType.current.search, sortType.current.sort, sortType.current.order, page))
        } else if (searchType.current.type === "creator") {
            dispatch(getStandardRatesByCreator(searchType.current.search, sortType.current.sort, sortType.current.order, page))
        } else {
            dispatch(getStandardRatesByStatus(searchType.current.search, sortType.current.sort, sortType.current.order, page))
        }
    }

    const showCreateStandardRate = (e) => {
        e.preventDefault()
        navigate("/standard-rates/new")
    }

    const showViewStandardRate = (standardRate, e) => {
        e.preventDefault()
        e.stopPropagation()
        navigate("/standard-rates/" + standardRate.id, {state: {standardRate: standardRate}})
    }

    const StandardRatesTable = () => {
        const columns = [
            {
                name: intl.formatMessage({id: "standard_rate.created_at"}),
                selector: row => createLocaleDateText(locale.name, row.created_at.substring(0, 10)) + " - " +
                    row.created_at.substring(11, 19),
                wrap: true,
                sortable: true,
                sortField: 'rates.created_at'
            },
            {
                name: intl.formatMessage({id: "standard_rate.currency"}),
                selector: row => row.currency_name,
                wrap: true,
                sortable: true,
                sortField: 'currencies.name'
            },
            {
                name: intl.formatMessage({id: "standard_rate.created_by"}),
                selector: row => row.user_username,
                wrap: true,
                sortable: true,
                sortField: 'users.username'
            },
            {
                name: intl.formatMessage({id: "standard_rate.active"}),
                selector: row => (
                    row.is_active ?
                        <span className="icon has-text-success">
                            <FontAwesomeIcon icon={faCheck} />
                        </span>
                        :
                        null
                ),
                center: true,
                wrap: true,
                sortable: true,
                sortField: 'rates.is_active',
                grow: 0.1
            }
        ]
        return (
            <Datatable
                columns={columns}
                customStyles={{rows: {style: {cursor: 'pointer'}}}}
                data={standardRates}
                defaultSortAsc={sortType.current.order !== "asc"}
                onRowClicked={showViewStandardRate}
                onSort={sortStandardRates}
                paginationComponent={() => <Pagination pagination={pagination} handler={getStandardRatesPerPage} />}
                paginationPerPage={pagination?.per_page ?? PAGINATION_PAGE_ROWS}
                progressPending={isGettingStandardRates}
                sortServer
            />
        )
    }

    return (
        <>
            <Title title={intl.formatMessage({id: "title.standard_rates"})} />
            <div className="container">
                {userType !== userTypes.RATE_VIEWER ?
                    <div className="level">
                        <div className="level-left">
                            <div className="level-item">
                                <button className="button is-link"
                                        onClick={(e) => showCreateStandardRate(e)}>
                                    <FormattedMessage id="standard_rate.new_standard_rate"/>
                                </button>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <label className="label">
                                <FormattedMessage id="general.filter_by" />:
                            </label>
                        </div>
                        <div className="level-item mr-0">
                            <Dropdown id="standardRateFilterDropdown"
                                      text={standardRateFilter ? intl.formatMessage({id: "standard_rate." + standardRateFilter}) : ""}
                                      content={<>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    resetData()
                                                    getData()
                                                }}>
                                              <FormattedMessage id={"standard_rate.all"} />
                                          </Link>
                                          <hr className="dropdown-divider" />
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setStandardRateFilter("creation_date")
                                                    resetData()
                                                }}>
                                              <FormattedMessage id={"standard_rate.creation_date"} />
                                          </Link>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setStandardRateFilter("currency")
                                                    resetData()
                                                    dispatch(getExchangeRates())
                                                }}>
                                              <FormattedMessage id={"standard_rate.currency"} />
                                          </Link>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setStandardRateFilter("creator")
                                                    resetData()
                                                }}>
                                              <FormattedMessage id={"standard_rate.creator"} />
                                          </Link>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setStandardRateFilter("status")
                                                    resetData()
                                                }}>
                                              <FormattedMessage id={"standard_rate.status"} />
                                          </Link>
                                      </>}
                            />
                        </div>
                        <div className="level-item">
                            <div className="field has-addons">
                                {standardRateFilter === "creation_date" ?
                                    <>
                                        <div className="control">
                                            <input className="input"
                                                   type="date"
                                                   value={creationDateFilter}
                                                   onChange={(e) => setCreationDateFilter(e.target.value)}
                                            />
                                        </div>
                                        <button className={`button is-link ${isGettingStandardRates &&
                                        creationDateFilter.trim() !== "" ? 'is-loading' : ''}`}
                                                disabled={creationDateFilter.trim() === ""}
                                                onClick={(e) => filterStandardRateByCreationDate(e)}>
                                            <FormattedMessage id="general.search" />
                                        </button>
                                    </>
                                    :
                                    standardRateFilter === "currency" ?
                                        <Dropdown id="standardRateCurrenciesDropdown"
                                                  text={currencyFilter ? currencyFilter : "-----"}
                                                  isLoading={isGettingExchangeRates}
                                                  content={<>
                                                      {exchangeRates?.map((exchangeRate, i) => (
                                                          <Link to="#" className="dropdown-item" key={i}
                                                                onClick={() => {
                                                                    setCurrencyFilter(exchangeRate.currency_name)
                                                                    filterStandardRateByCurrency(exchangeRate.currency_id)
                                                                }}>
                                                              {exchangeRate.currency_name}
                                                          </Link>
                                                      ))}
                                                  </>}
                                        />
                                        :
                                        standardRateFilter === "creator" ?
                                            <Search size={"normal"}
                                                    placeholder={intl.formatMessage({id: "placeholder.filter_by_creator"})}
                                                    value={creatorFilter}
                                                    inputChange={(e) => setCreatorFilter(e.target.value)}
                                                    maxLength={47}
                                                    isLoading={(isGettingStandardRates && creatorFilter.trim() !== "")}
                                                    disabledButton={creatorFilter.trim() === ""}
                                                    buttonClick={(e) => filterStandardRateByCreator(e)}
                                                    buttonText={intl.formatMessage({id: "general.search"})}
                                            />
                                            :
                                            standardRateFilter === "status" ?
                                                <Dropdown id="standardRateStatusDropdown"
                                                          text={statusFilter ? intl.formatMessage({id: "standard_rate." + statusFilter}) : ""}
                                                          content={<>
                                                              <Link to="#" className="dropdown-item"
                                                                    onClick={() => {
                                                                        setStatusFilter("active")
                                                                        filterStandardRateByStatus(true)
                                                                    }}>
                                                                  <FormattedMessage id={"standard_rate.active"} />
                                                              </Link>
                                                              <Link to="#" className="dropdown-item"
                                                                    onClick={() => {
                                                                        setStatusFilter("not_active")
                                                                        filterStandardRateByStatus(false)
                                                                    }}>
                                                                  <FormattedMessage id={"standard_rate.not_active"} />
                                                              </Link>
                                                          </>}
                                                />
                                                :
                                                null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <StandardRatesTable />
            </div>
        </>
    )
}
