import {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import * as actions from '../../store/Actions'
import {storeUnit} from '../../store/reducers/unit/unitActions'
import {DraggableModal} from '../UI/DraggableModal'
import * as modalTypes from '../../consts/enums/modalTypes'

export const ModalNewUnit = ({title = ""}) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const [type, setType] = useState("")

    const onHideClick = (e) => {
        e.preventDefault()
        dispatch({type: actions.HIDE_MODAL})
    }

    const createUnit = (e) => {
        e.preventDefault()
        const unit = {
            type: type
        }
        dispatch(storeUnit(unit))
        dispatch({type: actions.HIDE_MODAL})
        dispatch({type: actions.SHOW_MODAL, payload: {type:modalTypes.LOADING}})
    }

    const Body = () => {
        return (
            <div className="field">
                <label className="label">
                    <FormattedMessage id="unit.type" />
                    <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                    <span>:</span>
                </label>
                <div className="columns">
                    <div className="column is-one-third">
                        <div className="control">
                            <input className="input"
                                   type="text"
                                   placeholder={intl.formatMessage({id: "placeholder.type"})}
                                   value={type}
                                   onChange={(e) => setType(e.target.value)}
                                   autoFocus
                                   maxLength={20}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const Foot = () => {
        return (
            <>
                <button className="button is-link"
                        disabled={type.trim() === ""}
                        onClick={(e) => createUnit(e)}>
                    <FormattedMessage id="general.save" />
                </button>
                <button className="button"
                        onClick={(e) => onHideClick(e)}>
                    <FormattedMessage id="general.cancel" />
                </button>
            </>
        )
    }

    return (
        <DraggableModal title={title} body={Body()} foot={Foot()} onHideClick={onHideClick} />
    )
}
