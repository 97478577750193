export const Tag = ({size = "medium", text = "", title = null, buttonDelete = true, buttonDeleteSize = "small", handler = null}) => {

    return (
        <span className={"tag is-link is-light is-" + size + " mr-2"} title={title}>
                {text}
            {buttonDelete ?
                <button className={"delete is-" + buttonDeleteSize} onClick={handler} />
                :
                null
            }
        </span>
    )
}
