import {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import * as actions from '../../store/Actions'
import {USER_ROLES} from '../../consts/app'
import {DraggableModal} from '../UI/DraggableModal'

export const ModalEditUser = ({title = "", user = null, editUser = null}) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const [role, setRole] = useState("" + user.role)

    const onHideClick = (e) => {
        e.preventDefault()
        dispatch({type: actions.HIDE_MODAL})
        dispatch({type: actions.RESET_USERS_IDCP})
    }

    const edit = (e) => {
        e.preventDefault()
        const editedUser = {
            id: user.id,
            username: user.username,
            name: user.name,
            role: role
        }
        editUser(editedUser)
    }

    const Body = () => {
        return (
            <>
                <div className="field">
                    <label className="label">
                        <FormattedMessage id="user.user" />: {user.username} - {user.name}
                    </label>
                </div>
                <div className="field">
                    <label className="label">
                        <FormattedMessage id="user.role" />
                        <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                        <span>:</span>
                    </label>
                    <div className="select">
                        <select value={role}
                                onChange={(e) => setRole(e.target.value)}>
                            <option value="">
                                -----
                            </option>
                            {USER_ROLES?.map((role, i) => (
                                <option key={i} value={role}>
                                    {intl.formatMessage({id: "role." + role})}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </>
        )
    }

    const Foot = () => {
        return (
            <>
                <button className="button is-link"
                        disabled={role.trim() === ""}
                        onClick={(e) => edit(e)}>
                    <FormattedMessage id="general.save" />
                </button>
                <button className="button"
                        onClick={(e) => onHideClick(e)}>
                    <FormattedMessage id="general.cancel" />
                </button>
            </>
        )
    }

    return (
        <DraggableModal title={title} body={Body()} foot={Foot()} onHideClick={onHideClick} />
    )
}
