import {useEffect, useRef, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import * as actions from '../../store/Actions'
import {getAllValues, getValues} from '../../store/reducers/value/valueActions'
import {DraggableModal} from '../UI/DraggableModal'
import {STANDARD_CURRENCY_CODE} from '../../consts/app'
import {roundDecimalNumber} from '../../utils/roundDecimalNumber'

export const ModalAddTag = ({title = "", additionalData = null}) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const activeStandardRates = useSelector(state => state.rate.activeStandardRates)
    const allValues = useSelector(state => state.value.values)
    const isGettingValues = useSelector(state => state.value.isGettingValues)
    const allStandardRateValues = useSelector(state => state.value.allValues)
    const isGettingAllValues = useSelector(state => state.value.isGettingAllValues)
    const [values, setValues] = useState([])
    const [value, setValue] = useState("")
    const [activeStandardRate] = useState(activeStandardRates.length > 0 ?
        activeStandardRates.find(standardRate => standardRate.currency_code === STANDARD_CURRENCY_CODE) ??
        activeStandardRates[0] : null)
    const allValuesExist = useRef(additionalData.allValues.length > 0)
    const allStandardRateValuesExist = useRef(!additionalData.standard ?
        !!additionalData.allValues.find(value => value.rate_id === activeStandardRate?.id) : true)

    useEffect(() => {
        if (!allValuesExist.current) {
            dispatch(getValues(additionalData.rateId))
        }
        if (!allStandardRateValuesExist.current) {
            dispatch(getAllValues(activeStandardRate?.id))
            allValuesExist.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!isGettingValues && !isGettingAllValues && allValuesExist.current) {
            additionalData.allValues = additionalData.allValues.length > 0 ? [...additionalData.allValues] : [...allValues]
            if (!allStandardRateValuesExist.current) {
                for (let i = 0; i < allStandardRateValues.length; i++) {
                    if (!additionalData.allValues.find(value => value.tag_id === allStandardRateValues[i].tag_id &&
                        (!value.unit_id || !allStandardRateValues[i].unit_id || value.unit_id === allStandardRateValues[i].unit_id))) {
                        additionalData.allValues = [...additionalData.allValues, allStandardRateValues[i]]
                    }
                }
                sortValues()
            }
            additionalData.setAllValues([...additionalData.allValues])
            filterValues()
        }
        allValuesExist.current = true
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allValues, allStandardRateValues])

    const sortValues = () => {
        additionalData.allValues.sort((value1, value2) => {
            return value1.tag_name > value2.tag_name ? 1 : value1.tag_name < value2.tag_name ? -1 : 0
        }).sort((value1, value2) => {
            return value1.tag_type > value2.tag_type ? 1 : value1.tag_type < value2.tag_type ? -1 : 0
        })
    }

    const onHideClick = (e) => {
        e.preventDefault()
        dispatch({type: actions.HIDE_MODAL})
    }

    const filterValues = () => {
        const values = additionalData.allValues.filter(valueAvailable => !additionalData.values.find(value => value.id === valueAvailable.id))
        setValues([...values])
    }

    const add = (e) => {
        e.preventDefault()
        const values = JSON.parse(JSON.stringify(additionalData.allValues.filter(valueAvailable =>
            additionalData.values.find(value => value.id === valueAvailable.id) || parseInt(value) === valueAvailable.id)))
        for (let i = 0; i < values.length; i++) {
            const valueIndex = additionalData.values.indexOf(additionalData.values.find(value => value.id === values[i].id))
            if (valueIndex > -1) {
                values[i] = additionalData.values[valueIndex]
            } else {
                if (!additionalData.values.find(value => value.tag_id === values[i].reference_tag)) {
                    values[i].reference_tag = null
                    values[i].reference_tag_name = null
                    values[i].reference_tag_type = null
                }
            }
        }
        if (additionalData.exchangeRateValue || additionalData.valueExchangeRate) {
            const valueIndex = values.indexOf(values.find(v => v.id === parseInt(value)))
            if (valueIndex > -1 && values[valueIndex].price !== null) {
                let exchangeRate = roundDecimalNumber(additionalData.valueExchangeRate)
                exchangeRate = isNaN(exchangeRate) ? 1 : exchangeRate <= 0 ? 1 : exchangeRate
                values[valueIndex].price = values[valueIndex].price /
                    (values[valueIndex].rate_id === additionalData.rateId ? additionalData.exchangeRateValue : 1) * exchangeRate
            }
        }
        additionalData.getNewValues(values, additionalData.allValues)
        dispatch({type: actions.HIDE_MODAL})
    }

    const Body = () => {
        return (
            <div className={`select ${isGettingValues || isGettingAllValues ? 'is-loading' : ''}`}>
                <select onChange={(e) => setValue(e.target.value)}>
                    <option value="">
                        -----
                    </option>
                    {values?.map((value, i) => (
                        <option key={i} value={value.id}>
                            {value.tag_name} {value.unit_id ? "(" + value.unit_type + ")" : ""} - {intl.formatMessage({id: "tag_type." + value.tag_type})}
                        </option>
                    ))}
                </select>
            </div>
        )
    }

    const Foot = () => {
        return (
            <>
                <button className="button is-link"
                        disabled={value === ""}
                        onClick={(e) => add(e)}>
                    <FormattedMessage id="general.add" />
                </button>
                <button className="button"
                        onClick={(e) => onHideClick(e)}>
                    <FormattedMessage id="general.cancel" />
                </button>
            </>
        )
    }

    return (
        <DraggableModal title={title} body={Body()} foot={Foot()} onHideClick={onHideClick} />
    )
}
