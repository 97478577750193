import {axiosInstance} from '../../../hoc/Axios/Axios'
import * as actions from '../../Actions'
import * as toastTypes from '../../../consts/enums/toastTypes'
import {encodeParameter} from '../../../utils/encodeParameter'

export const getUnits = (sort = "type", order = "asc", page) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_UNITS})
    dispatch({type: actions.GETTING_UNITS})
    try {
        const url = "/units" + (page ? "?sort=" + sort + "&order=" + order + "&page=" + page : "/all")
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_UNITS, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_UNITS})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.units_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.UNIT_ERROR, payload: error.response})
    }
}

export const getUnitsByType = (type, sort = "type", order = "asc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_UNITS})
    dispatch({type: actions.GETTING_UNITS})
    try {
        type = encodeParameter(type)
        const url = "/units/by-type/" + type + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_UNITS, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_UNITS})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.units_by_type_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.UNIT_ERROR, payload: error.response})
    }
}

export const storeUnit = (unit) => async (dispatch, getState) => {
    try {
        await axiosInstance.post("/units/store", unit)
        dispatch({type: actions.UNIT_CREATED, payload: {status: true}})
    } catch (error) {
        dispatch({type: actions.UNIT_ERROR, payload: error.response})
    }
}

export const deleteUnit = (id) => async (dispatch, getState) => {
    try {
        await axiosInstance.delete("/units/" + id)
        dispatch({type: actions.UNIT_DELETED, payload: {status: true}})
    } catch (error) {
        dispatch({type: actions.UNIT_ERROR, payload: error.response})
    }
}
