import {axiosInstance} from '../../../hoc/Axios/Axios'
import * as actions from '../../Actions'
import * as toastTypes from '../../../consts/enums/toastTypes'
import {encodeParameter} from '../../../utils/encodeParameter'

export const getExchangeRates = (sort = "currencies.name", order = "asc", page) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_EXCHANGE_RATES})
    dispatch({type: actions.GETTING_EXCHANGE_RATES})
    try {
        const url = "/exchange-rates" + (page ? "?sort=" + sort + "&order=" + order + "&page=" + page : "/all")
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_EXCHANGE_RATES, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_EXCHANGE_RATES})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.currencies_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.EXCHANGE_RATE_ERROR, payload: error.response})
    }
}

export const getExchangeRatesByName = (name, sort = "currencies.name", order = "asc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_EXCHANGE_RATES})
    dispatch({type: actions.GETTING_EXCHANGE_RATES})
    try {
        name = encodeParameter(name)
        const url = "/exchange-rates/by-name/" + name + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_EXCHANGE_RATES, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_EXCHANGE_RATES})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.currencies_by_name_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.EXCHANGE_RATE_ERROR, payload: error.response})
    }
}

export const getExchangeRatesByExchangeRate = (min, max, exchangeRate, sort = "currencies.name", order = "asc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_EXCHANGE_RATES})
    dispatch({type: actions.GETTING_EXCHANGE_RATES})
    try {
        exchangeRate = exchangeRate === "exchange_rate_idcp" || exchangeRate === "exchange_rate_api" ? "currencies." + exchangeRate : "exchange_rates.value"
        const url = "/exchange-rates/by-exchange-rate/" + min + "/" + max + "/" + exchangeRate + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_EXCHANGE_RATES, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_EXCHANGE_RATES})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.currencies_by_exchange_rate_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.EXCHANGE_RATE_ERROR, payload: error.response})
    }
}

export const storeExchangeRate = (exchangeRate) => async (dispatch, getState) => {
    try {
        await axiosInstance.post("/exchange-rates/store", exchangeRate)
        dispatch({type: actions.EXCHANGE_RATE_CREATED, payload: {status: true}})
    } catch (error) {
        dispatch({type: actions.EXCHANGE_RATE_ERROR, payload: error.response})
    }
}

export const updateExchangeRate = (exchangeRate) => async (dispatch, getState) => {
    try {
        await axiosInstance.put("/exchange-rates/" + exchangeRate.id, exchangeRate)
        dispatch({type: actions.EXCHANGE_RATE_UPDATED, payload: {status: true}})
    } catch (error) {
        dispatch({type: actions.EXCHANGE_RATE_ERROR, payload: error.response})
    }
}

export const deleteExchangeRate = (id) => async (dispatch, getState) => {
    try {
        await axiosInstance.delete("/exchange-rates/" + id)
        dispatch({type: actions.EXCHANGE_RATE_DELETED, payload: {status: true}})
    } catch (error) {
        dispatch({type: actions.EXCHANGE_RATE_ERROR, payload: error.response})
    }
}
