import DataTable from 'react-data-table-component'
import {useIntl} from 'react-intl'
import {Loader} from '../UI/Loader'

export const Datatable = (props) => {
    const intl = useIntl()

    return (
        <DataTable
            highlightOnHover={true}
            noDataComponent={intl.formatMessage({id: "datatable.no_data_component"})}
            progressComponent={<Loader />}
            pagination
            responsive
            {...props}
        />
    )
}
