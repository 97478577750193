import {useEffect, useRef, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useLocation} from 'react-router-dom'
import * as actions from '../store/Actions'
import {getPrices} from '../store/reducers/price/priceActions'
import {getActiveStandardRates} from '../store/reducers/rate/rateActions'
import {getCustomersByName} from '../store/reducers/customer/customerActions'
import {getManufacturers, getManufacturersByName} from '../store/reducers/manufacturer/manufacturerActions'
import {getProjects, getProjectsByName} from '../store/reducers/project/projectActions'
import {getCustomerRatesFrom} from '../store/reducers/customerRate/customerRateActions'
import {getRatePercentages} from '../store/reducers/ratePercentage/ratePercentageActions'
import {Title} from '../components/UI/Title'
import {Dropdown} from '../components/UI/Dropdown'
import {Tag} from '../components/UI/Tag'
import {Search} from '../components/UI/Search'
import {Datatable} from '../components/Datatable/Datatable'
import {Pagination} from '../components/UI/Pagination'
import * as modalTypes from '../consts/enums/modalTypes'
import {MAX_REFERENCE_TAGS, PAGINATION_PAGE_ROWS, STANDARD_CURRENCY_CODE, UNIT_WORD_ID} from '../consts/app'
import {roundDecimalNumber} from '../utils/roundDecimalNumber'

export const ConsultPrice = () => {
    const location = useLocation()
    const intl = useIntl()
    const dispatch = useDispatch()
    const prices = useSelector(state => state.price.prices)
    const isGettingPrices = useSelector(state => state.price.isGettingPrices)
    const activeStandardRates = useSelector(state => state.rate.activeStandardRates)
    const isGettingActiveStandardRates = useSelector(state => state.rate.isGettingActiveStandardRates)
    const customers = useSelector(state => state.customer.customers)
    const isGettingCustomers = useSelector(state => state.customer.isGettingCustomers)
    const manufacturers = useSelector(state => state.manufacturer.manufacturers)
    const isGettingManufacturers = useSelector(state => state.manufacturer.isGettingManufacturers)
    const projects = useSelector(state => state.project.projects)
    const isGettingProjects = useSelector(state => state.project.isGettingProjects)
    const customerRates = useSelector(state => state.customerRate.customerRates)
    const isGettingCustomerRates = useSelector(state => state.customerRate.isGettingCustomerRates)
    const ratePercentages = useSelector(state => state.ratePercentage.ratePercentages)
    const isGettingRatePercentages = useSelector(state => state.ratePercentage.isGettingRatePercentages)
    const pagination = useSelector(state => state.ratePercentage.pagination)
    const searchedCustomer = useRef(null)
    const [rateType, setRateType] = useState("")
    const [standardRate, setStandardRate] = useState("")
    const [searchCustomer, setSearchCustomer] = useState("")
    const [searchManufacturer, setSearchManufacturer] = useState("")
    const [searchProject, setSearchProject] = useState("")
    const [searchLanguagePair, setSearchLanguagePair] = useState("")
    const [customer, setCustomer] = useState("")
    const [manufacturer, setManufacturer] = useState("")
    const [project, setProject] = useState("")
    const [customerRate, setCustomerRate] = useState("")
    const [price, setPrice] = useState("")
    const [languagePair, setLanguagePair] = useState("")
    const [values, setValues] = useState([])
    const [allValues, setAllValues] = useState([])
    const firstRender = useRef(true)
    const sortType = useRef({sort: "cat_tool_breaks.match_percent_min", order: "desc"})

    useEffect(() => {
        if (location.state) {
            searchedCustomer.current = location.state.customer
            if (location.state.standard) {
                setRateType("standard_rate")
                dispatch(getActiveStandardRates())
            } else {
                setRateType("customer_rate")
                setCustomer(location.state.customer)
                dispatch(getCustomerRatesFrom(location.state.customer.id))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!isGettingActiveStandardRates && !firstRender.current && searchedCustomer.current?.currency &&
            (!searchedCustomer.current.currency2 || searchedCustomer.current.currency === searchedCustomer.current.currency2)) {
            const standardRate = activeStandardRates.find(standardRate =>
                standardRate.currency_code === searchedCustomer.current.currency)
            if (standardRate) {
                resetStandardRate(standardRate)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGettingActiveStandardRates])

    useEffect(() => {
        if (!isGettingCustomerRates && !firstRender.current && customerRates.length === 1) {
            resetCustomerRate(customerRates[0])
        }
        firstRender.current = false
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGettingCustomerRates])

    const resetCustomerData = () => {
        setSearchCustomer("")
        setSearchManufacturer("")
        setSearchProject("")
        setCustomer("")
        setManufacturer("")
        setProject("")
        setCustomerRate("")
    }

    const resetData = () => {
        setPrice("")
        setLanguagePair("")
        setValues([])
        dispatch({type: actions.RESET_CUSTOMERS})
        dispatch({type: actions.RESET_MANUFACTURERS})
        dispatch({type: actions.RESET_PROJECTS})
    }

    const changeCustomerData = (e, data) => {
        e.preventDefault()
        setSearchCustomer("")
        setCustomer(data)
        setSearchManufacturer("")
        setManufacturer("")
        setSearchProject("")
        setProject("")
        setCustomerRate("")
        resetData()
        dispatch(getCustomerRatesFrom(data.id))
    }

    const changeManufacturerData = (e, data) => {
        e.preventDefault()
        setSearchManufacturer("")
        setManufacturer(data)
        setSearchProject("")
        setProject("")
        setCustomerRate("")
        resetData()
        dispatch(getCustomerRatesFrom(customer.id, data.id))
    }

    const changeProjectData = (e, data) => {
        setSearchProject("")
        setProject(data)
        setCustomerRate("")
        resetData()
        dispatch(getCustomerRatesFrom(customer.id, manufacturer.id, data.id))
    }

    const dataText = (data) => {
        if (data.type) {
            return data.name + " (" + data.type + ")"
        }
        return data.name
    }

    const getCustomersData = (e) => {
        e.preventDefault()
        dispatch(getCustomersByName(searchCustomer))
    }

    const getManufacturersData = (e) => {
        e.preventDefault()
        if (searchManufacturer.trim() === "") {
            dispatch(getManufacturers(customer.id))
        } else {
            dispatch(getManufacturersByName(searchManufacturer, customer.id))
        }
    }

    const getProjectsData = (e) => {
        e.preventDefault()
        if (searchProject.trim() === "") {
            dispatch(getProjects(manufacturer.id, customer.id))
        } else {
            dispatch(getProjectsByName(searchProject, manufacturer.id, customer.id))
        }
    }

    const removeCustomer = (e) => {
        e.preventDefault()
        resetCustomerData()
        resetData()
    }

    const removeManufacturer = (e) => {
        e.preventDefault()
        setManufacturer("")
        setSearchProject("")
        setProject("")
        setCustomerRate("")
        resetData()
        dispatch(getCustomerRatesFrom(customer.id))
    }

    const removeProject = (e) => {
        e.preventDefault()
        setProject("")
        setCustomerRate("")
        resetData()
        dispatch(getCustomerRatesFrom(customer.id, manufacturer.id))
    }

    const resetStandardRate = (standardRate) => {
        setStandardRate(standardRate)
        resetData()
        dispatch(getPrices(standardRate.id))
    }

    const resetCustomerRate = (customerRate) => {
        setCustomerRate(customerRate)
        resetData()
        dispatch(getPrices(customerRate.rate_id))
    }

    const resetLanguagePairPrice = (price, languagePair) => {
        setPrice(price)
        setLanguagePair(languagePair)
        if (values[0]?.price !== null || (values[0]?.price === null && values[0]?.percent === null)) {
            setValues([])
        } else {
            setValues([...values.filter(value => value.unit_type === price.unit_type || !value.unit_id)])
        }
        getRatePercentagesPerPage(1)
    }

    const createTextLanguagePair = (price) => {
        return price.translation_source_language +
            (price.translation_code_source_language ? " (" + price.translation_code_source_language + ")" : "") + " - " +
            price.translation_target_language +
            (price.translation_code_target_language ? " (" + price.translation_code_target_language + ")" : "") +
            " (" + price.unit_type + ")"
    }

    const languagePairText = (price) => {
        return price.translation_source_language + " - " + price.translation_target_language
    }

    const filterPrices = () => {
        if (searchLanguagePair.trim() !== "") {
            return prices.filter(price => createTextLanguagePair(price).toLowerCase().includes(searchLanguagePair.toLowerCase()) ||
                languagePairText(price).toLowerCase().includes(searchLanguagePair.toLowerCase())
            )
        }
        return prices
    }

    const selectTags = (values, allValues) => {
        setValues([...values])
        setAllValues([...allValues])
    }

    const removeTag = (e, i) => {
        e.preventDefault()
        const updateValues = [...values]
        updateValues.splice(i, 1)
        setValues([...updateValues])
    }

    const sortCatToolBreaks = (column, sortDirection) => {
        sortType.current = {sort: column.sortField, order: sortDirection}
        getRatePercentagesPerPage(1)
    }

    const getRatePercentagesPerPage = (page) => {
        dispatch(getRatePercentages(rateType === "standard_rate" ? standardRate?.id : customerRate?.rate_id,
            sortType.current.sort, sortType.current.order, page))
    }

    const calculateCustomerPrice = (price) => {
        return price * (customerRate.special_customer_percent !== null ?
            (customerRate.special_customer_percent / 100) : 1)
    }

    const calculateReferenceTagPrice = (total, referenceTag, unitId, referenceTagCount) => {
        if (referenceTagCount <= MAX_REFERENCE_TAGS) {
            const priceUnit = price ? price.unit_id : values[0].unit_id
            const referenceValue = allValues.find(value => value.tag_id === referenceTag &&
                (unitId ? value.unit_id === unitId || !value.unit_id :
                    priceUnit ? value.unit_id === priceUnit || !value.unit_id : true))
            if (referenceValue) {
                if (referenceValue.reference_tag) {
                    total = calculateReferenceTagPrice(total, referenceValue.reference_tag, referenceValue.unit_id, referenceTagCount + 1)
                }
                if ((priceUnit || !referenceValue.unit_id) && referenceValue.percent !== null) {
                    return total * (referenceValue.percent / 100)
                }
            }
        }
        return total
    }

    const calculatePrice = (total) => {
        if (customerRate) {
            total = calculateCustomerPrice(total)
        }
        if (values.length > 0) {
            for (let i = price ? 0 : 1; i < values.length; i++) {
                if (values[i].reference_tag) {
                    total = calculateReferenceTagPrice(total, values[i].reference_tag, values[i].unit_id, 1)
                }
                total = total * (values[i].percent / 100)
            }
        }
        return total
    }

    const isStandardCurrency = (currencyCode) => {
        return currencyCode === STANDARD_CURRENCY_CODE
    }

    const showCalculatedPrice = () => {
        let total = price ? price.total : values[0].price
        if (total !== null) {
            total = calculatePrice(total)
            if ((standardRate && !isStandardCurrency(standardRate.currency_code)) ||
                (customerRate && !isStandardCurrency(customerRate.currency_code))) {
                return roundDecimalNumber(total) + " (" + (standardRate ?
                    standardRate.currency_abbreviation ?? standardRate.currency_code :
                    customerRate.currency_abbreviation ?? customerRate.currency_code) + ")"
            } else {
                return "€ " + roundDecimalNumber(total)
            }
        }
        return intl.formatMessage({id: "datatable_tag.not_value"})
    }

    const showSelectTags = (e) => {
        e.preventDefault()
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                type: modalTypes.SELECT_TAGS,
                title: intl.formatMessage({id: "message_title.select_tags"}),
                additionalData: {
                    rateId: rateType === "standard_rate" ? standardRate?.id : customerRate?.rate_id,
                    unit: price?.unit_type,
                    tags: values,
                    selectTags: selectTags
                }
            }
        })
    }

    const CatToolBreaksTable = () => {
        const columns = [
            {
                name: intl.formatMessage({id: "cat_tool_break.name"}),
                selector: row => row.cat_tool_break_name + (row.cat_tool_break_match_percent_min !== null ?
                    " (" + row.cat_tool_break_match_percent_min + "%" + (row.cat_tool_break_match_percent_max ?
                        " - " + row.cat_tool_break_match_percent_max + "%)" : ")") : ""),
                wrap: true,
                sortable: true,
                sortField: 'cat_tool_breaks.match_percent_min'
            },
            {
                name: intl.formatMessage({id: "cat_tool_break.percent"}),
                selector: row => row.percent + "%",
                wrap: true,
                sortable: true,
                sortField: 'rate_percentages.percent'
            },
            {
                name: intl.formatMessage({id: "cat_tool_break.description"}),
                selector: row => row.description,
                wrap: true,
                sortable: true,
                sortField: 'rate_percentages.description'
            },
            {
                name: intl.formatMessage({id: "consult_price.price"}),
                selector: row => roundDecimalNumber(calculatePrice(price ? price.total : values[0].price) * (row.percent / 100)),
                wrap: true
            }
        ]
        return (
            <Datatable
                columns={columns}
                data={ratePercentages}
                defaultSortAsc={sortType.current.order !== "asc"}
                onSort={sortCatToolBreaks}
                paginationComponent={() => <Pagination pagination={pagination} handler={getRatePercentagesPerPage} />}
                paginationPerPage={pagination?.per_page ?? PAGINATION_PAGE_ROWS}
                progressPending={isGettingRatePercentages}
                sortServer
            />
        )
    }

    return (
        <>
            <Title title={intl.formatMessage({id: "title.consult_price"})} />
            <div className="container">
                <div className="level">
                    <div className="level-left">
                        {searchedCustomer.current ?
                            <div className="level-item">
                                <label className="label">
                                    <FormattedMessage id={"consult_price." + rateType} />
                                </label>
                            </div>
                            :
                            <>
                                <div className="level-item">
                                    <label className="label">
                                        <FormattedMessage id="consult_price.rate_type" />
                                        <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                                        <span>:</span>
                                    </label>
                                </div>
                                <div className="level-item">
                                    <Dropdown id="rateDropdown"
                                              text={rateType ? intl.formatMessage({id: "consult_price." + rateType}) : "-----"}
                                              content={<>
                                                  <Link to="#" className="dropdown-item"
                                                        onClick={() => {
                                                            dispatch(getActiveStandardRates())
                                                            setRateType("standard_rate")
                                                            resetCustomerData()
                                                            resetData()
                                                        }}>
                                                      <FormattedMessage id={"consult_price.standard_rate"} />
                                                  </Link>
                                                  <Link to="#" className="dropdown-item"
                                                        onClick={() => {
                                                            setRateType("customer_rate")
                                                            setStandardRate("")
                                                            resetCustomerData()
                                                            resetData()
                                                        }}>
                                                      <FormattedMessage id={"consult_price.customer_rate"} />
                                                  </Link>
                                              </>}
                                    />
                                </div>
                            </>
                        }
                    </div>
                </div>
                {rateType === "customer_rate" ?
                    <>
                        <div className="level mb-1">
                            <div className="level-left">
                                <div className="level-item">
                                    <label className="label">
                                        <FormattedMessage id="consult_price.customer" />
                                        <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                                        <span>:</span>
                                    </label>
                                </div>
                                {customer ?
                                    <div className="level-item">
                                        <Tag text={customer.name + " (" + customer.type + ")"}
                                             handler={(e) => removeCustomer(e)} />
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className={`level ${customer ? 'mb-1' : ''}`}>
                            <div className="level-left">
                                <div className="level-item">
                                    <div className="field has-addons">
                                        <Search placeholder={intl.formatMessage({id: "placeholder.search_customer"})}
                                                value={searchCustomer}
                                                inputChange={(e) => {
                                                    setSearchCustomer(e.target.value)
                                                    dispatch({type: actions.RESET_CUSTOMERS})
                                                }}
                                                autofocus={!searchedCustomer.current}
                                                maxLength={58}
                                                content={customers}
                                                dataClick={changeCustomerData}
                                                dataText={dataText}
                                                isLoading={isGettingCustomers}
                                                buttonClick={(e) => getCustomersData(e)}
                                                buttonText={intl.formatMessage({id: "general.search" +
                                                        (searchCustomer.trim() === "" ? "_all" : "")})}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {customer ?
                            <>
                                <div className="level mb-1">
                                    <div className="level-left">
                                        <div className="level-item">
                                            <label className="label">
                                                <FormattedMessage id="consult_price.manufacturer" />:
                                            </label>
                                        </div>
                                        {manufacturer ?
                                            <div className="level-item">
                                                <Tag text={manufacturer.name} handler={(e) => removeManufacturer(e)} />
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                                <div className={`level ${manufacturer ? 'mb-1' : ''}`}>
                                    <div className="level-left">
                                        <div className="level-item">
                                            <div className="field has-addons">
                                                <Search placeholder={intl.formatMessage({id: "placeholder.search_manufacturer"})}
                                                        value={searchManufacturer}
                                                        inputChange={(e) => {
                                                            setSearchManufacturer(e.target.value)
                                                            dispatch({type: actions.RESET_MANUFACTURERS})
                                                        }}
                                                        autofocus={!!searchedCustomer.current}
                                                        maxLength={110}
                                                        content={manufacturers}
                                                        dataClick={changeManufacturerData}
                                                        dataText={dataText}
                                                        isLoading={isGettingManufacturers}
                                                        buttonClick={(e) => getManufacturersData(e)}
                                                        buttonText={intl.formatMessage({id: "general.search" + (searchManufacturer.trim() === "" ? "_all" : "")})}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {manufacturer ?
                                    <>
                                        <div className="level mb-1">
                                            <div className="level-left">
                                                <div className="level-item">
                                                    <label className="label">
                                                        <FormattedMessage id="consult_price.project" />:
                                                    </label>
                                                </div>
                                                {project ?
                                                    <div className="level-item">
                                                        <Tag text={project.name} handler={(e) => removeProject(e)} />
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                        <div className="level">
                                            <div className="level-left">
                                                <div className="level-item">
                                                    <div className="field has-addons">
                                                        <Search placeholder={intl.formatMessage({id: "placeholder.search_project"})}
                                                                value={searchProject}
                                                                inputChange={(e) => {
                                                                    setSearchProject(e.target.value)
                                                                    dispatch({type: actions.RESET_PROJECTS})
                                                                }}
                                                                maxLength={47}
                                                                content={projects}
                                                                dataClick={changeProjectData}
                                                                dataText={dataText}
                                                                isLoading={isGettingProjects}
                                                                buttonClick={(e) => getProjectsData(e)}
                                                                buttonText={intl.formatMessage({id: "general.search" + (searchProject.trim() === "" ? "_all" : "")})}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    null
                                }
                                <div className="level">
                                    <div className="level-left">
                                        <div className="level-item">
                                            <label className="label">
                                                <FormattedMessage id="consult_price.rate" />
                                                <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                                                <span>:</span>
                                            </label>
                                        </div>
                                        <div className="level-item">
                                            <Dropdown id="customerRateDropdown"
                                                      text={customerRate ? customerRate.name : "-----"}
                                                      isLoading={isGettingCustomerRates}
                                                      content={<>
                                                          {customerRates.length > 0 ?
                                                              <Link to="#" className="dropdown-item"
                                                                    onClick={() => {
                                                                        setCustomerRate("")
                                                                        resetData()
                                                                    }}>
                                                                  -----
                                                              </Link>
                                                              :
                                                              null
                                                          }
                                                          {customerRates?.map((customerRate, i) => (
                                                              <Link to="#" className="dropdown-item" key={i}
                                                                    onClick={() => resetCustomerRate(customerRate)}>
                                                                  {customerRate.name}
                                                              </Link>
                                                          ))}
                                                      </>}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            null
                        }
                    </>
                    :
                    null
                }
                {rateType === "standard_rate" ?
                    <>
                        {searchedCustomer.current ?
                            <div className="level">
                                <div className="level-left">
                                    <div className="level-item">
                                        <label className="label">
                                            <FormattedMessage id="consult_price.customer" />:
                                        </label>
                                    </div>
                                    <div className="level-item">
                                        <Tag text={searchedCustomer.current.name + " (" + searchedCustomer.current.type + ")"}
                                             buttonDelete={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        <div className="level">
                            <div className="level-left">
                                <div className="level-item">
                                    <label className="label">
                                        <FormattedMessage id="consult_price.currency" />
                                        <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                                        <span>:</span>
                                    </label>
                                </div>
                                <div className="level-item">
                                    <Dropdown id="standardRateDropdown"
                                              text={standardRate ? standardRate.currency_name : "-----"}
                                              isLoading={isGettingActiveStandardRates}
                                              content={<>
                                                  {activeStandardRates.length > 0 ?
                                                      <Link to="#" className="dropdown-item"
                                                            onClick={() => {
                                                                setStandardRate("")
                                                                resetData()
                                                            }}>
                                                          -----
                                                      </Link>
                                                      :
                                                      null
                                                  }
                                                  {activeStandardRates?.map((standardRate, i) => (
                                                      <Link to="#" className="dropdown-item" key={i}
                                                            onClick={() => resetStandardRate(standardRate)}>
                                                          {standardRate.currency_name}
                                                      </Link>
                                                  ))}
                                              </>}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    null
                }
                <hr className="dropdown-divider" />
                {standardRate || customerRate ?
                    <>
                        <div className="level mb-0">
                            <div className="level-left">
                                <div className="level-item">
                                    <label className="label">
                                        <FormattedMessage id="consult_price.language_pair" />:
                                    </label>
                                </div>
                                <div className="level-item">
                                    <Dropdown id="pricesDropdown"
                                              text={languagePair ? languagePair : "-----"}
                                              isLoading={isGettingPrices}
                                              inputValue={searchLanguagePair}
                                              inputChange={(e) => setSearchLanguagePair(e.target.value)}
                                              content={<>
                                                  {prices.length > 0 ?
                                                      <Link to="#" className="dropdown-item"
                                                            onClick={() => {
                                                                resetData()
                                                            }}>
                                                          -----
                                                      </Link>
                                                      :
                                                      null
                                                  }
                                                  {filterPrices()?.map((price, i) => (
                                                      <Link to="#" className="dropdown-item" key={i}
                                                            onClick={() => resetLanguagePairPrice(price, createTextLanguagePair(price))}>
                                                          {createTextLanguagePair(price)}
                                                      </Link>
                                                  ))}
                                              </>}
                                    />
                                </div>
                                <div className="level-item">
                                    <button className="button is-link is-small"
                                            onClick={(e) => showSelectTags(e)}>
                                        <FormattedMessage id="consult_price.select_tags" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        {values.length > 0 ?
                            <div className="level">
                                <div className="level-left">
                                    <div className="level-item">
                                        <label className="label">
                                            <FormattedMessage id="consult_price.tags" />:
                                        </label>
                                    </div>
                                    <div className="level-item">
                                        <div className="container">
                                            {values?.map((value, i) => (
                                                <Tag key={i} text={value.tag_name + (value.unit_id ? " (" + value.unit_type + ")" : "")}
                                                     title={value.percent !== null ? value.percent + "%" + (value.reference_tag ?
                                                         intl.formatMessage({id: "datatable_tag.reference"}) + value.reference_tag_name :
                                                         intl.formatMessage({id: "datatable_tag.reference_base"})) : null}
                                                     handler={(e) => removeTag(e, i)}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        {price || (values[0] && values[0].percent === null) ?
                            <>
                                <div className="level">
                                    <div className="level-left">
                                        <div className="level-item">
                                            <label className="label" id="priceLabel">
                                                <FormattedMessage id="consult_price.price" />: {showCalculatedPrice()}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {(price && price.unit_id === UNIT_WORD_ID) ||
                                (values[0] && values[0].price !== null && values[0].unit_id === UNIT_WORD_ID) ?
                                    <>
                                        <div className="level">
                                            <div className="level-left">
                                                <div className="level-item">
                                                    <label className="label">
                                                        <FormattedMessage id="consult_price.cat_tool_breaks" />:
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <CatToolBreaksTable />
                                    </>
                                    :
                                    null
                                }
                            </>
                            :
                            null
                        }
                    </>
                    :
                    null
                }
            </div>
        </>
    )
}
