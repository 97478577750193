import {axiosInstance} from '../../../hoc/Axios/Axios'
import * as actions from '../../Actions'
import * as toastTypes from '../../../consts/enums/toastTypes'
import {encodeParameter} from '../../../utils/encodeParameter'

export const getCurrencies = () => async (dispatch, getState) => {
    dispatch({type: actions.RESET_CURRENCIES})
    dispatch({type: actions.GETTING_CURRENCIES})
    try {
        const res = await axiosInstance.get("/currencies")
        dispatch({type: actions.GET_CURRENCIES, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.currencies_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.CURRENCY_ERROR, payload: error.response})
    }
}

export const getCurrenciesIdcp = (currency) => async (dispatch, getState) => {
    dispatch({type: actions.GETTING_CURRENCIES_IDCP})
    try {
        currency = encodeParameter(currency)
        const res = await axiosInstance.get("/currencies-idcp/" + currency)
        dispatch({type: actions.GET_CURRENCIES_IDCP, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.currency_does_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.CURRENCY_ERROR, payload: error.response})
    }
}
