import {axiosInstance} from '../../../hoc/Axios/Axios'
import * as actions from '../../Actions'
import * as toastTypes from '../../../consts/enums/toastTypes'
import {encodeParameter} from '../../../utils/encodeParameter'

export const getStandardRates = (sort = "rates.created_at", order = "desc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_STANDARD_RATES})
    dispatch({type: actions.GETTING_STANDARD_RATES})
    try {
        const url = "/rates/standard-rates?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_STANDARD_RATES, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_STANDARD_RATES})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.standard_rates_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.RATE_ERROR, payload: error.response})
    }
}

export const getStandardRatesByCreationDate = (creationDate, sort = "rates.created_at", order = "desc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_STANDARD_RATES})
    dispatch({type: actions.GETTING_STANDARD_RATES})
    try {
        const url = "/rates/standard-rates/by-creation-date/" + creationDate + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_STANDARD_RATES, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_STANDARD_RATES})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.standard_rates_by_creation_date_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.RATE_ERROR, payload: error.response})
    }
}

export const getStandardRatesByCurrency = (currencyId, sort = "rates.created_at", order = "desc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_STANDARD_RATES})
    dispatch({type: actions.GETTING_STANDARD_RATES})
    try {
        const url = "/rates/standard-rates/by-currency/" + currencyId + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_STANDARD_RATES, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_STANDARD_RATES})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.standard_rates_by_currency_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.RATE_ERROR, payload: error.response})
    }
}

export const getStandardRatesByCreator = (creator, sort = "rates.created_at", order = "desc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_STANDARD_RATES})
    dispatch({type: actions.GETTING_STANDARD_RATES})
    try {
        creator = encodeParameter(creator)
        const url = "/rates/standard-rates/by-creator/" + creator + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_STANDARD_RATES, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_STANDARD_RATES})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.standard_rates_by_creator_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.RATE_ERROR, payload: error.response})
    }
}

export const getStandardRatesByStatus = (status, sort = "rates.created_at", order = "desc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_STANDARD_RATES})
    dispatch({type: actions.GETTING_STANDARD_RATES})
    try {
        const url = "/rates/standard-rates/by-status/" + (status ? 1 : 0) + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_STANDARD_RATES, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_STANDARD_RATES})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.standard_rates_by_status_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.RATE_ERROR, payload: error.response})
    }
}

export const getActiveStandardRates = () => async (dispatch, getState) => {
    dispatch({type: actions.RESET_ACTIVE_STANDARD_RATES})
    dispatch({type: actions.GETTING_ACTIVE_STANDARD_RATES})
    try {
        const res = await axiosInstance.get("/rates/active-standard-rates")
        dispatch({type: actions.GET_ACTIVE_STANDARD_RATES, payload: res.data})
    } catch (error) {
        dispatch({type: actions.RATE_ERROR, payload: error.response})
    }
}

export const storeStandardRate = (standardRate) => async (dispatch, getState) => {
    try {
        await axiosInstance.post("/rates/standard-rates/store", standardRate)
        dispatch({type: actions.STANDARD_RATE_CREATED, payload: {status: true}})
    } catch (error) {
        dispatch({type: actions.RATE_ERROR, payload: error.response})
    }
}

export const activateStandardRate = (id, currency_id) => async (dispatch, getState) => {
    try {
        await axiosInstance.put("/rates/standard-rates/" + id + "/" + currency_id + "/active")
        dispatch({type: actions.STANDARD_RATE_ACTIVATED, payload: {status: true}})
    } catch (error) {
        dispatch({type: actions.RATE_ERROR, payload: error.response})
    }
}
