import {useEffect, useRef, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {disableUser, getUsers, getUsersByRole, getUsersByUsername, updateUser} from '../store/reducers/user/userActions'
import * as actions from '../store/Actions'
import {Datatable} from '../components/Datatable/Datatable'
import {Title} from '../components/UI/Title'
import {Search} from '../components/UI/Search'
import {Dropdown} from '../components/UI/Dropdown'
import {TableIconButtonEdit} from '../components/UI/TableIconButtonEdit'
import {TableIconButtonDelete} from '../components/UI/TableIconButtonDelete'
import {Pagination} from '../components/UI/Pagination'
import * as modalTypes from '../consts/enums/modalTypes'
import * as toastTypes from '../consts/enums/toastTypes'
import {PAGINATION_PAGE_ROWS, USER_ROLES} from '../consts/app'

export const Users = () => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const loggedUser = useSelector(state => state.auth.user)
    const users = useSelector(state => state.user.users)
    const isGettingUsers = useSelector(state => state.user.isGettingUsers)
    const pagination = useSelector(state => state.user.pagination)
    const hasUserBeenCreated = useSelector(state => state.user.userCreated)
    const hasUserBeenUpdated = useSelector(state => state.user.userUpdated)
    const hasUserBeenDeleted = useSelector(state => state.user.userDeleted)
    const error = useSelector(state => state.user.error)
    const [userFilter, setUserFilter] = useState("all")
    const [nameFilter, setNameFilter] = useState("")
    const [roleFilter, setRoleFilter] = useState("")
    const searchType = useRef({type: "all", search: ""})
    const sortType = useRef({sort: "username", order: "asc"})

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (hasUserBeenCreated) {
            getData()
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.SUCCESS, message: "message.user_created"}})
            dispatch({type: actions.USER_CREATED, payload: {status: false}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasUserBeenCreated])

    useEffect(() => {
        if (hasUserBeenUpdated) {
            getData()
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.SUCCESS, message: "message.user_updated"}})
            dispatch({type: actions.USER_UPDATED, payload: {status: false}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasUserBeenUpdated])

    useEffect(() => {
        if (hasUserBeenDeleted) {
            getData()
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.SUCCESS, message: "message.user_deleted"}})
            dispatch({type: actions.USER_DELETED, payload: {status: false}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasUserBeenDeleted])

    useEffect(() => {
        if (error) {
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.RESET_USER_ERROR})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    const getData = () => {
        setUserFilter("all")
        searchType.current = {type: "all", search: ""}
        dispatch(getUsers(sortType.current.sort, sortType.current.order, 1))
    }

    const resetData = () => {
        setNameFilter("")
        setRoleFilter("")
    }

    const filterUsersByName = (e) => {
        e.preventDefault()
        searchType.current = {type: "name", search: nameFilter}
        dispatch(getUsersByUsername(nameFilter, sortType.current.sort, sortType.current.order, 1))
    }

    const filterUsersByRole = (role) => {
        searchType.current = {type: "role", search: role}
        dispatch(getUsersByRole(role, sortType.current.sort, sortType.current.order, 1))
    }

    const sortUsers = (column, sortDirection) => {
        sortType.current = {sort: column.sortField, order: sortDirection}
        getUsersPerPage(1)
    }

    const getUsersPerPage = (page) => {
        if (searchType.current.type === "all") {
            dispatch(getUsers(sortType.current.sort, sortType.current.order, page))
        } else if (searchType.current.type === "role") {
            dispatch(getUsersByRole(searchType.current.search, sortType.current.sort, sortType.current.order, page))
        } else {
            dispatch(getUsersByUsername(searchType.current.search, sortType.current.sort, sortType.current.order, page))
        }
    }

    const editUser = (user) => {
        dispatch(updateUser(user))
        dispatch({type: actions.HIDE_MODAL})
        dispatch({type: actions.SHOW_MODAL, payload: {type:modalTypes.LOADING}})
    }

    const deleteUser = (id) => {
        dispatch(disableUser(id))
        dispatch({type: actions.HIDE_MODAL})
        dispatch({type: actions.SHOW_MODAL, payload: {type:modalTypes.LOADING}})
    }

    const showCreateUser = (e) => {
        e.preventDefault()
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                type: modalTypes.NEW_USER,
                title: intl.formatMessage({id: "message_title.new_user"})
            }
        })
    }

    const showEditUser = (e, user) => {
        e.preventDefault()
        e.stopPropagation()
        if (user.id === loggedUser.id) {
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.cannot_edit_current_user"}})
        } else {
            dispatch({
                type: actions.SHOW_MODAL, payload: {
                    type: modalTypes.EDIT_USER,
                    title: intl.formatMessage({id: "message_title.edit_user"}),
                    additionalData: {
                        user: user,
                        editUser: editUser
                    }
                }
            })
        }
    }

    const showDeleteConfirmation = (e, id) => {
        e.preventDefault()
        e.stopPropagation()
        if (id === loggedUser.id) {
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.cannot_delete_current_user"}})
        } else {
            dispatch({
                type: actions.SHOW_MODAL, payload: {
                    buttons: [
                        {
                            label: intl.formatMessage({id: "general.delete"}),
                            classes: 'button is-link',
                            handler: () => {
                                deleteUser(id)
                            }
                        }
                    ]
                }
            })
        }
    }

    const UsersTable = () => {
        const columns = [
            {
                name: intl.formatMessage({id: "user.username"}),
                selector: row => row.username,
                wrap: true,
                sortable: true,
                sortField: 'username'
            },
            {
                name: intl.formatMessage({id: "user.name"}),
                selector: row => row.name,
                wrap: true,
                sortable: true,
                sortField: 'name'
            },
            {
                name: intl.formatMessage({id: "user.role"}),
                selector: row => intl.formatMessage({id: "role." + row.role}),
                wrap: true,
                sortable: true,
                sortField: 'role'
            },
            {
                selector: row => (
                    <>
                        <TableIconButtonEdit handler={(e) => showEditUser(e, row)} />
                        <TableIconButtonDelete handler={(e) => showDeleteConfirmation(e, row.id)} />
                    </>
                ),
                right: true,
                wrap: true,
                grow: 0.1
            }
        ]
        return (
            <Datatable
                columns={columns}
                data={users}
                defaultSortAsc={sortType.current.order !== "asc"}
                onSort={sortUsers}
                paginationComponent={() => <Pagination pagination={pagination} handler={getUsersPerPage} />}
                paginationPerPage={pagination?.per_page ?? PAGINATION_PAGE_ROWS}
                progressPending={isGettingUsers}
                sortServer
            />
        )
    }

    return (
        <>
            <Title title={intl.formatMessage({id: "title.users"})} />
            <div className="container">
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <button className="button is-link"
                                    onClick={(e) => showCreateUser(e)}>
                                <FormattedMessage id="user.new_user" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <label className="label">
                                <FormattedMessage id="general.filter_by" />:
                            </label>
                        </div>
                        <div className="level-item mr-0">
                            <Dropdown id="userFilterDropdown"
                                      text={userFilter ? intl.formatMessage({id: "user." + userFilter}) : ""}
                                      content={<>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    resetData()
                                                    getData()
                                                }}>
                                              <FormattedMessage id={"user.all"} />
                                          </Link>
                                          <hr className="dropdown-divider" />
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setUserFilter("name")
                                                    resetData()
                                                }}>
                                              <FormattedMessage id={"user.name"} />
                                          </Link>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setUserFilter("role")
                                                    resetData()
                                                }}>
                                              <FormattedMessage id={"user.role"} />
                                          </Link>
                                      </>}
                            />
                        </div>
                        <div className="level-item">
                            <div className="field has-addons">
                                {userFilter === "name" ?
                                    <Search size={"normal"}
                                            placeholder={intl.formatMessage({id: "placeholder.filter_by_name"})}
                                            value={nameFilter}
                                            inputChange={(e) => setNameFilter(e.target.value)}
                                            maxLength={47}
                                            isLoading={(isGettingUsers && nameFilter.trim() !== "")}
                                            disabledButton={nameFilter.trim() === ""}
                                            buttonClick={(e) => filterUsersByName(e)}
                                            buttonText={intl.formatMessage({id: "general.search"})}
                                    />
                                    :
                                    userFilter === "role" ?
                                        <Dropdown id="rolesDropdown"
                                                  text={roleFilter ? intl.formatMessage({id: "role." + roleFilter}) : "-----"}
                                                  content={<>
                                                      {USER_ROLES?.map((role, i) => (
                                                          <Link to="#" className="dropdown-item" key={i}
                                                                onClick={() => {
                                                                    setRoleFilter(role)
                                                                    filterUsersByRole(role)
                                                                }}>
                                                              <FormattedMessage id={"role." + role}/>
                                                          </Link>
                                                      ))}
                                                  </>}
                                        />
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <UsersTable />
            </div>
        </>
    )
}
