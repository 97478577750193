import {useEffect, useRef, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import * as actions from '../store/Actions'
import {deletePrice, getPrices, getPricesByLanguage, getPricesByPrice, getPricesByUnit, updatePrice}
    from '../store/reducers/price/priceActions'
import {getActiveStandardRates} from '../store/reducers/rate/rateActions'
import {getUnits} from '../store/reducers/unit/unitActions'
import {Datatable} from '../components/Datatable/Datatable'
import {Title} from '../components/UI/Title'
import {Dropdown} from '../components/UI/Dropdown'
import {Search} from '../components/UI/Search'
import {TableIconButtonEdit} from '../components/UI/TableIconButtonEdit'
import {TableIconButtonDelete} from '../components/UI/TableIconButtonDelete'
import {Pagination} from '../components/UI/Pagination'
import * as modalTypes from '../consts/enums/modalTypes'
import * as toastTypes from '../consts/enums/toastTypes'
import {PAGINATION_PAGE_ROWS, STANDARD_CURRENCY_CODE} from '../consts/app'
import {createLocaleDateText} from '../utils/createLocaleDateText'

export const LanguagePairs = () => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const prices = useSelector(state => state.price.prices)
    const isGettingPrices = useSelector(state => state.price.isGettingPrices)
    const pagination = useSelector(state => state.price.pagination)
    const hasPriceBeenCreated = useSelector(state => state.price.priceCreated)
    const hasPriceBeenUpdated = useSelector(state => state.price.priceUpdated)
    const hasPriceBeenDeleted = useSelector(state => state.price.priceDeleted)
    const priceError = useSelector(state => state.price.error)
    const languagePairCreated = useSelector(state => state.languagePair.languagePairCreated)
    const isCreatingLanguagePair = useSelector(state => state.languagePair.isCreatingLanguagePair)
    const languagePairError = useSelector(state => state.languagePair.error)
    const activeStandardRates = useSelector(state => state.rate.activeStandardRates)
    const isGettingActiveStandardRates = useSelector(state => state.rate.isGettingActiveStandardRates)
    const units = useSelector(state => state.unit.units)
    const isGettingUnits = useSelector(state => state.unit.isGettingUnits)
    const locale = useSelector(state => state.locale.locale)
    const [activeStandardRate, setActiveStandardRate] = useState(null)
    const [languagePairFilter, setLanguagePairFilter] = useState("all")
    const [languageFilter, setLanguageFilter] = useState("")
    const [unitFilter, setUnitFilter] = useState("")
    const [minFilter, setMinFilter] = useState("")
    const [maxFilter, setMaxFilter] = useState("")
    const firstRender = useRef(true)
    const searchType = useRef({type: "all", search: {param1: "", param2: ""}})
    const sortType = useRef({sort: "translations.source_language", order: "asc"})

    useEffect(() => {
        dispatch(getActiveStandardRates())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (hasPriceBeenCreated) {
            getData()
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.SUCCESS, message: "message.price_created"}})
            dispatch({type: actions.PRICE_CREATED, payload: {status: false}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasPriceBeenCreated])

    useEffect(() => {
        if (hasPriceBeenUpdated) {
            getData()
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.SUCCESS, message: "message.price_updated"}})
            dispatch({type: actions.PRICE_UPDATED, payload: {status: false}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasPriceBeenUpdated])

    useEffect(() => {
        if (hasPriceBeenDeleted) {
            getData()
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.SUCCESS, message: "message.price_deleted"}})
            dispatch({type: actions.PRICE_DELETED, payload: {status: false}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasPriceBeenDeleted])

    useEffect(() => {
        if (priceError) {
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.RESET_PRICE_ERROR})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [priceError])

    useEffect(() => {
        if (languagePairCreated) {
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.SUCCESS, message: "message.language_pair_created"}})
            dispatch({
                type: actions.SHOW_MODAL, payload: {
                    type: modalTypes.NEW_PRICE,
                    title: intl.formatMessage({id: "message_title.new_price"}),
                    additionalData: {
                        standardRateId: activeStandardRate.id,
                        languagePairId: languagePairCreated.id
                    }
                }
            })
            dispatch({type: actions.LANGUAGE_PAIR_CREATED, payload: {data: null}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languagePairCreated])

    useEffect(() => {
        if (languagePairError) {
            if (isCreatingLanguagePair) {
                dispatch({type: actions.HIDE_MODAL})
                dispatch({
                    type: actions.SHOW_MODAL, payload: {
                        type: modalTypes.NEW_PRICE,
                        title: intl.formatMessage({id: "message_title.new_price"}),
                        additionalData: {
                            standardRateId: activeStandardRate.id
                        }
                    }
                })
                dispatch({type: actions.CREATING_LANGUAGE_PAIR, payload: {status: false}})
            }
            dispatch({type: actions.RESET_LANGUAGE_PAIR_ERROR})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languagePairError])

    useEffect(() => {
        if (!isGettingActiveStandardRates && !firstRender.current) {
            if (activeStandardRates.length > 0) {
                setActiveStandardRate(activeStandardRates.find(standardRate => standardRate.currency_code === STANDARD_CURRENCY_CODE) ?? activeStandardRates[0])
            }
        }
        firstRender.current = false
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGettingActiveStandardRates])

    useEffect(() => {
        if (activeStandardRate) {
            getData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStandardRate])

    const getData = () => {
        setLanguagePairFilter("all")
        searchType.current = {type: "all", search: {param1: "", param2: ""}}
        dispatch(getPrices(activeStandardRate.id, sortType.current.sort, sortType.current.order, 1))
    }

    const resetStandardRate = (standardRate) => {
        setActiveStandardRate(standardRate)
        resetData()
    }

    const resetData = () => {
        setLanguageFilter("")
        setUnitFilter("")
        setMinFilter("")
        setMaxFilter("")
    }

    const filterLanguagePairsByLanguage = (e) => {
        e.preventDefault()
        searchType.current = {type: languagePairFilter, search: {param1: languageFilter, param2: ""}}
        if (languagePairFilter === "source_language") {
            dispatch(getPricesByLanguage(languageFilter, activeStandardRate.id, sortType.current.sort, sortType.current.order, 1))
        } else {
            dispatch(getPricesByLanguage(languageFilter, activeStandardRate.id, sortType.current.sort, sortType.current.order, 1, false))
        }
    }

    const filterLanguagePairsByUnit = (unit) => {
        searchType.current = {type: "unit", search: {param1: unit, param2: ""}}
        dispatch(getPricesByUnit(unit, activeStandardRate.id, sortType.current.sort, sortType.current.order, 1))
    }

    const filterLanguagePairsByPrice = (e) => {
        e.preventDefault()
        if (parseFloat(minFilter) > parseFloat(maxFilter)) {
            dispatch({type: actions.SHOW_TOAST, payload: {type:toastTypes.ERROR, message: "error.min_greater_than_max"}})
        } else {
            searchType.current = {type: "price", search: {param1: minFilter, param2: maxFilter}}
            dispatch(getPricesByPrice(minFilter, maxFilter, activeStandardRate.id, sortType.current.sort, sortType.current.order, 1))
        }
    }

    const sortLanguagePairs = (column, sortDirection) => {
        sortType.current = {sort: column.sortField, order: sortDirection}
        getPricesPerPage(1)
    }

    const getPricesPerPage = (page) => {
        if (searchType.current.type === "all") {
            dispatch(getPrices(activeStandardRate.id, sortType.current.sort, sortType.current.order, page))
        } else if (searchType.current.type === "source_language") {
            dispatch(getPricesByLanguage(searchType.current.search.param1, activeStandardRate.id, sortType.current.sort, sortType.current.order, page))
        } else if (searchType.current.type === "target_language") {
            dispatch(getPricesByLanguage(searchType.current.search.param1, activeStandardRate.id, sortType.current.sort, sortType.current.order, page, false))
        } else if (searchType.current.type === "unit") {
            dispatch(getPricesByUnit(searchType.current.search.param1, activeStandardRate.id, sortType.current.sort, sortType.current.order, page))
        } else {
            dispatch(getPricesByPrice(searchType.current.search.param1, searchType.current.search.param2,
                activeStandardRate.id, sortType.current.sort, sortType.current.order, page))
        }
    }

    const editPrice = (price) => {
        dispatch(updatePrice(price))
        dispatch({type: actions.HIDE_MODAL})
        dispatch({type: actions.SHOW_MODAL, payload: {type:modalTypes.LOADING}})
    }

    const deleteAPrice = (id) => {
        dispatch(deletePrice(id))
        dispatch({type: actions.HIDE_MODAL})
        dispatch({type: actions.SHOW_MODAL, payload: {type:modalTypes.LOADING}})
    }

    const showCreatePrice = (e) => {
        e.preventDefault()
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                type: modalTypes.NEW_PRICE,
                title: intl.formatMessage({id: "message_title.new_price"}),
                additionalData: {
                    standardRateId: activeStandardRate.id
                }
            }
        })
    }

    const showEditPrice = (e, price) => {
        e.preventDefault()
        e.stopPropagation()
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                type: modalTypes.EDIT_PRICE,
                title: intl.formatMessage({id: "message_title.edit_price"}),
                additionalData: {
                    price: price,
                    editPrice: editPrice
                }
            }
        })
    }

    const showDeleteConfirmation = (e, id) => {
        e.preventDefault()
        e.stopPropagation()
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                buttons: [
                    {
                        label: intl.formatMessage({id: "general.delete"}),
                        classes: 'button is-link',
                        handler: () => {
                            deleteAPrice(id)
                        }
                    }
                ]
            }
        })
    }

    const LanguagePairsTable = () => {
        const columns = [
            {
                name: intl.formatMessage({id: "language_pair.source_language"}),
                selector: row => row.translation_source_language + (row.translation_code_source_language ? ' (' + row.translation_code_source_language + ')' : ""),
                wrap: true,
                sortable: true,
                sortField: 'translations.source_language'
            },
            {
                name: intl.formatMessage({id: "language_pair.target_language"}),
                selector: row => row.translation_target_language + (row.translation_code_target_language ? ' (' + row.translation_code_target_language + ')' : ""),
                wrap: true,
                sortable: true,
                sortField: 'translations.target_language'
            },
            {
                name: intl.formatMessage({id: "language_pair.unit"}),
                selector: row => row.unit_type,
                wrap: true,
                sortable: true,
                sortField: 'units.type'
            },
            {
                name: intl.formatMessage({id: "language_pair.price"}),
                selector: row => row.total,
                wrap: true,
                sortable: true,
                sortField: 'prices.total'
            },
            {
                selector: row => (
                    <>
                        <TableIconButtonEdit handler={(e) => showEditPrice(e, row)} />
                        <TableIconButtonDelete handler={(e) => showDeleteConfirmation(e, row.id)} />
                    </>
                ),
                right: true,
                wrap: true,
                grow: 0.1
            }
        ]
        return (
            <Datatable
                columns={columns}
                data={activeStandardRate ? prices : []}
                defaultSortAsc={sortType.current.order !== "asc"}
                onSort={sortLanguagePairs}
                paginationComponent={() =>
                    <Pagination pagination={pagination} handler={getPricesPerPage}
                                additionalText={activeStandardRate ?
                                    intl.formatMessage({id: "datatable.of_standard_rate"}) +
                                    createLocaleDateText(locale.name, activeStandardRate.created_at.substring(0, 10)) +
                                    intl.formatMessage({id: "datatable.by_user"}) +
                                    activeStandardRate.user_username
                                    : null}
                    />}
                paginationPerPage={pagination?.per_page ?? PAGINATION_PAGE_ROWS}
                progressPending={isGettingPrices || isGettingActiveStandardRates}
                sortServer
            />
        )
    }

    return (
        <>
            <Title title={intl.formatMessage({id: "title.language_pairs"})} />
            <div className="container">
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <label className="label">
                                <FormattedMessage id="language_pair.standard_rate" />
                                <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                                <span>:</span>
                            </label>
                        </div>
                        <div className="level-item">
                            <Dropdown id="standardRateDropdown"
                                      text={activeStandardRate ? activeStandardRate.currency_name : "-----"}
                                      isLoading={isGettingActiveStandardRates}
                                      content={<>
                                          {activeStandardRates.length > 0 ?
                                              <Link to="#" className="dropdown-item"
                                                    onClick={() => resetStandardRate("")}>
                                                  -----
                                              </Link>
                                              :
                                              null
                                          }
                                          {activeStandardRates?.map((standardRate, i) => (
                                              <Link to="#" className="dropdown-item" key={i}
                                                    onClick={() => resetStandardRate(standardRate)}>
                                                  {standardRate.currency_name}
                                              </Link>
                                          ))}
                                      </>}
                            />
                        </div>
                    </div>
                </div>
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <button className="button is-link"
                                    disabled={!activeStandardRate}
                                    onClick={(e) => showCreatePrice(e)}>
                                <FormattedMessage id="language_pair.new_price" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <label className="label">
                                <FormattedMessage id="general.filter_by" />:
                            </label>
                        </div>
                        <div className="level-item mr-0">
                            <Dropdown id="languagePairFilterDropdown"
                                      text={languagePairFilter ? intl.formatMessage({id: "language_pair." + languagePairFilter}) : ""}
                                      disabled={!activeStandardRate}
                                      content={<>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    resetData()
                                                    getData()
                                                }}>
                                              <FormattedMessage id={"language_pair.all"} />
                                          </Link>
                                          <hr className="dropdown-divider" />
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setLanguagePairFilter("source_language")
                                                    resetData()
                                                }}>
                                              <FormattedMessage id={"language_pair.source_language"} />
                                          </Link>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setLanguagePairFilter("target_language")
                                                    resetData()
                                                }}>
                                              <FormattedMessage id={"language_pair.target_language"} />
                                          </Link>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setLanguagePairFilter("unit")
                                                    resetData()
                                                    dispatch(getUnits())
                                                }}>
                                              <FormattedMessage id={"language_pair.unit"} />
                                          </Link>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setLanguagePairFilter("price")
                                                    resetData()
                                                }}>
                                              <FormattedMessage id={"language_pair.price"} />
                                          </Link>
                                      </>}
                            />
                        </div>
                        <div className="level-item">
                            <div className="field has-addons">
                                {languagePairFilter === "source_language" || languagePairFilter === "target_language" ?
                                    <Search size={"normal"}
                                            placeholder={intl.formatMessage({id: "placeholder.filter_by_" + languagePairFilter})}
                                            value={languageFilter}
                                            inputChange={(e) => setLanguageFilter(e.target.value)}
                                            maxLength={24}
                                            isLoading={(isGettingPrices && languageFilter.trim() !== "")}
                                            disabledButton={languageFilter.trim() === ""}
                                            buttonClick={(e) => filterLanguagePairsByLanguage(e)}
                                            buttonText={intl.formatMessage({id: "general.search"})}
                                    />
                                    :
                                    languagePairFilter === "unit" ?
                                        <Dropdown id="languagePairUnitsDropdown"
                                                  text={unitFilter ? unitFilter : "-----"}
                                                  isLoading={isGettingUnits}
                                                  content={<>
                                                      {units?.map((unit, i) => (
                                                          <Link to="#" className="dropdown-item" key={i}
                                                                onClick={() => {
                                                                    setUnitFilter(unit.type)
                                                                    filterLanguagePairsByUnit(unit.type)
                                                                }}>
                                                              {unit.type}
                                                          </Link>
                                                      ))}
                                                  </>}
                                        />
                                        :
                                        languagePairFilter === "price" ?
                                            <>
                                                <div className="control">
                                                    <input className="input"
                                                           type="number"
                                                           placeholder={intl.formatMessage({id: "placeholder.min"})}
                                                           value={minFilter}
                                                           onChange={(e) => setMinFilter(e.target.value)}
                                                           min={0}
                                                           max={9999999}
                                                           step={0.1}
                                                           onWheel={(e) => e.target.blur()}
                                                    />
                                                </div>
                                                <div className="control">
                                                    <button className="button is-static" tabIndex="-1">-</button>
                                                </div>
                                                <div className="control">
                                                    <input className="input"
                                                           type="number"
                                                           placeholder={intl.formatMessage({id: "placeholder.max"})}
                                                           value={maxFilter}
                                                           onChange={(e) => setMaxFilter(e.target.value)}
                                                           min={0}
                                                           max={9999999}
                                                           step={0.1}
                                                           onWheel={(e) => e.target.blur()}
                                                    />
                                                </div>
                                                <button className={`button is-link ${isGettingPrices &&
                                                minFilter.trim() !== "" && maxFilter.trim() !== "" ? 'is-loading' : ''}`}
                                                        disabled={minFilter.trim() === "" || maxFilter.trim() === ""}
                                                        onClick={(e) => filterLanguagePairsByPrice(e)}>
                                                    <FormattedMessage id="general.search" />
                                                </button>
                                            </>
                                            :
                                            null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <LanguagePairsTable />
            </div>
        </>
    )
}
