import {useState} from 'react'
import {PAGINATION_PAGE_ROWS} from '../consts/app'

export const usePagination = () => {
    const [pagination, setPagination] = useState(null)

    const changePagination = (array, page) => {
        let pagination = null
        if (array.length > 0) {
            const links = []
            links.push({
                url: page > 1
            })
            let countLinks = 1
            for (let i = 0; i < array.length; i = i + PAGINATION_PAGE_ROWS) {
                links.push({
                    label: countLinks.toString(),
                    active: countLinks === page
                })
                countLinks++
            }
            links.push({
                url: page < links.length - 1
            })
            pagination = {
                "current_page": page,
                "links": links,
                "per_page": PAGINATION_PAGE_ROWS,
                "to": array.length < page * PAGINATION_PAGE_ROWS ? array.length : page * PAGINATION_PAGE_ROWS,
                "total": array.length
            }
        }
        setPagination(pagination)
    }

    const updatePagination = (array) => {
        let newPagination = null
        if (array.length > 0) {
            const links = []
            links.push(pagination.links[0])
            let countLinks = 1
            for (let i = 0; i < array.length; i = i + PAGINATION_PAGE_ROWS) {
                links.push(pagination.links[countLinks])
                countLinks++
            }
            links[links.length - 1].active = links.find(link => link.active) ? links[links.length - 1].active : true
            const currentPage = pagination.current_page < links.length ? pagination.current_page : pagination.current_page - 1
            links.push({url: currentPage < links.length - 1})
            newPagination = {
                "current_page": currentPage,
                "links": links,
                "per_page": PAGINATION_PAGE_ROWS,
                "to": array.length < currentPage * PAGINATION_PAGE_ROWS ? array.length : currentPage * PAGINATION_PAGE_ROWS,
                "total": array.length
            }
        }
        setPagination(newPagination)
    }

    return [
        pagination,
        changePagination,
        updatePagination
    ]
}
