import {useEffect, useRef, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import * as actions from '../store/Actions'
import {deleteExchangeRate, getExchangeRates, getExchangeRatesByExchangeRate, getExchangeRatesByName, updateExchangeRate} from '../store/reducers/exchangeRate/exchangeRateActions'
import {Datatable} from '../components/Datatable/Datatable'
import {Title} from '../components/UI/Title'
import {Dropdown} from '../components/UI/Dropdown'
import {Search} from '../components/UI/Search'
import {TableIconButtonEdit} from '../components/UI/TableIconButtonEdit'
import {TableIconButtonDelete} from '../components/UI/TableIconButtonDelete'
import {Pagination} from '../components/UI/Pagination'
import * as modalTypes from '../consts/enums/modalTypes'
import * as toastTypes from '../consts/enums/toastTypes'
import {PAGINATION_PAGE_ROWS, STANDARD_CURRENCY_CODE} from '../consts/app'
import {createLocaleDateText} from '../utils/createLocaleDateText'

export const Currencies = () => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const exchangeRates = useSelector(state => state.exchangeRate.exchangeRates)
    const isGettingExchangeRates = useSelector(state => state.exchangeRate.isGettingExchangeRates)
    const pagination = useSelector(state => state.exchangeRate.pagination)
    const hasExchangeRateBeenCreated = useSelector(state => state.exchangeRate.exchangeRateCreated)
    const hasExchangeRateBeenUpdated = useSelector(state => state.exchangeRate.exchangeRateUpdated)
    const hasExchangeRateBeenDeleted = useSelector(state => state.exchangeRate.exchangeRateDeleted)
    const exchangeRateError = useSelector(state => state.exchangeRate.error)
    const locale = useSelector(state => state.locale.locale)
    const [currencyFilter, setCurrencyFilter] = useState("all")
    const [nameFilter, setNameFilter] = useState("")
    const [minFilter, setMinFilter] = useState("")
    const [maxFilter, setMaxFilter] = useState("")
    const searchType = useRef({type: "all", search: {param1: "", param2: ""}})
    const sortType = useRef({sort: "currencies.name", order: "asc"})

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (hasExchangeRateBeenCreated) {
            getData()
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.SUCCESS, message: "message.currency_created"}})
            dispatch({type: actions.EXCHANGE_RATE_CREATED, payload: {status: false}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasExchangeRateBeenCreated])

    useEffect(() => {
        if (hasExchangeRateBeenUpdated) {
            getData()
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.SUCCESS, message: "message.currency_updated"}})
            dispatch({type: actions.EXCHANGE_RATE_UPDATED, payload: {status: false}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasExchangeRateBeenUpdated])

    useEffect(() => {
        if (hasExchangeRateBeenDeleted) {
            getData()
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.SUCCESS, message: "message.currency_deleted"}})
            dispatch({type: actions.EXCHANGE_RATE_DELETED, payload: {status: false}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasExchangeRateBeenDeleted])

    useEffect(() => {
        if (exchangeRateError) {
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.RESET_EXCHANGE_RATE_ERROR})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exchangeRateError])

    const getData = () => {
        setCurrencyFilter("all")
        searchType.current = {type: "all", search: {param1: "", param2: ""}}
        dispatch(getExchangeRates(sortType.current.sort, sortType.current.order, 1))
    }

    const resetData = () => {
        setNameFilter("")
        setMinFilter("")
        setMaxFilter("")
    }

    const filterCurrenciesByName = (e) => {
        e.preventDefault()
        searchType.current = {type: "name", search: {param1: nameFilter, param2: ""}}
        dispatch(getExchangeRatesByName(nameFilter, sortType.current.sort, sortType.current.order, 1))
    }

    const filterCurrenciesByExchangeRate = (e) => {
        e.preventDefault()
        if (parseFloat(minFilter) > parseFloat(maxFilter)) {
            dispatch({type: actions.SHOW_TOAST, payload: {type:toastTypes.ERROR, message: "error.min_greater_than_max"}})
        } else {
            searchType.current = {type: currencyFilter, search: {param1: minFilter, param2: maxFilter}}
            dispatch(getExchangeRatesByExchangeRate(minFilter, maxFilter, currencyFilter, sortType.current.sort, sortType.current.order, 1))
        }
    }

    const sortCurrencies = (column, sortDirection) => {
        sortType.current = {sort: column.sortField, order: sortDirection}
        getExchangeRatesPerPage(1)
    }

    const getExchangeRatesPerPage = (page) => {
        if (searchType.current.type === "all") {
            dispatch(getExchangeRates(sortType.current.sort, sortType.current.order, page))
        } else if (searchType.current.type === "name") {
            dispatch(getExchangeRatesByName(searchType.current.search.param1, sortType.current.sort, sortType.current.order, page))
        } else {
            dispatch(getExchangeRatesByExchangeRate(searchType.current.search.param1, searchType.current.search.param2,
                searchType.current.type, sortType.current.sort, sortType.current.order, page))
        }
    }

    const editExchangeRate = (exchangeRate) => {
        dispatch(updateExchangeRate(exchangeRate))
        dispatch({type: actions.HIDE_MODAL})
        dispatch({type: actions.SHOW_MODAL, payload: {type:modalTypes.LOADING}})
    }

    const deleteAExchangeRate = (id) => {
        dispatch(deleteExchangeRate(id))
        dispatch({type: actions.HIDE_MODAL})
        dispatch({type: actions.SHOW_MODAL, payload: {type:modalTypes.LOADING}})
    }

    const isStandardCurrency = (exchangeRate) => {
        return exchangeRate.currency_code === STANDARD_CURRENCY_CODE
    }

    const showCreateCurrency = (e) => {
        e.preventDefault()
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                type: modalTypes.NEW_CURRENCY,
                title: intl.formatMessage({id: "message_title.new_currency"})
            }
        })
    }

    const showEditExchangeRate = (e, exchangeRate) => {
        e.preventDefault()
        e.stopPropagation()
        if (isStandardCurrency(exchangeRate)) {
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.cannot_edit_standard_currency"}})
        } else {
            dispatch({
                type: actions.SHOW_MODAL, payload: {
                    type: modalTypes.EDIT_CURRENCY,
                    title: intl.formatMessage({id: "message_title.edit_currency"}),
                    additionalData: {
                        exchangeRate: exchangeRate,
                        editExchangeRate: editExchangeRate
                    }
                }
            })
        }
    }

    const showDeleteConfirmation = (e, exchangeRate) => {
        e.preventDefault()
        e.stopPropagation()
        if (isStandardCurrency(exchangeRate)) {
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.cannot_delete_standard_currency"}})
        } else {
            dispatch({
                type: actions.SHOW_MODAL, payload: {
                    buttons: [
                        {
                            label: intl.formatMessage({id: "general.delete"}),
                            classes: 'button is-link',
                            handler: () => {
                                deleteAExchangeRate(exchangeRate.id)
                            }
                        }
                    ]
                }
            })
        }
    }

    const CurrenciesTable = () => {
        const columns = [
            {
                name: intl.formatMessage({id: "currency.name"}),
                selector: row => row.currency_name + (row.currency_abbreviation ? " (" + (row.currency_abbreviation) + ")" : ""),
                wrap: true,
                sortable: true,
                sortField: 'currencies.name'
            },
            {
                name: intl.formatMessage({id: "currency.exchange_rate"}),
                selector: row => row.value,
                wrap: true,
                sortable: true,
                sortField: 'exchange_rates.value'
            },
            {
                name: intl.formatMessage({id: "currency.updated_at"}),
                selector: row => createLocaleDateText(locale.name, row.updated_at.substring(0, 10)) + " - " +
                    row.updated_at.substring(11, 19),
                wrap: true,
                sortable: true,
                sortField: 'exchange_rates.updated_at'
            },
            {
                name: intl.formatMessage({id: "currency.exchange_rate_idcp"}),
                selector: row => row.currency_exchange_rate_idcp,
                wrap: true,
                sortable: true,
                sortField: 'currencies.exchange_rate_idcp'
            },
            {
                name: intl.formatMessage({id: "currency.exchange_rate_api"}),
                selector: row => row.currency_exchange_rate_api,
                wrap: true,
                sortable: true,
                sortField: 'currencies.exchange_rate_api'
            },
            {
                selector: row => (
                    <>
                        <TableIconButtonEdit handler={(e) => showEditExchangeRate(e, row)} />
                        <TableIconButtonDelete handler={(e) => showDeleteConfirmation(e, row)} />
                    </>
                ),
                right: true,
                wrap: true,
                grow: 0.1
            }
        ]
        return (
            <Datatable
                columns={columns}
                data={exchangeRates}
                defaultSortAsc={sortType.current.order !== "asc"}
                onSort={sortCurrencies}
                paginationComponent={() => <Pagination pagination={pagination} handler={getExchangeRatesPerPage} />}
                paginationPerPage={pagination?.per_page ?? PAGINATION_PAGE_ROWS}
                progressPending={isGettingExchangeRates}
                sortServer
            />
        )
    }

    return (
        <>
            <Title title={intl.formatMessage({id: "title.currencies"})} />
            <div className="container">
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <button className="button is-link"
                                    onClick={(e) => showCreateCurrency(e)}>
                                <FormattedMessage id="currency.new_currency" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <label className="label">
                                <FormattedMessage id="general.filter_by" />:
                            </label>
                        </div>
                        <div className="level-item mr-0">
                            <Dropdown id="currencyFilterDropdown"
                                      text={currencyFilter ? intl.formatMessage({id: "currency." + currencyFilter}) : ""}
                                      content={<>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    resetData()
                                                    getData()
                                                }}>
                                              <FormattedMessage id={"currency.all"} />
                                          </Link>
                                          <hr className="dropdown-divider" />
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setCurrencyFilter("name")
                                                    resetData()
                                                }}>
                                              <FormattedMessage id={"currency.name"} />
                                          </Link>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setCurrencyFilter("exchange_rate")
                                                    resetData()
                                                }}>
                                              <FormattedMessage id={"currency.exchange_rate"} />
                                          </Link>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setCurrencyFilter("exchange_rate_idcp")
                                                    resetData()
                                                }}>
                                              <FormattedMessage id={"currency.exchange_rate_idcp"} />
                                          </Link>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setCurrencyFilter("exchange_rate_api")
                                                    resetData()
                                                }}>
                                              <FormattedMessage id={"currency.exchange_rate_api"} />
                                          </Link>
                                      </>}
                            />
                        </div>
                        <div className="level-item">
                            <div className="field has-addons">
                                {currencyFilter === "name" ?
                                    <Search size={"normal"}
                                            placeholder={intl.formatMessage({id: "placeholder.filter_by_name"})}
                                            value={nameFilter}
                                            inputChange={(e) => setNameFilter(e.target.value)}
                                            maxLength={20}
                                            isLoading={(isGettingExchangeRates && nameFilter.trim() !== "")}
                                            disabledButton={nameFilter.trim() === ""}
                                            buttonClick={(e) => filterCurrenciesByName(e)}
                                            buttonText={intl.formatMessage({id: "general.search"})}
                                    />
                                    :
                                    currencyFilter === "exchange_rate" || currencyFilter === "exchange_rate_idcp" || currencyFilter === "exchange_rate_api" ?
                                        <>
                                            <div className="control">
                                                <input className="input"
                                                       type="number"
                                                       placeholder={intl.formatMessage({id: "placeholder.min"})}
                                                       value={minFilter}
                                                       onChange={(e) => setMinFilter(e.target.value)}
                                                       min={0}
                                                       max={9999999}
                                                       step={0.1}
                                                       onWheel={(e) => e.target.blur()}
                                                />
                                            </div>
                                            <div className="control">
                                                <button className="button is-static" tabIndex="-1">-</button>
                                            </div>
                                            <div className="control">
                                                <input className="input"
                                                       type="number"
                                                       placeholder={intl.formatMessage({id: "placeholder.max"})}
                                                       value={maxFilter}
                                                       onChange={(e) => setMaxFilter(e.target.value)}
                                                       min={0}
                                                       max={9999999}
                                                       step={0.1}
                                                       onWheel={(e) => e.target.blur()}
                                                />
                                            </div>
                                            <button className={`button is-link ${isGettingExchangeRates &&
                                            minFilter.trim() !== "" && maxFilter.trim() !== "" ? 'is-loading' : ''}`}
                                                    disabled={minFilter.trim() === "" || maxFilter.trim() === ""}
                                                    onClick={(e) => filterCurrenciesByExchangeRate(e)}>
                                                <FormattedMessage id="general.search" />
                                            </button>
                                        </>
                                        :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <CurrenciesTable />
            </div>
        </>
    )
}
