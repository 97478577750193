import {Link} from 'react-router-dom'
import {NavbarItem} from './NavbarItem'

export const NavbarItemDropdown = ({text = "", dropdownItems = [], size = 4}) => {
    return (
        <div className="navbar-item has-dropdown is-hoverable">
            <Link to="#" className={"navbar-link is-arrowless is-size-" + size}>
                {text}
            </Link>
            <div className="navbar-dropdown">
                {dropdownItems?.map((dropdownItem, i) => (
                    <div key={i}>
                        {dropdownItem.menu ?
                            <div className="navbar-item">
                                <ul className="menu-list">
                                    <li>
                                        <p className="is-size-5">
                                            {dropdownItem.text}
                                        </p>
                                    </li>
                                    <li>
                                        <ul>
                                            {dropdownItem.menu.map((item, j) => (
                                                <li key={j}>
                                                    <NavbarItem to={item.to} text={item.text} size={size + 2} />
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            :
                            <NavbarItem to={dropdownItem.to} text={dropdownItem.text} size={size + 1} />
                        }
                    </div>
                ))}
            </div>
        </div>
    )
}
