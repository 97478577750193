import {useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import * as actions from '../../store/Actions'
import {storePrice} from '../../store/reducers/price/priceActions'
import {getLanguagePairs} from '../../store/reducers/languagePair/languagePairActions'
import {getUnits} from '../../store/reducers/unit/unitActions'
import {DraggableModal} from '../UI/DraggableModal'
import * as modalTypes from '../../consts/enums/modalTypes'
import {roundDecimalNumber} from '../../utils/roundDecimalNumber'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEuroSign} from '@fortawesome/free-solid-svg-icons'

export const ModalNewPrice = ({title = "", standardRateId = null, languagePairId = null}) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const languagePairs = useSelector(state => state.languagePair.languagePairs)
    const isGettingLanguagePairs = useSelector(state => state.languagePair.isGettingLanguagePairs)
    const units = useSelector(state => state.unit.units)
    const isGettingUnits = useSelector(state => state.unit.isGettingUnits)
    const [languagePair, setLanguagePair] = useState("")
    const [unit, setUnit] = useState("")
    const [total, setTotal] = useState("")

    useEffect(() => {
        dispatch(getLanguagePairs())
        dispatch(getUnits())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!isGettingLanguagePairs) {
            setLanguagePair(languagePairId ?? "")
        } else {
            setLanguagePair("")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGettingLanguagePairs])

    const onHideClick = (e) => {
        e.preventDefault()
        dispatch({type: actions.HIDE_MODAL})
    }

    const createTextLanguagePair = (languagePair) => {
        return languagePair.source_language +
            (languagePair.code_source_language ? " (" + languagePair.code_source_language + ")" : "") + " - " +
            languagePair.target_language +
            (languagePair.code_target_language ? " (" + languagePair.code_target_language + ")" : "")
    }

    const createPrice = (e) => {
        e.preventDefault()
        const price = {
            total: roundDecimalNumber(total),
            translation_id: parseInt(languagePair),
            unit_id: parseInt(unit),
            rate_id: standardRateId
        }
        dispatch(storePrice(price))
        dispatch({type: actions.HIDE_MODAL})
        dispatch({type: actions.SHOW_MODAL, payload: {type:modalTypes.LOADING}})
    }

    const showCreateLanguagePair = (e) => {
        e.preventDefault()
        dispatch({type: actions.HIDE_MODAL})
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                type: modalTypes.NEW_LANGUAGE_PAIR,
                title: intl.formatMessage({id: "message_title.new_language_pair"}),
                additionalData: standardRateId
            }
        })
    }

    const Body = () => {
        return (
            <>
                <div className="field">
                    <label className="label">
                        <FormattedMessage id="language_pair.source_language" /> - <FormattedMessage id="language_pair.target_language" />
                        <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                        <span>:</span>
                    </label>
                    <div className="field is-grouped is-grouped-multiline">
                        <div className="control">
                            <div className={`select ${isGettingLanguagePairs ? 'is-loading' : ''}`}>
                                <select value={languagePair}
                                        onChange={(e) => setLanguagePair(e.target.value)}>
                                    <option value="">
                                        -----
                                    </option>
                                    {languagePairs?.map((languagePair, i) => (
                                        <option key={i} value={languagePair.id}>
                                            {createTextLanguagePair(languagePair)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="control">
                            <button className="button is-link is-small"
                                    onClick={(e) => showCreateLanguagePair(e)}>
                                <FormattedMessage id="language_pair.new_pair"/>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="field">
                    <label className="label">
                        <FormattedMessage id="language_pair.unit" />
                        <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                        <span>:</span>
                    </label>
                    <div className={`select ${isGettingUnits ? 'is-loading' : ''}`}>
                        <select onChange={(e) => setUnit(e.target.value)}>
                            <option value="">
                                -----
                            </option>
                            {units?.map((unit, i) => (
                                <option key={i} value={unit.id}>
                                    {unit.type}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="field">
                    <label className="label">
                        <FormattedMessage id="language_pair.price" />
                        <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                        <span>:</span>
                    </label>
                    <div className="columns">
                        <div className="column is-one-third">
                            <div className="control has-icons-left">
                                <input className="input"
                                       type="number"
                                       placeholder={intl.formatMessage({id: "placeholder.price"})}
                                       value={total}
                                       onChange={(e) => setTotal(e.target.value)}
                                       autoFocus
                                       min={0}
                                       max={9999999}
                                       step={0.1}
                                       onWheel={(e) => e.target.blur()}
                                />
                                <span className="icon is-left">
                                    <FontAwesomeIcon icon={faEuroSign} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const Foot = () => {
        return (
            <>
                <button className="button is-link"
                        disabled={languagePair === "" || unit === "" || total.trim() === ""}
                        onClick={(e) => createPrice(e)}>
                    <FormattedMessage id="general.save" />
                </button>
                <button className="button"
                        onClick={(e) => onHideClick(e)}>
                    <FormattedMessage id="general.cancel" />
                </button>
            </>
        )
    }

    return (
        <DraggableModal title={title} body={Body()} foot={Foot()} onHideClick={onHideClick} />
    )
}
