import {axiosInstance} from '../../../hoc/Axios/Axios'
import * as actions from '../../Actions'
import * as toastTypes from '../../../consts/enums/toastTypes'
import {encodeParameter} from '../../../utils/encodeParameter'

export const getRatePercentages = (rateId, sort = "cat_tool_breaks.match_percent_min", order = "desc", page) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_RATE_PERCENTAGES})
    dispatch({type: actions.GETTING_RATE_PERCENTAGES})
    try {
        const url = "/rate-percentages/" + rateId + (page ? "?sort=" + sort + "&order=" + order + "&page=" + page : "/all")
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_RATE_PERCENTAGES, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_RATE_PERCENTAGES})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.rate_percentages_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.RATE_PERCENTAGE_ERROR, payload: error.response})
    }
}

export const getRatePercentagesByName = (name, rateId, sort = "cat_tool_breaks.match_percent_min", order = "desc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_RATE_PERCENTAGES})
    dispatch({type: actions.GETTING_RATE_PERCENTAGES})
    try {
        name = encodeParameter(name)
        const url = "/rate-percentages/by-name/" + name + "/" + rateId + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_RATE_PERCENTAGES, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_RATE_PERCENTAGES})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.rate_percentages_by_name_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.RATE_PERCENTAGE_ERROR, payload: error.response})
    }
}

export const getRatePercentagesByPercent = (min, max, percent, rateId, sort = "cat_tool_breaks.match_percent_min", order = "desc", page = 1) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_RATE_PERCENTAGES})
    dispatch({type: actions.GETTING_RATE_PERCENTAGES})
    try {
        const url = "/rate-percentages/by-" + (percent === "percent_range" ? "match-" : "") + "percent/" + min + "/" + max +
            "/" + rateId + "?sort=" + sort + "&order=" + order + "&page=" + page
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_RATE_PERCENTAGES, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.RESET_RATE_PERCENTAGES})
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.rate_percentages_by_percent_" + (percent === "percent_range" ? "range_" : "") + "do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.RATE_PERCENTAGE_ERROR, payload: error.response})
    }
}

export const getAllRatePercentages = (rateId) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_ALL_RATE_PERCENTAGES})
    dispatch({type: actions.GETTING_ALL_RATE_PERCENTAGES})
    try {
        const res = await axiosInstance.get("/rate-percentages/" + rateId + "/all")
        dispatch({type: actions.GET_ALL_RATE_PERCENTAGES, payload: res.data})
    } catch (error) {
        dispatch({type: actions.RATE_PERCENTAGE_ERROR, payload: error.response})
    }
}

export const storeRatePercentage = (ratePercentage) => async (dispatch, getState) => {
    try {
        await axiosInstance.post("/rate-percentages/store", ratePercentage)
        dispatch({type: actions.RATE_PERCENTAGE_CREATED, payload: {status: true}})
    } catch (error) {
        dispatch({type: actions.RATE_PERCENTAGE_ERROR, payload: error.response})
    }
}

export const updateRatePercentage = (ratePercentage) => async (dispatch, getState) => {
    try {
        await axiosInstance.put("/rate-percentages/" + ratePercentage.id, ratePercentage)
        dispatch({type: actions.RATE_PERCENTAGE_UPDATED, payload: {status: true}})
    } catch (error) {
        dispatch({type: actions.RATE_PERCENTAGE_ERROR, payload: error.response})
    }
}

export const deleteRatePercentage = (id) => async (dispatch, getState) => {
    try {
        await axiosInstance.delete("/rate-percentages/" + id)
        dispatch({type: actions.RATE_PERCENTAGE_DELETED, payload: {status: true}})
    } catch (error) {
        dispatch({type: actions.RATE_PERCENTAGE_ERROR, payload: error.response})
    }
}
