import {FormattedMessage} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import * as actions from '../../store/Actions'
import {LOCALES} from '../../consts/app'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faGlobe} from '@fortawesome/free-solid-svg-icons'

export const LanguageSelector = () => {
    const dispatch = useDispatch()
    const locale = useSelector(state => state.locale.locale)

    const getRemainingLanguages = (selectedLanguage) => {
        return LOCALES.filter(language => language.name !== selectedLanguage)
    }

    return (
        <div className="navbar-item has-dropdown is-hoverable">
            <Link to="#" className="navbar-link is-size-6">
                <span className="icon">
                    <FontAwesomeIcon icon={faGlobe} />
                </span>
                <span>
                    <FormattedMessage id={"languages." + locale.name} />
                </span>
            </Link>
            <div className="navbar-dropdown">
                {getRemainingLanguages(locale.name)?.map((language, i) => (
                    <Link to="#" className="navbar-item is-size-6" key={i}
                            onClick={() => dispatch({type: actions.SET_LOCALE, payload: language.name})}>
                        <FormattedMessage id={"languages." + language.name}/>
                    </Link>
                ))}
            </div>
        </div>
    )
}
