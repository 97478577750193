import {useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import * as actions from '../../store/Actions'
import {DraggableModal} from '../UI/DraggableModal'
import {roundDecimalNumber} from '../../utils/roundDecimalNumber'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEuroSign} from '@fortawesome/free-solid-svg-icons'

export const ModalEditPrice = ({title = "", price = null, editPrice = null, customerRate = null, standardRate = null}) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const [total, setTotal] = useState("")

    useEffect(() => {
        let total = price.total
        if (customerRate?.customerRatePercent) {
            total = total * (roundDecimalNumber(customerRate.customerRatePercent) / 100)
        } else if (standardRate) {
            if (standardRate.percent) {
                total = total + total * (roundDecimalNumber(standardRate.percent) / 100)
            }
        }
        setTotal("" + roundDecimalNumber(total))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onHideClick = (e) => {
        e.preventDefault()
        dispatch({type: actions.HIDE_MODAL})
    }

    const createTextLanguagePair = () => {
        return price.translation_source_language +
            (price.translation_code_source_language ? " (" + price.translation_code_source_language + ")" : "") + " - " +
            price.translation_target_language +
            (price.translation_code_target_language ? " (" + price.translation_code_target_language + ")" : "")
    }

    const calculatePrice = () => {
        let price = roundDecimalNumber(total)
        if (customerRate?.customerRatePercent) {
            const percent = roundDecimalNumber(customerRate.customerRatePercent)
            if (percent !== 0) {
                price = price / (percent / 100)
            } else {
                price = 0
            }
        } else if (standardRate) {
            if (standardRate.percent) {
                price = price / (1 + (roundDecimalNumber(standardRate.percent) / 100))
            }
        }
        return roundDecimalNumber(price)
    }

    const edit = (e) => {
        e.preventDefault()
        const editedPrice = {
            id: price.id,
            total: calculatePrice()
        }
        editPrice(editedPrice)
    }

    const Body = () => {
        return (
            <>
                <div className="field">
                    <label className="label">
                        <FormattedMessage id="language_pair.source_language" /> - <FormattedMessage id="language_pair.target_language" />
                        : {createTextLanguagePair()}
                    </label>
                </div>
                <div className="field">
                    <label className="label">
                        <FormattedMessage id="language_pair.unit" />: {price.unit_type}
                    </label>
                </div>
                <div className="field">
                    <label className="label">
                        <FormattedMessage id="language_pair.price" />
                        <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                        <span>:</span>
                    </label>
                    <div className="columns">
                        <div className="column is-one-third">
                            <div className={`control ${!customerRate ? 'has-icons-left' : ''}`}>
                                <input className="input"
                                       type="number"
                                       placeholder={intl.formatMessage({id: "placeholder.price"})}
                                       value={total}
                                       onChange={(e) => setTotal(e.target.value)}
                                       autoFocus
                                       min={0}
                                       max={9999999}
                                       step={0.1}
                                       onWheel={(e) => e.target.blur()}
                                />
                                {!customerRate ?
                                    <span className="icon is-left">
                                        <FontAwesomeIcon icon={faEuroSign}/>
                                    </span>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const Foot = () => {
        return (
            <>
                <button className="button is-link"
                        disabled={total.trim() === ""}
                        onClick={(e) => edit(e)}>
                    <FormattedMessage id="general.save" />
                </button>
                <button className="button"
                        onClick={(e) => onHideClick(e)}>
                    <FormattedMessage id="general.cancel" />
                </button>
            </>
        )
    }

    return (
        <DraggableModal title={title} body={Body()} foot={Foot()} onHideClick={onHideClick} />
    )
}
