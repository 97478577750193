import * as actions from '../../Actions'

const initialState = {
    units: [],
    isGettingUnits: false,
    pagination: null,
    unitCreated: false,
    unitDeleted: false,
    error: null
}

export default function reducer (state = initialState, action) {
    switch (action.type) {
        case actions.GET_UNITS:
            return {
                ...state,
                units: action.payload.data,
                pagination: {
                    "current_page": action.payload.current_page ?? null,
                    "links": action.payload.links ?? [],
                    "per_page": action.payload.per_page ?? null,
                    "to": action.payload.to ?? null,
                    "total": action.payload.total ?? null
                },
                isGettingUnits: false
            }
        case actions.GETTING_UNITS:
            return {
                ...state,
                isGettingUnits: true
            }
        case actions.UNIT_CREATED:
            return {
                ...state,
                unitCreated: action.payload.status
            }
        case actions.UNIT_DELETED:
            return {
                ...state,
                unitDeleted: action.payload.status
            }
        case actions.UNIT_ERROR:
            return {
                ...state,
                error: action.payload,
                isGettingUnits: false
            }
        case actions.RESET_UNITS:
            return {
                ...state,
                units: [],
                pagination: null
            }
        case actions.RESET_UNIT_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
