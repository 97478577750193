import {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import * as actions from '../../store/Actions'
import {DraggableModal} from '../UI/DraggableModal'
import {TAG_TYPES} from '../../consts/app'

export const ModalEditTag = ({title = "", tag = null, editTag = null}) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const [type, setType] = useState("" + tag.type)

    const onHideClick = (e) => {
        e.preventDefault()
        dispatch({type: actions.HIDE_MODAL})
    }

    const edit = (e) => {
        e.preventDefault()
        const editedTag = {
            id: tag.id,
            name: tag.name,
            type: type
        }
        editTag(editedTag)
    }

    const Body = () => {
        return (
            <>
                <div className="field">
                    <label className="label">
                        <FormattedMessage id="tag.name" />: {tag.name}
                    </label>
                </div>
                <div className="field">
                    <label className="label">
                        <FormattedMessage id="tag.type" />
                        <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                        <span>:</span>
                    </label>
                    <div className="select">
                        <select value={type}
                                onChange={(e) => setType(e.target.value)}>
                            <option value="">
                                -----
                            </option>
                            {TAG_TYPES?.map((type, i) => (
                                <option key={i} value={type}>
                                    {intl.formatMessage({id: "tag_type." + type})}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </>
        )
    }

    const Foot = () => {
        return (
            <>
                <button className="button is-link"
                        disabled={type.trim() === ""}
                        onClick={(e) => edit(e)}>
                    <FormattedMessage id="general.save" />
                </button>
                <button className="button"
                        onClick={(e) => onHideClick(e)}>
                    <FormattedMessage id="general.cancel" />
                </button>
            </>
        )
    }

    return (
        <DraggableModal title={title} body={Body()} foot={Foot()} onHideClick={onHideClick} />
    )
}
