import {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import * as actions from '../../store/Actions'
import {EXPORT_LANGUAGES} from '../../consts/app'
import {DraggableModal} from '../UI/DraggableModal'

export const ModalSelectLanguageExport = ({title = "", additionalData = null}) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const [selectedLanguage, setSelectedLanguage] = useState(null)

    const onHideClick = (e) => {
        e.preventDefault()
        dispatch({type: actions.HIDE_MODAL})
    }

    const accept = (e) => {
        e.preventDefault()
        dispatch({type: actions.HIDE_MODAL})
        additionalData.export(additionalData.units, additionalData.prices, additionalData.values, additionalData.ratePercentages, selectedLanguage)
    }

    const Body = () => {
        return (
            <>
                {EXPORT_LANGUAGES?.map((language, i) => (
                    <div className="level mb-0" key={i}>
                        <div className="level-left">
                            <label className="radio">
                                <input type="radio" name="language" checked={language.name === selectedLanguage?.name}
                                       onChange={() => setSelectedLanguage(language)} />
                                {intl.formatMessage({id: "languages." + language.name})}
                            </label>
                        </div>
                    </div>
                ))}
            </>
        )
    }

    const Foot = () => {
        return (
            <>
                <button className="button is-link"
                        disabled={!selectedLanguage}
                        onClick={(e) => accept(e)}>
                    <FormattedMessage id="general.accept"/>
                </button>
                <button className="button"
                        onClick={(e) => onHideClick(e)}>
                    <FormattedMessage id="general.cancel"/>
                </button>
            </>
        )
    }

    return (
        <DraggableModal title={title} body={Body()} foot={Foot()} onHideClick={onHideClick}/>
    )
}
