import {useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import * as actions from '../../store/Actions'
import {getPrices} from '../../store/reducers/price/priceActions'
import * as modalTypes from '../../consts/enums/modalTypes'
import {DraggableModal} from '../UI/DraggableModal'
import {Loader} from '../UI/Loader'

export const ModalSelectPairsNewRate = ({title = "", additionalData = null}) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const prices = useSelector(state => state.price.prices)
    const isGettingPrices = useSelector(state => state.price.isGettingPrices)
    const error = useSelector(state => state.price.error)
    const [allChecked, setAllChecked] = useState(false)
    const [checkboxes, setCheckboxes] = useState([])

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!isGettingPrices) {
            const checkboxes = []
            for (let i = 0; i < prices.length; i++) {
                checkboxes.push(false)
            }
            setCheckboxes([...checkboxes])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGettingPrices])

    useEffect(() => {
        if (error) {
            dispatch({type: actions.HIDE_MODAL})
            dispatch({type: actions.RESET_PRICE_ERROR})
            dispatch({type: actions.RESET_RATE_PERCENTAGES})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    const getData = () => {
        dispatch(getPrices(additionalData.rateId))
    }

    const onHideClick = (e) => {
        e.preventDefault()
        additionalData.getNewPrices([], prices)
        showSelectTags()
    }

    const showSelectTags = () => {
        dispatch({type: actions.HIDE_MODAL})
        dispatch({
            type: actions.SHOW_MODAL, payload: {
                type: modalTypes.SELECT_TAGS_NEW_RATE,
                title: intl.formatMessage({id: "message_title.select_tags_new_rate"}),
                additionalData: {
                    getNewValues: additionalData.getNewValues,
                    rateId: additionalData.rateId
                }
            }
        })
    }

    const changeAllChecked = () => {
        const checks = [...checkboxes]
        for (let i = 0; i < checks.length; i++) {
            checks[i] = !allChecked
        }
        setCheckboxes([...checks])
        setAllChecked(!allChecked)
    }

    const changeChecked = (i) => {
        const checks = [...checkboxes]
        checks[i] = !checks[i]
        setCheckboxes([...checks])
        if (allChecked || checks.filter(check => check === false).length < 1) {
            setAllChecked(!allChecked)
        }
    }

    const createTextLanguagePair = (price) => {
        return price.translation_source_language +
            (price.translation_code_source_language ? " (" + price.translation_code_source_language + ")" : "") + " - " +
            price.translation_target_language +
            (price.translation_code_target_language ? " (" + price.translation_code_target_language + ")" : "") +
            " (" + price.unit_type + ")"
    }

    const accept = (e) => {
        e.preventDefault()
        const newPrices = []
        for (let i = 0; i < prices.length; i++) {
            if (checkboxes[i]) {
                newPrices.push(JSON.parse(JSON.stringify(prices[i])))
            }
        }
        additionalData.getNewPrices(newPrices, prices)
        showSelectTags()
    }

    const Body = () => {
        return (
            <>
                {isGettingPrices ?
                    <Loader />
                    :
                    <>
                        <div className="level">
                            <div className="level-left">
                                <label className="checkbox has-text-weight-bold">
                                    <input type="checkbox" checked={allChecked}
                                           onChange={(e) => changeAllChecked()} />
                                    <FormattedMessage id="standard_rate.select_all_language_pairs" />
                                </label>
                            </div>
                        </div>
                        {prices?.map((price, i) => (
                            <div key={i} className="level mb-0">
                                <div className="level-left">
                                    <label className="checkbox">
                                        <input type="checkbox" checked={checkboxes[i] ?? false}
                                               onChange={() => changeChecked(i)} />
                                        {createTextLanguagePair(price)}
                                    </label>
                                </div>
                            </div>
                        ))}
                    </>
                }
            </>
        )
    }

    const Foot = () => {
        return (
            <>
                <button className="button is-link"
                        disabled={isGettingPrices}
                        onClick={(e) => accept(e)}>
                    <FormattedMessage id="general.accept" />
                </button>
                <button className="button"
                        onClick={(e) => onHideClick(e)}>
                    <FormattedMessage id="general.cancel" />
                </button>
            </>
        )
    }

    return (
        <DraggableModal title={title} body={Body()} foot={Foot()} onHideClick={onHideClick} />
    )
}
