import * as actions from '../../Actions'

const initialState = {
    catToolBreaks: [],
    isGettingCatToolBreaks: false,
    error: null
}

export default function reducer (state = initialState, action) {
    switch (action.type) {
        case actions.GET_CAT_TOOL_BREAKS:
            return {
                ...state,
                catToolBreaks: action.payload.data,
                isGettingCatToolBreaks: false
            }
        case actions.GETTING_CAT_TOOL_BREAKS:
            return {
                ...state,
                isGettingCatToolBreaks: true
            }
        case actions.CAT_TOOL_BREAK_ERROR:
            return {
                ...state,
                error: action.payload,
                isGettingCatToolBreaks: false
            }
        case actions.RESET_CAT_TOOL_BREAKS:
            return {
                ...state,
                catToolBreaks: []
            }
        case actions.RESET_CAT_TOOL_BREAK_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
