import {useEffect, useRef, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useNavigate} from 'react-router-dom'
import * as actions from '../store/Actions'
import {getCustomersIdcp} from '../store/reducers/customer/customerActions'
import {getManufacturersIdcp} from '../store/reducers/manufacturer/manufacturerActions'
import {getProjectsIdcp} from '../store/reducers/project/projectActions'
import {getNamedCustomerRate} from '../store/reducers/customerRate/customerRateActions'
import {Title} from '../components/UI/Title'
import {Search} from '../components/UI/Search'
import {Tag} from '../components/UI/Tag'
import * as toastTypes from '../consts/enums/toastTypes'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRight} from '@fortawesome/free-solid-svg-icons'

export const SelectCustomer = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const intl = useIntl()
    const dispatch = useDispatch()
    const customers = useSelector(state => state.customer.customersIdcp)
    const isGettingCustomersIdcp = useSelector(state => state.customer.isGettingCustomersIdcp)
    const manufacturers = useSelector(state => state.manufacturer.manufacturersIdcp)
    const isGettingManufacturersIdcp = useSelector(state => state.manufacturer.isGettingManufacturersIdcp)
    const projects = useSelector(state => state.project.projectsIdcp)
    const isGettingProjectsIdcp = useSelector(state => state.project.isGettingProjectsIdcp)
    const customerRate = useSelector(state => state.customerRate.customerRate)
    const isGettingCustomerRate = useSelector(state => state.customerRate.isGettingCustomerRate)
    const copyCustomerRate = useRef(null)
    const firstRender = useRef(true)
    const [searchCustomer, setSearchCustomer] = useState("")
    const [searchManufacturer, setSearchManufacturer] = useState("")
    const [searchProject, setSearchProject] = useState("")
    const [customer, setCustomer] = useState(null)
    const [manufacturer, setManufacturer] = useState(null)
    const [project, setProject] = useState(null)
    const [name, setName] = useState("")

    useEffect(() => {
        if (location.state?.customerRate) {
            copyCustomerRate.current = location.state.customerRate
            setCustomer({
                code: location.state.customerRate.customer_code,
                name: location.state.customerRate.customer_name,
                type: location.state.customerRate.customer_type
            })
            if (location.state.customerRate.manufacturer_id) {
                setManufacturer({
                    code: location.state.customerRate.manufacturer_code,
                    description: location.state.customerRate.manufacturer_name
                })
            }
            if (location.state.customerRate.project_id) {
                setProject({
                    code: location.state.customerRate.project_code,
                    description: location.state.customerRate.project_name
                })
                setName(location.state.customerRate.project_name)
            } else {
                setName(location.state.customerRate.customer_name)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!isGettingCustomerRate && !firstRender.current) {
            if (customerRate) {
                dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.customer_rate_name_already_exists"}})
            } else {
                navigate("/customer-rates/new", {
                    state: {
                        customerRateData: {
                            customer: customer,
                            manufacturer: manufacturer,
                            project: project,
                            name: name
                        },
                        linkedCustomerRate: copyCustomerRate.current
                    }
                })
            }
        }
        firstRender.current = false
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGettingCustomerRate])

    const resetData = () => {
        dispatch({type: actions.RESET_CUSTOMERS_IDCP})
        dispatch({type: actions.RESET_MANUFACTURERS_IDCP})
        dispatch({type: actions.RESET_PROJECTS_IDCP})
    }

    const changeCustomerData = (e, data) => {
        e.preventDefault()
        setCustomer(data)
        setSearchCustomer("")
        setManufacturer(null)
        setSearchManufacturer("")
        setProject(null)
        setSearchProject("")
        setName(data.name)
        resetData()
    }

    const changeManufacturerData = (e, data) => {
        e.preventDefault()
        setManufacturer(data)
        setSearchManufacturer("")
        setProject(null)
        setSearchProject("")
        setName(customer.name)
        resetData()
    }

    const changeProjectData = (e, data) => {
        e.preventDefault()
        setProject(data)
        setSearchProject("")
        setName(data.description)
        resetData()
    }

    const dataText = (data) => {
        if (data.name) {
            if (data.type) {
                return data.name + " (" + data.type + ")"
            }
            return data.name
        }
        return data.description
    }

    const getDataIdcp = (e, type) => {
        e.preventDefault()
        if (type === "customer") {
            dispatch({type: actions.RESET_CUSTOMERS_IDCP})
            dispatch(getCustomersIdcp(searchCustomer))
        } else if (type === "manufacturer") {
            dispatch({type: actions.RESET_MANUFACTURERS_IDCP})
            dispatch(getManufacturersIdcp(searchManufacturer, customer.code))
        } else {
            dispatch({type: actions.RESET_PROJECTS_IDCP})
            dispatch(getProjectsIdcp(searchProject, customer.code, manufacturer.code))
        }
    }

    const removeCustomer = (e) => {
        e.preventDefault()
        setCustomer(null)
        setManufacturer(null)
        setSearchManufacturer("")
        setProject(null)
        setSearchProject("")
        setName("")
        resetData()
    }

    const removeManufacturer = (e) => {
        e.preventDefault()
        setManufacturer(null)
        setProject(null)
        setSearchProject("")
        setName(customer.name)
        resetData()
    }

    const removeProject = (e) => {
        e.preventDefault()
        setProject(null)
        setName(customer.name)
        resetData()
    }

    const showCreateCustomerRate = (e) => {
        e.preventDefault()
        dispatch(getNamedCustomerRate(name))
    }

    return (
        <>
            <Title title={intl.formatMessage({id: "title.select_customer"})} />
            <div className="container">
                <div className="level mb-1">
                    <div className="level-left">
                        <div className="level-item">
                            <label className="label">
                                <FormattedMessage id="customer_rate.customer" />
                                <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                                <span>:</span>
                            </label>
                        </div>
                        {customer ?
                            <div className="level-item">
                                <Tag text={customer.name + " (" + customer.type + ")"}
                                     buttonDelete={!copyCustomerRate.current?.customer_id}
                                     handler={(e) => removeCustomer(e)}
                                />
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
                {!copyCustomerRate.current?.customer_id ?
                    <div className="level">
                        <div className="level-left">
                            <div className="level-item">
                                <div className="field has-addons">
                                    <Search placeholder={intl.formatMessage({id: "placeholder.search_customer"})}
                                            value={searchCustomer}
                                            inputChange={(e) => {
                                                setSearchCustomer(e.target.value)
                                                dispatch({type: actions.RESET_CUSTOMERS_IDCP})
                                            }}
                                            autofocus={true}
                                            maxLength={58}
                                            content={customers}
                                            dataClick={changeCustomerData}
                                            dataText={dataText}
                                            isLoading={isGettingCustomersIdcp}
                                            disabledButton={searchCustomer.trim() === ""}
                                            buttonClick={(e) => getDataIdcp(e, "customer")}
                                            buttonText={intl.formatMessage({id: "general.search"})}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
                {customer ?
                    <>
                        <div className="level mb-1">
                            <div className="level-left">
                                <div className="level-item">
                                    <label className="label">
                                        <FormattedMessage id="customer_rate.manufacturer" />:
                                    </label>
                                </div>
                                {manufacturer ?
                                    <div className="level-item">
                                        <Tag text={manufacturer.description}
                                             buttonDelete={!copyCustomerRate.current?.manufacturer_id}
                                             handler={(e) => removeManufacturer(e)}
                                        />
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        {!copyCustomerRate.current?.manufacturer_id ?
                            <div className="level">
                                <div className="level-left">
                                    <div className="level-item">
                                        <div className="field has-addons">
                                            <Search placeholder={intl.formatMessage({id: "placeholder.search_manufacturer"})}
                                                    value={searchManufacturer}
                                                    inputChange={(e) => {
                                                        setSearchManufacturer(e.target.value)
                                                        dispatch({type: actions.RESET_MANUFACTURERS_IDCP})
                                                    }}
                                                    autofocus={true}
                                                    maxLength={110}
                                                    content={manufacturers}
                                                    dataClick={changeManufacturerData}
                                                    dataText={dataText}
                                                    isLoading={isGettingManufacturersIdcp}
                                                    disabledButton={searchManufacturer.trim() === ""}
                                                    buttonClick={(e) => getDataIdcp(e, "manufacturer")}
                                                    buttonText={intl.formatMessage({id: "general.search"})}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                    </>
                    :
                    null
                }
                {manufacturer ?
                    <>
                        <div className="level mb-1">
                            <div className="level-left">
                                <div className="level-item">
                                    <label className="label">
                                        <FormattedMessage id="customer_rate.project" />:
                                    </label>
                                </div>
                                {project ?
                                    <div className="level-item">
                                        <Tag text={project.description} handler={(e) => removeProject(e)} />
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="level">
                            <div className="level-left">
                                <div className="level-item">
                                    <div className="field has-addons">
                                        <Search placeholder={intl.formatMessage({id: "placeholder.search_project"})}
                                                value={searchProject}
                                                inputChange={(e) => {
                                                    setSearchProject(e.target.value)
                                                    dispatch({type: actions.RESET_PROJECTS_IDCP})
                                                }}
                                                autofocus={true}
                                                maxLength={47}
                                                content={projects}
                                                dataClick={changeProjectData}
                                                dataText={dataText}
                                                isLoading={isGettingProjectsIdcp}
                                                disabledButton={searchProject.trim() === ""}
                                                buttonClick={(e) => getDataIdcp(e, "project")}
                                                buttonText={intl.formatMessage({id: "general.search"})}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    null
                }
                {customer ?
                    <div className="field mb-5">
                        <label className="label">
                            <FormattedMessage id="customer_rate.name_rate" />
                            <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                            <span>:</span>
                        </label>
                        <div className="control">
                            <input className="input"
                                   type="text"
                                   placeholder={intl.formatMessage({id: "placeholder.name"})}
                                   value={name}
                                   onChange={(e) => setName(e.target.value)}
                                   maxLength={100}
                            />
                        </div>
                    </div>
                    :
                    null
                }
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <button className={`button is-link ${isGettingCustomerRate ? 'is-loading' : ''}`}
                                    disabled={(!customer || project ? !manufacturer : false) || name.trim() === ""}
                                    onClick={(e) => showCreateCustomerRate(e)}>
                                <span>
                                    <FormattedMessage id="general.next" />
                                </span>
                                <span className="icon">
                                    <FontAwesomeIcon icon={faArrowRight} />
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
