import {FormattedMessage} from 'react-intl'
import {useDispatch} from 'react-redux'
import * as actions from '../../store/Actions'
import {DraggableModal} from '../UI/DraggableModal'

export const ModalConfirmation = ({title = "", message = "", buttons = []}) => {
    const dispatch = useDispatch()

    const onHideClick = (e) => {
        e.preventDefault()
        dispatch({type: actions.HIDE_MODAL})
    }

    const Foot = () => {
        return (
            <>
                {buttons?.map((button, i) => (
                    <button key={i} className={button.classes} onClick={button.handler}>
                        {button.label}
                    </button>
                ))}
                <button className="button" onClick={(e) => onHideClick(e)}>
                    <FormattedMessage id="general.cancel" />
                </button>
            </>
        )
    }

    return (
        <DraggableModal title={title !== "" ? title : <FormattedMessage id="message_title.delete" />}
                        body={message !== "" ? message : <FormattedMessage id="message.delete" />}
                        foot={Foot()}
                        onHideClick={onHideClick}
        />
    )
}
