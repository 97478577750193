import {useSelector} from 'react-redux'
import {ModalConfirmation} from './ModalConfirmation'
import {ModalSelectRate} from './ModalSelectRate'
import {ModalSelectPairsNewRate} from './ModalSelectPairsNewRate'
import {ModalSelectTagsNewRate} from './ModalSelectTagsNewRate'
import {ModalAddPair} from './ModalAddPair'
import {ModalAddTag} from './ModalAddTag'
import {ModalApplyPercentage} from './ModalApplyPercentage'
import {ModalSelectPairsExport} from './ModalSelectPairsExport'
import {ModalSelectTagsExport} from './ModalSelectTagsExport'
import {ModalSelectBreaksExport} from './ModalSelectBreaksExport'
import {ModalSelectLanguageExport} from './ModalSelectLanguageExport'
import {ModalSelectTags} from './ModalSelectTags'
import {ModalSelectCatToolBreak} from './ModalSelectCatToolBreak'
import {ModalNewUser} from './ModalNewUser'
import {ModalEditUser} from './ModalEditUser'
import {ModalNewPrice} from './ModalNewPrice'
import {ModalEditPrice} from './ModalEditPrice'
import {ModalNewLanguagePair} from './ModalNewLanguagePair'
import {ModalNewValue} from './ModalNewValue'
import {ModalEditValue} from './ModalEditValue'
import {ModalNewTag} from './ModalNewTag'
import {ModalEditTag} from './ModalEditTag'
import {ModalNewPercentage} from './ModalNewPercentage'
import {ModalEditPercentage} from './ModalEditPercentage'
import {ModalNewUnit} from './ModalNewUnit'
import {ModalNewCurrency} from './ModalNewCurrency'
import {ModalEditCurrency} from './ModalEditCurrency'
import * as modalTypes from '../../consts/enums/modalTypes'
import {Loader} from '../UI/Loader'

export const Modal = () => {
    const active = useSelector(state => state.modal.active)
    const type = useSelector(state => state.modal.type)
    const title = useSelector(state => state.modal.title)
    const message = useSelector(state => state.modal.message)
    const additionalData = useSelector(state => state.modal.additionalData)
    const buttons = useSelector(state => state.modal.buttons)

    const ModalContent = () => {
        switch (type) {
            case modalTypes.SELECT_RATE:
                return <ModalSelectRate title={title} additionalData={additionalData} />
            case modalTypes.SELECT_PAIRS_NEW_RATE:
                return <ModalSelectPairsNewRate title={title} additionalData={additionalData} />
            case modalTypes.SELECT_TAGS_NEW_RATE:
                return <ModalSelectTagsNewRate title={title} additionalData={additionalData} />
            case modalTypes.ADD_PAIR:
                return <ModalAddPair title={title} additionalData={additionalData} />
            case modalTypes.ADD_TAG:
                return <ModalAddTag title={title} additionalData={additionalData} />
            case modalTypes.APPLY_PERCENTAGE:
                return <ModalApplyPercentage title={title} setPercentage={additionalData} />
            case modalTypes.SELECT_PAIRS_EXPORT:
                return <ModalSelectPairsExport title={title} additionalData={additionalData} />
            case modalTypes.SELECT_TAGS_EXPORT:
                return <ModalSelectTagsExport title={title} additionalData={additionalData} />
            case modalTypes.SELECT_BREAKS_EXPORT:
                return <ModalSelectBreaksExport title={title} additionalData={additionalData} />
            case modalTypes.SELECT_LANGUAGE_EXPORT:
                return <ModalSelectLanguageExport title={title} additionalData={additionalData} />
            case modalTypes.SELECT_TAGS:
                return <ModalSelectTags title={title} additionalData={additionalData} />
            case modalTypes.SELECT_CAT_TOOL_BREAK:
                return <ModalSelectCatToolBreak title={title} additionalData={additionalData} />
            case modalTypes.NEW_USER:
                return <ModalNewUser title={title} />
            case modalTypes.EDIT_USER:
                return <ModalEditUser title={title} user={additionalData.user} editUser={additionalData.editUser} />
            case modalTypes.NEW_PRICE:
                return <ModalNewPrice title={title} standardRateId={additionalData.standardRateId} languagePairId={additionalData.languagePairId} />
            case modalTypes.EDIT_PRICE:
                return <ModalEditPrice title={title} price={additionalData.price} editPrice={additionalData.editPrice} customerRate={additionalData.customerRate} standardRate={additionalData.standardRate} />
            case modalTypes.NEW_LANGUAGE_PAIR:
                return <ModalNewLanguagePair title={title} standardRateId={additionalData} />
            case modalTypes.NEW_VALUE:
                return <ModalNewValue title={title} standardRateId={additionalData} />
            case modalTypes.EDIT_VALUE:
                return <ModalEditValue title={title} value={additionalData.value} editValue={additionalData.editValue} allValues={additionalData.allValues} customerRate={additionalData.customerRate} />
            case modalTypes.NEW_TAG:
                return <ModalNewTag title={title} />
            case modalTypes.EDIT_TAG:
                return <ModalEditTag title={title} tag={additionalData.tag} editTag={additionalData.editTag} />
            case modalTypes.NEW_PERCENTAGE:
                return <ModalNewPercentage title={title} newRatePercentage={additionalData.newPercentage} standardRateId={additionalData.standardRateId} />
            case modalTypes.EDIT_PERCENTAGE:
                return <ModalEditPercentage title={title} ratePercentage={additionalData.percentage} editRatePercentage={additionalData.editPercentage} />
            case modalTypes.NEW_UNIT:
                return <ModalNewUnit title={title} />
            case modalTypes.NEW_CURRENCY:
                return <ModalNewCurrency title={title} />
            case modalTypes.EDIT_CURRENCY:
                return <ModalEditCurrency title={title} exchangeRate={additionalData.exchangeRate} editExchangeRate={additionalData.editExchangeRate} />
            default:
                return <ModalConfirmation title={title} message={message} buttons={buttons} />
        }
    }

    return (
        <div className={"modal " + (active ? "is-active": "")}>
            <div className="modal-background" />
            {(type === modalTypes.LOADING) ?
                <Loader />
                :
                <ModalContent />
            }
        </div>
    )
}
