import * as actions from '../../Actions'

const initialState = {
    ratePercentages: [],
    allRatePercentages: [],
    isGettingRatePercentages: false,
    isGettingAllRatePercentages: false,
    pagination: null,
    ratePercentageCreated: false,
    ratePercentageUpdated: false,
    ratePercentageDeleted: false,
    error: null
}

export default function reducer (state = initialState, action) {
    switch (action.type) {
        case actions.GET_RATE_PERCENTAGES:
            return {
                ...state,
                ratePercentages: action.payload.data,
                pagination: {
                    "current_page": action.payload.current_page ?? null,
                    "links": action.payload.links ?? [],
                    "per_page": action.payload.per_page ?? null,
                    "to": action.payload.to ?? null,
                    "total": action.payload.total ?? null
                },
                isGettingRatePercentages: false
            }
        case actions.GET_ALL_RATE_PERCENTAGES:
            return {
                ...state,
                allRatePercentages: action.payload.data,
                isGettingAllRatePercentages: false
            }
        case actions.GETTING_RATE_PERCENTAGES:
            return {
                ...state,
                isGettingRatePercentages: true
            }
        case actions.GETTING_ALL_RATE_PERCENTAGES:
            return {
                ...state,
                isGettingAllRatePercentages: true
            }
        case actions.RATE_PERCENTAGE_CREATED:
            return {
                ...state,
                ratePercentageCreated: action.payload.status
            }
        case actions.RATE_PERCENTAGE_UPDATED:
            return {
                ...state,
                ratePercentageUpdated: action.payload.status
            }
        case actions.RATE_PERCENTAGE_DELETED:
            return {
                ...state,
                ratePercentageDeleted: action.payload.status
            }
        case actions.RATE_PERCENTAGE_ERROR:
            return {
                ...state,
                error: action.payload,
                isGettingRatePercentages: false,
                isGettingAllRatePercentages: false
            }
        case actions.RESET_RATE_PERCENTAGES:
            return {
                ...state,
                ratePercentages: [],
                pagination: null
            }
        case actions.RESET_ALL_RATE_PERCENTAGES:
            return {
                ...state,
                allRatePercentages: []
            }
        case actions.RESET_RATE_PERCENTAGE_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
