import * as actions from '../../Actions'

const initialState = {
    customerRates: [],
    isGettingCustomerRates: false,
    pagination: null,
    linkedCustomerRates: [],
    isGettingLinkedCustomerRates: false,
    customerRate: null,
    isGettingCustomerRate: false,
    customerRateCreated: null,
    customerRateActiveStatusUpdated: null,
    error: null
}

export default function reducer (state = initialState, action) {
    switch (action.type) {
        case actions.GET_CUSTOMER_RATES:
            return {
                ...state,
                customerRates: action.payload.data,
                pagination: {
                    "current_page": action.payload.current_page ?? null,
                    "links": action.payload.links ?? [],
                    "per_page": action.payload.per_page ?? null,
                    "to": action.payload.to ?? null,
                    "total": action.payload.total ?? null
                },
                isGettingCustomerRates: false
            }
        case actions.GET_LINKED_CUSTOMER_RATES:
            return {
                ...state,
                linkedCustomerRates: action.payload.data,
                isGettingLinkedCustomerRates: false
            }
        case actions.GET_CUSTOMER_RATE:
            return {
                ...state,
                customerRate: action.payload.data,
                isGettingCustomerRate: false
            }
        case actions.GETTING_CUSTOMER_RATES:
            return {
                ...state,
                isGettingCustomerRates: true
            }
        case actions.GETTING_LINKED_CUSTOMER_RATES:
            return {
                ...state,
                isGettingLinkedCustomerRates: true
            }
        case actions.GETTING_CUSTOMER_RATE:
            return {
                ...state,
                isGettingCustomerRate: true
            }
        case actions.CUSTOMER_RATE_CREATED:
            return {
                ...state,
                customerRateCreated: action.payload.data
            }
        case actions.CUSTOMER_RATE_ACTIVE_STATUS_UPDATED:
            return {
                ...state,
                customerRateActiveStatusUpdated: action.payload.data
            }
        case actions.CUSTOMER_RATE_ERROR:
            return {
                ...state,
                error: action.payload,
                isGettingCustomerRates: false,
                isGettingLinkedCustomerRates: false,
                isGettingCustomerRate: false
            }
        case actions.RESET_CUSTOMER_RATES:
            return {
                ...state,
                customerRates: [],
                pagination: null
            }
        case actions.RESET_LINKED_CUSTOMER_RATES:
            return {
                ...state,
                linkedCustomerRates: []
            }
        case actions.RESET_CUSTOMER_RATE:
            return {
                ...state,
                customerRate: null
            }
        case actions.RESET_CUSTOMER_RATE_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
