import * as actions from '../../Actions'

const initialState = {
    tags: [],
    isGettingTags: false,
    pagination: null,
    tagCreated: false,
    tagUpdated: false,
    tagDeleted: false,
    error: null
}

export default function reducer (state = initialState, action) {
    switch (action.type) {
        case actions.GET_TAGS:
            return {
                ...state,
                tags: action.payload.data,
                pagination: {
                    "current_page": action.payload.current_page ?? null,
                    "links": action.payload.links ?? [],
                    "per_page": action.payload.per_page ?? null,
                    "to": action.payload.to ?? null,
                    "total": action.payload.total ?? null
                },
                isGettingTags: false
            }
        case actions.GETTING_TAGS:
            return {
                ...state,
                isGettingTags: true
            }
        case actions.TAG_CREATED:
            return {
                ...state,
                tagCreated: action.payload.status
            }
        case actions.TAG_UPDATED:
            return {
                ...state,
                tagUpdated: action.payload.status
            }
        case actions.TAG_DELETED:
            return {
                ...state,
                tagDeleted: action.payload.status
            }
        case actions.TAG_ERROR:
            return {
                ...state,
                error: action.payload,
                isGettingTags: false
            }
        case actions.RESET_TAGS:
            return {
                ...state,
                tags: [],
                pagination: null
            }
        case actions.RESET_TAG_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
