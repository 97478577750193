import {useIntl} from 'react-intl'
import {Search} from './Search'
import {Loader} from './Loader'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleDown} from '@fortawesome/free-solid-svg-icons'

export const Dropdown = ({id = "", text = "", disabled = false, isLoading = false, inputValue= "", inputChange = null, content = null}) => {
    const intl = useIntl()

    const openDropdown = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (!disabled) {
            e.target.closest(".dropdown").classList.toggle("is-active")
        }
    }

    return (
        <div className="dropdown" onClick={(e) => openDropdown(e)}>
            <div className="dropdown-trigger">
                <button className="button" disabled={disabled} aria-haspopup="true" aria-controls={id}>
                    <span>
                        {text}
                    </span>
                    <span className="icon has-text-link">
                        <FontAwesomeIcon icon={faAngleDown} />
                    </span>
                </button>
            </div>
            <div className="dropdown-menu" id={id} role="menu">
                {isLoading ?
                    <div className='has-background-white'>
                        <Loader />
                    </div>
                    :
                    <>
                        {inputChange ?
                            <div className="has-background-white px-4" onClick={(e) => e.stopPropagation()}>
                                <Search placeholder={intl.formatMessage({id: "placeholder.search"})}
                                        value={inputValue}
                                        inputChange={inputChange}
                                />
                            </div>
                            :
                            null
                        }
                        <div className="dropdown-content">
                            {content}
                        </div>
                    </>
                }
            </div>
        </div>
    )
}
