import {Link} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch} from '@fortawesome/free-solid-svg-icons'

export const Search = ({size = "small",
                           type = "text", placeholder = null, value = null, inputChange = null, autofocus = false, maxLength = null,
                           content = null, dataClick = null, dataText = null,
                           isLoading = false, disabledButton = null, buttonClick = null, buttonText = null}) => {
    return (
        <>
            <div className="control">
                <div className={`control has-icons-left ${!buttonClick && isLoading ? 'is-loading' : ''}`}>
                    <input className={"input is-" + size}
                           type={type}
                           placeholder={placeholder}
                           value={value}
                           onChange={inputChange}
                           autoFocus={autofocus}
                           maxLength={maxLength}
                           disabled={!buttonClick && isLoading}
                    />
                    <span className="icon is-left">
                        <FontAwesomeIcon icon={faSearch} />
                    </span>
                </div>
                <nav className="panel">
                    {content?.map((data, i) => (
                        <Link to="#" className="panel-block" key={i}
                              onClick={(e) => dataClick(e, data)}>
                            {dataText(data)}
                        </Link>
                    ))}
                </nav>
            </div>
            {buttonClick ?
                <button className={`button is-link ${'is-' + size} ${isLoading ? 'is-loading' : ''}`}
                        disabled={disabledButton}
                        onClick={buttonClick}>
                    {buttonText}
                </button>
                :
                null
            }
        </>
    )
}
