import {axiosInstance} from '../../../hoc/Axios/Axios'
import * as actions from '../../Actions'
import * as toastTypes from '../../../consts/enums/toastTypes'
import {encodeParameter} from '../../../utils/encodeParameter'

export const getProjects = (manufacturerId, customerId) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_PROJECTS})
    dispatch({type: actions.GETTING_PROJECTS})
    try {
        const url = manufacturerId && customerId ? "/projects/" + manufacturerId + "/" + customerId : "/projects"
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_PROJECTS, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.projects_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.PROJECT_ERROR, payload: error.response})
    }
}

export const getProjectsByName = (name, manufacturerId, customerId) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_PROJECTS})
    dispatch({type: actions.GETTING_PROJECTS})
    try {
        name = encodeParameter(name)
        const url = "/projects/by-name/" + name + "/" + manufacturerId + "/" + customerId
        const res = await axiosInstance.get(url)
        dispatch({type: actions.GET_PROJECTS, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.projects_by_name_do_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.PROJECT_ERROR, payload: error.response})
    }
}

export const getProject = (code, currencyId) => async (dispatch, getState) => {
    dispatch({type: actions.RESET_PROJECTS})
    dispatch({type: actions.GETTING_PROJECTS})
    try {
        const res = await axiosInstance.get("/projects/by-code/" + code + "/" + currencyId)
        dispatch({type: actions.GET_PROJECTS, payload: res.data})
    } catch (error) {
        dispatch({type: actions.PROJECT_ERROR, payload: error.response})
    }
}

export const getProjectsIdcp = (name, customerCode, manufacturerCode) => async (dispatch, getState) => {
    dispatch({type: actions.GETTING_PROJECTS_IDCP})
    try {
        name = encodeParameter(name)
        const res = await axiosInstance.get("/projects-idcp/" + name + "/" + customerCode + "/" + manufacturerCode)
        dispatch({type: actions.GET_PROJECTS_IDCP, payload: res.data})
        if (res.data.data.length === 0) {
            dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.ERROR, message: "error.project_does_not_exist"}})
        }
    } catch (error) {
        dispatch({type: actions.PROJECT_ERROR, payload: error.response})
    }
}
