import {useEffect, useRef, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import * as actions from '../store/Actions'
import {getCustomerRates, getCustomerRatesByCreationDate, getCustomerRatesByCreator, getCustomerRatesByCustomer,
    getCustomerRatesByManufacturer, getCustomerRatesByName, getCustomerRatesByProject, getCustomerRatesByStatus}
    from '../store/reducers/customerRate/customerRateActions'
import {getCustomers} from '../store/reducers/customer/customerActions'
import {getManufacturers} from '../store/reducers/manufacturer/manufacturerActions'
import {getProjects} from '../store/reducers/project/projectActions'
import {Dropdown} from '../components/UI/Dropdown'
import {Datatable} from '../components/Datatable/Datatable'
import {Title} from '../components/UI/Title'
import {Search} from '../components/UI/Search'
import {TableIconButtonView} from '../components/UI/TableIconButtonView'
import {TableIconButtonCopy} from '../components/UI/TableIconButtonCopy'
import {Pagination} from '../components/UI/Pagination'
import {PAGINATION_PAGE_ROWS} from '../consts/app'
import * as userTypes from '../consts/enums/userTypes'
import {createLocaleDateText} from '../utils/createLocaleDateText'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck} from '@fortawesome/free-solid-svg-icons'

export const CustomerRates = () => {
    const navigate = useNavigate()
    const intl = useIntl()
    const dispatch = useDispatch()
    const customerRates = useSelector(state => state.customerRate.customerRates)
    const isGettingCustomerRates = useSelector(state => state.customerRate.isGettingCustomerRates)
    const pagination = useSelector(state => state.customerRate.pagination)
    const projects = useSelector(state => state.project.projects)
    const isGettingProjects = useSelector(state => state.project.isGettingProjects)
    const manufacturers = useSelector(state => state.manufacturer.manufacturers)
    const isGettingManufacturers = useSelector(state => state.manufacturer.isGettingManufacturers)
    const customers = useSelector(state => state.customer.customers)
    const isGettingCustomers = useSelector(state => state.customer.isGettingCustomers)
    const locale = useSelector(state => state.locale.locale)
    const userType = useSelector(state => state.auth.user.role)
    const [customerRateFilter, setCustomerRateFilter] = useState("all")
    const [nameFilter, setNameFilter] = useState("")
    const [selectedCustomer, setSelectedCustomer] = useState("")
    const [creationDateFilter, setCreationDateFilter] = useState("")
    const [creatorFilter, setCreatorFilter] = useState("")
    const [statusFilter, setStatusFilter] = useState("")
    const searchType = useRef({type: "all", search: ""})
    const sortType = useRef({sort: "rates.is_active", order: "desc"})

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = () => {
        setCustomerRateFilter("all")
        searchType.current = {type: "all", search: ""}
        dispatch(getCustomerRates(sortType.current.sort, sortType.current.order, 1))
    }

    const resetData = () => {
        setNameFilter("")
        setSelectedCustomer("")
        setCreationDateFilter("")
        setCreatorFilter("")
        setStatusFilter("")
        dispatch({type: actions.RESET_CUSTOMERS})
        dispatch({type: actions.RESET_MANUFACTURERS})
        dispatch({type: actions.RESET_PROJECTS})
    }

    const filterCustomerRateByName = (e) => {
        e.preventDefault()
        searchType.current = {type: "name", search: nameFilter}
        getCustomerRatesPerPage(1)
    }

    const filterCustomerRatesByCustomer = (id) => {
        searchType.current = {type: customerRateFilter, search: id}
        getCustomerRatesPerPage(1)
    }

    const filterCustomerRateByCreationDate = (e) => {
        e.preventDefault()
        searchType.current = {type: "creation_date", search: creationDateFilter}
        getCustomerRatesPerPage(1)
    }

    const filterCustomerRateByCreator = (e) => {
        e.preventDefault()
        searchType.current = {type: "creator", search: creatorFilter}
        getCustomerRatesPerPage(1)
    }

    const filterCustomerRateByStatus = (status) => {
        searchType.current = {type: "status", search: status}
        getCustomerRatesPerPage(1)
    }

    const sortCustomerRates = (column, sortDirection) => {
        sortType.current = {sort: column.sortField, order: sortDirection}
        getCustomerRatesPerPage(1)
    }

    const getCustomerRatesPerPage = (page) => {
        switch (searchType.current.type) {
            case "name":
                dispatch(getCustomerRatesByName(searchType.current.search, sortType.current.sort, sortType.current.order, page))
                break
            case "customer":
                dispatch(getCustomerRatesByCustomer(searchType.current.search, sortType.current.sort, sortType.current.order, page))
                break
            case "manufacturer":
                dispatch(getCustomerRatesByManufacturer(searchType.current.search, sortType.current.sort, sortType.current.order, page))
                break
            case "project":
                dispatch(getCustomerRatesByProject(searchType.current.search, sortType.current.sort, sortType.current.order, page))
                break
            case "creation_date":
                dispatch(getCustomerRatesByCreationDate(searchType.current.search, sortType.current.sort, sortType.current.order, page))
                break
            case "creator":
                dispatch(getCustomerRatesByCreator(searchType.current.search, sortType.current.sort, sortType.current.order, page))
                break
            case "status":
                dispatch(getCustomerRatesByStatus(searchType.current.search, sortType.current.sort, sortType.current.order, page))
                break
            default:
                dispatch(getCustomerRates(sortType.current.sort, sortType.current.order, page))
        }
    }

    const showCreateCustomerRate = (e) => {
        e.preventDefault()
        navigate("/customer-rates/select-customer")
    }

    const showViewCustomerRate = (customerRate, e) => {
        e.preventDefault()
        e.stopPropagation()
        navigate("/customer-rates/" + customerRate.id, {state: {customerRate: customerRate}})
    }

    const copyCustomerRate = (e, customerRate) => {
        e.preventDefault()
        e.stopPropagation()
        navigate("/customer-rates/select-customer", {state: {customerRate: customerRate}})
    }

    const CustomerRatesTable = () => {
        const columns = [
            {
                name: intl.formatMessage({id: "customer_rate.name"}),
                selector: row => row.name,
                wrap: true,
                sortable: true,
                sortField: 'customer_rates.name'
            },
            {
                name: intl.formatMessage({id: "customer_rate.customer"}),
                selector: row => row.customer_name + " (" + row.customer_type + ")",
                wrap: true,
                sortable: true,
                sortField: 'customers.name'
            },
            {
                name: intl.formatMessage({id: "customer_rate.manufacturer"}),
                selector: row => row.manufacturer_name,
                wrap: true,
                sortable: true,
                sortField: 'manufacturers.name'
            },
            {
                name: intl.formatMessage({id: "customer_rate.project"}),
                selector: row => row.project_name,
                wrap: true,
                sortable: true,
                sortField: 'projects.name'
            },
            {
                name: intl.formatMessage({id: "customer_rate.created_at"}),
                selector: row => createLocaleDateText(locale.name, row.created_at.substring(0, 10)),
                wrap: true,
                sortable: true,
                sortField: 'customer_rates.created_at'
            },
            {
                name: intl.formatMessage({id: "customer_rate.created_by"}),
                selector: row => row.user_username,
                wrap: true,
                sortable: true,
                sortField: 'users.username'
            },
            {
                name: intl.formatMessage({id: "standard_rate.active"}),
                selector: row => (
                    row.rate_is_active ?
                        <span className="icon has-text-success">
                            <FontAwesomeIcon icon={faCheck} />
                        </span>
                        :
                        null
                ),
                center: true,
                wrap: true,
                sortable: true,
                sortField: 'rates.is_active',
                grow: 0.1
            },
            {
                selector: row => (
                    <>
                        <TableIconButtonView handler={(e) => showViewCustomerRate(row, e)} />
                        <TableIconButtonCopy handler={(e) => copyCustomerRate(e, row)} />
                    </>
                ),
                right: true,
                wrap: true,
                grow: 0.1
            }
        ]
        return (
            <Datatable
                columns={columns}
                customStyles={{rows: {style: {cursor: 'pointer'}}}}
                data={customerRates}
                defaultSortAsc={sortType.current.order !== "asc"}
                onRowClicked={showViewCustomerRate}
                onSort={sortCustomerRates}
                paginationComponent={() => <Pagination pagination={pagination} handler={getCustomerRatesPerPage} />}
                paginationPerPage={pagination?.per_page ?? PAGINATION_PAGE_ROWS}
                progressPending={isGettingCustomerRates}
                sortServer
            />
        )
    }

    return (
        <>
            <Title title={intl.formatMessage({id: "title.customer_rates"})} />
            <div className="container">
                {userType !== userTypes.RATE_VIEWER ?
                    <div className="level">
                        <div className="level-left">
                            <div className="level-item">
                                <button className="button is-link"
                                        onClick={(e) => showCreateCustomerRate(e)}>
                                    <FormattedMessage id="customer_rate.new_customer_rate"/>
                                </button>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <label className="label">
                                <FormattedMessage id="general.filter_by" />:
                            </label>
                        </div>
                        <div className="level-item mr-0">
                            <Dropdown id="customerRateFilterDropdown"
                                      text={customerRateFilter ? intl.formatMessage({id: "customer_rate." + customerRateFilter}) : ""}
                                      content={<>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    resetData()
                                                    getData()
                                                }}>
                                              <FormattedMessage id={"customer_rate.all"} />
                                          </Link>
                                          <hr className="dropdown-divider" />
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setCustomerRateFilter("name")
                                                    resetData()
                                                }}>
                                              <FormattedMessage id={"customer_rate.name"} />
                                          </Link>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setCustomerRateFilter("customer")
                                                    resetData()
                                                    dispatch(getCustomers())
                                                }}>
                                              <FormattedMessage id={"customer_rate.customer"} />
                                          </Link>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setCustomerRateFilter("manufacturer")
                                                    resetData()
                                                    dispatch(getManufacturers())
                                                }}>
                                              <FormattedMessage id={"customer_rate.manufacturer"} />
                                          </Link>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setCustomerRateFilter("project")
                                                    resetData()
                                                    dispatch(getProjects())
                                                }}>
                                              <FormattedMessage id={"customer_rate.project"} />
                                          </Link>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setCustomerRateFilter("creation_date")
                                                    resetData()
                                                }}>
                                              <FormattedMessage id={"customer_rate.creation_date"} />
                                          </Link>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setCustomerRateFilter("creator")
                                                    resetData()
                                                }}>
                                              <FormattedMessage id={"customer_rate.creator"} />
                                          </Link>
                                          <Link to="#" className="dropdown-item"
                                                onClick={() => {
                                                    setCustomerRateFilter("status")
                                                    resetData()
                                                }}>
                                              <FormattedMessage id={"customer_rate.status"} />
                                          </Link>
                                      </>}
                            />
                        </div>
                        <div className="level-item">
                            <div className="field has-addons">
                                {customerRateFilter === "name" ?
                                    <Search size={"normal"}
                                            placeholder={intl.formatMessage({id: "placeholder.filter_by_name"})}
                                            value={nameFilter}
                                            inputChange={(e) => setNameFilter(e.target.value)}
                                            maxLength={100}
                                            isLoading={(isGettingCustomerRates && nameFilter.trim() !== "")}
                                            disabledButton={nameFilter.trim() === ""}
                                            buttonClick={(e) => filterCustomerRateByName(e)}
                                            buttonText={intl.formatMessage({id: "general.search"})}
                                    />
                                    :
                                    customerRateFilter === "customer" || customerRateFilter === "manufacturer" || customerRateFilter === "project" ?
                                        <Dropdown id="customerRateCustomersDropdown"
                                                  text={selectedCustomer ? selectedCustomer : "-----"}
                                                  isLoading={isGettingCustomers || isGettingManufacturers || isGettingProjects}
                                                  content={<>
                                                      {customers?.length > 0 ?
                                                          <>
                                                              {customers?.map((customer, i) => (
                                                                  <Link to="#" className="dropdown-item" key={i}
                                                                        onClick={() => {
                                                                            setSelectedCustomer(customer.name)
                                                                            filterCustomerRatesByCustomer(customer.id)
                                                                        }}>
                                                                      {customer.name}
                                                                  </Link>
                                                              ))}
                                                          </>
                                                          :
                                                          manufacturers?.length > 0 ?
                                                              <>
                                                                  {manufacturers.map((manufacturer, i) => (
                                                                      <Link to="#" className="dropdown-item" key={i}
                                                                            onClick={() => {
                                                                                setSelectedCustomer(manufacturer.name)
                                                                                filterCustomerRatesByCustomer(manufacturer.id)
                                                                            }}>
                                                                          {manufacturer.name}
                                                                      </Link>
                                                                  ))}
                                                              </>
                                                              :
                                                              projects?.length > 0 ?
                                                                  <>
                                                                      {projects.map((project, i) => (
                                                                          <Link to="#" className="dropdown-item" key={i}
                                                                                onClick={() => {
                                                                                    setSelectedCustomer(project.name)
                                                                                    filterCustomerRatesByCustomer(project.id)
                                                                                }}>
                                                                              {project.name}
                                                                          </Link>
                                                                      ))}
                                                                  </>
                                                                  :
                                                                  null
                                                      }
                                                  </>}
                                        />
                                        :
                                        customerRateFilter === "creation_date" ?
                                            <>
                                                <div className="control">
                                                    <input className="input"
                                                           type="date"
                                                           value={creationDateFilter}
                                                           onChange={(e) => setCreationDateFilter(e.target.value)}
                                                    />
                                                </div>
                                                <button className={`button is-link ${isGettingCustomerRates &&
                                                creationDateFilter.trim() !== "" ? 'is-loading' : ''}`}
                                                        disabled={creationDateFilter.trim() === ""}
                                                        onClick={(e) => filterCustomerRateByCreationDate(e)}>
                                                    <FormattedMessage id="general.search" />
                                                </button>
                                            </>
                                            :
                                            customerRateFilter === "creator" ?
                                                <Search size={"normal"}
                                                        placeholder={intl.formatMessage({id: "placeholder.filter_by_creator"})}
                                                        value={creatorFilter}
                                                        inputChange={(e) => setCreatorFilter(e.target.value)}
                                                        maxLength={47}
                                                        isLoading={(isGettingCustomerRates && creatorFilter.trim() !== "")}
                                                        disabledButton={creatorFilter.trim() === ""}
                                                        buttonClick={(e) => filterCustomerRateByCreator(e)}
                                                        buttonText={intl.formatMessage({id: "general.search"})}
                                                />
                                                :
                                                customerRateFilter === "status" ?
                                                    <Dropdown id="customerRateStatusDropdown"
                                                              text={statusFilter ? intl.formatMessage({id: "customer_rate." + statusFilter}) : ""}
                                                              content={<>
                                                                  <Link to="#" className="dropdown-item"
                                                                        onClick={() => {
                                                                            setStatusFilter("active")
                                                                            filterCustomerRateByStatus(true)
                                                                        }}>
                                                                      <FormattedMessage id={"customer_rate.active"} />
                                                                  </Link>
                                                                  <Link to="#" className="dropdown-item"
                                                                        onClick={() => {
                                                                            setStatusFilter("not_active")
                                                                            filterCustomerRateByStatus(false)
                                                                        }}>
                                                                      <FormattedMessage id={"customer_rate.not_active"} />
                                                                  </Link>
                                                              </>}
                                                    />
                                                    :
                                                    null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <CustomerRatesTable />
            </div>
        </>
    )
}
