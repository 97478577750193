import {axiosInstance} from '../../../hoc/Axios/Axios'
import * as actions from '../../Actions'
import * as toastTypes from "../../../consts/enums/toastTypes";

export const login = (user) => async (dispatch, getState) => {
    try {
        const res = await axiosInstance.post("/auth/login", user)
        dispatch({type: actions.LOGIN, payload: res.data})
        dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.SUCCESS, message: "message.welcome"}})
    } catch (error) {
        dispatch({type: actions.LOGIN_ERROR, payload: error.response})
    }
}

export const logout = () => async (dispatch, getState) => {
    dispatch({type: actions.LOGOUT})
    dispatch({type: actions.SHOW_TOAST, payload: {type: toastTypes.INFO, message: "message.logout"}})
    try {
        await axiosInstance.post("/auth/logout")
    } finally {
        dispatch({type: actions.RESET})
    }
}
