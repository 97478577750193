import {useEffect, useRef, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import * as actions from '../../store/Actions'
import {getAllPrices, getPrices} from '../../store/reducers/price/priceActions'
import {DraggableModal} from '../UI/DraggableModal'
import {STANDARD_CURRENCY_CODE} from '../../consts/app'
import {roundDecimalNumber} from '../../utils/roundDecimalNumber'

export const ModalAddPair = ({title = "", additionalData = null}) => {
    const dispatch = useDispatch()
    const activeStandardRates = useSelector(state => state.rate.activeStandardRates)
    const allPrices = useSelector(state => state.price.prices)
    const isGettingPrices = useSelector(state => state.price.isGettingPrices)
    const allStandardRatePrices = useSelector(state => state.price.allPrices)
    const isGettingAllPrices = useSelector(state => state.price.isGettingAllPrices)
    const [prices, setPrices] = useState([])
    const [price, setPrice] = useState("")
    const [activeStandardRate] = useState(activeStandardRates.length > 0 ?
        activeStandardRates.find(standardRate => standardRate.currency_code === STANDARD_CURRENCY_CODE) ??
        activeStandardRates[0] : null)
    const allPricesExist = useRef(additionalData.allPrices.length > 0)
    const allStandardRatePricesExist = useRef(!additionalData.standard ?
        !!additionalData.allPrices.find(price => price.rate_id === activeStandardRate?.id) : true)

    useEffect(() => {
        if (!allPricesExist.current) {
            dispatch(getPrices(additionalData.rateId))
        }
        if (!allStandardRatePricesExist.current) {
            dispatch(getAllPrices(activeStandardRate?.id))
            allPricesExist.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!isGettingPrices && !isGettingAllPrices && allPricesExist.current) {
            additionalData.allPrices = additionalData.allPrices.length > 0 ? [...additionalData.allPrices] : [...allPrices]
            if (!allStandardRatePricesExist.current) {
                for (let i = 0; i < allStandardRatePrices.length; i++) {
                    if (!additionalData.allPrices.find(price => price.translation_id === allStandardRatePrices[i].translation_id &&
                        price.unit_id === allStandardRatePrices[i].unit_id)) {
                        additionalData.allPrices = [...additionalData.allPrices, allStandardRatePrices[i]]
                    }
                }
                sortPrices()
            }
            additionalData.setAllPrices([...additionalData.allPrices])
            filterPrices()
        }
        allPricesExist.current = true
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allPrices, allStandardRatePrices])

    const sortPrices = () => {
        additionalData.allPrices.sort((price1, price2) => {
            return price1.translation_target_language > price2.translation_target_language ? 1 :
                price1.translation_target_language < price2.translation_target_language ? -1 : 0
        }).sort((price1, price2) => {
            return price1.translation_source_language > price2.translation_source_language ? 1 :
                price1.translation_source_language < price2.translation_source_language ? -1 : 0
        })
    }

    const onHideClick = (e) => {
        e.preventDefault()
        dispatch({type: actions.HIDE_MODAL})
    }

    const filterPrices = () => {
        const prices = additionalData.allPrices.filter(priceAvailable => !additionalData.prices.find(price => price.id === priceAvailable.id))
        setPrices([...prices])
    }

    const createTextLanguagePair = (price) => {
        return price.translation_source_language +
            (price.translation_code_source_language ? " (" + price.translation_code_source_language + ")" : "") + " - " +
            price.translation_target_language +
            (price.translation_code_target_language ? " (" + price.translation_code_target_language + ")" : "") +
            " (" + price.unit_type + ")"
    }

    const add = (e) => {
        e.preventDefault()
        const prices = JSON.parse(JSON.stringify(additionalData.allPrices.filter(priceAvailable =>
            additionalData.prices.find(price => price.id === priceAvailable.id) || parseInt(price) === priceAvailable.id)))
        for (let i = 0; i < prices.length; i++) {
            const priceIndex = additionalData.prices.indexOf(additionalData.prices.find(price => price.id === prices[i].id))
            if (priceIndex > -1) {
                prices[i] = additionalData.prices[priceIndex]
            }
        }
        if (additionalData.exchangeRateValue || additionalData.valueExchangeRate) {
            const priceIndex = prices.indexOf(prices.find(p => p.id === parseInt(price)))
            if (priceIndex > -1) {
                let exchangeRate = roundDecimalNumber(additionalData.valueExchangeRate)
                exchangeRate = isNaN(exchangeRate) ? 1 : exchangeRate <= 0 ? 1 : exchangeRate
                prices[priceIndex] = {
                    ...prices[priceIndex],
                    total: prices[priceIndex].total /
                        (prices[priceIndex].rate_id === additionalData.rateId ? additionalData.exchangeRateValue : 1) *
                        exchangeRate
                }
            }
        }
        additionalData.getNewPrices(prices, additionalData.allPrices)
        dispatch({type: actions.HIDE_MODAL})
    }

    const Body = () => {
        return (
            <div className={`select ${isGettingPrices || isGettingAllPrices ? 'is-loading' : ''}`}>
                <select onChange={(e) => setPrice(e.target.value)}>
                    <option value="">
                        -----
                    </option>
                    {prices?.map((price, i) => (
                        <option key={i} value={price.id}>
                            {createTextLanguagePair(price)}
                        </option>
                    ))}
                </select>
            </div>
        )
    }

    const Foot = () => {
        return (
            <>
                <button className="button is-link"
                        disabled={price === ""}
                        onClick={(e) => add(e)}>
                    <FormattedMessage id="general.add" />
                </button>
                <button className="button"
                        onClick={(e) => onHideClick(e)}>
                    <FormattedMessage id="general.cancel" />
                </button>
            </>
        )
    }

    return (
        <DraggableModal title={title} body={Body()} foot={Foot()} onHideClick={onHideClick} />
    )
}
