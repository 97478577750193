import {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import * as actions from '../../store/Actions'
import {DraggableModal} from '../UI/DraggableModal'
import * as toastTypes from '../../consts/enums/toastTypes'
import * as catToolBreakTypes from '../../consts/enums/catToolBreakTypes'
import {CAT_TOOL_BREAK_TYPES} from '../../consts/app'
import {roundDecimalNumber} from '../../utils/roundDecimalNumber'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPercent} from '@fortawesome/free-solid-svg-icons'

export const ModalNewPercentage = ({title = "", newRatePercentage = null, standardRateId = null}) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const [catToolBreak, setCatToolBreak] = useState("")
    const [matchPercentMin, setMatchPercentMin] = useState("")
    const [matchPercentMax, setMatchPercentMax] = useState("")
    const [percent, setPercent] = useState("")
    const [description, setDescription] = useState("")

    const onHideClick = (e) => {
        e.preventDefault()
        dispatch({type: actions.HIDE_MODAL})
    }

    const hasRange = () => {
        return catToolBreak === catToolBreakTypes.NEW_WORDS || catToolBreak === catToolBreakTypes.FUZZY_MATCHES
    }

    const createPercentage = (e) => {
        e.preventDefault()
        if (hasRange() && parseInt(matchPercentMin) > parseInt(matchPercentMax)) {
            dispatch({type: actions.SHOW_TOAST, payload: {type:toastTypes.ERROR, message: "error.percent_min_greater_than_percent_max"}})
        } else {
            const percentage = {
                name: catToolBreak,
                match_percent_min: parseInt(matchPercentMin),
                match_percent_max: parseInt(matchPercentMax),
                percent: roundDecimalNumber(percent),
                description: description,
                rate_id: standardRateId
            }
            if (description.trim() === "") {
                delete percentage.description
            }
            if (!hasRange()) {
                delete percentage.match_percent_min
                delete percentage.match_percent_max
            }
            newRatePercentage(percentage)
        }
    }

    const Body = () => {
        return (
            <>
                <div className="field">
                    <label className="label">
                        <FormattedMessage id="cat_tool_break.cat_tool_break" />
                        <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                        <span>:</span>
                    </label>
                    <div className="select">
                        <select onChange={(e) => {
                            setCatToolBreak(e.target.value)
                            setMatchPercentMin("")
                            setMatchPercentMax("")
                        }}>
                            <option value="">
                                -----
                            </option>
                            {CAT_TOOL_BREAK_TYPES?.map((type, i) => (
                                <option key={i} value={type}>
                                    {type}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                {hasRange() ?
                    <div className="field">
                        <label className="label">
                            <FormattedMessage id="cat_tool_break.range" />
                            <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                            <span>:</span>
                        </label>
                        <div className="columns">
                            <div className="column is-one-fifth">
                                <div className="control has-icons-right">
                                    <input className="input"
                                           type="number"
                                           placeholder={intl.formatMessage({id: "placeholder.min"})}
                                           value={matchPercentMin}
                                           onChange={(e) => setMatchPercentMin(e.target.value)}
                                           autoFocus
                                           min={0}
                                           max={99}
                                           onWheel={(e) => e.target.blur()}
                                    />
                                    <span className="icon is-right">
                                        <FontAwesomeIcon icon={faPercent} />
                                    </span>
                                </div>
                            </div>
                            <div className="column is-one-fifth">
                                <div className="control has-icons-right">
                                    <input className="input"
                                           type="number"
                                           placeholder={intl.formatMessage({id: "placeholder.max"})}
                                           value={matchPercentMax}
                                           onChange={(e) => setMatchPercentMax(e.target.value)}
                                           min={0}
                                           max={99}
                                           onWheel={(e) => e.target.blur()}
                                    />
                                    <span className="icon is-right">
                                        <FontAwesomeIcon icon={faPercent} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
                <div className="field">
                    <label className="label">
                        <FormattedMessage id="cat_tool_break.percent" />
                        <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                        <span>:</span>
                    </label>
                    <div className="columns">
                        <div className="column is-one-third">
                            <div className="control has-icons-right">
                                <input className="input"
                                       type="number"
                                       placeholder={intl.formatMessage({id: "placeholder.percent"})}
                                       value={percent}
                                       onChange={(e) => setPercent(e.target.value)}
                                       min={0}
                                       max={9999999}
                                       step={0.1}
                                       onWheel={(e) => e.target.blur()}
                                />
                                <span className="icon is-right">
                                    <FontAwesomeIcon icon={faPercent} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="field">
                    <label className="label">
                        <FormattedMessage id="cat_tool_break.description" />:
                    </label>
                    <div className="control">
                        <textarea className="textarea has-fixed-size"
                                  value={description}
                                  onChange={(e) => setDescription(e.target.value)}
                                  maxLength={255}
                        />
                    </div>
                </div>
            </>
        )
    }

    const Foot = () => {
        return (
            <>
                <button className="button is-link"
                        disabled={catToolBreak === "" || percent.trim() === "" ||
                            (hasRange() && (matchPercentMin.trim() === "" || matchPercentMax.trim() === ""))}
                        onClick={(e) => createPercentage(e)}>
                    <FormattedMessage id="general.save" />
                </button>
                <button className="button"
                        onClick={(e) => onHideClick(e)}>
                    <FormattedMessage id="general.cancel" />
                </button>
            </>
        )
    }

    return (
        <DraggableModal title={title} body={Body()} foot={Foot()} onHideClick={onHideClick} />
    )
}
