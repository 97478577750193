import {useIntl} from 'react-intl'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrash} from '@fortawesome/free-solid-svg-icons'

export const TableIconButtonDelete = ({handler = null}) => {
    const intl = useIntl()

    return (
        <button className="button is-small is-white" title={intl.formatMessage({id: "general.delete"})}
                onClick={handler}>
            <span className="icon has-text-danger-dark">
                <FontAwesomeIcon icon={faTrash} />
            </span>
        </button>
    )
}
