import * as actions from '../../Actions'

const initialState = {
    languagePairs: [],
    sourceLanguagesIdcp: [],
    targetLanguagesIdcp: [],
    isGettingLanguagePairs: false,
    isGettingSourceLanguagesIdcp: false,
    isGettingTargetLanguagesIdcp: false,
    languagePairCreated: null,
    isCreatingLanguagePair: false,
    error: null
}

export default function reducer (state = initialState, action) {
    switch (action.type) {
        case actions.GET_LANGUAGE_PAIRS:
            return {
                ...state,
                languagePairs: action.payload.data,
                isGettingLanguagePairs: false
            }
        case actions.GET_SOURCE_LANGUAGES_IDCP:
            return {
                ...state,
                sourceLanguagesIdcp: action.payload.data,
                isGettingSourceLanguagesIdcp: false
            }
        case actions.GET_TARGET_LANGUAGES_IDCP:
            return {
                ...state,
                targetLanguagesIdcp: action.payload.data,
                isGettingTargetLanguagesIdcp: false
            }
        case actions.GETTING_LANGUAGE_PAIRS:
            return {
                ...state,
                isGettingLanguagePairs: true
            }
        case actions.GETTING_SOURCE_LANGUAGES_IDCP:
            return {
                ...state,
                isGettingSourceLanguagesIdcp: true
            }
        case actions.GETTING_TARGET_LANGUAGES_IDCP:
            return {
                ...state,
                isGettingTargetLanguagesIdcp: true
            }
        case actions.LANGUAGE_PAIR_CREATED:
            return {
                ...state,
                languagePairCreated: action.payload.data
            }
        case actions.CREATING_LANGUAGE_PAIR:
            return {
                ...state,
                isCreatingLanguagePair: action.payload.status
            }
        case actions.LANGUAGE_PAIR_ERROR:
            return {
                ...state,
                error: action.payload,
                isGettingLanguagePairs: false,
                isGettingSourceLanguagesIdcp: false,
                isGettingTargetLanguagesIdcp: false
            }
        case actions.RESET_LANGUAGE_PAIRS:
            return {
                ...state,
                languagePairs: []
            }
        case actions.RESET_LANGUAGES_IDCP:
            return {
                ...state,
                sourceLanguagesIdcp: [],
                targetLanguagesIdcp: []
            }
        case actions.RESET_LANGUAGE_PAIR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}
