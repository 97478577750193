import {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import * as actions from '../../store/Actions'
import {storeTag} from '../../store/reducers/tag/tagActions'
import {DraggableModal} from '../UI/DraggableModal'
import * as modalTypes from '../../consts/enums/modalTypes'
import {TAG_TYPES} from '../../consts/app'

export const ModalNewTag = ({title = ""}) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const [name, setName] = useState("")
    const [type, setType] = useState("")

    const onHideClick = (e) => {
        e.preventDefault()
        dispatch({type: actions.HIDE_MODAL})
    }

    const createTag = (e) => {
        e.preventDefault()
        const tag = {
            name: name,
            type: type
        }
        dispatch(storeTag(tag))
        dispatch({type: actions.HIDE_MODAL})
        dispatch({type: actions.SHOW_MODAL, payload: {type: modalTypes.LOADING}})
    }

    const Body = () => {
        return (
            <>
                <div className="field">
                    <label className="label">
                        <FormattedMessage id="tag.name" />
                        <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                        <span>:</span>
                    </label>
                    <div className="columns">
                        <div className="column is-one-third">
                            <div className="control">
                                <input className="input"
                                       type="text"
                                       placeholder={intl.formatMessage({id: "placeholder.name"})}
                                       value={name}
                                       onChange={(e) => setName(e.target.value)}
                                       autoFocus
                                       maxLength={50}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="field">
                    <label className="label">
                        <FormattedMessage id="tag.type" />
                        <span className="has-text-danger-dark" title={intl.formatMessage({id: "general.required"})}>*</span>
                        <span>:</span>
                    </label>
                    <div className="select">
                        <select onChange={(e) => setType(e.target.value)}>
                            <option value="">
                                -----
                            </option>
                            {TAG_TYPES?.map((type, i) => (
                                <option key={i} value={type}>
                                    {intl.formatMessage({id: "tag_type." + type})}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </>
        )
    }

    const Foot = () => {
        return (
            <>
                <button className="button is-link"
                        disabled={name.trim() === "" || type.trim() === ""}
                        onClick={(e) => createTag(e)}>
                    <FormattedMessage id="general.save" />
                </button>
                <button className="button"
                        onClick={(e) => onHideClick(e)}>
                    <FormattedMessage id="general.cancel" />
                </button>
            </>
        )
    }

    return (
        <DraggableModal title={title} body={Body()} foot={Foot()} onHideClick={onHideClick} />
    )
}
