export const Modal = ({title = "", body = null, foot = null, onHideClick = null}) => {
    return (
        <div className="modal-card">
            <header className="modal-card-head">
                <p className="modal-card-title">
                    {title}
                </p>
                <button className="delete" aria-label="close" onClick={(e) => onHideClick(e)} />
            </header>
            <section className="modal-card-body">
                {body}
            </section>
            <footer className="modal-card-foot">
                {foot}
            </footer>
        </div>
    )
}
